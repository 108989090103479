import { styled, Box, Typography } from '@material-ui/core';
import { HelpRounded } from '@material-ui/icons';
import React, { FC } from 'react';

import { COLORS } from '../../config/colors';
import {
  formatStringifyNumberToDot,
  getEndEpochDate,
} from '../../utils/helpers';
import { EpochToolTipText } from '../../utils/toolTipTexts';
import { RowTitle } from '../shared/styles/DashboardStyles';
import { useStyles } from '../shared/styles/GlobalSections';
import { Tooltip } from '../shared/Tooltip';
import SmallBoldTypography from '../shared/Typography';

const monthNames = [
  'Jan.',
  'Feb.',
  'Mar.',
  'Apr.',
  'May',
  'June',
  'Jule',
  'Aug.',
  'Sep.',
  'Oct.',
  'Nov.',
  'Dec.',
];

const formatMonth = (date: Date) => {
  return monthNames[date.getUTCMonth()];
};

export const EpochInfo: React.FC<{
  type: 'previous' | 'actual';
  end?: Date;
  epochNumber: number;
  eligiblePool: string;
  rewardAllocation: string;
  yieldUSDC: string;
  apr: string;
  totalUSDCRewardValue: string;
}> = ({
  end,
  type,
  epochNumber,
  eligiblePool,
  rewardAllocation,
  yieldUSDC,
  totalUSDCRewardValue,
  apr,
}) => {
  const endDate = end ? end : getEndEpochDate(epochNumber).epochEndDate;
  const isPrevious = type === 'previous';
  return (
    <Container isPrev={isPrevious}>
      <EpochNumber style={{ marginRight: '-0.8em' }}>
        #{epochNumber < 10 ? `0${epochNumber}` : epochNumber}
      </EpochNumber>
      <EpochWrapper>
        <InfoRow
          value={formatStringifyNumberToDot(eligiblePool).slice(0, -5)}
          title={
            isPrevious ? 'Eligible Pool (USDC):' : 'Max. Eligible Pool (USDC):'
          }
          isPrev={isPrevious}
        />
        <HorizontalDivider />

        {epochNumber > 4 ? (
          <InfoRow
            value={totalUSDCRewardValue}
            title={'Boosted Rewards(USDC):'}
            isPrev={isPrevious}
          />
        ) : (
          <InfoRow
            value={formatStringifyNumberToDot(rewardAllocation).slice(0, -5)}
            title={'Reward Allocation (BUMP):'}
            isPrev={isPrevious}
          />
        )}

        <HorizontalDivider />
        {epochNumber < 5 ? (
          <InfoRow
            value={yieldUSDC}
            title={isPrevious ? 'USDC Yield:' : 'USDC Yield (14-day avg. APY):'}
            isPrev={isPrevious}
            tooltipText={EpochToolTipText}
          />
        ) : null}

        <HorizontalDivider />
        <InfoRow
          value={apr}
          title={'Total Epoch Reward (APR):'}
          isPrev={isPrevious}
        />
        <HorizontalDivider />
        <InfoRow
          value={`${formatMonth(endDate)} ${endDate.getDate()}, 12pm UTC`}
          title={'End Date:'}
          isPrev={isPrevious}
        />
      </EpochWrapper>
    </Container>
  );
};

const InfoRow: FC<{
  title: string;
  value: string;
  isPrev: boolean;
  tooltipText?: string;
}> = ({ title, value, isPrev, tooltipText }) => {
  const classes = useStyles();
  return (
    <Row isPrev={isPrev}>
      <RowTitle>
        {title}
        {tooltipText && !isPrev && (
          <Tooltip title={tooltipText}>
            <HelpRounded className={classes.icon} />
          </Tooltip>
        )}
      </RowTitle>
      <RowValue>{value}</RowValue>
    </Row>
  );
};
const Row = styled(Box)(({ isPrev }: any) => ({
  display: 'flex',
  alignItems: 'center',
  opacity: isPrev ? 0.7 : 1,
  width: '100%',
  gap: '8px',

  '&:not(:last-child)': {
    marginBottom: '1rem',
  },
  '&:not(:first-child)': {
    marginTop: '1rem',
  },
}));
const RowValue = styled(SmallBoldTypography)(({ theme }) => ({
  color: COLORS.Primary2,
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 700,

  [theme.breakpoints.down('xs')]: {
    fontSize: '14px',
  },
}));

const Container = styled(Box)(({ theme, isPrev }: any) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  height: 'fit-content',
  color: isPrev ? 'rgba(255, 255, 255, 0.7)' : COLORS.White,
  backgroundColor: COLORS.Green3,
  border: `2px solid ${isPrev ? COLORS.Border : COLORS.Primary2}`,
  padding: `44px 20px`,
  boxSizing: 'border-box',

  [theme.breakpoints.down('sm')]: {
    padding: '44px 60px 54px',
    width: '100%',
  },
}));
const EpochNumber = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  bottom: '20px',
  right: '44.5px',
  color: COLORS.EpochNumber,
  width: 'fit-content',
  height: 'fit-content',
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '24px',
  [theme.breakpoints.down('sm')]: {
    bottom: '28px',
    right: '20%',
  },
}));
const EpochWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));
const HorizontalDivider = styled('div')({
  height: '1px',
  width: '100%',
  backgroundColor: COLORS.Divider,
});
