import { Step, StepLabel } from '@material-ui/core';
import React from 'react';

import {
  PixelClaimStepperIcon,
  PixelStepper,
  PixelStepperDefaultIcon,
  PixelStepperIcon,
} from '../shared/Stepper';

interface IAddLiquidityStepperProps {
  activeStep: number;
  isNeedPresale?: boolean;
  isClaimStake?: boolean;
}

export const Stepper: React.FC<IAddLiquidityStepperProps> = (props) => {
  const StepperIcon = props.isNeedPresale
    ? PixelStepperIcon
    : PixelStepperDefaultIcon;
  return props.isClaimStake ? (
    <PixelStepper activeStep={props.activeStep}>
      <Step key="Claim">
        <StepLabel StepIconComponent={PixelClaimStepperIcon}></StepLabel>
      </Step>
      <Step key="Staking">
        <StepLabel StepIconComponent={PixelClaimStepperIcon}></StepLabel>
      </Step>
      <Step key="Confirm">
        <StepLabel StepIconComponent={PixelClaimStepperIcon}></StepLabel>
      </Step>
    </PixelStepper>
  ) : (
    <PixelStepper activeStep={props.activeStep}>
      <Step key="Details">
        <StepLabel StepIconComponent={StepperIcon}></StepLabel>
      </Step>
      {props.isNeedPresale && (
        <Step key="Presale">
          <StepLabel StepIconComponent={StepperIcon}></StepLabel>
        </Step>
      )}
      <Step key="Confirm">
        <StepLabel StepIconComponent={StepperIcon}></StepLabel>
      </Step>
    </PixelStepper>
  );
};
