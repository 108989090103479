import { Box, styled } from '@material-ui/core';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ClaimDetails } from './ClaimDetails';
import { ConfirmClaimProps } from './ConfirmClaimSection';

import { BUMP, USDCoin } from '../../config/coins';
import { Routes, subRoutes } from '../../config/routes';
import { ICoin } from '../../interfaces/ICoin';
import { PixelButton } from '../shared/PixelButton';
import { Title } from '../shared/styles/Section';
import { ButtonsContainer } from '../shared/styles/StakeStyles';

interface IClaimLiquidityProps {
  token?: ICoin | any;
  liquidityValue?: string | any;
  USDCForBumpPurchase?: string | any;
  currentTVL?: string | any;
  bumpPurchaseAllocation?: string | any;
}

export const ClaimLiquidity: React.FC<IClaimLiquidityProps> = () => {
  const history = useHistory();
  const location = useLocation<IClaimLiquidityProps>();
  const token = location.state ? location.state.token : USDCoin;
  const onCancel = () => {
    history.replace(Routes.DepositDashboard);
  };

  const onClick = () => {
    const props: ConfirmClaimProps = {
      token,
      toToken: BUMP,
    };
    history.push({
      pathname: `${Routes.ClaimDetails}/${subRoutes.Confirm}`,
      state: props,
    });
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="start">
      <Title>Claim Liquidity</Title>
      <Container>
        <Box gridArea="bump-purchase">
          <Box mt={4}>
            <ClaimDetails
              title="Claim amount"
              text="Choose the percentage or enter the specific amount you want to claim."
              token={BUMP}
            />
          </Box>
        </Box>
        <ButtonsContainer>
          <ButtonWrapper>
            <PixelButton fullWidth color="primary" onClick={onClick}>
              Next
            </PixelButton>
          </ButtonWrapper>
          <ButtonWrapper>
            <PixelButton fullWidth color="secondary" onClick={onCancel}>
              Cancel
            </PixelButton>
          </ButtonWrapper>
        </ButtonsContainer>
      </Container>
    </Box>
  );
};

const Container = styled(Box)(({ theme }) => ({
  maxWidth: '544px',
  display: 'grid',
  gridTemplateColumns: 'none',
  gridTemplateRows: 'none',
  gridTemplateAreas: `
     "bump-purchase"
     "action-buttons"
    `,
  gridGap: '16px',

  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  },
}));
const ButtonWrapper = styled(Box)(({ theme }) => ({
  width: '10em',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    '&:last-child': {
      marginTop: '1rem',
    },
  },
}));
