import { Box, styled } from '@material-ui/core';
import React from 'react';

import { StakingTabs } from './StakingSection';

import { MediumBoldRoboto } from '../shared/styles/TypographyRoboto';

interface ISwitchStakePeriodProps {
  value: StakingTabs;
  onSwitch: React.Dispatch<React.SetStateAction<number>>;
  disabled?: boolean;
}

const buttonsConfig: { name: string }[] = [
  {
    name: 'Fixed',
  },
  {
    name: 'Flexible',
  },
];
const DisableStyle = {
  zIndex: 0,
  opacity: 0.4,
};

export const SwitchStakePeriod: React.FC<ISwitchStakePeriodProps> = ({
  onSwitch,
  value,
  disabled,
}) => {
  return (
    <HeaderContainer>
      <Box
        display="flex"
        justifyContent="start"
        alignItems="center"
        style={{ gap: '12px' }}
      >
        {buttonsConfig.map(({ name }, index) => {
          let middleWareStyles: any =
            index === 0
              ? {
                  width: '131px',
                }
              : {
                  width: '142px',
                };
          if (index !== value) {
            middleWareStyles = {
              ...middleWareStyles,
              ...DisableStyle,
            };
          }

          if (disabled) {
            middleWareStyles = {
              ...DisableStyle,
              cursor: 'not-allowed',
            };
          }

          return (
            <PixelIconButton
              onClick={() => !disabled && onSwitch(index)}
              key={name}
              style={middleWareStyles}
            >
              <MediumBoldRoboto>{name}</MediumBoldRoboto>
            </PixelIconButton>
          );
        })}
      </Box>
    </HeaderContainer>
  );
};

const PixelIconButton = styled('div')(({ theme }) => ({
  width: '142px',
  height: '43px',
  padding: '0px',
  display: 'flex',
  paddingTop: '4px',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.other.Green1,
  border: `1px solid ${theme.palette.other.BoxOutline}`,
  borderRadius: '5px 5px 0 0',
  zIndex: 8,
  cursor: 'pointer',
  borderBottom: 'none',
  '&.MuiButton-root': {
    minWidth: '127px',
  },

  [theme.breakpoints.down('xs')]: {
    minWidth: '172px',
  },
}));

const HeaderContainer = styled('div')(() => ({
  marginBottom: '-1px',
}));
