import { Box, styled, makeStyles } from '@material-ui/core';
import React, { ReactFragment } from 'react';
import Lottie from 'react-lottie';
import { useHistory } from 'react-router-dom';

import loadingAnimation from '../../../assets/loadingAnimation.json';
import { Routes } from '../../../config/routes';
import { Header } from '../../common/Header';
import { LargeBoldRoboto } from '../../shared/styles/TypographyRoboto';
import { LargeBoldTypography, SmallTypography } from '../../shared/Typography';

interface IWalletApproveScreenProps {
  headingFirstText: string;
  headingSecondText?: string;
  subtitleFirstText?: string;
  subtitleSecondText?: ReactFragment;
  errorScreen?: boolean;
}
export const WalletApproveScreen: React.FC<IWalletApproveScreenProps> = ({
  headingFirstText,
  headingSecondText,
  subtitleSecondText,
  errorScreen,
}) => {
  const history = useHistory();
  const onCancel = () => {
    history.push(Routes.ConnectWallet);
  };
  const classes = styles(errorScreen)();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <>
      {errorScreen ? (
        <Header showStepper={false} activeStep={0} onCancel={onCancel} />
      ) : null}
      <ContainerBox
        display="flex"
        flexDirection="column"
        alignItems="center"
        maxWidth="351px"
        className={classes.containerBox}
      >
        <Box margin="auto">
          <Lottie
            options={defaultOptions}
            height={144}
            width={144}
            style={{ marginBottom: '2em' }}
          />
        </Box>
        <Heading>
          <LargeBoldRoboto>{headingFirstText}</LargeBoldRoboto>
          {headingSecondText ? headingSecondText : ''}
        </Heading>
        <Subtitle>{subtitleSecondText ? subtitleSecondText : ''}</Subtitle>
      </ContainerBox>
    </>
  );
};

const ContainerBox = styled(Box)(() => ({
  paddingTop: '20vh',
  marginLeft: 'auto',
  marginRight: 'auto',
}));

const Heading = styled(LargeBoldTypography)(() => ({
  marginBottom: '16px',
  textAlign: 'center',
}));

const Subtitle = styled(SmallTypography)(() => ({
  textAlign: 'center',
}));

const styles = (errorScreen: boolean | undefined) => {
  return makeStyles((theme) => ({
    containerBox: {
      marginTop: errorScreen ? undefined : `-${theme.spacing(9)}px`,
    },
  }));
};
