import { createSlice } from '@reduxjs/toolkit';

import { bUSDCCoin, USDCoin, BUMP } from '../../config/coins';
import { ICoinDetails } from '../../interfaces/ICoin';
import { fetchCoinDetails } from '../actions/coinActions';

interface IinitialState {
  coinDetails: {
    [coinName: string]: ICoinDetails;
  };
}

const initialState: IinitialState = {
  coinDetails: {
    [USDCoin.symbol]: {
      address: '',
      balance: '',
      logoUrl: '',
      name: '',
      price: '1',
      symbol: '',
      value: '0.0',
    },
    [bUSDCCoin.symbol]: {
      address: '',
      balance: '',
      logoUrl: '',
      name: '',
      price: '1',
      symbol: '',
      value: '0.0',
    },
    [BUMP.symbol]: {
      address: '',
      balance: '',
      logoUrl: '',
      name: '',
      price: '1',
      symbol: '',
      value: '0.0',
    },
  },
};

export const tokenSlice = createSlice({
  name: 'coin',
  initialState,
  reducers: {
    resetCoin: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCoinDetails.fulfilled, (state, action) => {
      action.payload.forEach((coin) => {
        state.coinDetails[coin.symbol] = { ...coin };
      });
    });
  },
});

export default tokenSlice.reducer;
export const { resetCoin } = tokenSlice.actions;
