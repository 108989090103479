import {
  withStyles,
  Tooltip as MuiTooltip,
  Theme,
  TooltipProps,
} from '@material-ui/core';
import React from 'react';

import { createPixelInsetBorder } from '../../utils/createPixelInsetBorder';

export const Tooltip = withStyles((theme: Theme) => ({
  tooltip: {
    boxShadow: createPixelInsetBorder('#ffffff', '#ffffff', '#ffffff'),
    borderRadius: '0px',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.dark,
    fontSize: '12px',
    lineHeight: '16px',
    padding: '8px 10px',
    opacity: 1,
    maxWidth: '173px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}))((props: TooltipProps) => (
  <MuiTooltip
    {...props}
    enterTouchDelay={0}
    placement="right-start"
    leaveTouchDelay={3000}
  />
));
