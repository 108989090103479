import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  name: {
    fontWeight: 700,
    fontSize: '18px',
    fontFamily: theme.font.secondary,
    lineHeight: '24px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    cursor: 'default',
  },
  light: {
    color: 'rgba(255, 255, 255, 0.6)',
  },
  symbol: {
    fontSize: '12px',
    fontWeight: 700,
    cursor: 'default',
  },
  icon: {
    marginRight: '10px',
    marginTop: '5px',
    height: '32px',
    width: '32px',
  },
  label: {
    fontFamily: theme.font.secondary,
    fontWeight: 700,
    fontSize: '11px',
    marginBottom: '4px',
    cursor: 'default',
  },
  inline: {
    display: 'inline',
  },
}));
