import { Box, styled, useMediaQuery } from '@material-ui/core';
import React from 'react';

// import { ClaimBlock } from './ClaimBlock';

import { theme } from '../../../providers/AppThemeProvider';
// import { BrianBottomPos } from '../../shared/BrianBottomPos';
import { PixelButton } from '../../shared/PixelButton';
import {
  ClaimButton,
  ContainerMainEpoch,
  Header,
  MainDescription,
  MainSecondContainer,
} from '../../shared/styles/DashboardStyles';

// const lmBumperLink = 'https://lm.bumper.fi';
// const uniswapV2Link =
  // 'https://app.uniswap.org/#/swap?inputCurrency=0x785c34312dfa6b74f6f1829f79ade39042222168&outputCurrency=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48';
export const DashboardVesting = () => {
  // const classes = styles();
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Box>
      <ContainerMainEpoch style={{ padding: '2.5em 2.8em 2.5em 2.8em' }}>
        <MainSecondContainer>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="start"
            gridGap="24px"
          >
            {!smallScreen && (
              <Header
                style={{
                  fontSize: '32px',
                  fontFamily: 'Roboto Mono',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                BUMPER PROTOCOL IS LIVE!
              </Header>
            )}
            {smallScreen && (
              <Header
                style={{
                  fontFamily: 'Roboto Mono',
                  fontSize: '20px',
                  lineHeight: '22px',
                  fontWeight: 400,
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                  marginLeft: '-1em',
                }}
              >
                BUMPER PROTOCOL IS LIVE!
              </Header>
            )}
            {!smallScreen && (
              <Box display="flex" flexDirection="row">
                <MainDescription>
                  We are live on Ethereum Mainnet. Visit our dapp to ride smoothly during volatile time and let Bumper protect you !                
                </MainDescription>
              </Box>
            )}
            {smallScreen && (
              <MainDescription>
                We are live on Ethereum Mainnet. Visit our dapp to ride smoothly during volatile time and let Bumper protect you !                
              </MainDescription>
            )}
            {!smallScreen && (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                gridColumnGap={"18px"}
              >
                <StakeButton href={"https://app.bumper.fi/protect/deposit/eth"}>Protect your ETH</StakeButton>
                <StakeButton href={"https://app.bumper.fi/console/earn/assets"}>Earn Yield on your USDC</StakeButton>
                {/* <PixelButton
                  style={{ maxWidth: '149px', maxHeight: '32px' }}
                  color="secondary"
                  // style={{ marginLeft: '2rem' }}
                  href={uniswapV2Link}
                >
                  BUY BUMP
                </PixelButton> */}
              </Box>
            )}
            {smallScreen && (
              <Box
                display="flex"
                flexDirection="row"
                alignItems={'center'}
                style={{ marginLeft: smallScreen && true ? '-1.1em' : '' }}
              >
                <ButtonsContainer>
                  {/* <PixelButton
                    size="small"
                    color="secondary"
                    style={{ maxWidth: '149px', maxHeight: '32px' }}
                    href="https://lm.bumper.fi"
                  >
                    Learn more
                  </PixelButton> */}
                  <StakeButton
                    style={{
                      marginTop: '1.5rem',
                      height: smallScreen && '32px',
                    }}
                    href={"https://app.bumper.fi/console/protect/assets"}
                  >
                    Protect your ETH !
                  </StakeButton>
                  <PixelButton
                    color="secondary"
                    style={{
                      marginTop: '1.5rem',
                      height: smallScreen && '32px',
                      maxWidth: '149px',
                      maxHeight: '32px',
                    }}
                    href={"https://app.bumper.fi/console/protect/assets"}
                  >
                    Earn Yield on your USDC
                  </PixelButton>
                </ButtonsContainer>
              </Box>
            )}
          </Box>
        </MainSecondContainer>
      </ContainerMainEpoch>
      {/* <ClaimBlock /> */}
    </Box>
  );
};

const StakeButton = styled(ClaimButton)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: '0',
  },
  [theme.breakpoints.down('xs')]: {
    marginTop: '1rem',
  },
}));

const ButtonsContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    display: 'flex',
    flexDirection: 'column',
    margintRight: '-2em',
  },
}));

// const BrianContainer = styled(Box)(({ theme }) => ({
//   marginTop: '3em',
//   marginLeft: '1.5rem',
//   marginBottom: '-1em',

//   [theme.breakpoints.down('xs')]: {
//     marginTop: '-2em',
//     marginBottom: '-4em',
//   },
// }));

// const styles = makeStyles((theme) => ({
//   brian: {
//     right: '5rem',
//     [theme.breakpoints.down('sm')]: {
//       right: '3rem !important',
//       top: '-1em',
//     },
//   },
// }));
