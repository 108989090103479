import { Box } from '@material-ui/core';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Routes, subRoutes } from '../../config/routes';
import { formatStringifyNumberToDot } from '../../utils/helpers';
import { PixelButton } from '../shared/PixelButton';
import {
  ClaimVestingSummary,
  ClaimVestingValue,
  CoinIconSmall,
  SymbolTypography,
} from '../shared/styles/ConfirmationPage';
import { DarkSection, Title } from '../shared/styles/Section';

interface IConfirmClaimProps {
  coinIcon: string;
}

export const ConfirmClaim: React.FC<IConfirmClaimProps> = ({ coinIcon }) => {
  const history = useHistory();
  const location = useLocation<{ claimedAmount: number }>();
  const { claimedAmount } = location.state;

  const onBackButtonPress = () => {
    history.replace(Routes.DepositDashboard);
  };

  return (
    <Box maxWidth={{ xs: '100%', sm: '100%' }} m="auto">
      <Box display="flex" justifyContent="center" mb={5}>
        <Title>Claim</Title>
      </Box>
      <DarkSection
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={{ xs: 3, sm: 3, lg: 4 }}
        position="relative"
        pt={{ xs: 9, sm: 9, lg: 10 }}
      >
        <CoinIconSmall src={coinIcon} />
        <Box display="flex" alignItems="baseline" mb={1}>
          <ClaimVestingValue variant="h1">
            +{formatStringifyNumberToDot(claimedAmount.toString())}
          </ClaimVestingValue>
          <SymbolTypography variant="h4" color="textSecondary">
            BUMP
          </SymbolTypography>
        </Box>
        <ClaimVestingSummary mt={5} mb={3} width="100%">
          The above figure is an estimate of the maximum amount
          <br />
          of tokens you can claim. The actual maximum amount
          <br />
          of claimed BUMP will be determined at the time the
          <br />
          transaction confirms on the blockchain.
        </ClaimVestingSummary>
        <ClaimVestingSummary width="100%">
          The next step to stake will include your newly claimed
          <br />
          BUMP tokens as per the above figure.
        </ClaimVestingSummary>
      </DarkSection>
      <Box
        display="grid"
        gridTemplateColumns="minmax(150px, 33%) auto minmax(150px, 33%)"
        mt={{ xs: 3, sm: 4 }}
        pb="10vh"
      >
        <Box>
          <PixelButton fullWidth color="secondary" onClick={onBackButtonPress}>
            Back
          </PixelButton>
        </Box>
        <Box gridColumn="3">
          <PixelButton
            fullWidth
            color="primary"
            onClick={() =>
              history.push({
                pathname: `${Routes.ClaimStake}/${subRoutes.Stake}`,
                state: {
                  stakeAmount: claimedAmount,
                  activeOptionIndex: 3,
                },
              })
            }
          >
            Next
          </PixelButton>
        </Box>
      </Box>
    </Box>
  );
};
