import { Slider as MuiSlider, withStyles, Theme } from '@material-ui/core';

import { CoinIcons } from './icons/CoinIcons';

import ProgressArrow from '../../assets/progress-arrow.svg';
import { createPixelInsetBorder } from '../../utils/createPixelInsetBorder';

export const Slider = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.other.BrightGreen,
    height: 6,
    width: '90%',
  },
  rail: {
    height: 4,
    backgroundColor: '#809FA3',
    opacity: 1,
  },
  track: {
    height: 6,
    marginTop: '-1px',
  },
  thumb: {
    marginTop: '-7px',
    height: 12,
    width: 12,
    border: '3px solid white',
    boxSizing: 'content-box',
  },
  mark: {
    height: '10px',
    width: '10px',
    borderRadius: '0',
    backgroundColor: '#809FA3',
    marginTop: '-6px',
    border: '3px solid #003E46',
    opacity: 1,
    marginLeft: '-4px',
  },
  markActive: {
    backgroundColor: 'currentColor',
  },
  valueLabel: {
    left: 'calc(-50% -3px)',
    top: 22,
    '& *': {
      background: 'transparent',
      color: theme.palette.text.primary,
    },
  },
}))(MuiSlider);

const PixelSlider = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.other.BrightGreen,
    height: 6,
    width: '100%',
  },
  rail: {
    height: 6,
    backgroundColor: '#1A626B',
    borderRadius: '0',
    boxShadow: createPixelInsetBorder('black', '#1A626B', '#1A626B'),
    opacity: 1,
  },
  track: {
    height: 6,
    marginTop: '-1px',
    borderRadius: 0,
    boxShadow: createPixelInsetBorder(
      'black',
      '#9CE1A9',
      `${theme.palette.other.BrightGreen}`,
    ),
  },
  thumb: {
    marginTop: '-9px',
    height: '22px',
    width: '14px',
    marginLeft: '0px',
    borderRadius: '0',
    backgroundColor: 'transparent',
    backgroundImage: `url(${ProgressArrow})`,
    backgroundRepeat: 'no-repeat',
    boxSizing: 'content-box',
    '&:focus, &:hover, &$active': {
      boxShadow: 'none',
    },
  },
  mark: {
    height: '16px',
    width: '2px',
    borderRadius: '0',
    backgroundColor: 'currentColor',
    border: '2px solid black',
    marginTop: '-8px',
    opacity: 1,
  },
  markActive: {},
  valueLabel: {
    left: 'calc(-50% -3px)',
    top: 30,
    '& *': {
      background: 'transparent',
      color: theme.palette.text.primary,
    },
    fontSize: '14px',
    fontWeight: 700,
  },
}))(MuiSlider);

const DistributionSlider: any = withStyles((theme: Theme) => ({
  root: {
    color: ({ isWithdraw }: any) => (isWithdraw ? '#2775CA' : '#E07A29'),
    height: 16,
    width: '100%',
  },
  rail: {
    height: 15,
    backgroundColor: '#FD4F00',
    opacity: 1,
  },
  track: {
    height: 16,
    marginTop: '-1px',
  },
  thumb: {
    marginTop: '-9px',
    height: 30,
    width: 30,
    boxSizing: 'content-box',
    backgroundImage: ({ isUsdc }: { isUsdc: boolean }) =>
      `url(${isUsdc ? CoinIcons.USDC : CoinIcons.bUSDC})`,
    backgroundPosition: '50% 25%',
    transform: 'translateX(-.675rem)',
    '&:focus, &:hover, &$active': {
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      width: 30,
      height: 30,
      marginTop: '-9px',
    },
    '&:after': {
      display: 'none',
    },
  },
  valueLabel: {
    left: 'calc(-50% -3px)',
    top: 22,
    '& *': {
      background: 'transparent',
      color: theme.palette.text.primary,
    },
  },
}))(MuiSlider);

const StakeSlider: any = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.other.Orange,
    height: 16,
    width: '100%',
  },
  rail: {
    height: 15,
    backgroundColor: '#2F6F77',
    opacity: 1,
  },
  track: {
    height: 16,
    marginTop: '-1px',
  },
  thumb: {
    marginTop: '-12px',
    height: '32px',
    width: '32px',
    boxSizing: 'content-box',
    backgroundImage: () => `url(${CoinIcons.BUMP})`,
    border: '2px solid #325F65',
    backgroundPosition: '50% 25%',
    transform: 'translateX(-.625rem)',
    '&:focus, &:hover, &$active': {
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      width: 30,
      height: 30,
      marginTop: '-9px',
    },
    '&:after': {
      display: 'none',
    },
  },
  valueLabel: {
    left: 'calc(-50% -3px)',
    top: 22,
    '& *': {
      background: 'transparent',
      color: theme.palette.text.primary,
    },
  },
}))(Slider);

export { PixelSlider, DistributionSlider, StakeSlider };
