import { styled } from '@material-ui/core';
import Jazzicon from '@metamask/jazzicon';
import React from 'react';

export const Identicon: React.FC<{ account: string }> = (props) => {
  return (
    <IdenticonContainer
      dangerouslySetInnerHTML={{
        __html: Jazzicon(16, parseInt(props.account.slice(2, 10), 16))
          .outerHTML,
      }}
    />
  );
};

const IdenticonContainer = styled('div')({
  height: '1rem',
});
