import { ApolloClient, InMemoryCache } from '@apollo/client';

export const clientLpp = new ApolloClient({
  uri: process.env.REACT_APP_SUBGRAPH_LPP_URL,
  cache: new InMemoryCache(),
});

export const client = new ApolloClient({
  uri: process.env.REACT_APP_SUBGRAPH_URL,
  cache: new InMemoryCache(),
});
