import {
  Box,
  IconButton,
  styled,
  DialogTitle,
  DialogContent,
  Link,
  LinkProps,
  Dialog,
  DialogProps,
  Slide,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { CloseRounded } from '@material-ui/icons';
import React, { useContext, useEffect } from 'react';
import { useHistory, NavLink, NavLinkProps } from 'react-router-dom';

import { BumpBalanceInfo } from './BumpBalanceInfo';
import { ProtectButton, EarnButton } from './CTAButtons';
import { WalletAccountInfo } from './WalletAccountInfo';

import pixelBumperIcon from '../../../assets/bumper-pixel-icon.svg';
import discordIcon from '../../../assets/discordMobileLogo.svg';
import telegramIcon from '../../../assets/telegramMobileLogo.svg';
// import { USDCoin } from '../../../config/coins';
import {
  discordLink,
  docsLink,
  supportLink,
  telegramLink,
} from '../../../config/links';
import { Routes } from '../../../config/routes';
import { WalletContext } from '../../../config/walletContext';
import { PixelButton } from '../PixelButton';
import { SlimDivider } from '../styles/Section';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="right" ref={ref} {...props} />;
});

interface IMobileNavDialogProps {
  isDialogOpen: boolean;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MobileNavDialog: React.FC<IMobileNavDialogProps> = (props) => {
  const history = useHistory();
  const isWalletConnected = useContext(WalletContext).isWalletConnected;
  // const usdc = useAppSelector(
  //   (state) => state.coin.coinDetails['USDC'].balance,
  // );

  useEffect(() => {
    props.setDialogOpen(false);
  }, [history.location.pathname]);

  return (
    <MuiDialog open={props.isDialogOpen} TransitionComponent={Transition}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <img
            style={{ width: '24px', height: '24px' }}
            src={pixelBumperIcon}
            alt=""
          />
          <IconButton
            aria-label="Close"
            onClick={() => props.setDialogOpen(false)}
            style={{ padding: '0px' }}
          >
            <CloseRounded style={{ color: 'white', fontSize: '30px' }} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display="grid" gridGap="32px">
          {isWalletConnected ? (
            <>
              <Box>
                <BumpBalanceInfo />
                <Box mt="16px">
                  <WalletAccountInfo />
                </Box>
              </Box>
              <Box display="grid" gridTemplateColumns="1fr" gridGap="24px">
              <ProtectButton href='https://app.bumper.fi/console/protect/assets' />
              <EarnButton href='https://app.bumper.fi/console/earn/assets' />
              </Box>
            </>
          ) : (
            <PixelButton
              color="primary"
              variant="contained"
              onClick={() => history.push(Routes.ConnectWallet)}
            >
              Connect Wallet
            </PixelButton>
          )}
          <SlimDivider />
          <MobileLink to={Routes.DepositDashboard}>Dashboard</MobileLink>
          <MobileLink to={Routes.Epochs}>Epoch</MobileLink>
          <MobileLink to={Routes.Staking}>Staking</MobileLink>
          <MobileLink to={Routes.Governance}>Governance</MobileLink>
          <MobileLink to={Routes.Protocol}>Protocol</MobileLink>
          <ExternalLink href={docsLink} target="_blank" rel="noreferrer">
            Docs
          </ExternalLink>
          <ExternalLink href={supportLink} target="_blank" rel="noreferrer">
            Support
          </ExternalLink>
          <Box>
            <ExternalLink href={discordLink} target="_blank" rel="noreferrer">
              <img
                style={{ width: '24px', height: '24px' }}
                src={discordIcon}
                alt=""
              />
            </ExternalLink>
            <ExternalLink href={telegramLink} target="_blank" rel="noreferrer">
              <img
                style={{ width: '24px', height: '24px', marginLeft: '24px' }}
                src={telegramIcon}
                alt=""
              />
            </ExternalLink>
          </Box>
        </Box>
      </DialogContent>
    </MuiDialog>
  );
};

const ExternalLink = styled((props: LinkProps) => {
  return <Link underline="none" {...props} />;
})(({ theme }) => ({
  fontFamily: theme.font.secondary,
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '18px',
  color: theme.palette.text.primary,

  '& :active': {
    color: 'rgba(255, 255, 255, 0.5)',
  },
}));

const MobileLink = styled((props: NavLinkProps) => {
  return <NavLink exact {...props} />;
})(({ theme }) => ({
  fontFamily: theme.font.secondary,
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '18px',
  color: theme.palette.text.primary,
  textDecoration: 'none',
  '& :active': {
    color: 'rgba(255, 255, 255, 0.5)',
  },

  '&.disabled-link': {
    pointerEvents: 'none',
    color: 'rgba(255,255,255, 0.2)',
  },
}));

const MuiDialog = styled((props: DialogProps) => {
  return <Dialog fullWidth fullScreen {...props} />;
})(({ theme }) => ({
  '& .MuiPaper-root': {
    background: theme.palette.backgroundColor.dark,
    padding: '24px',
  },
  '& .MuiDialogContent-root': {
    padding: '44px 4px 0px 4px',
  },
  '& .MuiDialogTitle-root': {
    padding: '4px 0px 0px 0px',
  },
}));
