import { styled } from '@material-ui/core';

import moonBg from '../../assets/moonBg.png';

export const MoonBackground = styled('div')(() => ({
  minHeight: '100vh',
  maxWidth: '100vw',
  backgroundImage: `url(${moonBg})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center bottom',
  backgroundAttachment: 'fixed',
  backgroundRepeat: 'no-repeat',
}));
