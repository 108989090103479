import { Typography, Grid } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { styled } from '@material-ui/core/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';

import BumperLogo from '../../../assets/bumper-horizontal-pixel-logo.svg';
import BinanceWalletLogo from '../../../assets/icon-wallet-binance.png';
import BrowserWalletLogo from '../../../assets/icon-wallet-browser.png';
import FrameWalletLogo from '../../../assets/icon-wallet-frame.png';
import LatticeWalletLogo from '../../../assets/icon-wallet-lattice.png';
import LedgerWalletLogo from '../../../assets/icon-wallet-ledger.png';
import PortisWalletLogo from '../../../assets/icon-wallet-portis.png';
import TrezorWalletLogo from '../../../assets/icon-wallet-trezor.png';
import WalletConnectWalletLogo from '../../../assets/icon-wallet-walletconnect.png';
import WalletLinkConnectWalletLogo from '../../../assets/icon-wallet-walletlink.png';
import { Routes } from '../../../config/routes';
import { PixelButton } from '../../shared/PixelButton';

interface IConnectWalletModalProps {
  onConnectInjected: () => Promise<void>;
  onConnectWalletConnect: () => Promise<void>;
  onConnectBsc: () => Promise<void>;
  onConnectLedger: () => Promise<void>;
  onConnectTrezor: () => Promise<void>;
  onConnectLattice: () => Promise<void>;
  onConnectWalletLink: () => Promise<void>;
  onConnectPortis: () => Promise<void>;
  onConnectFrame: () => Promise<void>;
}
export const ConnectWalletModal: React.FC<IConnectWalletModalProps> = ({
  onConnectInjected,
  onConnectWalletConnect,
  onConnectBsc,
  onConnectLedger,
  onConnectTrezor,
  onConnectLattice,
  onConnectWalletLink,
  onConnectPortis,
  onConnectFrame,
}) => {
  const history = useHistory();

  const WALLETS: {
    name: string;
    subtitle?: string;
    fn: () => Promise<void>;
    logo: string;
    disabled: boolean;
  }[] = [
    {
      name: 'Browser Wallet',
      subtitle: '(MetaMask, TrustWallet)',
      fn: onConnectInjected,
      logo: BrowserWalletLogo,
      disabled: false,
    },
    {
      name: 'WalletConnect',
      fn: onConnectWalletConnect,
      logo: WalletConnectWalletLogo,
      disabled: false,
    },
    {
      name: 'WalletLink',
      fn: onConnectWalletLink,
      logo: WalletLinkConnectWalletLogo,
      disabled: false,
    },
    {
      name: 'Binance',
      fn: onConnectBsc,
      logo: BinanceWalletLogo,
      disabled: false,
    },
    {
      name: 'Portis',
      fn: onConnectPortis,
      logo: PortisWalletLogo,
      disabled: true,
    },
    {
      name: 'Frame',
      fn: onConnectFrame,
      logo: FrameWalletLogo,
      disabled: false,
    },
    {
      name: 'Ledger',
      subtitle: 'BETA',
      fn: onConnectLedger,
      logo: LedgerWalletLogo,
      disabled: false,
    },
    {
      name: 'Trezor',
      subtitle: 'BETA',
      fn: onConnectTrezor,
      logo: TrezorWalletLogo,
      disabled: false,
    },
    {
      name: 'Lattice',
      subtitle: 'BETA',
      fn: onConnectLattice,
      logo: LatticeWalletLogo,
      disabled: false,
    },
  ];
  return (
    <>
      <BumperLogoSection src={BumperLogo} />
      <ConnectHeading>Connect Your Wallet</ConnectHeading>
      <ButtonContainer container spacing={4}>
        {WALLETS.map(({ name, subtitle, fn, logo, disabled }, i) => (
          <Grid item sm={4} key={i}>
            <WalletButton
              color="secondary"
              variant="contained"
              disabled={disabled}
              onClick={fn}
              style={disabled ? { opacity: '0.5' } : { opacity: '1' }}
            >
              <WalletButtonContent>
                <div
                  style={{
                    paddingTop: name === 'Browser Wallet' ? '13.25px' : 0,
                  }}
                >
                  <WalletLogo src={logo} alt="Logo" />
                  <div>
                    <StyledName>{name}</StyledName>
                    <StyledSubtitle>{subtitle}</StyledSubtitle>
                  </div>
                </div>
              </WalletButtonContent>
            </WalletButton>
          </Grid>
        ))}
      </ButtonContainer>
      <ConnectHeading
        style={{
          fontSize: '16px',
          cursor: 'pointer',
          width: 'max-content',
          margin: '2em auto 4em',
        }}
        onClick={() => history.push(`${Routes.DepositDashboard}`)}
      >
        Proceed without connecting wallet
      </ConnectHeading>
    </>
  );
};

const StyledSubtitle = styled(Typography)({
  fontFamily: 'Roboto',
  fontSize: '10px',
  lineHeight: '16px',
  textAlign: 'center',
});

const StyledName = styled(Typography)({
  fontFamily: 'Roboto',
  fontSize: '14px',
  lineHeight: '20px',
  width: '112px',
});

const ConnectHeading = styled(Typography)(({ theme }) => ({
  fontFamily: theme.font.secondary,
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.h4.fontWeight,
  lineHeight: '28px',
  textAlign: 'center',
  margin: '0em auto 2em',
}));
const BumperLogoSection = styled('img')(({ theme }) => ({
  display: 'flex',
  margin: 'auto',
  marginTop: '34px',
  marginBottom: '34px',
  width: '136px',
  height: '18px',
  [theme.breakpoints.down('sm')]: {
    marginTop: '0px',
  },
}));

const ButtonContainer = styled(Grid)(({ theme }) => ({
  marginBottom: '18px',
  [theme.breakpoints.down('sm')]: {
    padding: 0,
    justifyContent: 'space-evenly',
    gridGap: '0',
  },
  '@media (max-width: 320px)': {
    paddingLeft: '5px',
  },
}));

const WalletButton = styled((props: ButtonProps) => (
  <PixelButton color="secondary" variant="contained" {...props} />
))(({ theme }) => ({
  height: '8em',
  maxHeight: '128px',
  width: '151px !important',
  maxWidth: '161px !important',
  [theme.breakpoints.down('sm')]: {
    height: '8em',
    maxHeight: '8em',
    width: '8em !important',
    maxWidth: '8em !important',
    marginBottom: 0,
  },
  '@media (max-width: 320px)': {
    height: '6.55em',
    maxHeight: '6.55em',
    width: '6.75em !important',
    maxWidth: '6.75em !important',
    // padding: '10px',
    marginBottom: 0,
  },
}));

const WalletButtonContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
});

const WalletLogo = styled('img')(({ theme }) => ({
  width: '48px',
  height: '48px',
  marginBottom: '.5rem',
  [theme.breakpoints.down('sm')]: {
    height: '2em',
    width: '2em',
  },
}));
