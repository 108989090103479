import { Box, styled } from '@material-ui/core';
import React from 'react';
import Lottie from 'react-lottie';

import animation from '../../assets/animation.json';
import { SmallRoboto } from '../shared/styles/TypographyRoboto';
import { LargeBoldTypography } from '../shared/Typography';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const TransactionProcessingSection: React.FC = () => {
  return (
    <ContainerBox>
      <Box margin="auto">
        <Lottie options={defaultOptions} height={144} width={144} />
        <TransactionSectionLabel>
          <LargeBoldTypography>
            Your transaction is now in progress.
          </LargeBoldTypography>
          <StyledSmallRoboto style={{ marginTop: '1rem', opacity: 0.8 }}>
            This may take a few minutes.
          </StyledSmallRoboto>
        </TransactionSectionLabel>
      </Box>
    </ContainerBox>
  );
};

const StyledSmallRoboto = styled(SmallRoboto)({
  textAlign: 'center',
});

const TransactionSectionLabel = styled('div')(({ theme }) => ({
  fontFamily: theme.font.secondary,
  textAlign: 'center',
  marginTop: '36px',
  [theme.breakpoints.down('sm')]: {
    marginTop: '24px',
  },
}));

const ContainerBox = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '320px',
  transform: 'translate(-50%, -50%)',
});
