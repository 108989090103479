import { Box, IconButton } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import Countdown from 'react-countdown';
import { useHistory } from 'react-router-dom';

import { TopSection } from './../shared/TopSection';
import { CountDownStaking } from './FixedStakeCard';
import { SwitchComponent } from './SwitchComponent';

import { isFrontendFunctionalityDisabled } from '../../config';
import { Routes, subRoutes } from '../../config/routes';
import { ICoin } from '../../interfaces/ICoin';
import { theme } from '../../providers/AppThemeProvider';
import { useAppSelector } from '../../state/hooks';
import { formatStringifyNumberToDot } from '../../utils/helpers';
// import { ConfirmRestakeState } from '../ConfirmStaking/ConfirmRestake';
import { CoinIcon } from '../shared/CoinIcon';
import { ContentSection } from '../shared/ContentSection';
import { CoinIcons } from '../shared/icons/CoinIcons';
import { PixelButton } from '../shared/PixelButton';
import { CustomHelpIcon } from '../shared/styles/GlobalSections';
import {
  SectionFixed,
  SectionContent,
  SectionInfo,
  SectionName,
  StakedAmount,
  UnstakeButton,
} from '../shared/styles/StakeStyles';
import { Tooltip } from '../shared/Tooltip';
import { LargeBoldTypography } from '../shared/Typography';

export interface FakeStakeCardState {
  token: ICoin;
  amount: number;
  rewards: number;
  endDate: number;
}

export const FakeStakeCard: React.FC<FakeStakeCardState> = React.memo(
  ({ token, amount, rewards, endDate }) => {
    const utcTime = useAppSelector((state) => state.lockTimestamp.utcTime);
    const history = useHistory();

    const startClaimFlow = () => {
      history.push(Routes.Vesting + '/' + subRoutes.Confirm, {
        claimedAmount: amount + rewards,
        isNeedBackToStakingPage: true,
      });
    };

    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    return (
      <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
        <TopSection color={`${theme.palette.other.Turquoise}`}>
          <Box display="flex" alignItems="center">
            <CoinIcon src={CoinIcons[token.symbol]} />
            {!smallScreen && (
              <LargeBoldTypography secondaryFont>
                {token.symbol}
              </LargeBoldTypography>
            )}
          </Box>
          <Box display="flex" alignItems="center">
            {!smallScreen && (
              <SwitchComponent
                value={false}
                text="Auto-renew"
                tooltip={
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <div>
                      Select ‘Auto-renew’ to continue staking beyond your
                      current fixed term.
                    </div>
                    <div>
                      If selected, at the end of your current fixed term, you
                      will enter another term for the same fixed period as
                      before. This option will incur a network transaction fee.
                    </div>
                    <br />
                    <div>
                      <b>Note:</b> If you purchased your BUMP via the Public
                      Sale staking option, auto-renew will not be available to
                      you.
                    </div>
                  </Box>
                }
                disabled={
                  isFrontendFunctionalityDisabled(utcTime) ||
                  endDate <= Date.now()
                }
                confirmAutoRenew={() => {
                  return;
                }}
              />
            )}
            {/* <PixelButton
              size="small"
              color="secondary"
              style={{
                marginLeft: smallScreen ? 0 : '20px',
                marginTop: smallScreen ? '0.5em' : 0,
              }}
              disabled={endDate > Date.now()}
              onClick={() => {
                const props: ConfirmRestakeState = {
                  stakeIndex: 0,
                  amount,
                  option: 3,
                  rewards,
                  isVestingRestake: true,
                };
                history.push({
                  pathname: `${Routes.Restake}/${subRoutes.Confirm}`,
                  state: props,
                });
              }}
            >
              Restake
            </PixelButton> */}
            <UnstakeButton
              onClick={startClaimFlow}
              disabled={endDate > Date.now()}
              style={{ marginTop: smallScreen ? '0.5em' : 0 }}
            >
              Unstake
            </UnstakeButton>
          </Box>
        </TopSection>
        <ContentSection>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <SectionFixed>
              {smallScreen && (
                <SectionInfo style={{ paddingTop: '5px' }}>
                  <SwitchComponent
                    value={false}
                    text="Auto-renew"
                    tooltip={
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <div>
                          Select ‘Auto-renew’ to continue staking beyond your
                          current fixed term.
                        </div>
                        <div>
                          If selected, at the end of your current fixed term,
                          you will enter another term for the same fixed period
                          as before. This option will incur a network
                          transaction fee.
                        </div>
                        <br />
                        <div>
                          <b>Note:</b> If you purchased your BUMP via the Public
                          Sale staking option, auto-renew will not be available
                          to you.
                        </div>
                      </Box>
                    }
                    disabled={endDate <= Date.now()}
                    confirmAutoRenew={() => {
                      return;
                    }}
                  />
                </SectionInfo>
              )}
              <SectionInfo>
                <Box display="flex" flexDirection="row">
                  <SectionName>Staked</SectionName>
                </Box>
                <SectionContent>
                  <StakedAmount>{`${formatStringifyNumberToDot(
                    amount + '',
                  )} `}</StakedAmount>
                  <StakedAmount
                    style={{
                      lineHeight: '16px',
                      fontSize: '12px',
                      padding: '0.4em 0 0 0.4em',
                    }}
                  >
                    BUMP
                  </StakedAmount>
                </SectionContent>
              </SectionInfo>
              <SectionInfo>
                <Box display="flex" alignItems="center">
                  <SectionName>Period:</SectionName>
                  <Box display="flex" flexDirection="row">
                    <SectionName
                      style={{
                        color: '#8CA6AE',
                      }}
                    >
                      Fixed
                    </SectionName>
                  </Box>
                </Box>
                {!Number.isNaN(endDate) && (
                  <SectionContent>
                    <Countdown
                      date={endDate}
                      renderer={(properties) => (
                        <CountDownStaking {...properties} />
                      )}
                    />
                  </SectionContent>
                )}
              </SectionInfo>
              <SectionInfo
                style={{
                  flexDirection: 'row',
                  flexWrap: smallScreen ? 'wrap' : 'nowrap',
                  alignItems: 'center',
                  justifyContent: smallScreen ? 'center' : 'unset',
                  paddingRight: 0,
                  borderRight: 0,
                  width: 275,
                }}
              >
                <Box
                  sx={{
                    minWidth: 178,
                    width: smallScreen ? '100%' : 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: smallScreen ? 'center' : 'unset',
                  }}
                >
                  <Box display="flex" flexDirection="row">
                    <SectionName>Earned</SectionName>
                    <Tooltip
                      title={
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <div>
                            ‘Earned’ shows your total BUMP staking rewards{'\n'}
                            for your current term.
                          </div>
                          <br />
                          <div>
                            <b>Note:</b> If you purchased your BUMP via the
                            Public Sale staking option, the claim function will
                            not be available until the end{'\n'}of your staking
                            period.
                          </div>
                        </Box>
                      }
                    >
                      <IconButton
                        style={{
                          padding: '0px',
                          marginLeft: '0.375rem',
                        }}
                      >
                        <CustomHelpIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box display="flex" sx={{ paddingTop: '.5em' }}>
                    <StakedAmount
                      style={{ fontStyle: 'bold', color: '#ffffff' }}
                    >
                      {`${formatStringifyNumberToDot(rewards + '')} `}
                    </StakedAmount>
                    <StakedAmount
                      style={{
                        lineHeight: '16px',
                        fontSize: '12px',
                        color: '#ffffff',
                        padding: '0.4em 0 0 0.4em',
                      }}
                    >
                      BUMP
                    </StakedAmount>
                  </Box>
                </Box>
                <PixelButton
                  size="small"
                  color="primary"
                  style={{
                    color: '#ffffff',
                    opacity: rewards === 0 ? 0.6 : 1,
                    minWidth: 90,
                    marginTop: smallScreen ? '1rem' : '0',
                  }}
                  disabled={endDate > Date.now()}
                  onClick={startClaimFlow}
                >
                  Claim
                </PixelButton>
              </SectionInfo>
            </SectionFixed>
          </Box>
        </ContentSection>
      </Box>
    );
  },
);
