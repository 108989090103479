import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { IRenderRoutesProps, IRoute } from './interfaces';

/**
 * Render a route with potential sub routes
 * https://reacttraining.com/react-router/web/example/route-config
 */

const RouteWithSubRoutes = (route: IRoute) => {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );
};
export const RenderRoutes: React.FC<IRenderRoutesProps> = ({ routes }) => {
  return (
    <Switch>
      {routes &&
        routes.map((route) => {
          return <RouteWithSubRoutes key={route.path} {...route} />;
        })}
    </Switch>
  );
};
