import { BscConnector } from '@binance-chain/bsc-connector';
import { AbstractConnector } from '@web3-react/abstract-connector';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { FrameConnector } from '@web3-react/frame-connector';
import { InjectedConnector } from '@web3-react/injected-connector';
import { LatticeConnector } from '@web3-react/lattice-connector';
import { LedgerConnector } from '@web3-react/ledger-connector';
import { PortisConnector } from '@web3-react/portis-connector';
import { TrezorConnector } from '@web3-react/trezor-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import { ethers } from 'ethers';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { bUSDCCoin, USDCoin } from '../config/coins';
import { incorrectChainIDError } from '../config/errors';
import { Routes } from '../config/routes';
import { SUPPORTED_CHAINS } from '../config/supportedChains';
import { WalletContext } from '../config/walletContext';
import {
  bsc,
  frame,
  getWeb3WalletType,
  injected,
  lattice,
  ledger,
  portis,
  removeWeb3WalletType,
  trezor,
  walletConnect,
  walletLink,
  Web3WalletType,
} from '../config/wallets';
import { EthersServiceProvider } from '../services/ethersServiceProvider';
import { getAirdropData } from '../state/actions/airdropActions';
import { fetchBumpDetails } from '../state/actions/bumpDetailsAction';
import { fetchCoinDetails } from '../state/actions/coinActions';
import {
  getEpochsSummary,
  getEpochsRewards,
} from '../state/actions/epochsActions';
import { fetchbUSDDetails } from '../state/actions/liquidityActions';
import { fetchLockTimestamp } from '../state/actions/lockTimestampAction';
import {
  getStakingDetails,
  getStakingMerkleInfo,
  getVestingClaimDetails,
} from '../state/actions/merkleTreeActions';
import { getVestingMerkleInfo } from '../state/actions/merkleTreeActions';
import { useAppSelector } from '../state/hooks';
import { resetCoin } from '../state/reducers/coinReducer';
import { createError, removeError } from '../state/reducers/errorReducer';
import { resetLiquidity } from '../state/reducers/liquidityReducer';
import { setTried } from '../state/reducers/uiStateManagementReducer';

type useWalletTuple = {
  active: boolean;
  tried: boolean;
  connectWallet: () => Promise<void>;
};

const useWalletBase = (connector: AbstractConnector): useWalletTuple => {
  const tried = useAppSelector(
    (state) => state.uiStateMgmt.uiStateManagement.tried,
  );
  const merkleState = useAppSelector((state) => state.merkleTree.merkle);
  const epochsState = useAppSelector((state) => state.epochs);

  const { active, activate, account, chainId, error } = useWeb3React();
  const history = useHistory();
  const ethersServiceProvider: EthersServiceProvider =
    EthersServiceProvider.getInstance();
  const walletContext = useContext(WalletContext);
  const dispatch = useDispatch();
  const [activeChainId, setActiveChainId] = useState<number>();

  const connectWallet = async () => {
    try {
      await activate(connector, undefined, true);
      ethersServiceProvider.provider = new ethers.providers.Web3Provider(
        await connector.getProvider(),
      );
      history.replace(Routes.DepositDashboard);
      if (!(connector instanceof PortisConnector)) {
        window.location.reload();
      }
    } catch (err) {
      if (err instanceof UnsupportedChainIdError) {
        await activate(connector);
        connector.getChainId().then((res) => setActiveChainId(res as number));
      } else {
        history.replace(Routes.WalletNotFound);
        console.error(err, 'Error in connecting wallet');
        return Promise.reject();
      }
    }
  };

  const activateWallet = () => {
    activate(connector, undefined, true)
      .then(() => {
        if (ethersServiceProvider && connector) {
          connector
            .getProvider()
            .then((provider) => {
              ethersServiceProvider.provider =
                new ethers.providers.Web3Provider(provider);
            })
            .then(() => {
              dispatch(setTried(true));
            });
        }
      })
      .catch((e) => {
        console.error('Error:', e);
        dispatch(setTried(true));
      });
  };

  useEffect(() => {
    const type = getWeb3WalletType();
    if (
      connector instanceof InjectedConnector &&
      type === Web3WalletType.INJECTED
    ) {
      connector.isAuthorized().then((isAuthorized) => {
        if (isAuthorized) {
          activateWallet();
        } else {
          dispatch(setTried(true));
        }
      });
    } else if (
      (connector instanceof BscConnector && type === Web3WalletType.BSC) ||
      (connector instanceof WalletConnectConnector &&
        window.localStorage.walletconnect) ||
      (connector instanceof WalletLinkConnector &&
        window.localStorage.getItem(
          '-walletlink:https://www.walletlink.org:Addresses',
        ) !== null) ||
      (connector instanceof PortisConnector &&
        type === Web3WalletType.PORTIS) ||
      (connector instanceof FrameConnector && type === Web3WalletType.FRAME) ||
      (connector instanceof LedgerConnector &&
        type === Web3WalletType.LEDGER) ||
      (connector instanceof TrezorConnector &&
        type === Web3WalletType.TREZOR) ||
      (connector instanceof LatticeConnector && type === Web3WalletType.LATTICE)
    ) {
      activateWallet();
    } else {
      dispatch(setTried(true));
    }
  }, [activate]);

  useEffect(() => {
    walletContext.isWalletConnected = active;
    if (active) {
      setActiveChainId(chainId);
      dispatch(setTried(true));
    } else if (!active) {
      dispatch(resetLiquidity());
      dispatch(resetCoin());
    } else if (error instanceof UnsupportedChainIdError) {
      setActiveChainId(chainId);
    }
  }, [active]);

  useEffect(() => {
    if (account) {
      ethersServiceProvider.setCurrentAccount(account);
      if (
        connector instanceof WalletConnectConnector &&
        connector.walletConnectProvider
      ) {
        connector.walletConnectProvider.on('disconnect', () =>
          window.location.reload(),
        );
      }
      dispatch(fetchbUSDDetails());
      dispatch(fetchBumpDetails());
      dispatch(fetchCoinDetails([USDCoin, bUSDCCoin]));
      dispatch(fetchLockTimestamp());
      dispatch(getVestingMerkleInfo(account));
      dispatch(getStakingMerkleInfo(account));
      dispatch(getAirdropData(account));
    }
    dispatch(getEpochsSummary());
  }, [account]);

  useEffect(() => {
    dispatch(
      getEpochsRewards({
        address: account,
        epochsCount: epochsState.epochsData.length,
      }),
    );
  }, [epochsState.epochsData.length, account]);

  useEffect(() => {
    dispatch(getStakingDetails(merkleState.staking));
  }, [merkleState.staking]);

  useEffect(() => {
    dispatch(getVestingClaimDetails(merkleState.vesting));
  }, [merkleState.vesting]);

  useEffect(() => {
    if (activeChainId) {
      if (SUPPORTED_CHAINS.includes(activeChainId)) {
        dispatch(removeError());
        if (account) {
          dispatch(getAirdropData(account));
        }
      } else if (!SUPPORTED_CHAINS.includes(activeChainId)) {
        dispatch(createError(incorrectChainIDError));
      }
    }
  }, [activeChainId]);

  return {
    active,
    tried,
    connectWallet,
  };
};

export const useInjected = (): useWalletTuple => useWalletBase(injected);
export const useBsc = (): useWalletTuple => useWalletBase(bsc);
export const useWalletConnect = (): useWalletTuple =>
  useWalletBase(walletConnect);
export const useLedger = (): useWalletTuple => useWalletBase(ledger);
export const useTrezor = (): useWalletTuple => useWalletBase(trezor);
export const useLattice = (): useWalletTuple => useWalletBase(lattice);
export const useWalletLink = (): useWalletTuple => useWalletBase(walletLink);
export const useFrame = (): useWalletTuple => useWalletBase(frame);
export const usePortis = (): useWalletTuple => useWalletBase(portis);

export const useWeb3Wallet = (): useWalletTuple => {
  const defaultWallet = {
    active: false,
    tried: true,
    connectWallet: () => Promise.resolve(),
  };
  switch (getWeb3WalletType()) {
    case Web3WalletType.INJECTED:
      return useInjected();
    case Web3WalletType.BSC:
      return useBsc();
    case Web3WalletType.WALLET_CONNECT:
      if (!window.localStorage.walletconnect) {
        removeWeb3WalletType();
        return defaultWallet;
      }
      return useWalletConnect();
    case Web3WalletType.LATTICE:
      return useLattice();
    case Web3WalletType.TREZOR:
      return useTrezor();
    case Web3WalletType.LEDGER:
      return useLedger();
    case Web3WalletType.PORTIS:
      removeWeb3WalletType();
      return defaultWallet;
    case Web3WalletType.WALLET_LINK:
      if (
        !window.localStorage.getItem(
          '-walletlink:https://www.walletlink.org:Addresses',
        )
      ) {
        removeWeb3WalletType();
        return defaultWallet;
      }
      return useWalletLink();
    case Web3WalletType.FRAME:
      return useFrame();
    default: {
      removeWeb3WalletType();
      return defaultWallet;
    }
  }
};
