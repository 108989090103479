export const COLORS = {
  Green1: '#004851',
  Green2: '#00353C',
  Green3: '#002429',
  Green4: '#325F65',
  Green5: '#60C670',
  Green6: '#5C9AA0',
  LightGreen: '#8CA6AE',
  White: '#FFFFFF',
  Black: '#000000',
  Primary1: '#FE5000',
  Primary2: '#E07A29',
  Primary3: '#004851',
  Secondary1: '#0085CA',
  Secondary2: '#60C770',
  Support: '#BBBBBB',
  DarkGreen: '#00232F',
  LightText: 'rgba(255, 255, 255, 0.8)',
  ExtraLightText: 'rgba(255, 255, 255, 0.7)',
  LabelText: 'rgba(255, 255, 255, 0.6)',
  Turquoise: '#00FFFF',
  ChartAxis: '#ECE9F1',
  ChartTick: '#B2BDBF',
  TextField: '#2F6F77',
  Orange: '#FF7D2B',
  DarkOrange: '#8F4B15',
  LightGrey: '#99A7A9',
  Border: '#4A7D83',
  EpochNumber: '#033840',
  DarkGrey: 'rgba(255, 255, 255, 0.2)',
  BoxOutline: '#5E979E',
  Divider: '#20474B',
  Red: '#E00C0C',
  CardBackground: 'rgba(0, 0, 0, 0.37)',
};
