import {
  withStyles,
  makeStyles,
  Stepper,
  Theme,
  StepIconProps,
  StepConnector,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { createPixelInsetBorder } from '../../utils/createPixelInsetBorder';

const PixelStepper = withStyles(() => ({
  root: {
    padding: '0px',
    backgroundColor: 'transparent',
    '& .MuiStep-horizontal': {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    '& .MuiStepLabel-iconContainer': {
      paddingRight: '0px',
    },
  },
}))((props) => (
  <Stepper {...props} connector={<PixelStepConnector />}>
    {props.children}
  </Stepper>
)) as typeof Stepper;

const PixelStepConnector = withStyles((theme: Theme) => ({
  active: {
    '& $line': {
      backgroundColor: theme.palette.other.White,
    },
  },
  completed: {
    '& $line': {
      backgroundColor: theme.palette.other.White,
    },
  },
  line: {
    height: 2,
    border: 0,
    backgroundColor: theme.palette.other.LightGrey,
    borderRadius: 1,
    width: '64px',
  },
}))(StepConnector);

const usePixelStepIconStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'transparent',
    zIndex: 1,
    fontFamily: 'Roboto Mono',
    color: theme.palette.other.LightGrey,
    fontSize: '14px',
    fontWeight: 700,
    display: 'flex',
    borderRadius: 0,
    boxShadow: createPixelInsetBorder(
      `${theme.palette.other.LightGrey}`,
      `${theme.palette.other.Green3}`,
      `${theme.palette.other.Green3}`,
    ),
    justifyContent: 'center',
    alignItems: 'center',
    padding: '6px 18px',
    [theme.breakpoints.down('sm')]: {
      padding: '.125rem .5rem',
    },
  },
  active: {
    color: theme.palette.other.Turquoise,
    boxShadow: createPixelInsetBorder(
      `${theme.palette.other.Turquoise}`,
      `${theme.palette.other.Green3}`,
      `${theme.palette.other.Green3}`,
    ),
  },
  completed: {
    color: theme.palette.other.White,
    boxShadow: createPixelInsetBorder(
      `${theme.palette.other.White}`,
      `${theme.palette.other.Green3}`,
      `${theme.palette.other.Green3}`,
    ),
  },
}));

const PixelClaimStepperIcon = (props: StepIconProps) => {
  const classes = usePixelStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <div>Claim</div>,
    2: <div>Staking</div>,
    3: <div>Confirm</div>,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
};
const PixelStepperIcon = (props: StepIconProps) => {
  const classes = usePixelStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <div>Details</div>,
    2: <div>Pre-sale</div>,
    3: <div>Confirm</div>,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
};
const PixelStepperDefaultIcon = (props: StepIconProps) => {
  const classes = usePixelStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <div>Details</div>,
    2: <div>Confirm</div>,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
};

export {
  PixelStepper,
  PixelStepperIcon,
  PixelStepperDefaultIcon,
  PixelClaimStepperIcon,
};
