import { Box } from '@material-ui/core';
import React from 'react';

import { PixelButton } from '../shared/PixelButton';
import {
  ContainerMainEpoch,
  Description,
  Header,
  MainSecondContainer,
} from '../shared/styles/DashboardStyles';

export const StakingSection: React.FC = () => {
  return (
    <ContainerMainEpoch>
      <MainSecondContainer>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gridGap="24px"
        >
          <Header>STAKE</Header>
          <Description style={{ marginTop: 0 }}>
            Staking program is not active anymore. You can claim your rewards or exit your positions. Visit bumper site to know more !
          </Description>
          <Box display={"flex"} gridColumnGap={"20px"}>

          <a
            href="https://bumper.fi/"
            target="_blank"
            style={{ textDecoration: 'none' }}
            rel="noopener noreferrer"
          >
            <PixelButton
              size="small"
              color="secondary"
              style={{ width: 'fit-content', height: '48px' }}
            >
             {"What's new with Bumper"}
            </PixelButton>
          </a>
          <a
          
            href="https://bumper.fi/stake-bump"
            target="_blank"
            style={{ textDecoration: 'none' }}
            rel="noopener noreferrer"
          >
            <PixelButton
              size="small"
              color="secondary"
              style={{ width: 'fit-content', height: '48px' }}
            >
             {"Staking Guides"}
            </PixelButton>
          </a>
          </Box>
        </Box>
      </MainSecondContainer>
    </ContainerMainEpoch>
  );
};
