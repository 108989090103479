import { Box, Backdrop, useMediaQuery } from '@material-ui/core';
import { format } from 'date-fns';
import React from 'react';

import WhiteArrowRight from '../../assets/white-arrow-right.svg';
import { COLORS } from '../../config/colors';
import { theme } from '../../providers/AppThemeProvider';
import { HistoryStakeType } from '../../services/stakingService';
import { formatStringifyNumberToDot } from '../../utils/helpers';
import { PixelCloseIcon } from '../shared/icons/PixelCloseIcon';
import {
  AmountRow,
  AmountTypography,
  CancelButtonSection,
  ColumnName,
  ContainerHistory,
  HistoryHeader,
  ScrollingList,
  StyledWhiteArrow,
} from '../shared/styles/History';

export enum OperationType {
  Stake = 'Stake',
  Unstake = 'Unstake',
  Claim = 'Claim',
  RequestWithdraw = 'Request Withdraw',
  Unknown = 'Unknown',
}

interface HistoryModalProps {
  close: () => void;
  visible: boolean;
  historyData: HistoryStakeType[];
}

const formatOperations = (data: HistoryStakeType[], isMobile?: boolean) =>
  data.map(({ timestamp, operation, amount }) => ({
    timestamp: `${format(
      parseInt(timestamp.toString(), 10) * 1000,
      isMobile ? 'MMM-d-yyyy' : 'MMM-d-yyyy hh:mm:ss bb',
    )} ${isMobile ? '' : '+UTC'}`,
    operation,
    amount: formatStringifyNumberToDot(amount.toString()),
  }));

const getOperationColor = (operation: string) => {
  switch (operation) {
    case OperationType.Stake:
      return COLORS.Secondary2;
    case OperationType.Unstake:
      return COLORS.Green6;
    case OperationType.Claim:
      return COLORS.Orange;
    default:
      return COLORS.Green6;
  }
};

export const HistoryModal: React.FC<HistoryModalProps> = ({
  close,
  visible,
  historyData,
}) => {
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const formattedData = formatOperations(historyData, smallScreen);
  return (
    <Backdrop open={visible} style={{ zIndex: 1000 }}>
      <ContainerHistory>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={5}
        >
          <HistoryHeader>History</HistoryHeader>
          <Box display="flex" alignItems="center">
            <CancelButtonSection onClick={close}>
              <PixelCloseIcon style={{ cursor: 'pointer' }} />
            </CancelButtonSection>
          </Box>
        </Box>
        <ScrollingList>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <ColumnName>Timestamp</ColumnName>
            <ColumnName>Operation</ColumnName>
            <ColumnName>Amount </ColumnName>
          </Box>
          {formattedData && (
            <Box sx={{ marginTop: '1.5rem' }}>
              {formattedData.map(({ timestamp, amount, operation }, index) => (
                <AmountRow key={`${operation}-${timestamp}-${index}`}>
                  <AmountTypography>{timestamp}</AmountTypography>
                  <AmountTypography
                    style={{ color: getOperationColor(operation) }}
                  >
                    {operation}
                    <StyledWhiteArrow src={WhiteArrowRight} />
                  </AmountTypography>
                  <AmountTypography>{amount}</AmountTypography>
                </AmountRow>
              ))}
            </Box>
          )}
        </ScrollingList>
      </ContainerHistory>
    </Backdrop>
  );
};
