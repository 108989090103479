import { Box, styled } from '@material-ui/core';

export const FlowContainer = styled('div')(({ theme }) => ({
  maxWidth: '40vw',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: theme.spacing(9),
  margin: 'auto',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '90%',
    paddingTop: theme.spacing(3),
  },
}));

export const MainFlowContainer = styled(Box)(({ theme }) => ({
  maxWidth: '544px',
  width: '544px',
  display: 'grid',
  gridTemplateColumns: 'none',
  gridTemplateRows: 'none',
  gridTemplateAreas: `"coin-details"
     "deposit-details"
     "something-else"
     "action-buttons"
    `,
  gridGap: '16px',
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
    width: 'unset',
  },
}));

export const FlowCard = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    maxWidth: '500px',
  },
}));

export const FlowButtonsBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '1rem',
  paddingBottom: '10vh',
  [theme.breakpoints.down('sm')]: {
    marginTop: '.625rem',
    flexDirection: 'column-reverse',
  },
}));

export const FlowButtonWrapper = styled(Box)(({ theme }) => ({
  width: '10rem',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    '&:last-child': {
      marginBottom: '1rem',
    },
  },
}));
