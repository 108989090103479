import React from 'react';

import { Container } from '../components/common/MainContainer';
import { Wrapper } from '../components/common/Wrapper';
import { AvailableReserveCard } from '../components/ProtocolPage/AvailableReserveCard';
import { USDCoin } from '../config/coins';

export const ProtocolPage: React.FC = () => {
  return (
    <>
      <Container>
        <Wrapper title="Bumper Status">
          <AvailableReserveCard token={USDCoin} />
        </Wrapper>
      </Container>
    </>
  );
};
