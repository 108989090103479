import { styled, Box } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Routes } from '../../config/routes';
import { PixelButton } from '../shared/PixelButton';
import { ExtraSmallRoboto } from '../shared/styles/TypographyRoboto';

export const ConnectBlock: React.FC = () => {
  const history = useHistory();

  return (
    <StyledConnectBlock>
      <WarnText>
        Duh! You’re not plugged in! Connect your wallet to continue.
      </WarnText>
      <PixelButton
        size="small"
        color="secondary"
        onClick={() => history.push(Routes.ConnectWallet)}
      >
        Connect
      </PixelButton>
    </StyledConnectBlock>
  );
};

const StyledConnectBlock = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#002429',
  border: '2.5px solid black',
  padding: '1rem 1rem',
});

const WarnText = styled(ExtraSmallRoboto)(({ theme }) => ({
  fontStyle: 'normal',
  lineHeight: '18px',
  textAlign: 'center',
  color: '#8CA6AE',
  marginRight: '7rem',
  [theme.breakpoints.down('sm')]: {
    marginRight: '1rem',
  },
}));
