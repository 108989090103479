import { createAsyncThunk } from '@reduxjs/toolkit';

import { chainName } from '../../config/contractAddresses';
import { getAirdropUserData } from '../../services/firebaseStorageService';
import {
  AirdropMerkleNodeType,
  AirdropMerkleType,
} from '../reducers/merkleTree';

export const getAirdropData = createAsyncThunk<
  AirdropMerkleType | null,
  string
>('getAirdropData', async (address: string) => {
  return {
    available: await getAirdropClaimDetails(address),
  };
});

const getAirdropClaimDetails = async (address: string) => {
  try {
    const data = await getAirdropUserData(chainName, address);

    return data.map(({ index, usdc, bump, proof }) => ({
      index,
      amountUSDC: usdc,
      amountBUMP: bump,
      account: address,
      proof,
    })) as AirdropMerkleNodeType;
  } catch (err) {
    console.error(err);
    return null;
  }
};
