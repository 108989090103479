const discordLink = 'https://discord.gg/YyzRws4Ujd';
const telegramLink = 'https://t.me/bumperfinance';
const termsAndConditionsLink = 'https://www.bumper.fi/terms-and-conditions';
const docsLink = 'https://docs.bumper.fi/';
const supportLink = 'mailto:support@bumper.fi';
const learnMoreLink = 'https://www.bumper.fi/learn';
const bumperGuideLink =
  'https://bumper-finance.webflow.io/learn/depositing-during-lp-program#metamask';
const feeAndLevyLink = 'https://app.gitbook.com/login/bumper/documentation';
const learnMorePost14Oct =
  'https://www.bumper.fi/learn/participate-in-the-pre-sale';
export {
  discordLink,
  telegramLink,
  termsAndConditionsLink,
  docsLink,
  supportLink,
  learnMoreLink,
  bumperGuideLink,
  feeAndLevyLink,
  learnMorePost14Oct,
};
