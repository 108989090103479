import { Box, styled } from '@material-ui/core';
import React from 'react';

import Typography, { ExtraSmallTypography } from '../../shared/Typography';

interface WrapperProps {
  title: string;
  lowTitle?: string;
}

export const Wrapper: React.FC<WrapperProps> = (props) => {
  return (
    <Box mb={5}>
      {props.title.length !== 0 && (
        <Typography
          variant="h4"
          secondaryFont
          style={{ paddingBottom: '25px' }}
        >
          <Title>
            {props.title}
            <ExtraSmallTypography>{props.lowTitle}</ExtraSmallTypography>
          </Title>
        </Typography>
      )}
      {props.children}
    </Box>
  );
};

const Title = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
});
