import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { IConfirmDepositLocationProp } from './ConfirmDepositSection';

import { bUSDCCoin } from '../../config/coins';
import { Routes, subRoutes } from '../../config/routes';
import { ICoin } from '../../interfaces/ICoin';
import { useAppSelector } from '../../state/hooks';
import { dateFormatter } from '../../utils/helpers';
import { CoinDetails } from '../common/CoinDetails';
import { DepositDetails } from '../common/DepositDetails';
import { PixelButton } from '../shared/PixelButton';
import {
  FlowButtonsBlock,
  FlowButtonWrapper,
  FlowCard,
  MainFlowContainer,
} from '../shared/styles/FlowPages';
import { Title } from '../shared/styles/Section';
interface ILiquidityDepositSectionProps {
  token: ICoin;
}

export const LiquidityDepositSection: React.FC<ILiquidityDepositSectionProps> =
  ({ token }) => {
    const location = useLocation<IConfirmDepositLocationProp>();
    const { liquidityDepositValue } = location.state || {};

    const history = useHistory();
    const coin = useAppSelector(
      (state) => state.coin.coinDetails[token.symbol],
    );

    const [liquidityValue, setLiquidityValue] = useState<string>(
      liquidityDepositValue || '',
    );

    const unlockTime = useAppSelector(
      (state) => state.lockTimestamp.lockTimestampDetails.SwapUnlockTime,
    );

    const onClick = () => {
      const props: IConfirmDepositLocationProp = {
        liquidityDepositValue: liquidityValue,
        token,
        toToken: bUSDCCoin,
        unlockDate: dateFormatter(unlockTime),
        lockedDeposit: parseFloat(liquidityValue).toFixed(6),
        estimatedBumpPurchase: '0',
      };
      history.push({
        pathname: `${Routes.AddLiquidity}/${subRoutes.Confirm}`,
        state: props,
      });
    };

    const onCancel = () => {
      history.replace(Routes.DepositDashboard);
    };

    const getCoinDetails = () => {
      return {
        ...coin,
        value: parseFloat(coin.value).toFixed(2),
        price: parseFloat(coin.price).toFixed(2),
      };
    };

    return (
      <Box display="flex" flexDirection="column" alignItems="start">
        <Title>Deposit Liquidity</Title>
        <MainFlowContainer>
          <FlowCard gridArea="coin-details">
            <CoinDetails token={token} {...getCoinDetails()} />
          </FlowCard>
          <FlowCard gridArea="deposit-details">
            <DepositDetails
              liquidityValue={liquidityValue}
              setLiquidityValue={setLiquidityValue}
              token={token}
              text="Enter the amount you want to deposit."
              title="Deposit Amount"
            />
          </FlowCard>
          <FlowButtonsBlock>
            <FlowButtonWrapper>
              <PixelButton fullWidth color="secondary" onClick={onCancel}>
                Cancel
              </PixelButton>
            </FlowButtonWrapper>
            <FlowButtonWrapper>
              <PixelButton fullWidth color="primary" onClick={onClick}>
                Next
              </PixelButton>
            </FlowButtonWrapper>
          </FlowButtonsBlock>
        </MainFlowContainer>
      </Box>
    );
  };
