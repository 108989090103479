import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

import TickPixelIcon from '../../assets/tick-pixel-icon.svg';
import { createPixelInsetBorder } from '../../utils/createPixelInsetBorder';

const useStyles = makeStyles((theme) => ({
  icon: {
    borderRadius: 0,
    width: 28,
    height: 28,
    boxShadow: createPixelInsetBorder('black', '#C4C4C4', '#979797'),
    backgroundColor: theme.palette.other.White,
  },
  checkedIcon: {
    backgroundColor: theme.palette.other.Green1,
    boxShadow: createPixelInsetBorder('black', '#00A9BE', '#012A2E'),
    '&:before': {
      display: 'block',
      width: 28,
      height: 28,
      backgroundImage: `url(${TickPixelIcon})`,
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      content: '""',
    },
  },
}));

const PixelCheckbox: React.FC<CheckboxProps> = (props) => {
  const classes = useStyles();

  return (
    <Checkbox
      disableRipple
      color="default"
      icon={<span className={classes.icon} />}
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      inputProps={{ 'aria-label': 'checkbox' }}
      {...props}
    />
  );
};

export { PixelCheckbox };
