import { Box, IconButton, makeStyles, useMediaQuery } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import simpleBumperIcon from '../../../assets/bumper-simple-icon.svg';
import { theme } from '../../../providers/AppThemeProvider';
import { fetchBumpDetails } from '../../../state/actions/bumpDetailsAction';
import { useAppSelector } from '../../../state/hooks';
import { formatStringifyNumberToDot } from '../../../utils/helpers';
import { CustomHelpIcon } from '../styles/DashboardStyles';
import { Tooltip } from '../Tooltip';
import { H7Typography } from '../Typography';

const useStyles = makeStyles({
  mobileLogoSize: {
    width: '16px',
    height: '16px',
  },
  desktopLogoSize: {
    width: '12px',
    height: '12px',
  },
  mobileFontSize: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
  },
});
const bumpBalanceLockedText =
  'BUMP tokens are locked as per your Protection or Deposit Policy details.';
export const BumpBalanceInfo: React.FC = () => {
  const classes = useStyles();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  const { bumpBalance, bumpBalanceInUsdc } = useAppSelector(
    (state) => state.bumpDetails,
  );

  useEffect(() => {
    const polling = setInterval(async () => {
      dispatch(fetchBumpDetails());
    }, 10000);

    return () => clearInterval(polling);
  }, []);

  return (
    <Box
      display="flex"
      alignItems="center"
      flexWrap="wrap"
      sx={{ gridGap: '8px' }}
    >
      <img
        className={
          smallScreen ? classes.mobileLogoSize : classes.desktopLogoSize
        }
        src={simpleBumperIcon}
      />
      <Box display="flex" justifyContent="center" alignItems="center">
        <H7Typography
          secondaryFont
          style={{ color: 'rgba(255, 255, 255, 0.6)' }}
          className={smallScreen ? classes.mobileFontSize : ''}
        >
          {' BUMP Balance '}
        </H7Typography>
        <Tooltip title={bumpBalanceLockedText}>
          <IconButton style={{ padding: '0px', paddingBottom: '2px' }}>
            <CustomHelpIcon
              style={{
                color: 'rgba(255, 255, 255, 0.6)',
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <H7Typography
        className={smallScreen ? classes.mobileFontSize : ''}
        secondaryFont
      >
        {`${formatStringifyNumberToDot(bumpBalance) || '0.0000'}`}
      </H7Typography>
      <H7Typography
        className={smallScreen ? classes.mobileFontSize : ''}
        secondaryFont
      >
        {` ($${formatStringifyNumberToDot(bumpBalanceInUsdc)} USD)`}
      </H7Typography>
    </Box>
  );
};
