import { Link } from '@material-ui/core';
import React from 'react';

import { Container } from '../components/common/MainContainer';
import { Wrapper as GovernanceSection } from '../components/common/Wrapper';
import { MediumTypography } from '../components/shared/Typography';
import { docsLink } from '../config/links';
import { theme } from '../providers/AppThemeProvider';

export const GovernancePage: React.FC = () => {
  return (
    <>
      <Container>
        <GovernanceSection title="Executive Summary">
          <MediumTypography>
            BUMPER is a decentralized finance protocol aimed at protecting
            cryptocurrency holders from downside risk, while preserving
            opportunities for appreciation. Bumper consists of a decentralised
            software facility for &apos;Takers&apos; of protection to operate
            diametrically to &apos;Makers&apos; of liquidity.
          </MediumTypography>
          <br></br>
          <MediumTypography>
            BUMP is the ERC-20 standard governance token for Bumper, and is
            designed to provide increasing levels of decision-making ability and
            control over the product to the users, asset owners, and other
            stakeholders.
          </MediumTypography>
        </GovernanceSection>
        <GovernanceSection title="Bump Token">
          <MediumTypography>
            The Bumper protocol will be governed by holders of the Bumper
            Governance Token, BUMP, an ERC-20 token. BUMP can be acquired in
            several ways. The primary method of receiving Bumper will be as a
            reward for acting as a Maker or Taker in using the protocol. BUMP
            can also be acquired through pre-existing token allocations, token
            rewards for protocol research and design tasks, decentralized
            &quot;Yield Farming&quot; in protocol bootstrap phase ahead of v1.0
            launch, decentralized swaps / purchases in protocol bootstrap phase
            ahead of v1.0 launch, and payment to service providers.
          </MediumTypography>
        </GovernanceSection>
        <GovernanceSection title="Voting and Control">
          <MediumTypography>
            The holders of BUMP will control policies critical to the
            development and functions of the Bumper protocol and the Bumper
            marketplace. BUMP holders will propose and vote on referendums
            regarding protocol safety, economics, expansion and updates, as well
            as decisions on which digital assets should be accepted in the
            Reserve.
          </MediumTypography>
          <br></br>
          <MediumTypography>
            Find out more at{' '}
            <Link
              href={docsLink}
              target="_blank"
              rel="noreferrer"
              style={{
                color: theme.palette.other.BrightGreen,
              }}
            >
              docs.bumper.fi
            </Link>
          </MediumTypography>
        </GovernanceSection>
      </Container>
    </>
  );
};
