import { Box } from '@material-ui/core';
import React from 'react';

import { checkNullable, formatStringifyNumberToDot } from '../../utils/helpers';
import { useStyles } from '../shared/styles/GlobalSections';
import { SlimDivider } from '../shared/styles/Section';
import { SummaryField } from '../shared/SummaryField';
import { MediumTypography, MediumBoldTypography } from '../shared/Typography';

interface IWithdrawSummaryProps {
  totalWithdrawAmt: string;
  totalWithdrawAmtInUsd: string;
  usdcAmount: string;
}

export const WithdrawalDetailsSummary: React.FC<IWithdrawSummaryProps> = (
  props,
) => {
  const classes = useStyles();

  return (
    <>
      <SlimDivider style={{ marginBottom: '1rem' }} />
      <Box display="grid" gridGap="24px">
        {checkNullable(props.usdcAmount) && (
          <SummaryField>
            <MediumTypography color="secondary">
              Withdrawal Amount
            </MediumTypography>
            <MediumBoldTypography secondaryFont>
              {formatStringifyNumberToDot(props.usdcAmount)}
              <span className={classes.unit}> USDC</span>
            </MediumBoldTypography>
          </SummaryField>
        )}
      </Box>
    </>
  );
};
