import { format } from 'date-fns';
import { BigNumberish, ethers } from 'ethers';
import floor from 'lodash/floor';

import { CONTRACTS } from '../config/contractAddresses';
import { UniswapService } from '../services/uniswapService';

// Takes an Ethereum address and shortens it to '0x0000...0000' format.
const shortenAddress = (account: string) => {
  return account.slice(0, 6) + '...' + account.slice(-4);
};

const dayTickFormatter = (dayNumber: number | 'auto') => {
  if (dayNumber === 'auto') return '';

  return format(new Date(dayNumber * 86400 * 1000), 'd MMM');
};

const weekTickFormatter = (weekNumber: number) => {
  return format(new Date(weekNumber * 86400 * 7 * 1000), 'd MMM');
};

const numberFormatter = (number: number) => {
  if (number > 1000000000) {
    return (number / 1000000000).toFixed(2).toString() + 'B';
  } else if (number > 1000000) {
    return (number / 1000000).toFixed(2).toString() + 'M';
  } else {
    return number.toString();
  }
};

const dateFormatter = (timestamp: number) => {
  return format(new Date(timestamp * 1000), 'd MMM yyyy');
};

// Returns false if coin balance is defined and not zero
// Returns true if coin balance is null, undefined, empty  string, or zero.
const isCoinBalanceZero = (balance: string) => {
  return isNaN(parseFloat(balance)) || parseFloat(balance) == 0;
};

// Can be used to display a number formatted with commas, e.g. 1,479,0202
const formatToLocaleString = (
  val: string | number,
  options?: Intl.NumberFormatOptions,
) => {
  if (typeof val === 'string') {
    const floatVal = parseFloat(val);
    return !isNaN(floatVal)
      ? floatVal.toLocaleString(undefined, { ...options })
      : val;
  }

  return !isNaN(val) ? val.toLocaleString(undefined, { ...options }) : '';
};

// Can be used to round down a decimal to a certain precision.
const floorDecimal = (number: number, precision: number) => {
  return floor(number, precision);
};

// All token values should be rounded DOWN to 4 decimal places before display
// NOTE: this is for the purpose of display only, not calculations.
const floorTokenValue = (val: string | number, precision = 6) => {
  const value: number = typeof val === 'string' ? parseFloat(val) : val;

  return !isNaN(value) ? floorDecimal(value, precision).toFixed(precision) : '';
};

const formatUSDC = (val: BigNumberish): string => {
  const usdcDecimals = 6;
  return formatStringifyNumberToDot(
    ethers.utils.formatUnits(val, usdcDecimals),
  );
};

const formatBUMP = (val: BigNumberish): string => {
  const usdcDecimals = 18;
  return formatStringifyNumberToDot(
    ethers.utils.formatUnits(val, usdcDecimals),
  );
};

// All token values displayed to the user should have precision of 4 decimal places.
const formatTokenValue = (val: string, precision = 4) => {
  const floatVal = parseFloat(val);

  return !isNaN(floatVal)
    ? formatToLocaleString(floorTokenValue(val, precision), {
        minimumFractionDigits: precision,
      })
    : val;
};

const convertBumpToDollars = async (bump: string): Promise<string> => {
  const uniswap = UniswapService.getInstance();
  // Return $0.00 by default if user has no BUMP or app still loading data
  if (!bump || !uniswap) {
    return '0.00';
  }

  const dollarValue = await uniswap.getBumpPriceInUSDC(
    ethers.utils.parseUnits(bump, CONTRACTS.TOKEN_DETAILS.BUMP.decimal),
  );

  return ethers.utils.formatUnits(
    dollarValue,
    CONTRACTS.TOKEN_DETAILS.USDC.decimal,
  );
};

const formatStringifyNumberToDot = (value: string, digits?: number) => {
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: digits !== undefined && digits >= 0 ? digits : 4, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: digits !== undefined && digits >= 0 ? digits : 4, // (causes 2500.99 to be printed as $2,501)
  });
  const numberValue = parseFloat(value) || 0;
  return currencyFormatter.format(numberValue).slice(1);
};
const checkNullable = (value: string): boolean => {
  return parseFloat(value) > 0;
};

const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
  list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous[group]) previous[group] = [];
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);

const EPOCH_DURATION = 14;
const getEpoch = (
  timestamp: number = Date.now() - new Date().getTimezoneOffset() * 60 * 1000,
) => {
  // currently epoch count calculation may be not really obvious
  // because each epoch is 14 days long, but there was 7 days delay
  // before 5th epoch start
  const calcPassedEpochs = (daysGone: number) =>
    Math.floor(daysGone / EPOCH_DURATION);

  let daysGone =
    (timestamp - Date.parse('2021-10-14 12:00:00 GMT')) / 1000 / 3600 / 24;

  daysGone -= calcPassedEpochs(daysGone) >= 5 ? 7 : 0;

  const epochsGone = calcPassedEpochs(daysGone);

  const currentEpoch = epochsGone + 1;

  const { epochStartDate, epochEndDate } = getEndEpochDate(currentEpoch);

  return {
    newEpochStartDate: epochStartDate,
    newEpochEndDate: epochEndDate,
    epoch: currentEpoch,
  };
};
const getEndEpochDate = (epoch: number) => {
  // Had to introduce a gap of 7 days since there was a gap of 7 days between end of Epoch 4 and start of Epoch 5
  const epochStartDate =
    epoch <= 4
      ? new Date(
          new Date(Date.UTC(2021, 9, 14, 12, 0, 0)).toUTCString().slice(0, -3),
        )
      : new Date(
          new Date(Date.UTC(2021, 9, 21, 12, 0, 0)).toUTCString().slice(0, -3),
        );

  epochStartDate.setDate(
    epochStartDate.getDate() + (epoch - 1) * EPOCH_DURATION,
  );
  const epochEndDate = new Date(
    epochStartDate.getTime() - epochStartDate.getTimezoneOffset(),
  );
  epochEndDate.setDate(epochEndDate.getDate() + EPOCH_DURATION);

  return { epochStartDate, epochEndDate };
};
const timeInMilliseconds = (lockTimestamp: number) => {
  const date = new Date(0);
  return date.setUTCSeconds(lockTimestamp);
};

function countProps(obj: any) {
  let count = 0;
  for (const k in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(k)) {
      count++;
    }
  }
  return count;
}

function objectEquals(v1: any, v2: any) {
  if (typeof v1 !== typeof v2) {
    return false;
  }

  if (typeof v1 === 'function') {
    return v1.toString() === v2.toString();
  }

  if (v1 instanceof Object && v2 instanceof Object) {
    if (countProps(v1) !== countProps(v2)) {
      return false;
    }
    let r = true;
    for (const k in v1) {
      r = objectEquals(v1[k], v2[k]);
      if (!r) {
        return false;
      }
    }
    return true;
  } else {
    return v1 === v2;
  }
}

export {
  shortenAddress,
  dayTickFormatter,
  weekTickFormatter,
  numberFormatter,
  isCoinBalanceZero,
  dateFormatter,
  formatToLocaleString,
  floorDecimal,
  floorTokenValue,
  formatTokenValue,
  convertBumpToDollars,
  formatStringifyNumberToDot,
  checkNullable,
  getEpoch,
  getEndEpochDate,
  timeInMilliseconds,
  formatUSDC,
  formatBUMP,
  groupBy,
  objectEquals,
};
