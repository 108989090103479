import { Box, makeStyles, Paper, styled } from '@material-ui/core';
import React from 'react';
import { ToastProps } from 'react-toast-notifications';

import { ToastCrossIcon } from './icons/ToastCross';
import { ToastTickIcon } from './icons/ToastTick';
import { ToastWarningIcon } from './icons/ToastWarning';
import { MediumBoldTypography, SmallTypography } from './Typography';

const useStyles = makeStyles((theme) => ({
  success: {
    boxShadow: `0px -3px 0px ${theme.palette.highlight.second},
    inset 0px 0px 0px ${theme.palette.backgroundColor.dark},
    0px -6px 0px ${theme.palette.backgroundColor.dark},
    -6px 0px 0px ${theme.palette.backgroundColor.dark},
    6px 0px 0px ${theme.palette.backgroundColor.dark},
    0px 6px 0px ${theme.palette.backgroundColor.dark},
    3px -3px 0px ${theme.palette.backgroundColor.dark}, 
    -3px -3px 0px ${theme.palette.backgroundColor.dark}, 
    -3px 3px 0px ${theme.palette.backgroundColor.dark},
    3px 3px 0px ${theme.palette.backgroundColor.dark}`,
  },
  warning: {
    boxShadow: `0px -3px 0px ${theme.palette.primary.main},
    inset 0px 0px 0px ${theme.palette.backgroundColor.dark},
    0px -6px 0px ${theme.palette.backgroundColor.dark},
    -6px 0px 0px ${theme.palette.backgroundColor.dark},
    6px 0px 0px ${theme.palette.backgroundColor.dark},
    0px 6px 0px ${theme.palette.backgroundColor.dark},
    3px -3px 0px ${theme.palette.backgroundColor.dark}, 
    -3px -3px 0px ${theme.palette.backgroundColor.dark}, 
    -3px 3px 0px ${theme.palette.backgroundColor.dark},
    3px 3px 0px ${theme.palette.backgroundColor.dark}`,
  },
}));

export const Toast: React.FC<ToastProps> = (props: ToastProps) => {
  const classes = useStyles();

  const appearance = {
    success: props.appearance == 'success',
    warning: props.appearance === 'warning',
  };

  return (
    <ToastPaper
      className={
        appearance.success
          ? `${classes.success}`
          : appearance.warning
          ? `${classes.warning}`
          : ''
      }
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <Box style={{ marginLeft: '8px', marginTop: '12px' }}>
        {appearance.success ? (
          <ToastTickIcon />
        ) : appearance.warning ? (
          <ToastWarningIcon />
        ) : null}
      </Box>
      <Box style={{ marginTop: '12px', marginLeft: '16px', width: '194px' }}>
        <MediumBoldTypography>
          {appearance.success
            ? 'Transaction Complete'
            : appearance.warning
            ? 'Transaction Error'
            : null}
        </MediumBoldTypography>
        <SmallTypography style={{ fontWeight: 400 }}>
          {props.children}
        </SmallTypography>
      </Box>
      <Box
        style={{ marginLeft: '16px', marginTop: '12px', cursor: 'pointer' }}
        onClick={() => props.onDismiss()}
      >
        <ToastCrossIcon />
      </Box>
    </ToastPaper>
  );
};

const ToastPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  width: '291px',
  height: 'fit-content',
  padding: '0.5em 0 1em 0',
  background: theme.palette.backgroundColor.dark,
  borderRadius: '0px',
  margin: '38px',
}));
