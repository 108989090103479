import { Box, IconButton, styled } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import { useHistory } from 'react-router-dom';

import { SwitchComponent } from './SwitchComponent';

import { isFrontendFunctionalityDisabled } from '../../config';
import { COLORS } from '../../config/colors';
import { Routes, subRoutes } from '../../config/routes';
import { ICoin } from '../../interfaces/ICoin';
import { theme } from '../../providers/AppThemeProvider';
import { RewardType, secondsByDays } from '../../services/stakingService';
import { useAppSelector } from '../../state/hooks';
import {
  formatStringifyNumberToDot,
  timeInMilliseconds,
} from '../../utils/helpers';
import { ConfirmAutoRenewState } from '../ConfirmStaking/ConfirmAutoRenew';
import { ConfirmClaimRewardsState } from '../ConfirmStaking/ConfirmClaimRewards';
import { ConfirmEjectStakeState } from '../ConfirmStaking/ConfirmEject';
// import { ConfirmRestakeState } from '../ConfirmStaking/ConfirmRestake';
import { ConfirmUnstakeState } from '../ConfirmStaking/ConfirmUnstake';
import { CoinIcon } from '../shared/CoinIcon';
import { ContentSection } from '../shared/ContentSection';
import { CoinIcons } from '../shared/icons/CoinIcons';
import { PixelButton } from '../shared/PixelButton';
import { CustomHelpIcon } from '../shared/styles/GlobalSections';
import {
  SectionFixed,
  SectionContent,
  SectionInfo,
  SectionName,
  StakedAmount,
  UnstakeButton,
} from '../shared/styles/StakeStyles';
import { Tooltip } from '../shared/Tooltip';
import { TopSection } from '../shared/TopSection';
import { LargeBoldTypography } from '../shared/Typography';

export interface FixedStakeCardState {
  token: ICoin;
  amount: number;
  rewards: RewardType;
  autorenew: boolean;
  stakeIndex: number;
  option: number;
  start: number;
  withdrawWindow: number;
  interval: number;
  isEjectEnabled: boolean;
  end: number;
}

export const CountDownStaking = React.memo<CountdownRenderProps>((props) => {
  return (
    <>
      {props.completed ? (
        <Timer>
          00
          <span>:</span>
          00
          <span>:</span>
          00
          <span>:</span>
          00
        </Timer>
      ) : (
        <Timer>
          {props.formatted.days}
          <span>:</span>
          {props.formatted.hours}
          <span>:</span>
          {props.formatted.minutes}
          <span>:</span>
          {props.formatted.seconds}
        </Timer>
      )}
    </>
  );
});

export const FixedStakeCard: React.FC<FixedStakeCardState> = ({
  token,
  amount,
  rewards,
  autorenew,
  stakeIndex,
  // option,
  start,
  interval,
  withdrawWindow,
  isEjectEnabled,
  end,
}) => {
  const history = useHistory();
  const utcTime = useAppSelector((state) => state.lockTimestamp.utcTime);
  const { rewards: stakingRewards } = rewards;

  const startUnstakeFlow = () => {
    const props: ConfirmUnstakeState = {
      stakeIndex,
      amount,
      rewards: stakingRewards,
    };
    history.push({
      pathname: `${Routes.Unstake}/${subRoutes.Confirm}`,
      state: props,
    });
  };

  const startEjectFlow = () => {
    const props: ConfirmEjectStakeState = {
      stakeIndex,
      amount,
      rewards: stakingRewards,
    };
    history.push({
      pathname: `${Routes.EjectStake}/${subRoutes.Confirm}`,
      state: props,
    });
  };

  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  let periods = 0;
  const dayDiff = Date.now() / 1000 - start;

  if (autorenew) {
    periods = dayDiff / (secondsByDays(interval) + withdrawWindow);
    periods = Math.ceil(periods);
  } else {
    const endStartDiff = end - start;
    // Implies that this is an untouched position with no auto-renew adultration
    if (endStartDiff / secondsByDays(interval) === 1) {
      periods = endStartDiff / secondsByDays(interval);
      periods = Math.ceil(periods);
    } else {
      periods = dayDiff / secondsByDays(interval);
      periods = Math.ceil(periods);
    }
  }

  // This gets us the cycle between two periods
  // const cycleBetweenStartAndStakeEnd = (end - start) / 86400;

  let multiplier = 1;
  if (autorenew || periods > 1) {
    multiplier = periods;
  }

  let endDate = 0;
  if (autorenew && periods > 1) {
    endDate = timeInMilliseconds(
      start +
        withdrawWindow * (periods - 1) +
        multiplier * secondsByDays(interval),
    );
  } else {
    endDate = timeInMilliseconds(start + multiplier * secondsByDays(interval));
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
      <TopSection color={`${theme.palette.other.Turquoise}`}>
        <Box display="flex" alignItems="center">
          <CoinIcon src={CoinIcons[token.symbol]} />
          {!smallScreen && (
            <LargeBoldTypography secondaryFont>
              {token.symbol}
            </LargeBoldTypography>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          {!smallScreen &&
            !Number.isNaN(endDate) &&
            autorenew &&
            Date.now() > endDate && (
              <Box mr={3} display="flex" alignItems="center">
                <Tooltip title={'Time in which you can unstake your BUMP'}>
                  <IconButton
                    style={{
                      padding: '0px',
                      marginRight: '0.375rem',
                    }}
                  >
                    <CustomHelpIcon />
                  </IconButton>
                </Tooltip>
                <Countdown
                  date={endDate + withdrawWindow * 1000}
                  renderer={(properties) => (
                    <CountDownStaking {...properties} />
                  )}
                  key={endDate}
                />
              </Box>
            )}
          {!smallScreen && (
            <SwitchComponent
              value={autorenew}
              text="Auto-renew"
              tooltip="Select ‘Auto-renew’ to continue your staking beyond your current fixed term. If selected, at the end of your current fixed term, you will enter another term for the same fixed period as before. This option will incur a network transaction fee."
              disabled={
                isFrontendFunctionalityDisabled(utcTime) ||
                endDate <= Date.now()
              }
              confirmAutoRenew={() => {
                const props: ConfirmAutoRenewState = {
                  autorenew: true,
                  renewValue: autorenew,
                  stakeIndex,
                };
                history.push({
                  pathname: `${Routes.ConfirmAutoRenew}/${subRoutes.Confirm}`,
                  state: props,
                });
              }}
            />
          )}
          {/* {!(autorenew && Date.now() > endDate) && (
            <PixelButton
              size="small"
              color="secondary"
              style={{
                marginLeft: smallScreen ? 0 : '20px',
                marginTop: smallScreen ? '0.5em' : 0,
              }}
              disabled={autorenew || endDate > Date.now()}
              onClick={() => {
                const props: ConfirmRestakeState = {
                  stakeIndex,
                  amount,
                  option,
                  rewards: stakingRewards,
                };
                history.push({
                  pathname: `${Routes.Restake}/${subRoutes.Confirm}`,
                  state: props,
                });
              }}
            >
              Restake
            </PixelButton>
          )} */}
          <UnstakeButton
            onClick={startUnstakeFlow}
            disabled={endDate > Date.now()}
            style={{ marginTop: smallScreen ? '0.5em' : 0 }}
          >
            Unstake
          </UnstakeButton>
          {Date.now() <= endDate && isEjectEnabled ? (
            <PixelButton
              onClick={() => startEjectFlow()}
              disabled={!isEjectEnabled}
              size="small"
              color="secondary"
              style={{
                marginLeft: smallScreen ? 0 : '20px',
                marginTop: smallScreen ? '0.5em' : 0,
              }}
            >
              Eject
            </PixelButton>
          ) : (
            <></>
          )}
        </Box>
      </TopSection>
      <ContentSection>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <SectionFixed>
            {smallScreen && (
              <SectionInfo sx={{ paddingTop: '5px' }}>
                <SwitchComponent
                  value={autorenew}
                  text="Auto-renew"
                  tooltip="Select ‘Auto-renew’ to continue your staking beyond your current fixed term. If selected, at the end of your current fixed term, you will enter another term for the same fixed period as before. This option will incur a network transaction fee."
                  disabled={endDate <= Date.now()}
                  confirmAutoRenew={() => {
                    const props: ConfirmAutoRenewState = {
                      autorenew: true,
                      renewValue: autorenew,
                      stakeIndex,
                    };
                    history.push({
                      pathname: `${Routes.ConfirmAutoRenew}/${subRoutes.Confirm}`,
                      state: props,
                    });
                  }}
                />
              </SectionInfo>
            )}
            <SectionInfo>
              <Box display="flex" flexDirection="row">
                <SectionName>Staked:</SectionName>
              </Box>
              <SectionContent>
                <StakedAmount>{`${formatStringifyNumberToDot(
                  amount + '',
                )} `}</StakedAmount>
                <StakedAmount
                  style={{
                    lineHeight: '16px',
                    fontSize: '12px',
                    padding: '0.4em 0 0 0.4em',
                  }}
                >
                  BUMP
                </StakedAmount>
              </SectionContent>
            </SectionInfo>
            <SectionInfo>
              <Box display="flex" alignItems="center">
                <SectionName>Period:</SectionName>
                <Box display="flex" flexDirection="row">
                  <SectionName
                    style={{
                      color: '#8CA6AE',
                    }}
                  >
                    Fixed
                  </SectionName>
                </Box>
              </Box>
              {!Number.isNaN(endDate) && (
                <SectionContent>
                  <Countdown
                    date={endDate}
                    renderer={(properties) => (
                      <CountDownStaking {...properties} />
                    )}
                  />
                </SectionContent>
              )}
            </SectionInfo>
            <SectionInfo
              style={{
                flexDirection: 'row',
                flexWrap: smallScreen ? 'wrap' : 'nowrap',
                alignItems: 'center',
                justifyContent: smallScreen ? 'center' : 'unset',
                paddingRight: 0,
                borderRight: 0,
                width: 275,
              }}
            >
              <Box
                sx={{
                  minWidth: 178,
                  width: smallScreen ? '100%' : 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: smallScreen ? 'center' : 'unset',
                }}
              >
                <Box display="flex" flexDirection="row">
                  <SectionName>Earned</SectionName>
                  <Tooltip
                    title={
                      '‘Earned’ shows your total BUMP staking rewards for your current term.'
                    }
                  >
                    <IconButton
                      style={{
                        padding: '0px',
                        marginLeft: '0.375rem',
                      }}
                    >
                      <CustomHelpIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box display="flex" sx={{ paddingTop: '.5em' }}>
                  <StakedAmount style={{ fontStyle: 'bold', color: '#ffffff' }}>
                    {`${formatStringifyNumberToDot(stakingRewards + '')} `}
                  </StakedAmount>
                  <StakedAmount
                    style={{
                      lineHeight: '16px',
                      fontSize: '12px',
                      color: '#ffffff',
                      padding: '0.4em 0 0 0.4em',
                    }}
                  >
                    BUMP
                  </StakedAmount>
                </Box>
              </Box>
              <PixelButton
                size="small"
                color="primary"
                style={{
                  color: '#ffffff',
                  opacity: rewards.rewards === 0 ? 0.6 : 1,
                  minWidth: 90,
                  marginTop: smallScreen ? '1rem' : '0',
                }}
                disabled={
                  isFrontendFunctionalityDisabled(utcTime) ||
                  rewards.rewards < 0.1
                }
                onClick={() => {
                  const props: ConfirmClaimRewardsState = {
                    stakeIndex,
                    rewardsAmount: stakingRewards,
                  };
                  history.push({
                    pathname: `${Routes.ClaimRewardsStaking}/${subRoutes.Confirm}`,
                    state: props,
                  });
                }}
              >
                Claim
              </PixelButton>
            </SectionInfo>
          </SectionFixed>
        </Box>
      </ContentSection>
    </Box>
  );
};

const Timer = styled('span')(({ theme }) => ({
  fontFamily: 'Roboto Mono',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: COLORS.Primary2,
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    lineHeight: '19.2px',
  },
}));
