import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import { ConfirmClaimAirdrop } from '../components/ClaimAirdrop/ConfirmClaimAirdrop';
import { ConfirmInWalletAirdrop } from '../components/ClaimAirdrop/ConfirmInWalletAirdrop';
import { Header } from '../components/common/Header';
import { TransactionProcessingSection } from '../components/common/TransactionProcessingSection';
import { CoinIcons } from '../components/shared/icons/CoinIcons';
import { FlowContainer } from '../components/shared/styles/FlowPages';
import { Routes, subRoutes } from '../config/routes';
import { useAppDispatch } from '../state/hooks';
import { toggleToolbar } from '../state/reducers/uiStateManagementReducer';

export const ClaimAirdrop: React.FC = () => {
  const history = useHistory();
  const { step } = useParams<{ step?: string }>();
  const coinIcons = {
    USDC: CoinIcons['USDC'],
    BUMP: CoinIcons['BUMP'],
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(toggleToolbar());

    return () => {
      dispatch(toggleToolbar());
    };
  }, []);

  const onCancel = () => {
    history.replace(Routes.DepositDashboard);
  };

  const showSection = () => {
    switch (step) {
      case subRoutes.Confirm: {
        return <ConfirmClaimAirdrop coinIcons={coinIcons} />;
      }
      case subRoutes.Approve: {
        return <ConfirmInWalletAirdrop />;
      }
      case subRoutes.Processing: {
        return <TransactionProcessingSection />;
      }
      default: {
        return <ConfirmClaimAirdrop coinIcons={coinIcons} />;
      }
    }
  };

  return (
    <>
      <Header onCancel={onCancel} showStepper={true} activeStep={1} />
      <FlowContainer>{showSection()}</FlowContainer>
    </>
  );
};
