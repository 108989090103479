import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { termsAndConditionsLink } from '../../config/links';
import { Routes, subRoutes } from '../../config/routes';
import { VestingService } from '../../services/vestingService';
import { fetchBumpDetails } from '../../state/actions/bumpDetailsAction';
import { getVestingClaimDetails } from '../../state/actions/merkleTreeActions';
import { useAppSelector } from '../../state/hooks';
import { formatStringifyNumberToDot } from '../../utils/helpers';
import { PixelCheckbox } from '../shared/Checkbox';
import { PixelButton } from '../shared/PixelButton';
import {
  ClaimVestingSummary,
  ClaimVestingValue,
  CoinIconSmall,
  GreenLink,
  SymbolTypography,
} from '../shared/styles/ConfirmationPage';
import { DarkSection, Title } from '../shared/styles/Section';
import { WhiteSmallRoboto } from '../shared/styles/TypographyRoboto';

interface IConfirmClaimVestingProps {
  coinIcon: string;
}

export const ConfirmClaimVesting: React.FC<IConfirmClaimVestingProps> = ({
  coinIcon,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { addToast } = useToasts();
  const location =
    useLocation<{ claimedAmount: number; isNeedBackToStakingPage?: boolean }>();
  const { claimedAmount, isNeedBackToStakingPage } = location.state;
  const vestingData = useAppSelector((state) => state.merkleTree.vesting);
  const merkleState = useAppSelector((state) => state.merkleTree.merkle);

  const vestingService = VestingService.getInstance();

  const onBackButtonPress = () => {
    history.replace(
      isNeedBackToStakingPage ? Routes.Staking : Routes.DepositDashboard,
    );
  };

  const onConfirmClaimVesting = async () => {
    try {
      history.replace(`${Routes.Vesting}/${subRoutes.Approve}`);
      if (!vestingData) throw new Error('Vesting data is null of undefined');
      const tx = await vestingService.claim(vestingData);
      history.replace(`${Routes.Vesting}/${subRoutes.Processing}`);
      await tx.wait();
      dispatch(fetchBumpDetails());
      dispatch(getVestingClaimDetails(merkleState.vesting));
      history.replace(`${Routes.DepositDashboard}`);
      addToast('Later Dude!', {
        appearance: 'success',
      });
    } catch (err) {
      console.error(err);
      history.replace(Routes.DepositDashboard);
      addToast('An unexpected error occurred. Please try again.', {
        appearance: 'warning',
      });
    }
  };

  const handleCheckbox = () => {
    setTermsAccepted(!termsAccepted);
  };

  return (
    <Box maxWidth={{ xs: '100%', sm: '100%' }} m="auto">
      <Box display="flex" justifyContent="center" mb={5}>
        <Title>Confirmation</Title>
      </Box>
      <DarkSection
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={{ xs: 3, sm: 3, lg: 4 }}
        position="relative"
        pt={{ xs: 9, sm: 9, lg: 10 }}
      >
        <CoinIconSmall src={coinIcon} />
        <Box display="flex" alignItems="baseline" mb={1}>
          <ClaimVestingValue variant="h1">
            +{formatStringifyNumberToDot(claimedAmount.toString())}
          </ClaimVestingValue>
          <SymbolTypography variant="h4" color="textSecondary">
            BUMP
          </SymbolTypography>
        </Box>
        <ClaimVestingSummary mt={5} width="100%">
          The above figure is an estimate of the maximum amount of tokens you
          can claim. The actual maximum amount converted from <i>vested</i> BUMP
          to BUMP will be determined at the time the transaction confirms on the
          blockchain.
        </ClaimVestingSummary>
      </DarkSection>
      <Box mt={3} display="flex" alignItems="center">
        <Box mr={3} style={{ position: 'relative', top: '-1px' }}>
          <PixelCheckbox
            checked={termsAccepted}
            onChange={handleCheckbox}
            style={{ padding: 0 }}
          />
        </Box>
        <Box>
          <WhiteSmallRoboto color="secondary">
            By checking this box you acknowledge and agree to our{' '}
            <GreenLink
              href={termsAndConditionsLink}
              target="_blank"
              rel="noreferrer"
            >
              terms and conditions
            </GreenLink>
            .
          </WhiteSmallRoboto>
        </Box>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="minmax(150px, 33%) auto minmax(150px, 33%)"
        mt={{ xs: 3, sm: 4 }}
        pb="10vh"
      >
        <Box>
          <PixelButton fullWidth color="secondary" onClick={onBackButtonPress}>
            Back
          </PixelButton>
        </Box>
        <Box gridColumn="3">
          <PixelButton
            fullWidth
            color="primary"
            disabled={!termsAccepted}
            onClick={onConfirmClaimVesting}
          >
            Confirm
          </PixelButton>
        </Box>
      </Box>
    </Box>
  );
};
