import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { ClaimStakeSection } from '../components/ClaimStake/ClaimStakeSection';
import { ConfirmClaim } from '../components/ClaimStake/ConfirmClaim';
import { ConfirmStakeSection } from '../components/ClaimStake/ConfirmStakeSection';
import { GeneralErrorScreen } from '../components/common/GeneralErrorScreen';
import { Header } from '../components/common/Header';
import { NewApproveSection } from '../components/common/NewApproveSection';
import { Stepper } from '../components/common/Stepper';
import { TransactionProcessingSection } from '../components/common/TransactionProcessingSection';
import { CoinIcons } from '../components/shared/icons/CoinIcons';
import { FlowContainer } from '../components/shared/styles/FlowPages';
import { StakingSection } from '../components/StakingDetails/StakingSection';
import { BUMP } from '../config/coins';
import { Routes, subRoutes } from '../config/routes';
import { ICoin } from '../interfaces/ICoin';
import { useAppDispatch } from '../state/hooks';
import { toggleToolbar } from '../state/reducers/uiStateManagementReducer';

interface IClaimStakeProps {
  token: ICoin;
}

export const ClaimStake: React.FC<IClaimStakeProps> = () => {
  const history = useHistory();
  const { step } = useParams<{ step?: string }>();
  const location = useLocation<IClaimStakeProps>();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();

  const token = location.state ? location.state.token : BUMP;

  const onCancel = () => {
    history.replace(Routes.DepositDashboard);
  };
  useEffect(() => {
    dispatch(toggleToolbar());
    return () => {
      dispatch(toggleToolbar());
    };
  }, []);

  const coinIcon = CoinIcons['BUMP'];

  const showSection = () => {
    switch (step) {
      case subRoutes.Claim: {
        return <ConfirmClaim coinIcon={coinIcon} />;
      }
      case subRoutes.Stake: {
        return <ClaimStakeSection token={token} isClaimStake />;
      }
      case subRoutes.Confirm: {
        return <ConfirmStakeSection />;
      }
      case subRoutes.Processing: {
        return <TransactionProcessingSection />;
      }
      case subRoutes.Approve: {
        return <NewApproveSection />;
      }
      case subRoutes.Error: {
        return <GeneralErrorScreen />;
      }
      default: {
        return <StakingSection token={token} isClaimStake />;
      }
    }
  };

  let activeStep = 0;
  switch (step) {
    case subRoutes.Claim: {
      activeStep = 0;
      break;
    }
    case subRoutes.Stake: {
      activeStep = 1;
      break;
    }
    case subRoutes.Confirm: {
      activeStep = 2;
      break;
    }
    case subRoutes.Approve: {
      activeStep = 2;
      break;
    }
    case subRoutes.Processing: {
      activeStep = 2;
      break;
    }
    default: {
      activeStep = 0;
      break;
    }
  }

  return (
    <>
      <Header
        onCancel={onCancel}
        showStepper={!isSmallScreen}
        activeStep={activeStep}
        isClaimStake
      />
      {isSmallScreen && (
        <Box mt="30px" mb="16px" display="flex" justifyContent="center">
          <Stepper isClaimStake={true} activeStep={activeStep} />
        </Box>
      )}
      <FlowContainer>{showSection()}</FlowContainer>
    </>
  );
};
