import { Box, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { termsAndConditionsLink } from '../../config/links';
import { Routes, subRoutes } from '../../config/routes';
import { StakingService } from '../../services/stakingService';
import { formatStringifyNumberToDot } from '../../utils/helpers';
import { PixelCheckbox } from '../shared/Checkbox';
import { PixelButton } from '../shared/PixelButton';
import {
  ClaimVestingValue,
  CoinIconSmall,
  ConfirmationDivider,
  GreenLink,
  SymbolTypography,
} from '../shared/styles/ConfirmationPage';
import { DarkSection, Title } from '../shared/styles/Section';
import { FinalText, WhiteSmallRoboto } from '../shared/styles/TypographyRoboto';
import { MediumBoldTypography } from '../shared/Typography';

interface IConfirmClaimVestingProps {
  coinIcon: string;
}
export interface ConfirmUnstakeState {
  amount: number;
  stakeIndex?: number;
  rewards?: number;
}

const useStyles = makeStyles((theme) => ({
  unit: {
    fontSize: '14px',
    color: theme.palette.other.LabelText,
    fontWeight: 700,
    fontFamily: 'Roboto',
  },
}));
export const ConfirmUnstake: React.FC<IConfirmClaimVestingProps> = ({
  coinIcon,
}) => {
  const history = useHistory();
  const location = useLocation<ConfirmUnstakeState>();
  const { amount, stakeIndex, rewards } = location.state;
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const { addToast } = useToasts();
  const stakingService = StakingService.getInstance();
  const classes = useStyles();
  const onBackButtonPress = () => {
    history.replace(Routes.Staking);
  };

  const onConfirmUnstake = async () => {
    try {
      history.replace(`${Routes.ClaimRewardsStaking}/${subRoutes.Approve}`);
      const tx = await stakingService.unstake(stakeIndex || 0);

      history.replace(`${Routes.ClaimRewardsStaking}/${subRoutes.Processing}`);
      await tx.wait();

      history.replace(`${Routes.Staking}`);
      addToast('Later Dude!', {
        appearance: 'success',
      });
    } catch (err) {
      console.error(err);
      history.replace(Routes.Staking);
      addToast(
        'An unexpected error occurred when trying to unstake your BUMP. Please try again.',
        {
          appearance: 'warning',
        },
      );
    }
  };

  const handleCheckbox = () => {
    setTermsAccepted(!termsAccepted);
  };

  return (
    <Box maxWidth={{ xs: '100%', sm: '100%' }} m="auto">
      <Box display="flex" justifyContent="center" mb={5}>
        <Title>Confirmation</Title>
      </Box>
      <DarkSection
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={{ xs: 3, sm: 3, lg: 4 }}
        position="relative"
        pt={{ xs: 9, sm: 9, lg: 10 }}
      >
        <CoinIconSmall src={coinIcon} />
        <Box display="flex" alignItems="baseline" mb={1}>
          <ClaimVestingValue variant="h1">
            {formatStringifyNumberToDot((amount + (rewards || 0)).toString())}
          </ClaimVestingValue>
          <SymbolTypography variant="h4" color="textSecondary">
            BUMP
          </SymbolTypography>
        </Box>
        <ConfirmationDivider style={{ margin: '30px 0' }} />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          style={{ width: '100%' }}
        >
          <FinalText>Unstake Balance</FinalText>
          <MediumBoldTypography secondaryFont>
            +{formatStringifyNumberToDot(amount.toString())}
            <span className={classes.unit}> BUMP</span>
          </MediumBoldTypography>
        </Box>
        {rewards && rewards > 0 ? (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            style={{ width: '100%' }}
            mt={3}
          >
            <FinalText>Claim Rewards</FinalText>
            <MediumBoldTypography secondaryFont>
              {formatStringifyNumberToDot(rewards.toString())}
              <span className={classes.unit}> BUMP</span>
            </MediumBoldTypography>
          </Box>
        ) : null}
      </DarkSection>
      <Box mt={3} display="flex" alignItems="center">
        <Box mr={3} style={{ position: 'relative', top: '-1px' }}>
          <PixelCheckbox
            checked={termsAccepted}
            onChange={handleCheckbox}
            style={{ padding: 0 }}
          />
        </Box>
        <Box>
          <WhiteSmallRoboto color="secondary">
            By checking this box you acknowledge and agree to our{' '}
            <GreenLink
              href={termsAndConditionsLink}
              target="_blank"
              rel="noreferrer"
            >
              terms and conditions
            </GreenLink>
            .
          </WhiteSmallRoboto>
        </Box>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="minmax(150px, 33%) auto minmax(150px, 33%)"
        mt={{ xs: 3, sm: 4 }}
        pb="10vh"
      >
        <Box>
          <PixelButton fullWidth color="secondary" onClick={onBackButtonPress}>
            Back
          </PixelButton>
        </Box>
        <Box gridColumn="3">
          <PixelButton
            fullWidth
            color="primary"
            disabled={!termsAccepted}
            onClick={onConfirmUnstake}
          >
            Confirm
          </PixelButton>
        </Box>
      </Box>
    </Box>
  );
};
