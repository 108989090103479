import { BigNumber, ethers } from 'ethers';

import { EthersServiceProvider } from './ethersServiceProvider';

import { Quoter__factory, SwapRouter__factory } from '../types';
import { encodePath } from '../utils/contract-helpers';

export class UniswapService {
  private static instance: UniswapService;

  private ethersServiceProvider: EthersServiceProvider;

  private readonly routerAddress = process.env
    .REACT_APP_SWAP_ROUTER_ADDR as string;
  private readonly quoterAddress = process.env
    .REACT_APP_SWAP_QUOTER_ADDR as string;

  private readonly bumpAddress = process.env
    .REACT_APP_BUMP_TOKEN_ADDR as string;
  private readonly usdcAddress = process.env.REACT_APP_USDC_ADDRESS as string;

  private readonly bumpWethFee = +(process.env
    .REACT_APP_SWAP_BUMP_WETH_FEE as string);
  private readonly wethUsdcFee = +(process.env
    .REACT_APP_SWAP_WETH_USDC_FEE as string);

  private constructor() {
    this.ethersServiceProvider = EthersServiceProvider.getInstance();
  }

  public static getInstance(): UniswapService {
    if (!UniswapService.instance) {
      UniswapService.instance = new UniswapService();
    }

    return UniswapService.instance;
  }

  public async getBumpPriceInUSDC(bumpAmount: BigNumber): Promise<BigNumber> {
    if (bumpAmount.isZero()) {
      return BigNumber.from('0');
    }

    const router = SwapRouter__factory.connect(
      this.routerAddress,
      this.ethersServiceProvider.provider?.getSigner(
        0,
      ) as ethers.providers.JsonRpcSigner,
    );
    const quoter = Quoter__factory.connect(this.quoterAddress, router.provider);

    const weth = await router.WETH9();

    const path = encodePath(
      [this.bumpAddress, weth, this.usdcAddress],
      [this.bumpWethFee, this.wethUsdcFee],
    );

    try {
      return await quoter.callStatic.quoteExactInput(path, bumpAmount);
    } catch (e) {
      console.error(e);
    }

    return BigNumber.from('0');
  }
}
