import { createAsyncThunk } from '@reduxjs/toolkit';
import { BigNumber, ethers } from 'ethers';

import { chainName } from '../../config/contractAddresses';
import { EthersServiceProvider } from '../../services/ethersServiceProvider';
import {
  getStakingUserData,
  getVestingUserData,
  StakingMerkleInfo,
  VestingMerkleInfo,
} from '../../services/firebaseStorageService';
import { StakingService } from '../../services/stakingService';
import { VestingService } from '../../services/vestingService';
import { StakingType, VestingMerkleType } from '../reducers/merkleTree';

export const getVestingMerkleInfo = createAsyncThunk<
  VestingMerkleInfo | null,
  string
>('getVestingMerkleInfo', async (address) => {
  try {
    const data = await getVestingUserData(chainName, address);

    return data;
  } catch (err) {
    console.error(err);
    return null;
  }
});

export const getVestingClaimDetails = createAsyncThunk<
  VestingMerkleType | null,
  VestingMerkleInfo | null
>('getVestingClaimDetails', async (merkleInfo) => {
  try {
    if (!merkleInfo) return null;
    const address = EthersServiceProvider.getInstance().currentAccount;
    const info = {
      info: merkleInfo,
      account: address,
      proof: merkleInfo.proof,
      availableToClaim: BigNumber.from(0),
      unvested: BigNumber.from(0),
      claimed: BigNumber.from(0),
    };

    info.availableToClaim =
      await VestingService.getInstance().getClaimableAmount(info);
    info.unvested = await VestingService.getInstance().totalLockedOf(info);
    info.claimed = await VestingService.getInstance().totalClaimedOf(address);

    return info;
  } catch (err) {
    console.error(err);
    return null;
  }
});

export const getStakingMerkleInfo = createAsyncThunk<
  StakingMerkleInfo | null,
  string
>('getStakingMerkleInfo', async (address) => {
  try {
    const data = await getStakingUserData(chainName, address);
    return data;
  } catch (err) {
    console.error(err);
    return null;
  }
});

export const getStakingDetails = createAsyncThunk<
  StakingType | null,
  StakingMerkleInfo | null
>('getStakingDetails', async (merkleTreeData) => {
  try {
    if (!merkleTreeData) return null;

    const stakingService = StakingService.getInstance();

    const rewards = await stakingService.calculateRewardsForFakeStaking(
      merkleTreeData.amount,
    );

    return {
      amount: parseFloat(ethers.utils.formatUnits(merkleTreeData.amount)),
      amountWithoutStaking: parseFloat(
        ethers.utils.formatUnits(merkleTreeData.amountWithoutStaking),
      ),
      rewards,
    };
  } catch (err) {
    console.error(err);
    return null;
  }
});
