import { Box, styled } from '@material-ui/core';
import { BigNumber, ethers } from 'ethers';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { USDCoin } from '../../config/coins';
import { CONTRACTS } from '../../config/contractAddresses';
import { termsAndConditionsLink } from '../../config/links';
import { Routes, subRoutes } from '../../config/routes';
import { AirdropService } from '../../services/airdropService';
import { fetchBumpDetails } from '../../state/actions/bumpDetailsAction';
import { fetchCoinDetails } from '../../state/actions/coinActions';
import { AirdropMerkleNodeType } from '../../state/reducers/merkleTree';
import { formatStringifyNumberToDot } from '../../utils/helpers';
import { PixelCheckbox } from '../shared/Checkbox';
import { PixelButton } from '../shared/PixelButton';
import {
  ClaimVestingSummary,
  ClaimVestingValue,
  CoinIconSmall,
  GreenLink,
  SymbolTypography,
} from '../shared/styles/ConfirmationPage';
import { useStyles } from '../shared/styles/GlobalSections';
import { DarkSection, Title } from '../shared/styles/Section';
import { FinalText, WhiteSmallRoboto } from '../shared/styles/TypographyRoboto';
import { MediumBoldTypography } from '../shared/Typography';

interface IConfirmClaimAirdrop {
  coinIcons: {
    BUMP: string;
    USDC: string;
  };
}

const ConfirmClaimAirdrop: React.FC<IConfirmClaimAirdrop> = ({ coinIcons }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { addToast } = useToasts();
  const location = useLocation<{
    amountBUMP: BigNumber;
    amountUSDC: BigNumber;
    values: AirdropMerkleNodeType;
  }>();
  let { amountBUMP, amountUSDC } = location.state;
  const { values } = location.state;
  amountBUMP = amountBUMP ?? ethers.utils.parseUnits('0');
  amountUSDC =
    amountUSDC ??
    ethers.utils.parseUnits('0', CONTRACTS.TOKEN_DETAILS.USDC.decimal);
  const classes = useStyles();
  const airdropService = AirdropService.getInstance();
  const onBackButtonPress = () => {
    history.replace(Routes.DepositDashboard);
  };

  const onConfirmClaimAirdrop = async () => {
    try {
      if (!values) {
        throw Error('No values');
      }
      history.replace(`${Routes.Airdrop}/${subRoutes.Approve}`);
      const tx = await airdropService.claimBulkDecodedArgs(values);
      history.replace(`${Routes.Airdrop}/${subRoutes.Processing}`);
      await tx.wait();
      dispatch(fetchBumpDetails());
      dispatch(fetchCoinDetails([USDCoin]));
      history.replace(Routes.DepositDashboard);
      addToast('Later Dude!', {
        appearance: 'success',
      });
    } catch (err) {
      console.error(err);
      history.replace(Routes.DepositDashboard);
      addToast(
        'We were unable to convert your asset into BUMP and/or USDC. Please try again.',
        {
          appearance: 'warning',
        },
      );
    }
  };

  const handleCheckbox = () => {
    setTermsAccepted(!termsAccepted);
  };

  return (
    <Box>
      <Box display="flex" justifyContent="center" mb={5}>
        <Title>Confirmation</Title>
      </Box>
      <DarkSection
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={{ xs: 3, sm: 3, lg: 4 }}
        position="relative"
        pt={{ xs: 9, sm: 9, lg: 10 }}
      >
        <CoinIcons>
          {amountBUMP.gt(0) && (
            <CoinIconSmall
              src={coinIcons.BUMP}
              className={amountUSDC.gt(0) ? classes.firstIcon : ''}
            />
          )}
          {amountUSDC.gt(0) && (
            <CoinIconSmall
              src={coinIcons.USDC}
              className={amountBUMP.gt(0) ? classes.secondIcon : ''}
            />
          )}
        </CoinIcons>
        {amountBUMP.gt(0) && (
          <Box display="flex" alignItems="baseline" mb={2}>
            <ClaimVestingValue variant="h1">
              +
              {formatStringifyNumberToDot(ethers.utils.formatUnits(amountBUMP))}
            </ClaimVestingValue>
            <SymbolTypography variant="h4" color="textSecondary">
              BUMP
            </SymbolTypography>
          </Box>
        )}
        {amountUSDC.gt(0) && (
          <Box display="flex" alignItems="baseline">
            <ClaimVestingValue variant="h1">
              +
              {formatStringifyNumberToDot(
                ethers.utils.formatUnits(amountUSDC, 6),
              )}
            </ClaimVestingValue>
            <SymbolTypography variant="h4" color="textSecondary">
              USDC
            </SymbolTypography>
          </Box>
        )}
        <ClaimVestingSummary mt={5} width="100%">
          {amountBUMP.gt(0) && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              style={{ width: '100%' }}
            >
              <FinalText>BUMP Balance</FinalText>
              <MediumBoldTypography secondaryFont>
                <span style={{ color: 'white' }}>
                  {formatStringifyNumberToDot(
                    ethers.utils.formatUnits(amountBUMP),
                  )}
                </span>
                <span className={classes.unit}> BUMP</span>
              </MediumBoldTypography>
            </Box>
          )}
          {amountUSDC.gt(0) && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              mt={2}
              style={{ width: '100%' }}
            >
              <FinalText>USDC Balance</FinalText>
              <MediumBoldTypography secondaryFont>
                <span style={{ color: 'white' }}>
                  {formatStringifyNumberToDot(
                    ethers.utils.formatUnits(amountUSDC, 6),
                  )}
                </span>
                <span className={classes.unit}> USDC</span>
              </MediumBoldTypography>
            </Box>
          )}
        </ClaimVestingSummary>
      </DarkSection>
      <Box mt={3} display="flex" alignItems="center">
        <Box mr={3} style={{ position: 'relative', top: '-1px' }}>
          <PixelCheckbox
            checked={termsAccepted}
            onChange={handleCheckbox}
            style={{ padding: 0 }}
          />
        </Box>
        <Box>
          <WhiteSmallRoboto color="secondary">
            By checking this box you acknowledge and agree to our{' '}
            <GreenLink
              href={termsAndConditionsLink}
              target="_blank"
              rel="noreferrer"
            >
              terms and conditions
            </GreenLink>
            .
          </WhiteSmallRoboto>
        </Box>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="minmax(150px, 33%) auto minmax(150px, 33%)"
        mt={{ xs: 3, sm: 4 }}
        pb="10vh"
      >
        <Box>
          <PixelButton fullWidth color="secondary" onClick={onBackButtonPress}>
            Back
          </PixelButton>
        </Box>
        <Box gridColumn="3">
          <PixelButton
            fullWidth
            color="primary"
            disabled={!termsAccepted}
            onClick={onConfirmClaimAirdrop}
          >
            Confirm
          </PixelButton>
        </Box>
      </Box>
    </Box>
  );
};
const CoinIcons = styled('div')(() => ({
  position: 'absolute',
  top: '-35px',
  display: 'flex',
  justifyContent: 'space-around',
}));

export { ConfirmClaimAirdrop };
