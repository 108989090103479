import { Box } from '@material-ui/core';
import { styled } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { NumberFormatValues } from 'react-number-format';

import { BUMP } from '../../config/coins';
import { COLORS } from '../../config/colors';
import { formatStringifyNumberToDot } from '../../utils/helpers';
import { DepositNumberField } from '../common/DepositNumberField';
import { PixelButton } from '../shared/PixelButton';
import { StakeSlider } from '../shared/Slider';
import { useStyles } from '../shared/styles/GlobalSections';
import { Section } from '../shared/styles/Section';
import {
  ExtraSmallRoboto,
  WhiteSmallRoboto,
} from '../shared/styles/TypographyRoboto';
import { LargeBoldTypography } from '../shared/Typography';

interface ISliderSectionProps {
  title: string;
  text: string;
  bumpBalance: string;
  stakeAmount: number;
  setStakeAmount: React.Dispatch<React.SetStateAction<number>>;
}

export const SliderSection: React.FC<ISliderSectionProps> = ({
  title,
  text,
  bumpBalance,
  stakeAmount,
  setStakeAmount,
}) => {
  const classes = useStyles();

  const [percent, setPercent] = useState<number>(
    stakeAmount ? (stakeAmount / parseFloat(bumpBalance)) * 100 : 50,
  );
  useEffect(() => {
    onLiquidityChange(InputTypes.Slider, percent.toString());
  }, [percent]);

  enum InputTypes {
    Slider,
    Input,
  }
  const onLiquidityChange = (inputType: InputTypes, value: string) => {
    switch (inputType) {
      case InputTypes.Slider: {
        const newStakeAmount =
          (parseFloat(bumpBalance) * parseFloat(value)) / 100;
        setStakeAmount(isNaN(newStakeAmount) ? 0 : newStakeAmount);
        setPercent(parseFloat(value));
        break;
      }
      case InputTypes.Input: {
        setStakeAmount(isNaN(parseFloat(value)) ? 0 : parseFloat(value));
        const newPercents = (parseFloat(value) * 100) / parseFloat(bumpBalance);
        setPercent(newPercents);
        break;
      }
    }
  };

  const onMaxButtonClick = () => {
    onLiquidityChange(InputTypes.Slider, '100');
  };

  return (
    <>
      <Section>
        <Box>
          <LargeBoldTypography>{title}</LargeBoldTypography>
          <Box mt={1}>
            <WhiteSmallRoboto color="secondary">{text}</WhiteSmallRoboto>
          </Box>
          <Box mt={3} display="flex" justifyContent="center">
            <StakeSlider
              defaultValue={0}
              min={0}
              max={100}
              step={1}
              value={percent}
              onChange={(_: number, newValue: number) => setPercent(newValue)}
            />
          </Box>
          <RangeContainer>
            <ExtraSmallRoboto
              style={{ paddingRight: '1em', visibility: 'hidden' }}
            >
              min.
            </ExtraSmallRoboto>
            <PixelButton
              size="small"
              color="secondary"
              fullWidth
              style={{
                width: '55px',
                maxHeight: 'none',
                padding: '5px 30px',
                backgroundColor:
                  percent === 100 ? COLORS.Secondary2 : COLORS.Green1,
                color: percent === 100 ? 'black' : 'white',
              }}
              onClick={onMaxButtonClick}
            >
              MAX
            </PixelButton>
          </RangeContainer>
          <NumberFieldsWrapper>
            <NumberFieldWrapper>
              <Box>
                <BumpAmount
                  display="flex"
                  alignItems="center"
                  flexDirection="row"
                >
                  <DepositNumberField
                    token={BUMP}
                    value={formatStringifyNumberToDot('' + stakeAmount)}
                    onChange={(event) => {
                      onLiquidityChange(InputTypes.Input, event.target.value);
                    }}
                    isAllowed={(val: NumberFormatValues) => {
                      const { floatValue } = val;
                      return floatValue
                        ? floatValue <= parseFloat(bumpBalance)
                        : true;
                    }}
                    className={classes.input}
                  />
                  <Remaining>
                    Remaining Balance:{' '}
                    <strong>
                      {formatStringifyNumberToDot(
                        '' + (parseFloat(bumpBalance) - stakeAmount),
                      )}
                    </strong>{' '}
                    <span style={{ fontSize: 12 }}>BUMP</span>
                  </Remaining>
                </BumpAmount>
              </Box>
            </NumberFieldWrapper>
          </NumberFieldsWrapper>
        </Box>
      </Section>
    </>
  );
};

const RangeContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
});

const BumpAmount = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    width: '211px',
  },
}));

const NumberFieldsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '1rem 0',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'space-between',
  },
}));

const NumberFieldWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gridGap: 7,
  [theme.breakpoints.down('sm')]: {
    width: '211px',
  },
}));

const Remaining = styled(WhiteSmallRoboto)(({ theme }) => ({
  marginLeft: '2.2em',
  span: {
    border: '1px solid red',
  },
  [theme.breakpoints.down('xs')]: {
    width: '245px',
    marginTop: '16px',
  },
}));
