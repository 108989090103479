import { Box } from '@material-ui/core';
import { useWeb3React } from '@web3-react/core';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { TopSection } from './../shared/TopSection';
import { Vesting } from './Vesting';
import {
  RefreshButton,
  HistoryButton,
  ThreeDotsButton,
} from './Vesting/Buttons';

import { WalletContext } from '../../config/walletContext';
import { theme } from '../../providers/AppThemeProvider';
import { getVestingClaimDetails } from '../../state/actions/merkleTreeActions';
import { useAppSelector } from '../../state/hooks';
import { formatStringifyNumberToDot } from '../../utils/helpers';
import { IBalanceCardProps } from '../interfaces/BalanceCardProps';
import { CoinIcon } from '../shared/CoinIcon';
import { ContentSection } from '../shared/ContentSection';
import { SecondDataField } from '../shared/DataField';
import { CoinIcons } from '../shared/icons/CoinIcons';
import { LargeBoldTypography } from '../shared/Typography';

export const SecondBalanceCard: React.FC<IBalanceCardProps> = (props) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  const { token, openHistoryModal, vestingClaimEvents, refreshClaimEvents } =
    props;

  const { bumpBalance, bumpBalanceInUsdc } = useAppSelector(
    (v) => v.bumpDetails,
  );

  const merkleState = useAppSelector(
    (state) => state.merkleTree.merkle.vesting,
  );

  const isWalletConnected = useContext(WalletContext).isWalletConnected;

  const dispatch = useDispatch();
  const { account } = useWeb3React();

  const refreshVestingInfo = useCallback(() => {
    if (account && merkleState) {
      if (refreshClaimEvents) refreshClaimEvents();
      dispatch(getVestingClaimDetails(merkleState));
    }
  }, [merkleState]);

  useEffect(() => {
    const vestingUpdateInterval = setInterval(refreshVestingInfo, 15000);
    return () => clearInterval(vestingUpdateInterval);
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
      <TopSection color={`${theme.palette.other.Turquoise}`}>
        <Box display="flex" alignItems="center">
          <CoinIcon src={CoinIcons[token.symbol]} />
          <LargeBoldTypography secondaryFont style={{ marginTop: '0.3em' }}>
            {token.symbol}
          </LargeBoldTypography>
        </Box>
        <RefreshButton refreshClaimEvents={refreshVestingInfo} />
      </TopSection>
      {!isWalletConnected ? null : (
        <ContentSection>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <SecondDataField
              name="Balance"
              value={formatStringifyNumberToDot(bumpBalance)}
              currentBumpPriceInDollars={formatStringifyNumberToDot(
                bumpBalanceInUsdc,
              )}
              unit={token.symbol}
            />
            {!isVisible ? (
              <HistoryButton onClick={openHistoryModal} />
            ) : (
              <ThreeDotsButton onClick={() => setIsVisible(!isVisible)} />
            )}
          </Box>
          <Vesting vestingClaimEvents={vestingClaimEvents} />
        </ContentSection>
      )}
    </Box>
  );
};
