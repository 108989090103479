import { styled, Box, Typography, Grid, makeStyles } from '@material-ui/core';
import { format } from 'date-fns';
import React from 'react';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import { useHistory } from 'react-router-dom';

import { CurrentDashboardEpochInfo } from './CurrentDashboardEpochInfo';

import { FUNCTIONALITY_ENABLES_AT } from '../../config';
import { COLORS } from '../../config/colors';
import { Routes } from '../../config/routes';
import { Brian } from '../shared/Brian';
import { PixelButton } from '../shared/PixelButton';
import {
  ContainerMainEpoch,
  // Description,
  Header,
  MainDescription,
  MainSecondContainer,
  // StyledButton,
} from '../shared/styles/DashboardStyles';

// const toMonthName = (monthNumber: number) => {
//   return [
//     'January',
//     'February',
//     'March',
//     'April',
//     'May',
//     'June',
//     'July',
//     'August',
//     'September',
//     'October',
//     'November',
//     'December',
//   ][monthNumber];
// };

export const EpochSection: React.FC = () => {
  const classes = styles();

  // const nextEpoch = getEpoch().epoch + 1;

  // const nextEpochStart = getEndEpochDate(nextEpoch).epochStartDate;

  return (
    <ContainerEpochs>
      <SecondContainer>
        <Brian
          classes={classes.brian}
          text="What are you still doing here?"
        />
        <Logo>
          <Header
            style={{
              padding: '0.75rem 0.675rem 0.5rem 1rem',
            }}
          >
            EPOCH
          </Header>
        </Logo>
        <p style={{
          fontFamily:"Roboto Mono"
        }}>
          This program is no longer active. Please claim your pending rewards. Please visit <a href="https://bumper.fi">https://bumper.fi
            </a> to see what is new with Bumper!
        </p>
    
        <TimerContainer>
          <Countdown
            date={FUNCTIONALITY_ENABLES_AT}
            renderer={(properties) => <StakingTimer {...properties} />}
          />
          {/* <PixelButton
            size="small"
            color="primary"
            style={{
              color: '#ffffff',
              width: '132px',
            }}
            onClick={() =>
              !isWalletConnected
                ? history.push(Routes.ConnectWallet)
                : history.push(`${Routes.StakingDetails}/${subRoutes.Select}`)
            }
            disabled={isFrontendFunctionalityDisabled(utcTime)}
          >
            Stake
          </PixelButton> */}
        </TimerContainer>
      </SecondContainer>
    </ContainerEpochs>
  );
};

export const MainEpochSection: React.FC<CountdownRenderProps> = (props) => {
  const history = useHistory();
  return (
    <>
      {!props.completed && (
        <ContainerMainEpoch>
          <MainSecondContainer>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="start"
              gridGap="24px"
            >
              <Header style={{ fontFamily: 'Press Start' }}>EARN</Header>
              <MainDescription>
                For those awesome LP farmers that know a good thing, the next
                epoch will start at 12pm UTC on{' '}
                {format(new Date(props.props.date || 0), 'd MMM yyyy')}. Get
                your deposit in by then and watch those fat yields drop into
                your wallet!
              </MainDescription>
              <PixelButton
                size="small"
                color="secondary"
                onClick={() => history.push(Routes.Epochs)}
              >
                Learn more
              </PixelButton>
            </Box>
            <CurrentDashboardEpochInfo
              eligiblePool={'10400000'}
              rewardAllocation={'137000'}
              yieldUSDC={'TBC'}
            />
          </MainSecondContainer>
        </ContainerMainEpoch>
      )}
    </>
  );
};

export const StakingTimer: React.FC<CountdownRenderProps> = (props) => {
  return (
    <>
      {!props.completed && (
        <ContainerStakingTimer>
          <TimeContainerStaking alignItems="center">
            <TimeUnitsStakingTimer>
              {props.formatted.days}
            </TimeUnitsStakingTimer>
          </TimeContainerStaking>
          <TimeContainerStaking alignItems="center">
            <TimeUnitsStakingTimer white>:</TimeUnitsStakingTimer>
          </TimeContainerStaking>
          <TimeContainerStaking alignItems="center">
            <TimeUnitsStakingTimer>
              {props.formatted.hours}
            </TimeUnitsStakingTimer>
          </TimeContainerStaking>
          <TimeContainerStaking alignItems="center">
            <TimeUnitsStakingTimer white>:</TimeUnitsStakingTimer>
          </TimeContainerStaking>
          <TimeContainerStaking alignItems="center">
            <TimeUnitsStakingTimer>
              {props.formatted.minutes}
            </TimeUnitsStakingTimer>
          </TimeContainerStaking>
          <TimeContainerStaking alignItems="center">
            <TimeUnitsStakingTimer white>:</TimeUnitsStakingTimer>
          </TimeContainerStaking>
          <TimeContainerStaking alignItems="center">
            <TimeUnitsStakingTimer>
              {props.formatted.seconds}
            </TimeUnitsStakingTimer>
          </TimeContainerStaking>
        </ContainerStakingTimer>
      )}
    </>
  );
};

export const EpochTimer: React.FC<
  CountdownRenderProps & {
    epoch: number;
  }
> = (props) => {
  return (
    <>
      {!props.completed && (
        <ContainerTimer>
          <TimerEpoch>
            <PreSaleDurationIndicator>
              Next Epoch (#{props.epoch < 10 ? `0${props.epoch}` : props.epoch})
              starts in:
            </PreSaleDurationIndicator>
            <OnlyTimerEpoch>
              <TimeContainer container alignItems="center">
                <TimeUnitsPreSaleEpoch>
                  {props.formatted.days}
                </TimeUnitsPreSaleEpoch>
              </TimeContainer>
              <TimeContainer container alignItems="center">
                <TimeUnitsPreSaleEpoch>:</TimeUnitsPreSaleEpoch>
              </TimeContainer>
              <TimeContainer container alignItems="center">
                <TimeUnitsPreSaleEpoch>
                  {props.formatted.hours}
                </TimeUnitsPreSaleEpoch>
              </TimeContainer>
              <TimeContainer container alignItems="center">
                <TimeUnitsPreSaleEpoch>:</TimeUnitsPreSaleEpoch>
              </TimeContainer>
              <TimeContainer container alignItems="center">
                <TimeUnitsPreSaleEpoch>
                  {props.formatted.minutes}
                </TimeUnitsPreSaleEpoch>
              </TimeContainer>
              <TimeContainer container alignItems="center">
                <TimeUnitsPreSaleEpoch>:</TimeUnitsPreSaleEpoch>
              </TimeContainer>
              <TimeContainer container alignItems="center">
                <TimeUnitsPreSaleEpoch>
                  {props.formatted.seconds}
                </TimeUnitsPreSaleEpoch>
              </TimeContainer>
            </OnlyTimerEpoch>
          </TimerEpoch>
        </ContainerTimer>
      )}
    </>
  );
};

const TimerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: 3,

  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column-reverse',
  },
}));

const ContainerEpochs = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: COLORS.Green3,
  border: `2px solid ${COLORS.Border}`,
  padding: `35.5px 43px 35.5px 45px`,
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: '2.5em 1.5em',
  },
}));
const ContainerTimer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  height: 'fit-content',
  backgroundColor: COLORS.Green3,
  border: `2px solid ${COLORS.Border}`,
  padding: '1.5em 4em',
  marginTop: '2rem',
  marginBottom: '2rem',
  [theme.breakpoints.down('sm')]: {
    padding: '10px 37px',
  },
}));
const ContainerStakingTimer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: 'fit-content',
  marginRight: '1rem',
  [theme.breakpoints.down('sm')]: {
    padding: '10px 37px',
    marginRight: 0,
  },
}));
const TimeContainerStaking = styled(Box)(({ theme }) => ({
  display: 'flex',
  '&:not(:last-child)': {
    marginRight: '.675rem',
    [theme.breakpoints.down('sm')]: {
      marginRight: '.375rem',
    },
  },
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
    '& > p': {
      fontSize: '1rem',
      paddingRight: 0,
    },
  },
}));
const TimeUnitsStakingTimer = styled(Typography)(({ theme, white }: any) => ({
  fontFamily: 'Roboto',
  color: white ? 'white' : COLORS.Primary2,
  fontWeight: 700,
  fontSize: '16px',
  fontStyle: 'normal',
  lineHeight: '26px',

  [theme.breakpoints.down('sm')]: {
    lineHeight: '120%',
    fontSize: '16px',
  },
}));

const SecondContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: 0,
  },
}));

const PreSaleDurationIndicator = styled(Box)(({ theme }) => ({
  height: 'fit-content',
  color: COLORS.White,
  fontFamily: 'Roboto Mono',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '24px',
  width: 'fit-content',
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    fontSize: '1rem',
    lineHeight: '26px',
  },
}));

const Logo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '19%',
  [theme.breakpoints.down('sm')]: {
    height: 'fit-content',
    width: 'fit-content',
    alignSelf: 'flex-start',
  },
}));

const TimerEpoch = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  gap: '32px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: '12px',
  },
}));

const OnlyTimerEpoch = styled(Box)(({ theme }) => ({
  width: '30%',
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'space-around',
    width: 'auto',
    marginBottom: 0,
  },
}));

const TimeContainer = styled(Grid)(({ theme }) => ({
  '&:not(:last-child)': {
    marginRight: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginRight: '.25rem',
    },
  },
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
    '& > p': {
      fontSize: '1em',
      paddingRight: 0,
    },
  },
}));

const TimeUnitsPreSaleEpoch = styled(Typography)(({ theme }) => ({
  fontFamily: 'Roboto',
  color: COLORS.Primary2,
  fontWeight: 700,
  fontSize: '24px',
  fontStyle: 'normal',
  lineHeight: '26px',

  [theme.breakpoints.down('sm')]: {
    lineHeight: '120%',
    fontSize: '16px',
  },
}));

const styles = makeStyles((theme) => ({
  brian: {
    right: '5rem',
    [theme.breakpoints.down('sm')]: {
      right: '3rem !important',
      top: '-1em',
    },
  },
}));
