import { styled, Box, makeStyles } from '@material-ui/core';
import { HelpRounded } from '@material-ui/icons';
import React, { useContext } from 'react';

import { UnlockTimeContext } from '../../../config/unlockTimeContext';
import { formatStringifyNumberToDot } from '../../../utils/helpers';
import { unlockDateToolTipText } from '../../../utils/toolTipTexts';
import { SlimDivider } from '../../shared/styles/Section';
import { Tooltip } from '../../shared/Tooltip';
import {
  MediumTypography,
  MediumBoldTypography,
} from '../../shared/Typography';

interface IDepositSummaryProps {
  unlockDate: string;
  totalDeposit: string;
}

const useStyles = makeStyles((theme) => ({
  yieldValue: {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 'bold',
  },
  greenText: {
    color: theme.palette.other.BrightGreen,
  },
  icon: {
    fontSize: '0.75rem',
    marginLeft: '0.4rem',
    width: '1rem',
    height: '1rem',
    position: 'relative',
    top: '0.1rem',
  },
  unit: {
    fontSize: '14px',
    color: theme.palette.other.LabelText,
    fontWeight: 700,
    fontFamily: 'Roboto',
  },
  justifyRight: {
    textAlign: 'right',
  },
  opacity: {
    opacity: 0.8,
    textAlign: 'right',
  },
  spacing: {
    width: '54%',
  },
}));

export const DepositSummary: React.FC<IDepositSummaryProps> = (props) => {
  const classes = useStyles();
  const isLocked = useContext(UnlockTimeContext)?.isLocked;

  return (
    <>
      <Box display="grid" gridGap="24px">
        {isLocked && (
          <>
            <DepositSummaryField>
              <MediumTypography color="secondary">
                Unlock Date
                <Tooltip title={unlockDateToolTipText}>
                  <HelpRounded className={classes.icon} />
                </Tooltip>
              </MediumTypography>
              <MediumBoldTypography secondaryFont>
                {props.unlockDate}
                <span className={classes.unit}> UTC</span>
              </MediumBoldTypography>
            </DepositSummaryField>
          </>
        )}
        <SlimDivider />
        <DepositSummaryField>
          <MediumTypography color="secondary">
            {'Deposit Amount'}
          </MediumTypography>
          <MediumBoldTypography secondaryFont>
            {formatStringifyNumberToDot(props.totalDeposit)}
            <span className={classes.unit}> USDC</span>
          </MediumBoldTypography>
        </DepositSummaryField>
      </Box>
    </>
  );
};

export const DepositSummaryField = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));
