import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { BUMP } from '../../config/coins';
import { termsAndConditionsLink } from '../../config/links';
import { Routes, subRoutes } from '../../config/routes';
import { ICoin } from '../../interfaces/ICoin';
import { PixelCheckbox } from '../shared/Checkbox';
import { CoinIcons } from '../shared/icons/CoinIcons';
import { PixelButton } from '../shared/PixelButton';
import {
  CoinIconSmall,
  GreenLink,
  NumberTypography,
  SymbolTypography,
} from '../shared/styles/ConfirmationPage';
import { DarkSection, Title } from '../shared/styles/Section';
import { WhiteSmallRoboto } from '../shared/styles/TypographyRoboto';

export interface ConfirmClaimProps {
  token: ICoin;
  toToken?: ICoin | any;
}

export const ConfirmClaimSection: React.FC<ConfirmClaimProps> = () => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const location = useLocation<ConfirmClaimProps>();
  const history = useHistory();

  const toToken = location.state ? location.state.toToken : BUMP;

  const handleCheckbox = () => {
    setTermsAccepted(!termsAccepted);
  };

  const onBackButtonPress = () => {
    history.push(`${Routes.ClaimDetails}/${subRoutes.Select}`, {
      ...location.state,
    });
  };

  return (
    <Box maxWidth={{ xs: '100%', sm: '100%' }} m="auto">
      <Box display="flex" justifyContent="center" mb={5}>
        <Title>Confirmation</Title>
      </Box>
      <DarkSection
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={{ xs: 3, sm: 3, lg: 4 }}
        position="relative"
        pt={{ xs: 9, sm: 9, lg: 10 }}
      >
        <CoinIconSmall src={true && CoinIcons['BUMP']} />
        <Box display="flex" alignItems="baseline" mb={1}>
          <NumberTypography variant="h1" style={{ marginRight: '8px' }}>
            +
          </NumberTypography>
          <SymbolTypography variant="h4" color="textSecondary">
            {toToken.symbol}
          </SymbolTypography>
        </Box>
      </DarkSection>
      <Box mt={3} display="flex" alignItems="center">
        <Box mr={3} style={{ position: 'relative', top: '-1px' }}>
          <PixelCheckbox checked={termsAccepted} onChange={handleCheckbox} />
        </Box>
        <Box>
          <WhiteSmallRoboto>
            By checking this box you acknowledge and agree to our
            <GreenLink
              href={termsAndConditionsLink}
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              terms and conditions
            </GreenLink>
            .
          </WhiteSmallRoboto>
        </Box>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="minmax(150px, 33%) auto minmax(150px, 33%)"
        mt={{ xs: 3, sm: 4 }}
        pb="10vh"
      >
        <Box>
          <PixelButton fullWidth color="secondary" onClick={onBackButtonPress}>
            Back
          </PixelButton>
        </Box>
        <Box gridColumn="3">
          <PixelButton fullWidth color="primary">
            Confirm
          </PixelButton>
        </Box>
      </Box>
    </Box>
  );
};
