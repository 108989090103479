import { Toolbar, Box, Link, LinkProps, styled } from '@material-ui/core';
import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { BumpBalanceInfo } from './BumpBalanceInfo';
import { ProtectButton, EarnButton } from './CTAButtons';
import { WalletAccountInfo } from './WalletAccountInfo';

import HorizontalPixelLogo from '../../../assets/bumper-horizontal-pixel-logo.svg';
import discordIcon from '../../../assets/discordLogo.svg';
import telegramIcon from '../../../assets/telegramLogo.svg';
import {
  discordLink,
  docsLink,
  supportLink,
  telegramLink,
} from '../../../config/links';
import { Routes } from '../../../config/routes';
import { WalletContext } from '../../../config/walletContext';
// import { useAppSelector } from '../../../state/hooks';
import { PixelButton } from '../PixelButton';
import { PixelTabs, PixelTab } from '../Tabs';
interface IDesktopAppBarProps {
  showNavToolbar: boolean;
}

export const DesktopAppBar: React.FC<IDesktopAppBarProps> = (props) => {
  const history = useHistory();
  // const usdc = useAppSelector(
  //   (state) => state.coin.coinDetails['USDC'].balance,
  // );
  const isWalletConnected = useContext(WalletContext).isWalletConnected;

  return (
    <>
      <DesktopMainToolbar>
        <Box
          display="flex"
          width="100%"
          justifyContent={isWalletConnected ? 'space-between' : 'flex-end'}
        >
          {isWalletConnected ? <BumpBalanceInfo /> : <></>}
          <Box
            display="grid"
            alignItems="center"
            gridAutoFlow="column"
            gridGap="32px"
          >
            <MuiLink
              href={docsLink}
              target="_blank"
              rel="noreferrer"
              style={{ marginBottom: '5px' }}
            >
              Docs
            </MuiLink>
            <MuiLink
              href={supportLink}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginBottom: '5px' }}
            >
              Support
            </MuiLink>

            <MuiLink href={discordLink} target="_blank" rel="noreferrer">
              <img
                style={{ width: '16px', height: '16px' }}
                src={discordIcon}
                alt=""
              />
            </MuiLink>
            <MuiLink href={telegramLink} target="_blank" rel="noreferrer">
              <img
                style={{ width: '16px', height: '16px' }}
                src={telegramIcon}
                alt=""
              />
            </MuiLink>
            {isWalletConnected ? (
              <WalletAccountInfo />
            ) : (
              <PixelButton
                color="primary"
                variant="contained"
                size="small"
                onClick={() => history.push(Routes.ConnectWallet)}
                style={{ fontSize: '11px', padding: '5.5px 12px 6.5px 12px' }}
              >
                Connect Wallet
              </PixelButton>
            )}
          </Box>
        </Box>
      </DesktopMainToolbar>
      {props.showNavToolbar ? (
        <DesktopNavToolbar>
          <Box
            width="100%"
            display="grid"
            alignItems="center"
            gridTemplateColumns="minmax(max-content, 292px) 1fr minmax(270px, 10fr) 1fr max-content"
            gridGap="24px"
          >
            <img src={HorizontalPixelLogo} alt="bumper logo" />
            <Box gridColumn={3}>
              <PixelTabs value={history.location.pathname} variant="scrollable">
                <PixelTab
                  label="Dashboard"
                  value={Routes.DepositDashboard}
                  to={Routes.DepositDashboard}
                  component={RouterLink}
                />
                <PixelTab
                  label="Epoch"
                  value={Routes.Epochs}
                  to={Routes.Epochs}
                  component={RouterLink}
                />
                <PixelTab
                  label="Staking"
                  value={Routes.Staking}
                  to={Routes.Staking}
                  component={RouterLink}
                />
                {/* <PixelTab
                  label="Governance"
                  value={Routes.Governance}
                  to={Routes.Governance}
                  component={RouterLink}
                /> */}
                {/* <PixelTab
                  label="Protocol"
                  value={Routes.Protocol}
                  to={Routes.Protocol}
                  component={RouterLink}
                /> */}
              </PixelTabs>
            </Box>
            <Box
              gridColumn={5}
              display="grid"
              gridTemplateColumns="1fr 1fr"
              gridGap="24px"
            >
              <ProtectButton href='https://app.bumper.fi/console/protect/assets' />
              <EarnButton href='https://app.bumper.fi/console/earn/assets' />
              {/* <ButtonSelection coin={usdc} /> */}
            </Box>
          </Box>
        </DesktopNavToolbar>
      ) : null}
    </>
  );
};

const DesktopMainToolbar = styled(Toolbar)(({ theme }) => ({
  background: theme.palette.other.Green2,
  minHeight: '48px',
}));

const DesktopNavToolbar = styled(Toolbar)(({ theme }) => ({
  background: theme.palette.other.Green3,
  height: '72px',
}));

const MuiLink = styled((props: LinkProps) => {
  return <Link underline="none" {...props} />;
})(({ theme }) => ({
  color: theme.palette.other.LabelText,
  fontFamily: theme.font.secondary,
  fontWeight: 700,
  fontSize: '11px',
  fontHeight: '14px',
}));
