import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { DepositSummary } from './common/DepositSummary';

import { bUSDCCoin } from '../../config/coins';
import { CONTRACTS, TokenList } from '../../config/contractAddresses';
import { termsAndConditionsLink } from '../../config/links';
import { Routes, subRoutes } from '../../config/routes';
import { ICoin } from '../../interfaces/ICoin';
import { MakerService } from '../../services/makerService';
import { fetchBumpDetails } from '../../state/actions/bumpDetailsAction';
import { fetchCoinDetails } from '../../state/actions/coinActions';
import { fetchbUSDDetails } from '../../state/actions/liquidityActions';
import { useAppSelector } from '../../state/hooks';
import {
  checkNullable,
  formatStringifyNumberToDot,
  timeInMilliseconds,
} from '../../utils/helpers';
import { PixelCheckbox } from '../shared/Checkbox';
import { CoinIcons } from '../shared/icons/CoinIcons';
import { PixelButton } from '../shared/PixelButton';
import {
  CoinIcon,
  DepositValue,
  GreenLink,
  SymbolTypography,
} from '../shared/styles/ConfirmationPage';
import { Title, DarkSection } from '../shared/styles/Section';
import { WhiteSmallRoboto } from '../shared/styles/TypographyRoboto';

export interface IConfirmDepositLocationProp {
  liquidityDepositValue: string;
  token: ICoin;
  toToken: ICoin;
  unlockDate: string;
  lockedDeposit: string;
  estimatedBumpPurchase: string;
}
interface ConfirmDepositSectionProps {
  token: ICoin;
}

export const ConfirmDepositSection: React.FC<ConfirmDepositSectionProps> = (
  props: ConfirmDepositSectionProps,
) => {
  const dispatch = useDispatch();
  const location = useLocation<IConfirmDepositLocationProp>();

  const {
    liquidityDepositValue,
    unlockDate,
    lockedDeposit,
    estimatedBumpPurchase,
  } = location.state;

  const token: ICoin = props.token;
  const toToken: ICoin = location.state ? location.state.toToken : bUSDCCoin;
  const makerService: MakerService = MakerService.getInstance();
  const history = useHistory();
  const [termsAccepted, setTermsAccepted] = useState(false);

  const { addToast } = useToasts();

  const onBackButtonPress = () => {
    history.push(`${Routes.AddLiquidity}/${subRoutes.Select}`, {
      ...location.state,
    });
  };

  const onConfirmDeposit = async () => {
    try {
      history.replace(`${Routes.AddLiquidity}/${subRoutes.Approve}`, {
        token,
      });
      const tokenDetails =
        CONTRACTS.TOKEN_DETAILS[token.symbol as keyof TokenList];
      const tx = await makerService.transferMakerTokensToReserve(
        `${parseFloat(liquidityDepositValue).toFixed(tokenDetails.decimal)}`,
        '0',
        tokenDetails,
      );

      history.replace(`${Routes.AddLiquidity}/${subRoutes.Processing}`, {
        token,
      });
      await tx.wait();
      dispatch(fetchCoinDetails([token]));
      history.replace(`${Routes.DepositDashboard}`);

      addToast('Later Dude!', {
        appearance: 'success',
      });
      dispatch(fetchbUSDDetails());
      dispatch(fetchBumpDetails());
    } catch (err) {
      console.error(err);
      history.replace(`${Routes.DepositDashboard}`);
      addToast('We were unable to deposit your assets. Please try again.', {
        appearance: 'warning',
      });
    }
  };

  const handleCheckbox = () => {
    setTermsAccepted(!termsAccepted);
  };

  const unlockTime = useAppSelector(
    (state) => state.lockTimestamp.lockTimestampDetails.SwapUnlockTime,
  );

  return (
    <Box maxWidth={{ xs: '100%', sm: '100%' }} m="auto">
      <Box display="flex" justifyContent="center" mb={5}>
        <Title>Confirmation</Title>
      </Box>
      <DarkSection
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={{ xs: 3, sm: 3, lg: 4 }}
        position="relative"
        pt={{ xs: 9, sm: 9, lg: 10 }}
      >
        <CoinIcon
          src={
            checkNullable(lockedDeposit)
              ? CoinIcons[toToken.symbol]
              : CoinIcons['BUMP']
          }
        />
        <Box display="flex" alignItems="baseline" mb={1}>
          <DepositValue variant="h1">
            +
            {checkNullable(lockedDeposit)
              ? formatStringifyNumberToDot(lockedDeposit)
              : formatStringifyNumberToDot(estimatedBumpPurchase)}
          </DepositValue>
          <SymbolTypography variant="h4" color="textSecondary">
            {checkNullable(lockedDeposit) ? toToken.symbol : 'BUMP'}
          </SymbolTypography>
        </Box>
        <Box mt={5} width="100%">
          <DepositSummary
            unlockDate={unlockDate}
            totalDeposit={liquidityDepositValue}
          />
        </Box>
      </DarkSection>
      <Box mt={3} display="flex" alignItems="center">
        <Box mr={3} style={{ position: 'relative', top: '-1px' }}>
          <PixelCheckbox
            checked={termsAccepted}
            onChange={handleCheckbox}
            style={{ padding: 0 }}
          />
        </Box>
        <Box>
          <WhiteSmallRoboto color="secondary">
            By checking this box you acknowledge and agree to our{' '}
            <GreenLink
              href={termsAndConditionsLink}
              target="_blank"
              rel="noreferrer"
            >
              terms and conditions
            </GreenLink>
            .
          </WhiteSmallRoboto>
        </Box>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="minmax(150px, 33%) auto minmax(150px, 33%)"
        mt={{ xs: 3, sm: 4 }}
        pb="10vh"
      >
        <Box>
          <PixelButton
            fullWidth
            color="secondary"
            disabled={timeInMilliseconds(unlockTime) > Date.now()}
            onClick={onBackButtonPress}
          >
            Back
          </PixelButton>
        </Box>
        <Box gridColumn="3">
          <PixelButton
            fullWidth
            color="primary"
            disabled={
              !termsAccepted || timeInMilliseconds(unlockTime) > Date.now()
            }
            onClick={onConfirmDeposit}
          >
            Confirm
          </PixelButton>
        </Box>
      </Box>
    </Box>
  );
};
