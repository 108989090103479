import { ApolloProvider } from '@apollo/client/react';
import { Web3ReactProvider } from '@web3-react/core';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';

import { BackgroundImage } from './components/shared/BackgroundImage';
import { Toast } from './components/shared/Toast';
import { appRoutes } from './pages/routes/AppRoutes';
import { RenderRoutes } from './pages/routes/RenderRoutes';
import { AppThemeProvider } from './providers/AppThemeProvider';
import { ErrorHandler } from './providers/ErrorHandler';
import { client } from './providers/graphql';
import { ReduxProvider } from './providers/ReduxProvider';
import { EthersServiceProvider } from './services/ethersServiceProvider';
import { ScrollToTop } from './utils/scrollToTop';

export const App: React.FC = () => {
  return (
    <AppThemeProvider>
      <ToastProvider
        components={{ Toast: Toast }}
        placement="bottom-left"
        autoDismissTimeout={20000}
        autoDismiss={true}
      >
        <Web3ReactProvider
          getLibrary={() => EthersServiceProvider.getInstance().getProvider()}
        >
          <ApolloProvider client={client}>
            <ReduxProvider>
              <BrowserRouter>
                <ErrorHandler>
                  <ScrollToTop />
                  <BackgroundImage>
                    <RenderRoutes routes={appRoutes} />
                  </BackgroundImage>
                </ErrorHandler>
              </BrowserRouter>
            </ReduxProvider>
          </ApolloProvider>
        </Web3ReactProvider>
      </ToastProvider>
    </AppThemeProvider>
  );
};
