import { Box } from '@material-ui/core';
import { BigNumber, ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ConfirmFixedSectionProps } from './ConfirmStakeSection';

import { isFrontendFunctionalityDisabled } from '../../config';
import { Routes, subRoutes } from '../../config/routes';
import { ICoin } from '../../interfaces/ICoin';
import { StakeOptionType, StakingService } from '../../services/stakingService';
import { useAppSelector } from '../../state/hooks';
import { PixelButton } from '../shared/PixelButton';
import {
  FlowButtonWrapper,
  FlowCard,
  MainFlowContainer,
} from '../shared/styles/FlowPages';
import { Title } from '../shared/styles/Section';
import { ButtonsContainer } from '../shared/styles/StakeStyles';
import { FixedStakePeriod } from '../StakingDetails/FixedStakePeriod';
import { FlexibleStakePeriod } from '../StakingDetails/FlexibleStakePeriod';
import { SliderSection } from '../StakingDetails/SliderStakingSection';
import { StakeCoinDetails } from '../StakingDetails/StakeCoinDetails';
import { SwitchStakePeriod } from '../StakingDetails/SwitchStakePeriod';

interface IStakingSectionProps {
  token: ICoin;
  isClaimStake?: boolean;
}

interface TabPanelProps {
  value: StakingTabs;
  index: StakingTabs;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {
  return (
    <div hidden={value !== index} style={{ zIndex: 5 }}>
      {value === index && children}
    </div>
  );
};

export enum StakingTabs {
  fixed,
  flexible,
}

const FLEXIBLE_OPTION_INDEX = 0;

export const ClaimStakeSection: React.FC<IStakingSectionProps> = ({
  token,
  isClaimStake,
}) => {
  const history = useHistory();
  const location = useLocation<ConfirmFixedSectionProps>();

  const stakingService = StakingService.getInstance();
  const bumpBalance = useAppSelector((state) => state.bumpDetails.bumpBalance);
  const utcTime = useAppSelector((state) => state.lockTimestamp.utcTime);

  const defaultStakeAmount = location.state?.stakeAmount || +bumpBalance / 2;

  const [activeTab, setActiveTab] = useState(StakingTabs.fixed);
  const [confirmCheckbox, setConfirmCheckbox] = useState(false);
  const [autoRenew, setAutoRenew] = useState<boolean>(false);
  const [stakeAmount, setStakeAmount] = useState<number>(defaultStakeAmount);
  const [isFirstRender, setFirstRender] = useState<boolean>(true);
  const [areTabsDisabled, disableTabs] = useState<boolean>(true);

  const [activeOptionIndex, setActiveOptionIndex] = useState<number>(
    location.state?.activeOptionIndex,
  );
  const [stakeOptions, setStakeOptions] = useState<StakeOptionType[]>([
    { periodInDays: 30, multiplier: 100 },
  ]);

  const stakeOption = stakeOptions[activeOptionIndex];

  const onCancel = () => {
    if (isClaimStake) {
      return history.push({
        pathname: `${Routes.ClaimStake}/${subRoutes.Claim}`,
        state: {
          claimedAmount: location.state?.stakeAmount,
        },
      });
    }
    history.replace(Routes.Staking);
  };

  const onClick = () => {
    const isFlexible = activeTab === StakingTabs.flexible && confirmCheckbox;

    const props: ConfirmFixedSectionProps = {
      token,
      stakingType: activeTab,
      stakeAmount,
      option: isFlexible ? stakeOptions[FLEXIBLE_OPTION_INDEX] : stakeOption,
      activeOptionIndex: isFlexible ? FLEXIBLE_OPTION_INDEX : activeOptionIndex,
      autorenew: autoRenew,
      claimedAmount: location.state?.stakeAmount,
      walletAmount:
        defaultStakeAmount < stakeAmount
          ? ethers.utils.parseUnits(
              (stakeAmount - defaultStakeAmount).toString(),
              18,
            )
          : BigNumber.from(0),
    };
    history.push({
      pathname: isClaimStake
        ? `${Routes.ClaimStake}/${subRoutes.Confirm}`
        : `${Routes.StakingDetails}/${subRoutes.Confirm}`,
      state: props,
    });
  };

  useEffect(() => {
    (async () => {
      const options = await stakingService.getStakeOptions();
      setStakeOptions(options);
    })();
  }, []);
  useEffect(() => {
    setConfirmCheckbox(false);
  }, [activeTab]);
  useEffect(() => {
    if (
      isFirstRender &&
      defaultStakeAmount === stakeAmount &&
      !areTabsDisabled
    ) {
      disableTabs(true);
    } else {
      disableTabs(false);
      setFirstRender(false);
    }
  }, [stakeAmount]);

  return (
    <Box display="flex" flexDirection="column" alignItems="start">
      <Title>Staking</Title>
      <MainFlowContainer>
        <FlowCard gridArea="coin-details">
          <StakeCoinDetails />
        </FlowCard>
        <SliderSection
          title="Stake Amount"
          text="Move the slider to choose how much you want to stake or enter the amount in the field below. You must do either to to proceed to the next step."
          bumpBalance={(
            parseFloat(bumpBalance) + location.state?.stakeAmount
          ).toString()}
          stakeAmount={stakeAmount}
          setStakeAmount={setStakeAmount}
        />
        <Box style={{ opacity: areTabsDisabled ? '.6' : 1 }}>
          <SwitchStakePeriod
            onSwitch={setActiveTab}
            value={activeTab}
            disabled={areTabsDisabled}
          />
          <TabPanel value={activeTab} index={StakingTabs.flexible}>
            <FlexibleStakePeriod
              title={'Flexible Stake Period'}
              text={
                <span>
                  By clicking the below box, you are confirming your choice of
                  the <strong>flexible</strong> staking option. This means you
                  will be able to unstake at any time but you will be subject to
                  a <strong>10-day cooldown</strong> period before you can claim
                  your stake. At that point, there is a two (2) day window to{' '}
                  <strong>unstake</strong> before you will have to activate your
                  cooldown period again.
                </span>
              }
              confirmCheckbox={confirmCheckbox}
              setConfirmCheckbox={setConfirmCheckbox}
              disabled={areTabsDisabled}
            />
          </TabPanel>
          <TabPanel value={activeTab} index={StakingTabs.fixed}>
            <FixedStakePeriod
              title="Fixed Stake Period"
              text="Choose your staking term."
              stakeOptions={stakeOptions.slice(1)}
              activeOptionIndex={activeOptionIndex}
              setActiveOptionIndex={setActiveOptionIndex}
              autoRenew={autoRenew}
              setAutoRenew={setAutoRenew}
              disabled={areTabsDisabled}
            />
          </TabPanel>
        </Box>
        <ButtonsContainer>
          <FlowButtonWrapper>
            <PixelButton
              fullWidth
              color="primary"
              onClick={onClick}
              disabled={
                stakeAmount === 0 ||
                (activeTab === StakingTabs.fixed && !stakeOption) ||
                (activeTab === StakingTabs.flexible && !confirmCheckbox) ||
                isFrontendFunctionalityDisabled(utcTime)
              }
            >
              Next
            </PixelButton>
          </FlowButtonWrapper>
          <FlowButtonWrapper>
            <PixelButton fullWidth color="secondary" onClick={onCancel}>
              Cancel
            </PixelButton>
          </FlowButtonWrapper>
        </ButtonsContainer>
      </MainFlowContainer>
    </Box>
  );
};
