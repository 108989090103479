import { styled, Box } from '@material-ui/core';
import React from 'react';

import { Identicon } from './Identicon';

import { EthersServiceProvider } from '../../../services/ethersServiceProvider';
import { createPixelInsetBorder } from '../../../utils/createPixelInsetBorder';
import { shortenAddress } from '../../../utils/helpers';
import { ExtraSmallTypography } from '../Typography';

export const WalletAccountInfo: React.FC = () => {
  const ethersService: EthersServiceProvider =
    EthersServiceProvider.getInstance();

  return (
    <MetamaskAccountContainer
      display="grid"
      alignItems="center"
      gridAutoFlow="column"
      gridGap="8px"
    >
      <Identicon account={ethersService.currentAccount} />
      <ExtraSmallTypography style={{ lineHeight: 'normal' }}>
        {shortenAddress(ethersService.currentAccount)}
      </ExtraSmallTypography>
      <Dot />
    </MetamaskAccountContainer>
  );
};

const Dot = styled('div')(({ theme }) => ({
  width: '6px',
  height: '6px',
  borderRadius: '50%',
  background: theme.palette.other.BrightGreen,
}));

const MetamaskAccountContainer = styled(Box)(({ theme }) => ({
  boxShadow: createPixelInsetBorder(
    `${theme.palette.other.Support}`,
    `${theme.palette.other.Green2}`,
    `${theme.palette.other.Green2}`,
    1,
  ),
  padding: '7px 11px',
  maxWidth: '150px',
}));
