import { createSlice } from '@reduxjs/toolkit';

import { ILockTimestamp } from '../../interfaces/ILockTimestamp';
import {
  fetchLockTimestamp,
  fetchUtcTime,
} from '../actions/lockTimestampAction';
const initialState: { lockTimestampDetails: ILockTimestamp; utcTime: number } =
  {
    lockTimestampDetails: {
      BUSDCUnlockTime: 0,
      SwapUnlockTime: 0,
    },
    utcTime: Date.now(),
  };
export const aprSlice = createSlice({
  name: 'lockTimestamp',
  initialState,
  reducers: {
    incrementUtcTime: (state) => {
      state.utcTime += 1000;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLockTimestamp.fulfilled, (state, action) => {
      state.lockTimestampDetails = { ...action.payload };
    });
    builder.addCase(fetchUtcTime.fulfilled, (state, action) => {
      state.utcTime = action.payload;
    });
  },
});

export const { incrementUtcTime } = aprSlice.actions;

export default aprSlice.reducer;
