import { Box, Button, styled, Typography } from '@material-ui/core';
import { HelpRounded } from '@material-ui/icons';

import { COLORS } from '../../../config/colors';
import {
  createPixelInsetBorder,
  createPixelInsetBorder2,
} from '../../../utils/createPixelInsetBorder';
import SmallTypography from '../Typography';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: 'fit-content',
  width: '255px',
  [theme.breakpoints.down('xs')]: {
    paddingBottom: '1rem',
    marginBottom: '1rem',
    borderBottom: `2px solid ${theme.palette.other.BoxOutline}`,
  },
}));

export const MainSecondContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  padding: '0 2rem',
  position: 'relative',
  gap: '30px',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 0,
  },
}));

export const Description = styled(Typography)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  fontFamily: 'Roboto Mono',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '26px',
  height: 'fit-content',
  color: COLORS.LightGreen,
  margin: '24px 0',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '26px',
    marginTop: '2rem',
  },
}));

export const MainDescription = styled(Typography)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  fontFamily: 'Roboto Mono',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '26px',
  height: 'fit-content',
  maxWidth: '100%',
  color: COLORS.LightGreen,
  [theme.breakpoints.down('sm')]: {
    width: '120%',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '26px',
    marginLeft: '-1.5em',
  },
}));

export const Header = styled(Typography)(({ theme }) => ({
  height: 'fit-content',
  border: `4px solid ${COLORS.Primary2}`,
  color: COLORS.Primary2,
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '44px',
  lineHeight: '110%',
  padding: '.5rem .675rem',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'fit-content',
    fontSize: '1.75em',
    padding: '0.4em 0.2em .875rem 0.3em',
    border: `2.5px solid ${COLORS.Primary2}`,
  },
}));

export const ContainerMainEpoch = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  height: 'fit-content',
  backgroundColor: COLORS.Green3,
  border: `2px solid ${COLORS.Border}`,
  padding: '2.5em',
  marginBottom: '2rem',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: '2.5em 1.5em',
  },
}));

export const CoinToCoinContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
  height: '48px',
});

export const StyledCoin = styled('img')({
  width: '3em',
  height: '3em',
});

export const StyledVector = styled('img')(({ theme }) => ({
  width: '32px',
  height: '28px',
  position: 'relative',
  top: '-3px',
  [theme.breakpoints.down('sm')]: {
    top: 0,
  },
}));

export const StyledButton = styled(Box)({
  height: '30px',
  width: '31px',
  maxHeight: '34px',
  color: COLORS.White,
  boxSizing: 'border-box',
  textTransform: 'inherit',
  borderRadius: '2px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  boxShadow: createPixelInsetBorder(
    COLORS.Green1,
    COLORS.Green1,
    COLORS.Green1,
    1,
  ),
  backgroundColor: COLORS.Primary3,

  '&:hover': {
    background: COLORS.Green3,
    cursor: 'pointer',
  },
  img: {
    width: '16px',
    height: '16px',
  },
});

export const StyledHistoryButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.font.secondary,
  height: '28px',
  width: '110px',
  fontWeight: 700,
  fontSize: '16px',
  maxHeight: '42px',
  color: COLORS.White,
  padding: '12px 32.5px',
  boxSizing: 'border-box',
  textTransform: 'inherit',
  borderRadius: '1px',
  marginLeft: '.75rem',

  boxShadow: createPixelInsetBorder(
    COLORS.Green3,
    COLORS.Green3,
    COLORS.Green3,
    1.5,
  ),
  backgroundColor: COLORS.Primary3,

  '&:hover': {
    background: COLORS.Green3,
  },

  '&.MuiButton-sizeSmall': {
    padding: '5px 22px',
    fontSize: '16px',
    maxHeight: '28px',
  },
  '&.MuiButton-sizeLarge': {
    padding: '12px 56px',
    fontSize: '22px',
    maxHeight: '52px',
  },
}));

export const ClaimButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.font.secondary,
  fontWeight: 700,
  fontSize: '16px',
  maxHeight: '42px',
  height: '30px',
  padding: '0 1.375rem',
  color: COLORS.White,
  textTransform: 'inherit',
  borderRadius: '0px',

  '&:first-child': {
    marginRight: '1rem',
  },

  boxShadow: createPixelInsetBorder('#000000', '#E0AD85', '#914F1B'),
  backgroundColor: COLORS.Primary2,

  '&:hover': {
    background: '#BB621B',
  },
  '&.MuiButton-containedPrimary': {
    backgroundColor: theme.palette.primary.light,
    boxShadow: createPixelInsetBorder('#000000', '#E0AD85', '#914F1B'),
    color: theme.palette.common.black,

    '&:active': {
      boxShadow: createPixelInsetBorder('#000000', '#914F1B', '#E0AD85'),
    },

    '&.Mui-disabled': {
      color: theme.palette.other.DarkOrange,
      boxShadow: createPixelInsetBorder('#000000', '#D6925C', '#BD6723'),
    },
  },

  '&.MuiButton-sizeSmall': {
    padding: '5px 22px',
    fontSize: '16px',
    maxHeight: '28px',
  },
  '&.MuiButton-sizeLarge': {
    padding: '12px 56px',
    fontSize: '22px',
    maxHeight: '52px',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '0 1rem',
    marginTop: '1rem',
    width: '200px',

    '&:first-child': {
      marginRight: '0 !important',
    },
  },
}));

export const RowTitle = styled(SmallTypography)(({ theme }) => ({
  color: COLORS.White,
  fontSize: '14px',
  lineHeight: '20px',

  [theme.breakpoints.down('xs')]: {
    fontSize: '12px',
  },
}));

export const WithdrawButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.font.secondary,
  height: '30px',
  width: '125px',
  fontWeight: 700,
  fontSize: '16px',
  maxHeight: '42px',
  color: COLORS.White,
  padding: '12px 32.5px',
  boxSizing: 'border-box',
  textTransform: 'inherit',
  borderRadius: '0px',

  boxShadow: createPixelInsetBorder2('#002429', '#002429', '#002429', 1),
  backgroundColor: COLORS.Primary3,

  '&:hover': {
    background: COLORS.Green3,
  },

  '&.MuiButton-sizeSmall': {
    padding: '5px 22px',
    fontSize: '16px',
    maxHeight: '28px',
  },
  '&.MuiButton-sizeLarge': {
    padding: '12px 56px',
    fontSize: '22px',
    maxHeight: '52px',
  },
}));

export const BranchIcon = styled('img')({
  height: '18px',
  width: '27px',
  marginLeft: '19px',
});
export const BusdcIcon = styled('img')({
  marginLeft: '6px',
  height: '24px',
  width: '24px',
});
export const CustomHelpIcon = styled(HelpRounded)({
  width: '.8rem',
  height: '.8rem',
  color: '#ffffff',
  marginLeft: '7px',
  marginTop: '3px',
});
export const TimeUnits = styled('span')({
  fontSize: '11px',
  marginRight: '6px',
});
export const TimeUnitsPreSale = styled(Typography)(({ theme }) => ({
  fontFamily: 'Roboto',
  color: COLORS.Primary2,
  fontWeight: 700,
  fontSize: '34px',
  fontStyle: 'normal',
  lineHeight: '49px',

  [theme.breakpoints.down('sm')]: {
    lineHeight: '120%',
    fontSize: '16px',
  },
}));
export const DataPreSale = styled(Typography)(({ theme }) => ({
  fontFamily: 'Roboto',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontSize: '34px',
  lineHeight: '120%',
  marginLeft: '17.5%',
  [theme.breakpoints.down('sm')]: {
    width: '5px',
    height: '19px',
    fontSize: '16px',
    lineHeight: '120%',
    color: COLORS.Primary2,
    marginLeft: '25%',
  },
}));
