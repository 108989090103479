import { createSlice } from '@reduxjs/toolkit';

import { IBumpDetails } from '../../interfaces/IBumpDetails';
import {
  fetchBumpDetails,
  fetchCurrentTVL,
  fetchBumpPurchaseAllocation,
  fetchBumpRewardAllocation,
  fetchMaxPercent,
} from '../actions/bumpDetailsAction';

const initialState: IBumpDetails = {
  bumpBalance: '',
  bumpBalanceInUsdc: '',
  // FIXME: Literal
  currentBumpPrice: '1.5',
  bumpPurchaseAllocation: '',
  bumpRewardAllocation: '',
  maxPercent: 0,
  currentTVL: '',
};

export const bumpSlice = createSlice({
  name: 'bumpBalance',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBumpDetails.fulfilled, (state, action) => {
      state.bumpBalance = action.payload.bumpBalance;
      state.bumpBalanceInUsdc = action.payload.bumpBalanceInUsdc;
      state.currentBumpPrice = action.payload.currentBumpPrice;
    });
    builder.addCase(fetchCurrentTVL.fulfilled, (state, action) => {
      state.currentTVL = action.payload;
    });
    builder.addCase(fetchBumpPurchaseAllocation.fulfilled, (state, action) => {
      state.bumpPurchaseAllocation = action.payload;
    });
    builder.addCase(fetchBumpRewardAllocation.fulfilled, (state, action) => {
      state.bumpRewardAllocation = action.payload;
    });
    builder.addCase(fetchMaxPercent.fulfilled, (state, action) => {
      state.maxPercent = action.payload;
    });
  },
});
export default bumpSlice.reducer;
