import { styled, Box } from '@material-ui/core';
import React from 'react';

import Typography, { IMuiTypographyProps } from '../Typography';

export const Section = styled(Box)(({ theme }) => ({
  padding: '24px',
  background: theme.palette.other.Green1,
  border: `2px solid ${theme.palette.other.BoxOutline}`,
  height: '100%',
  zIndex: 1,
}));
export const PreSaleDepositSection = styled(Box)(({ theme }) => ({
  padding: '24px',
  background: theme.palette.other.Green2,
  border: `2px solid #E07A29`,
  height: '100%',
  zIndex: 1,
}));

export const Title = styled((props: IMuiTypographyProps) => (
  <Typography variant="h1" secondaryFont {...props} />
))(({ theme }) => ({
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
  },
}));

export const SlimDivider = styled('div')({
  background: 'rgba(255, 255, 255, 0.1)',
  height: '1px',
});

export const Divider = styled('div')({
  background: 'rgba(255, 255, 255, 0.2)',
  height: '2px',
});

export const DarkSection = styled(Box)(({ theme }) => ({
  background: theme.palette.other.Green3,
  border: '2px solid black',
}));
