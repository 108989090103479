import { Box, Button, styled, Typography } from '@material-ui/core';

import { MediumBoldRoboto } from './TypographyRoboto';

import { COLORS } from '../../../config/colors';
import { createPixelInsetBorder2 } from '../../../utils/createPixelInsetBorder';

export const ContainerStake = styled(Box)(({ theme }) => ({
  gridGap: '16px',
  background: COLORS.Green1,
  border: `1px solid ${COLORS.BoxOutline}`,
  padding: '24px',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '544px',
    width: 'unset',
  },
}));

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '1rem',
  paddingBottom: '10vh',
  flexDirection: 'row-reverse',
  [theme.breakpoints.down('sm')]: {
    marginTop: '.625rem',
    flexDirection: 'column',
  },
}));

export const CoinDetailsWrapper = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
}));

export const SectionFixed = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  alignContent: 'center',

  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

export const SectionName = styled(Typography)(({ theme }) => ({
  fontFamily: 'Roboto Mono',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '14px',
  color: 'rgba(255, 255, 255, 0.7)',

  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
  },
}));

export const SectionInfo = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRight: `1px solid ${COLORS.BoxOutline}`,
  width: '185px',
  padding: '5px 0 5px 32px',

  '&:first-child': {
    paddingLeft: '.5rem',
  },

  [theme.breakpoints.down('sm')]: {
    '&:not(:last-child)': {
      borderBottom: `1px solid ${COLORS.BoxOutline}`,
    },
    width: '100%',
    textAlign: 'center',
    margin: '0 auto',
    alignItems: 'center',
    borderRight: 'none',
    padding: '24px 0',
  },
}));

export const UnstakeButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.font.secondary,
  height: '30px',
  width: '121px',
  fontWeight: 700,
  fontSize: '16px',
  maxHeight: '42px',
  color: COLORS.White,
  padding: '12px 32.5px',
  boxSizing: 'border-box',
  textTransform: 'inherit',
  borderRadius: '0px',
  marginLeft: '18px',

  boxShadow: createPixelInsetBorder2('#000', '#000', '#000', 1),
  backgroundColor: COLORS.Primary3,

  '&:hover': {
    background: COLORS.Green3,
  },
  '&.Mui-disabled': {
    '&> span': {
      color: '#99AEB1',
    },
  },
  '&.MuiButton-sizeSmall': {
    padding: '5px 22px',
    fontSize: '16px',
    maxHeight: '28px',
  },
  '&.MuiButton-sizeLarge': {
    padding: '12px 56px',
    fontSize: '22px',
    maxHeight: '52px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '112px',
  },
}));

export const StakedAmount = styled(MediumBoldRoboto)({
  color: COLORS.LightGreen,
});

export const SectionContent = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  height: '70%',
  paddingTop: '0.5em',
});
