import * as Sentry from '@sentry/react';
import { signERC2612Permit } from 'eth-permit';
import { RSV } from 'eth-permit/dist/rpc';
import { BigNumber, BigNumberish, ContractTransaction, ethers } from 'ethers';

import { EthersServiceProvider } from './ethersServiceProvider';

import { OperationType } from '../components/Staking/HistoryModal';
import { chainID, CONTRACTS, TokenDetails } from '../config/contractAddresses';
import { StakeRewards, StakeRewards__factory } from '../types';

export type StakeOptionType = {
  periodInDays: number;
  multiplier: number;
};
export type StakeType = {
  amount: number;
  start: number;
  autorenew: boolean;
  requestedAt: number;
  end: number;
  lastCI: number;
  withdrawWindow: number;
  claimed: number;
  option: number;
};
export type RewardType = {
  rewards: number;
  claimable: boolean;
  withdrawable: boolean;
  endOfLastPeriod: number;
};
export type StakeWithRewards = StakeType & {
  rewards: RewardType;
  cooldownPeriod: number;
};
export type HistoryStakeType = {
  timestamp: number;
  operation: OperationType;
  amount: number;
};

export const secondsByDays = (days: number): number => days * 86400;

export class StakingService {
  private static instance: StakingService;
  private ethersServiceProvider: EthersServiceProvider;

  private constructor() {
    this.ethersServiceProvider = EthersServiceProvider.getInstance();
  }

  public static getInstance(): StakingService {
    if (!StakingService.instance) {
      StakingService.instance = new StakingService();
    }
    return StakingService.instance;
  }

  private getStaking(): StakeRewards {
    return StakeRewards__factory.connect(
      CONTRACTS.CONTRACT_ADDRESS.Staking.address,
      this.ethersServiceProvider.provider?.getSigner(
        0,
      ) as ethers.providers.JsonRpcSigner,
    );
  }

  private async getAccount(address?: string): Promise<string> {
    return address ?? (await this.ethersServiceProvider.getUserAddress());
  }

  private async tryPermit(
    tokenDetails: TokenDetails,
    userAddress: string,
    transferAmount: BigNumber,
  ): Promise<({ deadline: number | string } & RSV) | undefined> {
    // @todo: Need to update Bump permit arguments
    const bumpDomain2 = {
      name: 'BUMP',
      version: '1',
      chainId: chainID,
      verifyingContract: tokenDetails.address,
    };
    const deadline = Math.floor(new Date().getTime() / 1000) + 1000000;
    let permit;
    try {
      permit = await signERC2612Permit(
        this.ethersServiceProvider.provider,
        bumpDomain2,
        userAddress,
        CONTRACTS.CONTRACT_ADDRESS.Staking.address,
        transferAmount.toString(),
        deadline,
      );
    } catch (err) {
      Sentry.captureException(err);
      return undefined;
    }

    if (permit.v !== 27 && permit.v !== 28) {
      return undefined;
    }

    return permit;
  }

  public async calcRewardsByIndex(stakeIndex: number): Promise<RewardType> {
    const { rewards, claimable, withdrawable, endOfLastPeriod } =
      await this.getStaking().calcRewardsByIndex(stakeIndex);
    return {
      rewards: +rewards / 1e18,
      claimable,
      withdrawable,
      endOfLastPeriod: +endOfLastPeriod,
    };
  }

  public async getUserStakes(address?: string): Promise<StakeWithRewards[]> {
    const staking = this.getStaking();

    const [stakes, withdrawWindow, cooldownPeriod] = await Promise.all([
      staking.getUserStakes(await this.getAccount(address)),
      staking.withdrawWindow(),
      staking.cooldownPeriod(),
    ]);

    return Promise.all(
      stakes.map(async (stake, index) => {
        const {
          amount,
          start,
          autorenew,
          claimed,
          end,
          option,
          requestedAt,
          lastCI,
        } = stake;
        const rewards = await this.calcRewardsByIndex(index);
        return {
          amount: +amount / 1e18,
          option,
          start: +start,
          withdrawWindow: +withdrawWindow,
          autorenew,
          end: +end,
          claimed: +claimed / 1e18,
          requestedAt: +requestedAt,
          lastCI: +lastCI,
          rewards,
          cooldownPeriod: +cooldownPeriod,
        };
      }),
    );
  }

  public async getUnlockTimestamp(): Promise<BigNumber> {
    return this.getStaking().unlockTimestamp();
  }

  public async getStakeOptions(): Promise<StakeOptionType[]> {
    const multipliers = await this.getStaking().multipliers();
    const periods = await this.getStaking().periods();
    return multipliers.map((multiplier, optionIndex) => ({
      // get period in days from seconds
      periodInDays: periods[optionIndex] / 86400,
      multiplier,
    }));
  }

  public async getPeriods(): Promise<number[]> {
    const periods = await this.getStaking().periods();
    return periods.map((period) => period / 86400);
  }

  public async getCooldown(): Promise<number> {
    return await this.getStaking().cooldownPeriod();
  }

  public async stake(
    amount: string,
    periodIndex: string,
    autorenew: boolean,
  ): Promise<ContractTransaction> {
    const stakeAmount = ethers.utils.parseUnits(
      amount,
      CONTRACTS.TOKEN_DETAILS.BUMP.decimal,
    );

    if (
      (
        await this.ethersServiceProvider.approveAmount(
          await this.getAccount(),
          (
            await this.getStaking()
          ).address,
          CONTRACTS.TOKEN_DETAILS.BUMP.address,
        )
      ).gte(stakeAmount)
    ) {
      return await this.getStaking().stake(
        stakeAmount,
        BigNumber.from(periodIndex),
        autorenew,
      );
    }

    const permit = await this.tryPermit(
      CONTRACTS.TOKEN_DETAILS.BUMP,
      await this.getAccount(),
      stakeAmount,
    );

    if (permit) {
      return this.getStaking().stakeWithPermit(
        stakeAmount,
        BigNumber.from(periodIndex),
        autorenew,
        permit.deadline,
        permit.v,
        permit.r,
        permit.s,
      );
    }

    const tx = await this.ethersServiceProvider.approveTokenAmount(
      stakeAmount.toString(),
      (
        await this.getStaking()
      ).address,
      CONTRACTS.TOKEN_DETAILS.BUMP.address,
    );
    await tx.wait();

    return await this.getStaking().stake(
      stakeAmount,
      BigNumber.from(periodIndex),
      autorenew,
    );
  }

  public async requestUnstake(
    stakeIndex: number,
  ): Promise<ContractTransaction> {
    return await this.getStaking().requestWithdraw(stakeIndex);
  }

  public async unstake(stakeIndex: number): Promise<ContractTransaction> {
    return await this.getStaking().withdraw(stakeIndex);
  }

  public async canEject(stakeIndex: number): Promise<boolean> {
    return this.getStaking().canEmergencyWithdraw(
      stakeIndex,
      await this.ethersServiceProvider.getUserAddress(),
    );
  }

  public async eject(stakeIndex: number): Promise<ContractTransaction> {
    return this.getStaking().emergencyWithdraw(stakeIndex);
  }

  public async restake(
    stakeIndex: number,
    option: number,
    withRewards: boolean,
    autorenew: boolean,
  ): Promise<ContractTransaction> {
    return this.getStaking().restake(
      BigNumber.from(stakeIndex),
      BigNumber.from(option),
      withRewards,
      autorenew,
    );
  }

  public async switchAutorenew(
    stakeIndex: number,
  ): Promise<ContractTransaction> {
    return this.getStaking().switchAutorenew(stakeIndex);
  }

  public async claimRewards(stakeIndex: number): Promise<ContractTransaction> {
    return this.getStaking().claimRewards(stakeIndex);
  }

  public async calculateRewards(
    amount: string,
    periodIndex: number,
  ): Promise<string> {
    const amountDec = ethers.utils.parseUnits(amount, 18);
    const stakeOption = (await this.getStaking().getStakeOptions())[
      periodIndex
    ];
    const periodInSeconds =
      periodIndex === 0 ? 86400 : periodIndex * 30 * 86400;
    const calculatedRewards =
      (+amountDec / +stakeOption.total) *
      +stakeOption.emission *
      periodInSeconds;
    return (calculatedRewards / 1e18).toString();
  }

  public async calculateRewardsForFakeStaking(
    amount: BigNumberish,
  ): Promise<number> {
    const endOfRewardsCalculating = new Date(2022, 2, 16, 12).getTime();
    const stakeOption = (await this.getStaking().getStakeOptions())[3];

    const timestamp = parseInt(
      endOfRewardsCalculating < Date.now()
        ? (endOfRewardsCalculating / 1000).toString()
        : (Date.now() / 1000).toString(),
    );
    const lastCumIndexTimestamp = await this.getStaking().lastIndexTimestamp();
    const timestampInterval = BigNumber.from(timestamp).sub(
      lastCumIndexTimestamp,
    );

    const calculatedIndex = timestampInterval
      .mul(stakeOption.emission)
      .mul(BigNumber.from(10).pow(18))
      .div(stakeOption.total);
    const cumulativeIndex = stakeOption.index.add(calculatedIndex);

    return +ethers.utils.formatUnits(
      BigNumber.from(amount).mul(cumulativeIndex),
      36,
    );
  }
}
