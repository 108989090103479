import Button, { ButtonProps } from '@material-ui/core/Button';
import { styled } from '@material-ui/core/styles';
import React from 'react';

import { createPixelInsetBorder } from '../../utils/createPixelInsetBorder';

export const PixelButton = styled((props: ButtonProps) => (
  <Button {...props} variant="contained" disableRipple />
))(({ theme }) => ({
  fontFamily: theme.font.secondary,
  fontWeight: 700,
  fontSize: '16px',
  maxHeight: '42px',
  color: theme.palette.text.primary,
  padding: '12px 32.5px',
  textTransform: 'inherit',
  borderRadius: '0px',

  boxShadow: createPixelInsetBorder('#000000', '#00A9BE', '#012A2E'),
  backgroundColor: theme.palette.primary.dark,

  '&.MuiButton-containedPrimary': {
    backgroundColor: theme.palette.primary.light,
    boxShadow: createPixelInsetBorder('#000000', '#E0AD85', '#914F1B'),
    color: theme.palette.common.black,

    '&:hover': {
      background: '#BB621B',
    },
    '&:active': {
      boxShadow: createPixelInsetBorder('#000000', '#914F1B', '#E0AD85'),
    },

    '&.Mui-disabled': {
      '&> span': {
        color: '#8F4B15',
      },
      backgroundColor: '#865E31',
      boxShadow: createPixelInsetBorder('#000000', '#867D68', '#867D68'),
    },
  },

  '&.MuiButton-containedSecondary': {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: createPixelInsetBorder('#000000', '#00A9BE', '#012A2E'),
    '&:hover': {
      background: '#00363D',
    },

    '&:active': {
      boxShadow: createPixelInsetBorder('#000000', '#012A2E', '#00A9BE'),
    },
    '&.Mui-disabled': {
      color: '#99AEB1',
      boxShadow: createPixelInsetBorder('#000000', '#41767D', '#002A2F'),
    },
  },

  '&.MuiButton-sizeSmall': {
    padding: '5px 22px',
    fontSize: '16px',
    maxHeight: '28px',
  },
  '&.MuiButton-sizeLarge': {
    padding: '12px 56px',
    fontSize: '22px',
    maxHeight: '52px',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '1rem 0',
  },
}));
