import { Box, styled } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { useStyles } from './styles';

import { ICoinDetails } from '../../../interfaces/ICoin';
import { ICoin } from '../../../interfaces/ICoin';
import { formatStringifyNumberToDot } from '../../../utils/helpers';
import { DataField } from '../../shared/DataField';
import { CoinIcons } from '../../shared/icons/CoinIcons';
import { Section } from '../../shared/styles/Section';
import MediumBoldTypography, {
  ExtraSmallBoldTypography,
} from '../../shared/Typography';

interface ICoinDetailsProps extends Omit<ICoinDetails, 'price'> {
  price?: string;
  percentageOfAvailableAsset?: string;
  token: ICoin;
}

export const CoinDetails: React.FC<ICoinDetailsProps> = (props) => {
  const classes = useStyles();
  return (
    <Section>
      <HeaderWrapper>
        <Box display="flex">
          <img className={classes.icon} src={CoinIcons[props.token.symbol]} />
          <div>
            <div className={classes.name}>{props.name}</div>
            <div className={clsx(classes.symbol, classes.light)}>
              {props.symbol}
            </div>
          </div>
        </Box>
        {props.price && (
          <MediumBoldTypography
            secondaryFont
            style={{ fontWeight: 700, fontSize: '16px' }}
          >
            ${formatStringifyNumberToDot(props.price)} = 1
            <ExtraSmallBoldTypography
              className={clsx(classes.light, classes.inline)}
            >
              {' Unit'}
            </ExtraSmallBoldTypography>
          </MediumBoldTypography>
        )}
      </HeaderWrapper>
      <ValuesContainer
        display="grid"
        gridTemplateColumns="repeat(auto-fit, minmax(130px, 1fr))"
        gridGap="10px"
        style={{ padding: '12px', background: '#00353C', marginTop: '24px' }}
      >
        <DataField
          name="Units"
          value={formatStringifyNumberToDot(props.balance).slice(0, -2)}
        />
        <DataField
          name="Value"
          value={`$${formatStringifyNumberToDot(props.value).slice(0, -2)}`}
          unit=""
        />
      </ValuesContainer>
    </Section>
  );
};

const ValuesContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '70%',
  gap: '20px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
