import { CONTRACTS } from './contractAddresses';

import { ICoin } from '../interfaces/ICoin';

const USDCoin: ICoin = {
  name: 'USD Coin',
  symbol: 'USDC',
  logoUrl: '',
  address: CONTRACTS.TOKEN_DETAILS.USDC.address,
};
const bUSDCCoin: ICoin = {
  name: 'bUSDC Coin',
  symbol: 'bUSDC',
  logoUrl: '',
  address: CONTRACTS.CONTRACT_ADDRESS.BUSDC.address,
};
const BUMP: ICoin = {
  name: 'BUMP',
  symbol: 'BUMP',
  logoUrl: '',
  address: CONTRACTS.CONTRACT_ADDRESS.BumpToken.address,
};

export { USDCoin, bUSDCCoin, BUMP };
