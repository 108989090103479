import { Box, styled } from '@material-ui/core';
import React from 'react';

import { PixelCheckbox } from '../shared/Checkbox';
import { ContainerStake } from '../shared/styles/StakeStyles';
import {
  LargeBoldRoboto,
  SmallRoboto,
} from '../shared/styles/TypographyRoboto';

interface IFlexibleStakePeriodProps {
  title: string;
  text: string | React.ReactNode;
  confirmCheckbox: boolean;
  setConfirmCheckbox: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
}

export const FlexibleStakePeriod: React.FC<IFlexibleStakePeriodProps> = ({
  title,
  text,
  confirmCheckbox,
  setConfirmCheckbox,
  disabled,
}) => {
  const handleCheckbox = () => {
    setConfirmCheckbox(!confirmCheckbox);
  };

  return (
    <Box>
      <ContainerStake>
        <LargeBoldRoboto>{title}</LargeBoldRoboto>
        <SmallRoboto>{text}</SmallRoboto>
        <StyledConfirmSection>
          <PixelCheckbox
            onChange={handleCheckbox}
            checked={confirmCheckbox}
            style={{ transform: 'scale(0.8)', marginLeft: '-7px' }}
            disabled={disabled}
          />
          <StyledConfirmText>Confirm Selection</StyledConfirmText>
        </StyledConfirmSection>
      </ContainerStake>
    </Box>
  );
};

const StyledConfirmSection = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: '-0.6em',
});

const StyledConfirmText = styled(SmallRoboto)({
  marginLeft: '0.5em',
});
