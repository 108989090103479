import { createSlice } from '@reduxjs/toolkit';

import { ILiquidityDetails } from '../../interfaces/ILiquidityDetails';
import { fetchbUSDDetails } from '../actions/liquidityActions';

const initialState: { liquidityDetails: ILiquidityDetails } = {
  liquidityDetails: {
    bUSD: {
      amount: '',
    },
  },
};

export const liquiditySlice = createSlice({
  name: 'liquidity',
  initialState,
  reducers: {
    resetLiquidity: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchbUSDDetails.fulfilled, (state, action) => {
      state.liquidityDetails = { ...action.payload };
    });
  },
});

export default liquiditySlice.reducer;
export const { resetLiquidity } = liquiditySlice.actions;
