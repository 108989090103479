import { useQuery } from '@apollo/client';
import { Box, useTheme, styled } from '@material-ui/core';
import React from 'react';

import { ICoin } from '../../interfaces/ICoin';
import { clientLpp } from '../../providers/graphql';
import { GetBumperStatusData } from '../../providers/graphql/lpp/__generated__/GetBumperStatusData';
import { BUMPER_STATUS_DATA } from '../../providers/graphql/lpp/queries';
import { formatStringifyNumberToDot } from '../../utils/helpers';
import { LineChart, LineChartTypes } from '../common/LineChart';
import { CoinIcon } from '../shared/CoinIcon';
import { ContentSection } from '../shared/ContentSection';
import { DataField } from '../shared/DataField';
import { CoinIcons } from '../shared/icons/CoinIcons';
import { LargeTypography } from '../shared/Typography';

interface IAvailableReserveCardProps {
  token: ICoin;
}

export const AvailableReserveCard: React.FC<IAvailableReserveCardProps> =
  React.memo(({ ...props }) => {
    const theme = useTheme();

    const { loading, error, data } = useQuery<GetBumperStatusData>(
      BUMPER_STATUS_DATA,
      { client: clientLpp },
    );
    const formatDailyReserveData = (data: GetBumperStatusData | undefined) => {
      let copy = [];
      const result: { date: number; reserveValue: number }[] = [];

      if (data?.dailyReserveDatas) {
        copy = Array.from(data.dailyReserveDatas);
        copy.reverse().map((rd) => {
          result.push({
            date: parseInt(rd.id),
            reserveValue: parseFloat(rd.reserveBalance),
          });
        });
      }

      return result;
    };

    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <ContentSection
          borderTop={`2px solid ${theme.palette.other.BoxOutline} !important`}
        >
          <Box display="flex" alignItems="center">
            <CoinIcon src={CoinIcons[props.token.symbol]} />
            <LargeTypography secondaryFont style={{ fontWeight: 700 }}>
              {props.token.symbol} Reserves
            </LargeTypography>
          </Box>
          <ReserveInfo>
            <Box display="grid" gridGap="16px">
              <DataField
                name="Reserve Balance"
                value={
                  !loading && !error && data?.dailyReserveDatas?.length !== 0
                    ? `${formatStringifyNumberToDot(
                        data?.dailyReserveDatas[0].reserveBalance,
                      )}`
                    : '0'
                }
                unit="USDC"
              />
              {!loading && !error && (
                <LineChart
                  chartTitle="Reserve Balance"
                  lineDataKey="reserveValue"
                  XAxisDataKey="date"
                  YAxisDataKey="reserveValue"
                  chartData={formatDailyReserveData(data)}
                  type={LineChartTypes.Daily}
                />
              )}
            </Box>
          </ReserveInfo>
        </ContentSection>
      </Box>
    );
  });

const ReserveInfo = styled(Box)({
  marginTop: '16px',
  display: 'grid',
  gridTemplateColumns: '2fr',
});
