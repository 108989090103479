import { Box, styled } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { VestingInfoBlock } from './VestingInfoBlock';
import { wrapVestingComponent } from './wrapVestingComponent';

import { isFrontendFunctionalityDisabled } from '../../../config';
import { COLORS } from '../../../config/colors';
import { Routes } from '../../../config/routes';
import { subRoutes } from '../../../config/routes';
import { theme } from '../../../providers/AppThemeProvider';
import { useAppSelector } from '../../../state/hooks';
import { ClaimButton } from '../../shared/styles/DashboardStyles';

export const Vesting = wrapVestingComponent((props) => {
  const { unvested, vested, claimed } = props;

  const history = useHistory();
  const utcTime = useAppSelector((state) => state.lockTimestamp.utcTime);

  const claimTokens = () => {
    if (!vested) return;
    history.push(Routes.Vesting + '/' + subRoutes.Confirm, {
      claimedAmount: vested,
    });
  };

  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Box mt={3}>
      <HorizontalDivider />
      <VestingInfo>
        <VestingInfoBlock
          title="Unvested"
          value={unvested}
          textType="secondary"
          tooltipText="Unvested BUMP tokens will vest based on the vesting schedule as stipulated in your Token Purchase Agreement."
        />
        {smallScreen ? <HorizontalDivider /> : <VerticalDivider />}
        <CentralVestingInfoBlock>
          <VestingInfoBlock
            title="Claimable"
            value={vested}
            textType="green"
            tooltipText="‘Claimable’ tokens have been vested and you are able to transfer them to your wallet using the ‘Claim’ button. This will incur a network transaction fee."
          />
          <ClaimButtonsBox mt={2}>
            <ClaimButton
              onClick={claimTokens}
              disabled={
                isFrontendFunctionalityDisabled(utcTime) || vested === 0
              }
            >
              Claim
            </ClaimButton>
            <ClaimButton
              onClick={() =>
                history.push({
                  pathname: `${Routes.ClaimStake}/${subRoutes.Claim}`,
                  state: {
                    claimedAmount: vested,
                  },
                })
              }
              disabled={
                isFrontendFunctionalityDisabled(utcTime) || vested === 0
              }
            >
              Claim & Stake
            </ClaimButton>
          </ClaimButtonsBox>
        </CentralVestingInfoBlock>
        {smallScreen ? <HorizontalDivider /> : <VerticalDivider />}
        <Box style={{ paddingRight: '2em' }}>
          <VestingInfoBlock
            title="Claimed"
            value={claimed}
            textType="normal"
            tooltipText="‘Claimed’ tokens are tokens you have claimed and can now be transferred to another wallet."
          />
        </Box>
      </VestingInfo>
    </Box>
  );
});

const VestingInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('xs')]: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gridGap: theme.spacing(2),
  },
}));

const CentralVestingInfoBlock = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column',
  },
}));
const ClaimButtonsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const HorizontalDivider = styled('div')({
  height: '2px',
  width: '100%',
  backgroundColor: COLORS.BoxOutline,
  marginTop: '24px',
});
const VerticalDivider = styled('div')({
  height: '57px',
  width: '2px',
  backgroundColor: COLORS.BoxOutline,
});
