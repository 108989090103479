import { styled, Box } from '@material-ui/core';

export const ContentSection = styled(Box)(({ theme }) => ({
  background: theme.palette.other.Green2,
  padding: theme.spacing(2),
  paddingRight: '1.5rem',
  border: `2px solid ${theme.palette.other.BoxOutline}`,
  borderTop: 'none',
  [theme.breakpoints.down('sm')]: {
    width: '89%',
    paddingRight: theme.spacing(2),
  },
  width: '97%',
}));
