import React from 'react';

import { WalletApproveScreen } from '../components/common/WalletApproveScreen';

export const WalletNotFound: React.FC = () => {
  return (
    <WalletApproveScreen
      headingFirstText="You are not connected to this wallet."
      headingSecondText="Please connect the right wallet or cancel and select another."
      errorScreen
    />
  );
};
