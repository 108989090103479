import { Box, Grid, styled } from '@material-ui/core';
import React from 'react';

import { ConnectWalletModal } from './ConnectWalletModal';
import { Meta } from './Meta';

import { JupiterBackground } from '../../shared/JupiterBackground';
interface ILoginSectionProps {
  onConnectInjected: () => Promise<void>;
  onConnectWalletConnect: () => Promise<void>;
  onConnectBsc: () => Promise<void>;
  onConnectLedger: () => Promise<void>;
  onConnectTrezor: () => Promise<void>;
  onConnectLattice: () => Promise<void>;
  onConnectWalletLink: () => Promise<void>;
  onConnectPortis: () => Promise<void>;
  onConnectFrame: () => Promise<void>;
}

export const LoginSection: React.FC<ILoginSectionProps> = ({
  onConnectInjected,
  onConnectWalletConnect,
  onConnectBsc,
  onConnectLedger,
  onConnectTrezor,
  onConnectLattice,
  onConnectWalletLink,
  onConnectPortis,
  onConnectFrame,
}) => {
  return (
    <BackgroundContainer>
      <LayerContainer>
        <ModalContainer>
          <Container>
            <ConnectWalletModal
              onConnectInjected={onConnectInjected}
              onConnectWalletConnect={onConnectWalletConnect}
              onConnectBsc={onConnectBsc}
              onConnectLedger={onConnectLedger}
              onConnectTrezor={onConnectTrezor}
              onConnectLattice={onConnectLattice}
              onConnectWalletLink={onConnectWalletLink}
              onConnectPortis={onConnectPortis}
              onConnectFrame={onConnectFrame}
            />
          </Container>
          <Meta />
        </ModalContainer>
      </LayerContainer>
    </BackgroundContainer>
  );
};

// Modal wrapper exceeds height of the main background
// So I need to create a new container for stretched background
const BackgroundContainer = styled(JupiterBackground)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
});
const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.other.Green1,
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 57px',
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
    padding: '3em 2em 1em 2em',
  },
}));
const LayerContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: 'rgb(0,0,0,0.4)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '2rem 0 8rem',
  [theme.breakpoints.down('sm')]: {
    padding: '0 0 3em 0',
    // backgroundColor: theme.palette.other.Green1,
  },
}));

const ModalContainer = styled(Grid)(({ theme }) => ({
  width: 650,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
