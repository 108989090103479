import { Box } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useState, useEffect } from 'react';

import { BUMP } from '../../config/coins';
import { ICoin } from '../../interfaces/ICoin';
import { theme } from '../../providers/AppThemeProvider';
import { DecimalNumberField } from '../shared/DecimalNumberField';
import {
  ContainerDetails,
  RemainingAmount,
} from '../shared/styles/GlobalSections';
import { Section } from '../shared/styles/Section';
import { WhiteSmallRoboto } from '../shared/styles/TypographyRoboto';
import { ToggleButton, ToggleButtonGroup } from '../shared/ToggleButton';
import { LargeBoldTypography } from '../shared/Typography';

interface IClaimDetailsProps {
  title: string;
  text: string;
  token: ICoin;
  isClaim?: boolean;
}

const PERCENT_OPTIONS = [10, 25, 50, 75, 90, 100];

export const ClaimDetails: React.FC<IClaimDetailsProps> = ({
  title,
  text,
  isClaim,
}) => {
  const [selectedPercent, setSelectedPercent] = useState<number>(0);

  const handleToggleButtonChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: number | null,
  ) => {
    if (newValue !== null) {
      setSelectedPercent(newValue);
    }
  };

  useEffect(() => {
    const percent = isClaim ? 10 : 100;
    setSelectedPercent(percent);
  }, []);

  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Section>
      <Box>
        <LargeBoldTypography>{title}</LargeBoldTypography>
        <Box mt={1}>
          <WhiteSmallRoboto color="secondary">{text}</WhiteSmallRoboto>
        </Box>
        <Box mt={4}>
          <ToggleButtonGroup
            exclusive
            value={selectedPercent}
            onChange={handleToggleButtonChange}
          >
            {PERCENT_OPTIONS.map((pOpt, i) => (
              <ToggleButton key={i} value={pOpt}>
                {pOpt}%
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
        {!smallScreen && (
          <ContainerDetails mt={3}>
            <DecimalNumberField token={BUMP} />
            <RemainingAmount color="textSecondary">
              Remaining Balance:{' '}
            </RemainingAmount>
          </ContainerDetails>
        )}
      </Box>
    </Section>
  );
};
