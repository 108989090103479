import React from 'react';

import { WalletApproveScreen } from '../common/WalletApproveScreen';
import {
  ApproveContainer,
  ApproveTextContainer,
} from '../shared/styles/ApproveSection';
import { SmallTypography } from '../shared/Typography';

const ConfirmInWalletVesting: React.FC = () => {
  return (
    <WalletApproveScreen
      headingFirstText="Confirm transaction in your wallet"
      subtitleSecondText={
        <React.Fragment>
          <ApproveContainer>
            <ApproveTextContainer>
              <SmallTypography style={{ fontWeight: 700 }}>
                *** IMPORTANT ***
              </SmallTypography>
              To complete your deposit, you need to do one (1) step to confirm
              your transaction.
              <br />
              <br />
              <SmallTypography>
                <strong>Step 1</strong>: Transfer funds from Vesting contract to
                your address - gas fee required.
              </SmallTypography>
              <br />
              <SmallTypography style={{ fontWeight: 700 }}>
                GAS FEES
              </SmallTypography>
              Setting a higher or {"'Fast'"} gas fee will complete your
              transaction quickly.
            </ApproveTextContainer>
          </ApproveContainer>
        </React.Fragment>
      }
    />
  );
};

export { ConfirmInWalletVesting };
