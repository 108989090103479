const getSupportedChains = (): number[] => {
  const supportedChainsStr = process.env.REACT_APP_CHAIN_IDs;
  if (supportedChainsStr) {
    return supportedChainsStr.split(',').map((x) => parseInt(x, 10));
  } else {
    return [];
  }
};

export const SUPPORTED_CHAINS = getSupportedChains();
