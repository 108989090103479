import { useTheme, useMediaQuery, AppBar } from '@material-ui/core';
import React from 'react';
import { useLocation, matchPath } from 'react-router-dom';

import { DesktopAppBar } from './DesktopAppBar';
import { MobileAppBar } from './MobileAppBar';

import { Routes } from '../../../config/routes';

interface IMainAppBarProps {
  showNavToolbar: boolean;
}

const RoutesToDisableMobileAppBar = [
  Routes.AddLiquidity,
  Routes.WithdrawDetails,
  Routes.WithdrawLiquidity,
  Routes.SwapPage,
];

export const MainAppBar: React.FC<IMainAppBarProps> = (props) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();

  // Returns the first route which matches location.pathname, if none match it returns null.
  const disabledMobileRouteMatches = matchPath(
    location.pathname,
    RoutesToDisableMobileAppBar,
  );

  const renderAppBar = () => {
    if (isSmall) {
      if (!disabledMobileRouteMatches) {
        return <MobileAppBar />;
      } else {
        return null;
      }
    } else {
      return <DesktopAppBar showNavToolbar={props.showNavToolbar} />;
    }
  };

  return (
    <AppBar position="static" elevation={0}>
      {renderAppBar()}
    </AppBar>
  );
};
