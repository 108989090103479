import { Box, styled, Typography } from '@material-ui/core';

import { SmallRoboto, WhiteSmallRoboto } from './TypographyRoboto';

import { COLORS } from '../../../config/colors';

export const ScrollingList = styled(Box)({
  height: '450px',
  overflowY: 'scroll',
  paddingRight: '15px',
  ['&::-webkit-scrollbar']: {
    width: '8px',
    marginRight: '1px',
  },
  ['&::-webkit-scrollbar-track-piece']: {
    backgroundColor: COLORS.TextField,
  },
  ['&::-webkit-scrollbar-thumb']: {
    backgroundColor: COLORS.Secondary2,
    outline: `1px solid ${COLORS.Secondary2}`,
  },
});
export const ContainerHistory = styled(Box)(({ theme }) => ({
  width: '50vw',
  height: '620px',
  background: COLORS.Green1,
  border: `4px solid ${COLORS.BoxOutline}`,
  padding: '2.5rem',

  [theme.breakpoints.down('sm')]: {
    width: '65vw',
  },
  [theme.breakpoints.down('xs')]: {
    padding: '1rem',
    width: '90%',
  },
}));

export const HistoryHeader = styled(SmallRoboto)({
  fontStyle: 'normal',
  fontSize: '22px',
  lineHeight: '28px',
});

export const CancelButtonSection = styled(Box)({
  '&:hover': {
    opacity: '0.8',
  },
});

export const ColumnName = styled(WhiteSmallRoboto)(({ theme }) => ({
  width: '27.5%',
  fontFamily: 'Roboto Mono',
  fontWeight: 700,
  '&:first-child': {
    width: '45%',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    '&:first-child': {
      width: 'auto !important',
    },
    '&:last-child': {
      width: '25%',
      textAlign: 'right',
      paddingRight: '.5rem',
    },
  },
}));

export const AmountRow = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderTop: '1px solid #5E979E',
  paddingTop: '1.5rem',

  '&:not(:last-child)': {
    marginBottom: '1.5rem',
  },
});
export const AmountTypography = styled(WhiteSmallRoboto)(({ theme }) => ({
  width: '27.5%',
  fontWeight: 700,
  fontFamily: 'Roboto Mono',
  '&:first-child': {
    width: '45%',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    '&:first-child': {
      width: 'auto !important',
    },
    '&:last-child': {
      width: '30%',
    },
  },
}));

export const StyledWhiteArrow = styled('img')({
  height: '12px',
  width: '7px',
  marginLeft: '40%',
});

export const CloseTypography = styled(Typography)({
  fontFamily: 'Roboto',
  fontWeight: 400,
  fontSize: '16px',
  textAlign: 'center',
  opacity: '0.7',
  marginRight: '1.75rem',
});
