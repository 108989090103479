import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { IConfirmWithdrawalLocationProp } from './ConfirmWithdrawSection';

import { USDCoin } from '../../config/coins';
import { Routes, subRoutes } from '../../config/routes';
import { ICoin } from '../../interfaces/ICoin';
import { useAppSelector } from '../../state/hooks';
import { floorTokenValue } from '../../utils/helpers';
import { CoinDetails } from '../common/CoinDetails';
import { DepositDetails } from '../common/DepositDetails';
import { PixelButton } from '../shared/PixelButton';
import {
  FlowButtonsBlock,
  FlowButtonWrapper,
  FlowCard,
  MainFlowContainer,
} from '../shared/styles/FlowPages';
import { Title } from '../shared/styles/Section';

interface ILiquidityDepositSectionProps {
  token: ICoin;
}

export const WithdrawSection: React.FC<ILiquidityDepositSectionProps> = ({
  token,
}) => {
  const location = useLocation<IConfirmWithdrawalLocationProp>();
  const { liquidityDepositValue } = location.state || {};

  const [liquidityValue, setLiquidityValue] = useState<string>(
    liquidityDepositValue || '',
  );
  const history = useHistory();
  const coin = useAppSelector((state) => state.coin.coinDetails[token.symbol]);

  const onClick = () => {
    const props: IConfirmWithdrawalLocationProp = {
      withdrawLiquidityValue: floorTokenValue(liquidityValue),
      liquidityDepositValue: liquidityValue,
      token,
      toToken: USDCoin,
      estimatedBumpPurchase: '0',
    };
    history.push({
      pathname: `${Routes.WithdrawDetails}/${subRoutes.Confirm}`,
      state: props,
    });
  };

  const onCancel = () => {
    history.replace(Routes.DepositDashboard);
  };

  const getCoinDetails = () => {
    return {
      ...coin,
      value: parseFloat(coin.value).toFixed(2),
      price: parseFloat(coin.price).toFixed(2),
    };
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="start">
      <Title>Withdraw Liquidity</Title>
      <MainFlowContainer>
        <FlowCard gridArea="coin-details">
          <CoinDetails token={token} {...getCoinDetails()} />
        </FlowCard>
        <FlowCard gridArea="deposit-details">
          <DepositDetails
            liquidityValue={liquidityValue}
            setLiquidityValue={setLiquidityValue}
            token={token}
            text={'Enter the amount you want to withdraw.'}
            title="Withdraw Amount"
            isWithdraw
          />
        </FlowCard>
        <FlowButtonsBlock>
          <FlowButtonWrapper>
            <PixelButton fullWidth color="secondary" onClick={onCancel}>
              Cancel
            </PixelButton>
          </FlowButtonWrapper>
          <FlowButtonWrapper>
            <PixelButton fullWidth color="primary" onClick={onClick}>
              Next
            </PixelButton>
          </FlowButtonWrapper>
        </FlowButtonsBlock>
      </MainFlowContainer>
    </Box>
  );
};
