import { createAsyncThunk } from '@reduxjs/toolkit';

import { ILockTimestamp } from '../../interfaces/ILockTimestamp';
import { MakerService } from '../../services/makerService';
const makerService = MakerService.getInstance();
export const fetchLockTimestamp = createAsyncThunk<ILockTimestamp>(
  'fetchLockTimestamp',
  async () => {
    const [resBUSDC, resSwap] = await Promise.all([
      makerService.getBUSDCUnlockTimestamp(),
      makerService.getSwapUnlockTimestamp(),
    ]);
    return {
      BUSDCUnlockTime: resBUSDC,
      SwapUnlockTime: resSwap,
    };
  },
);

export const fetchUtcTime = createAsyncThunk<number>(
  'fetchUtcTime',
  async () => {
    const utcTime = await (
      await fetch('https://worldtimeapi.org/api/timezone/Etc/UTC')
    ).json();
    return new Date(utcTime.datetime).getTime();
  },
);
