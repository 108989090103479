import {
  withStyles,
  TextField,
  TextFieldProps,
  Theme,
} from '@material-ui/core';
import React from 'react';

import { createPixelInsetBorder } from '../../utils/createPixelInsetBorder';

export const MuiTextField = React.memo(
  withStyles((theme: Theme) => ({
    root: {
      background: theme.palette.backgroundColor.light,
      borderRadius: 0,
      '& .MuiInputBase-root': {
        height: theme.spacing(6),
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: theme.palette.backgroundColor.light,
        },
        '&:hover fieldset': {
          borderColor: theme.palette.other.BrightGreen,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.other.BrightGreen,
          borderWidth: '1px',
        },

        '& input': {
          '&::placeholder': {
            color: 'rgba(255, 255, 255, 0.6)',
          },
          color: theme.palette.other.White, // if you also want to change the color of the input, this is the prop you'd use
        },
      },
    },
  }))((props: TextFieldProps) => <TextField variant="outlined" {...props} />),
);

const MuiPixelTextField = withStyles((theme: Theme) => ({
  root: {
    borderRadius: 0,
    cursor: 'default',
    '& .MuiInputBase-root': {
      height: theme.spacing(6),
    },
    '& .MuiOutlinedInput-root': {
      background: theme.palette.other.TextField,
      borderRadius: 0,
      '& fieldset': {
        border: 'none',
        borderRadius: 0,
        boxShadow: createPixelInsetBorder(
          `${theme.palette.other.Green3}`,
          `${theme.palette.other.TextField}`,
          `${theme.palette.other.TextField}`,
        ),
      },
      '&:hover fieldset': {
        boxShadow: createPixelInsetBorder(
          `${theme.palette.other.BrightGreen}`,
          `${theme.palette.other.TextField}`,
          `${theme.palette.other.TextField}`,
        ),
      },
      '&.Mui-focused fieldset': {
        boxShadow: createPixelInsetBorder(
          `${theme.palette.other.BrightGreen}`,
          `${theme.palette.other.TextField}`,
          `${theme.palette.other.TextField}`,
        ),
      },
      '&.Mui-error fieldset': {
        boxShadow: createPixelInsetBorder(
          `${theme.palette.other.Red}`,
          `${theme.palette.other.TextField}`,
          `${theme.palette.other.TextField}`,
        ),
      },

      '& input': {
        '&::placeholder': {
          color: 'rgba(255, 255, 255, 0.6)',
        },
        color: theme.palette.other.White, // if you also want to change the color of the input, this is the prop you'd use
        fontFamily: theme.font.secondary,
        fontWeight: 700,
      },

      '&.MuiOutlinedInput-adornedEnd': {
        paddingRight: '6px',
      },
      '&.MuiOutlinedInput-adornedStart': {
        paddingLeft: '6px',
      },
    },
    '& .MuiFormHelperText-root': {
      marginTop: '10px',
      fontSize: '12px',
      marginLeft: '0px',
      marginRight: '0px',
      width: '151px',
      height: '30px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '&.Mui-error': {
        color: theme.palette.other.red,
        fontWeight: 700,
        background: theme.palette.other.Green2,
      },
    },
    '& p': {
      display: 'inline-block',
      marginInlineEnd: 'auto !important',
    },
  },
}))((props: TextFieldProps) => <TextField variant="outlined" {...props} />);

const MuiPixelTextDepositField = withStyles((theme: Theme) => ({
  root: {
    borderRadius: 0,
    cursor: 'default',
    '& .MuiInputBase-root': {
      height: '32px',
      width: '152px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    '& .MuiOutlinedInput-root': {
      background: theme.palette.other.TextField,
      borderRadius: 0,
      '& fieldset': {
        border: 'none',
        borderRadius: 0,
        boxShadow: createPixelInsetBorder(
          `${theme.palette.other.Green3}`,
          `${theme.palette.other.TextField}`,
          `${theme.palette.other.TextField}`,
        ),
      },
      '&:hover fieldset': {
        boxShadow: createPixelInsetBorder(
          `${theme.palette.other.BrightGreen}`,
          `${theme.palette.other.TextField}`,
          `${theme.palette.other.TextField}`,
        ),
      },
      '&.Mui-focused fieldset': {
        boxShadow: createPixelInsetBorder(
          `${theme.palette.other.BrightGreen}`,
          `${theme.palette.other.TextField}`,
          `${theme.palette.other.TextField}`,
        ),
      },
      '&.Mui-error fieldset': {
        boxShadow: createPixelInsetBorder(
          `${theme.palette.other.Red}`,
          `${theme.palette.other.TextField}`,
          `${theme.palette.other.TextField}`,
        ),
      },

      '& input': {
        '&::placeholder': {
          color: 'rgba(255, 255, 255, 0.6)',
        },
        color: theme.palette.other.White, // if you also want to change the color of the input, this is the prop you'd use
        fontFamily: theme.font.secondary,
        fontWeight: 700,
        fontSize: '1rem',
      },

      '&.MuiOutlinedInput-adornedEnd': {
        paddingRight: '6px',
      },
      '&.MuiOutlinedInput-adornedStart': {
        paddingLeft: '6px',
      },
    },
    '& .MuiFormHelperText-root': {
      marginTop: '10px',
      fontSize: '12px',
      marginLeft: '0px',
      marginRight: '0px',
      width: '133px',
      height: '32px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '&.Mui-error': {
        color: theme.palette.other.red,
        fontWeight: 700,
        background: theme.palette.other.Green2,
      },
    },
    '& p': {
      display: 'inline-block',
      marginInlineEnd: 'auto !important',
    },

    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}))((props: TextFieldProps) => <TextField variant="outlined" {...props} />);

const PixelTextField = React.memo(MuiPixelTextField);
const PixelTextDepositField = React.memo(MuiPixelTextDepositField);

export { PixelTextField, PixelTextDepositField };
