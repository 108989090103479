import {
  configureStore,
  combineReducers,
  ThunkAction,
  Action,
} from '@reduxjs/toolkit';

import bumpDetailsReducer from './reducers/bumpDetailsReducer';
import coinReducer from './reducers/coinReducer';
import epochsReducer from './reducers/epochsReducer';
import errorReducer from './reducers/errorReducer';
import liquidityReducer from './reducers/liquidityReducer';
import lockTimestampReducer from './reducers/lockTimestampReducer';
import merkleTreeReducer from './reducers/merkleTree';
import prospectiveAprReducer from './reducers/prospectiveAprReducer';
import uiStateManagementReducer from './reducers/uiStateManagementReducer';
import vestingReducer from './reducers/vestingReducer';

const rootReducer = combineReducers({
  coin: coinReducer,
  error: errorReducer,
  liquidityDetails: liquidityReducer,
  bumpDetails: bumpDetailsReducer,
  lockTimestamp: lockTimestampReducer,
  uiStateMgmt: uiStateManagementReducer,
  prospectiveApr: prospectiveAprReducer,
  vesting: vestingReducer,
  merkleTree: merkleTreeReducer,
  epochs: epochsReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
