import { initializeApp } from '@firebase/app';

// MAKE SURE THAT ALL ENVS ARE SET
// ESPECIALLY ANALYTICS_MEASUREMENT_ID
const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;

export const firebaseApp = initializeApp(
  {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: `${projectId}.firebaseapp.com`,
    projectId,
    storageBucket: `${projectId}.appspot.com`,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_ANALYTICS_MEASUREMENT_ID || undefined,
  },
  'DEFAULT',
);
