import { createTheme, ThemeProvider, CssBaseline } from '@material-ui/core';
import React from 'react';

import { COLORS } from '../config/colors';

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    font: {
      primary: string;
      secondary: string;
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    font: {
      primary: string;
      secondary: string;
    };
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    backgroundColor: Palette['primary'];
    highlight: {
      first: React.CSSProperties['color'];
      second: React.CSSProperties['color'];
    };
    other: {
      [key: string]: React.CSSProperties['color'];
    };
  }
  interface PaletteOptions {
    backgroundColor: PaletteOptions['primary'];
    highlight: {
      first: React.CSSProperties['color'];
      second: React.CSSProperties['color'];
    };
    other: {
      [key: string]: React.CSSProperties['color'];
    };
  }
}

export const theme = createTheme({
  palette: {
    background: {
      default: COLORS.Green1,
    },
    backgroundColor: {
      light: COLORS.Green1,
      main: COLORS.Green2,
      dark: COLORS.Green3,
    },
    primary: {
      light: COLORS.Primary2,
      main: COLORS.Primary1,
      dark: COLORS.Green1,
    },
    highlight: {
      first: COLORS.Secondary1,
      second: COLORS.Secondary2,
    },
    // You can comment this out to make text black again.
    text: {
      primary: COLORS.White,
      secondary: COLORS.LightGreen,
    },
    other: {
      LightGreen: COLORS.LightGreen,
      DarkGreen: COLORS.DarkGreen,
      White: COLORS.White,
      Support: COLORS.Support,
      BrightGreen: COLORS.Secondary2,
      BrightBlue: COLORS.Secondary1,
      LightText: COLORS.LightText,
      ExtraLightText: COLORS.ExtraLightText,
      LabelText: COLORS.LabelText,
      Green1: COLORS.Green1,
      Green2: COLORS.Green2,
      Green3: COLORS.Green3,
      Green4: COLORS.Green4,
      Green5: COLORS.Green5,
      Green6: COLORS.Green6,
      Turquoise: COLORS.Turquoise,
      TextField: COLORS.TextField,
      Orange: COLORS.Orange,
      DarkOrange: COLORS.DarkOrange,
      LightGrey: COLORS.LightGrey,
      BoxOutline: COLORS.BoxOutline,
      Divider: COLORS.Divider,
      Red: COLORS.Red,
    },
  },
  shape: {
    borderRadius: 4,
  },
  font: {
    primary: ['Roboto', 'sans-serif'].join(','),
    secondary: ['Roboto Mono', 'monospace'].join(','),
  },
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    h1: {
      fontSize: '34px',
      fontWeight: 700,
    },
    h2: {
      fontSize: '30px',
      fontWeight: 700,
    },
    h3: {
      fontSize: '26px',
      fontWeight: 700,
    },
    h4: {
      fontSize: '22px',
      fontWeight: 700,
    },
    h5: {
      fontSize: '18px',
      fontWeight: 700,
    },
    h6: {
      fontSize: '16px',
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: '16px',
      lineHeight: '24px',
      color: 'rgba(255, 255, 255, 0.8);',
    },
    body1: {
      fontSize: 18,
    },
    body2: {
      fontSize: 16,
    },
    caption: {
      fontSize: 14,
    },
  },
});

export const AppThemeProvider: React.FC = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  );
};
