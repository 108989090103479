import { BscConnector } from '@binance-chain/bsc-connector';
import { FrameConnector } from '@web3-react/frame-connector';
import { InjectedConnector } from '@web3-react/injected-connector';
import { LatticeConnector } from '@web3-react/lattice-connector';
import { LedgerConnector } from '@web3-react/ledger-connector';
import { PortisConnector } from '@web3-react/portis-connector';
import { TrezorConnector } from '@web3-react/trezor-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';

import { SUPPORTED_CHAINS } from './supportedChains';

const POLLING_INTERVAL = 12000;
const APP_NAME = 'Bumper';
const RPC = process.env.REACT_APP_PROVIDER_URL as string;
const PORTIS_DAPP_ID = process.env.REACT_APP_PORTIS_DAPP_ID as string;

export const injected = new InjectedConnector({
  supportedChainIds: SUPPORTED_CHAINS,
}); // Only Kovan supported in dev
export const walletConnect = new WalletConnectConnector({
  rpc: {
    [SUPPORTED_CHAINS[0]]: RPC,
  },
  supportedChainIds: SUPPORTED_CHAINS,
  qrcode: true,
  // eslint-disable-next-line
  // @ts-ignore
  pollingInterval: POLLING_INTERVAL,
});
export const bsc = new BscConnector({ supportedChainIds: SUPPORTED_CHAINS });
export const ledger = new LedgerConnector({
  chainId: SUPPORTED_CHAINS[0],
  pollingInterval: POLLING_INTERVAL,
  url: RPC,
  baseDerivationPath: "44'/60'/0'",
  accountFetchingConfigs: {
    addressSearchLimit: 1,
    shouldAskForOnDeviceConfirmation: false,
  },
});
export const lattice = new LatticeConnector({
  appName: APP_NAME,
  chainId: SUPPORTED_CHAINS[0],
  pollingInterval: POLLING_INTERVAL,
  url: RPC,
});
export const trezor = new TrezorConnector({
  chainId: SUPPORTED_CHAINS[0],
  pollingInterval: POLLING_INTERVAL,
  url: RPC,
  manifestAppUrl: window.location.origin,
  manifestEmail: 'test@test.com',
});
export const walletLink = new WalletLinkConnector({
  appName: APP_NAME,
  supportedChainIds: SUPPORTED_CHAINS,
  url: RPC,
});
export const frame = new FrameConnector({
  supportedChainIds: SUPPORTED_CHAINS,
});
export const portis = new PortisConnector({
  dAppId: PORTIS_DAPP_ID,
  networks: [{ chainId: '1', nodeUrl: RPC }],
});

const Web3WalletTypeName = 'web3_wallet_type';

export const getWeb3WalletType = (): string | null =>
  window.localStorage.getItem(Web3WalletTypeName);
export const setWeb3WalletType = (type: Web3WalletType): void =>
  window.localStorage.setItem(Web3WalletTypeName, type);
export const removeWeb3WalletType = (): void =>
  window.localStorage.removeItem(Web3WalletTypeName);

export enum Web3WalletType {
  INJECTED = 'INJECTED',
  BSC = 'BSC',
  WALLET_CONNECT = 'WALLET_CONNECT',
  LEDGER = 'LEDGER',
  TREZOR = 'TREZOR',
  LATTICE = 'LATTICE',
  WALLET_LINK = 'WALLET_LINK',
  PORTIS = 'PORTIS',
  FRAME = 'FRAME',
}
