import { styled, ButtonProps } from '@material-ui/core';
import React from 'react';

import BadgeIcon from '../../../assets/badge-icon.svg';
import ColorIcon from '../../../assets/ColorIcon.png';
import GreyBadgeIcon from '../../../assets/grey-badge-icon.svg';
import GreyCoinIcon from '../../../assets/grey-coin-icon.svg';
import { PixelButton } from '../../shared/PixelButton';

const ProtectButton = styled((props: ButtonProps) => (
  <CTAButton color="secondary" {...props}>
    {!props.disabled ? <img src={BadgeIcon} /> : <img src={GreyBadgeIcon} />}
    Protect
  </CTAButton>
))({});

const DepositButton = styled((props: ButtonProps) => (
  <CTAButton color="secondary" {...props}>
    {!props.disabled ? <img src={ColorIcon} /> : <img src={GreyCoinIcon} />}
    Deposit
  </CTAButton>
))({});

const EarnButton = styled((props: ButtonProps) => (
  <CTAButton color="secondary" {...props}>
    {!props.disabled ? <img src={ColorIcon} /> : <img src={GreyCoinIcon} />}
    Earn
  </CTAButton>
))({});

const CTAButton = styled(PixelButton)(() => ({
  padding: '8px 16px',
  maxHeight: '38px',
  minWidth: '162px',
  '& img': {
    height: '24px',
    width: '24px',
    marginRight: '10px',
  },
}));

export { ProtectButton, DepositButton, EarnButton };
