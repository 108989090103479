import { styled, Box } from '@material-ui/core';
import React from 'react';

import { Stepper } from './Stepper';

import BumperHorizontalLogo from '../../assets/bumper-horizontal-pixel-logo.svg';
import ToastCrossIcon from '../../assets/toast-cross.svg';
import { PixelButton } from '../shared/PixelButton';
import Typography from '../shared/Typography';

interface IHeaderProps {
  onCancel: () => void;
  showStepper: boolean;
  activeStep: number;
  isNeedPresale?: boolean;
  isClaimStake?: boolean;
}

export const Header: React.FC<IHeaderProps> = (props) => {
  return (
    <HeaderContainer>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" flex="2">
          <Logo src={BumperHorizontalLogo} />
        </Box>
        {props.showStepper && (
          <Box>
            <Stepper
              activeStep={props.activeStep}
              isNeedPresale={props.isNeedPresale}
              isClaimStake={props.isClaimStake}
            />
          </Box>
        )}
        <Box display="flex" flex="2" justifyContent="flex-end">
          <Box display="flex" alignItems="center" mr="28px">
            <Typography variant="body2">Cancel</Typography>
          </Box>
          <PixelIconButton onClick={props.onCancel} color="secondary">
            <img src={ToastCrossIcon} />
          </PixelIconButton>
        </Box>
      </Box>
    </HeaderContainer>
  );
};

const PixelIconButton = styled(PixelButton)(() => ({
  width: '28px',
  height: '28px',
  padding: '0px',
  '&.MuiButton-root': {
    minWidth: '28px',
  },
}));

const Logo = styled('img')(({ theme }) => ({
  width: '167px',
  height: '24px',
  [theme.breakpoints.down('sm')]: {
    width: '121px',
    height: '15px',
  },
}));

const HeaderContainer = styled('div')(({ theme }) => ({
  padding: '20px 30px',
  background: theme.palette.other.Green3,
  [theme.breakpoints.down('sm')]: {
    padding: '25px 22px',
  },
}));
