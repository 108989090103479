import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { WithdrawalDetailsSummary } from './WithdrawalDetailsSummary';

import { bUSDCCoin, USDCoin } from '../../config/coins';
import { CONTRACTS, TokenList } from '../../config/contractAddresses';
import { termsAndConditionsLink } from '../../config/links';
import { Routes, subRoutes } from '../../config/routes';
import { ICoin } from '../../interfaces/ICoin';
import { MakerService } from '../../services/makerService';
import { fetchCoinDetails } from '../../state/actions/coinActions';
import { fetchbUSDDetails } from '../../state/actions/liquidityActions';
import { useAppSelector } from '../../state/hooks';
import {
  checkNullable,
  formatStringifyNumberToDot,
  timeInMilliseconds,
} from '../../utils/helpers';
import { PixelCheckbox } from '../shared/Checkbox';
import { CoinIcons } from '../shared/icons/CoinIcons';
import { PixelButton } from '../shared/PixelButton';
import {
  CoinIcon,
  GreenLink,
  NumberTypography,
  SymbolTypography,
} from '../shared/styles/ConfirmationPage';
import { DarkSection, Title } from '../shared/styles/Section';
import { WhiteSmallRoboto } from '../shared/styles/TypographyRoboto';

export interface IConfirmWithdrawalLocationProp {
  withdrawLiquidityValue: string;
  token: ICoin;
  toToken: ICoin;
  liquidityDepositValue: string;
  estimatedBumpPurchase: string;
}

export const ConfirmWithdrawalSection: React.FC = () => {
  const location = useLocation<IConfirmWithdrawalLocationProp>();
  const {
    withdrawLiquidityValue,
    liquidityDepositValue,
    estimatedBumpPurchase,
  } = location.state;
  const makerService: MakerService = MakerService.getInstance();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const toToken = location.state ? location.state.toToken : USDCoin;
  const token = location.state ? location.state.token : bUSDCCoin;
  const history = useHistory();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const coinPriceUSDC = useAppSelector(
    (state) => state.coin.coinDetails['USDC'].price,
  );

  const onBackButtonPress = () => {
    history.push(`${Routes.WithdrawDetails}/${subRoutes.Select}`, {
      ...location.state,
    });
  };

  const onConfirmWithdrawal = async () => {
    try {
      history.replace(`${Routes.WithdrawLiquidity}/${subRoutes.Approve}`, {
        token,
      });
      const tokenDetails =
        CONTRACTS.TOKEN_DETAILS[token.symbol as keyof TokenList];
      const tx = await makerService.withdrawFromBumpMarket(
        `${parseFloat(withdrawLiquidityValue).toFixed(tokenDetails.decimal)}`,
      );

      history.replace(`${Routes.WithdrawLiquidity}/${subRoutes.Processing}`, {
        token,
      });
      await tx.wait();
      dispatch(fetchCoinDetails([token]));
      history.replace(`${Routes.DepositDashboard}`);
      addToast('Later Dude!', {
        appearance: 'success',
      });
      dispatch(fetchbUSDDetails());
    } catch (err) {
      console.error(err);
      history.replace(`${Routes.DepositDashboard}`);
      addToast('We were unable to withdraw your assets. Please try again.', {
        appearance: 'warning',
      });
    }
  };

  const handleCheckbox = () => {
    setTermsAccepted(!termsAccepted);
  };

  const unlockTime = useAppSelector(
    (state) => state.lockTimestamp.lockTimestampDetails.SwapUnlockTime,
  );
  return (
    <Box maxWidth={{ xs: '100%', sm: '100%' }} m="auto">
      <Box display="flex" justifyContent="center" mb={5}>
        <Title>Confirmation</Title>
      </Box>
      <DarkSection
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={{ xs: 3, sm: 3, lg: 4 }}
        position="relative"
        pt={{ xs: 9, sm: 9, lg: 10 }}
      >
        <CoinIcon
          src={
            checkNullable(withdrawLiquidityValue)
              ? CoinIcons[toToken.symbol]
              : CoinIcons['BUMP']
          }
        />
        <Box display="flex" alignItems="baseline" mb={1}>
          <NumberTypography variant="h1" style={{ marginRight: '8px' }}>
            +
            {checkNullable(withdrawLiquidityValue)
              ? formatStringifyNumberToDot(withdrawLiquidityValue)
              : formatStringifyNumberToDot(estimatedBumpPurchase)}
          </NumberTypography>
          <SymbolTypography variant="h4" color="textSecondary">
            {checkNullable(withdrawLiquidityValue) ? toToken.symbol : 'BUMP'}
          </SymbolTypography>
        </Box>
        <Box mt={5} width="100%">
          <WithdrawalDetailsSummary
            totalWithdrawAmt={liquidityDepositValue}
            totalWithdrawAmtInUsd={(
              parseFloat(liquidityDepositValue) * parseFloat(coinPriceUSDC)
            ).toString()}
            usdcAmount={withdrawLiquidityValue}
          />
        </Box>
      </DarkSection>
      <Box mt={3} display="flex" alignItems="center">
        <Box mr={3} style={{ position: 'relative', top: '-1px' }}>
          <PixelCheckbox checked={termsAccepted} onChange={handleCheckbox} />
        </Box>
        <Box>
          <WhiteSmallRoboto color="secondary">
            By checking this box you acknowledge and agree to our
            <GreenLink
              href={termsAndConditionsLink}
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              terms and conditions
            </GreenLink>
            .
          </WhiteSmallRoboto>
        </Box>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="minmax(150px, 33%) auto minmax(150px, 33%)"
        mt={{ xs: 3, sm: 4 }}
        pb="10vh"
      >
        <Box>
          <PixelButton
            fullWidth
            color="secondary"
            onClick={onBackButtonPress}
            disabled={timeInMilliseconds(unlockTime) > Date.now()}
          >
            Back
          </PixelButton>
        </Box>
        <Box gridColumn="3">
          <PixelButton
            fullWidth
            color="primary"
            disabled={
              !termsAccepted || timeInMilliseconds(unlockTime) > Date.now()
            }
            onClick={onConfirmWithdrawal}
          >
            Confirm
          </PixelButton>
        </Box>
      </Box>
    </Box>
  );
};
