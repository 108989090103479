import { withStyles, Theme } from '@material-ui/core';
import MuiToggleButton from '@material-ui/lab/ToggleButton';
import MuiToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { createPixelInsetBorder } from '../../utils/createPixelInsetBorder';

const ToggleButton = withStyles((theme: Theme) => ({
  root: {
    background: theme.palette.other.Green1,
    borderRadius: 0,
    border: 'none',
    boxShadow: createPixelInsetBorder('black', '#00A9BE', '#012A2E'),
    color: theme.palette.other.White,
    fontFamily: 'Roboto Mono',
    fontWeight: 700,
    fontSize: '14px',

    '&.Mui-selected': {
      backgroundColor: theme.palette.other.BrightGreen,
      boxShadow: createPixelInsetBorder('black', '#9CE1A9', '#20772E'),
      color: 'black',
      '&:hover': {
        background: theme.palette.other.BrightGreen,
      },
    },
    '&.Mui-disabled': {
      color: '#4A7D83',
    },
  },

  [theme.breakpoints.down('sm')]: {
    width: '72px',
    height: '49px',
  },
}))(MuiToggleButton);

const ToggleButtonGroup = withStyles(() => ({
  root: {
    display: 'grid',
    gridGap: '16px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(68px, 1fr))',
  },
}))(MuiToggleButtonGroup);

export { ToggleButton, ToggleButtonGroup };
