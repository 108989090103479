import { Box, styled } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { COLORS } from '../../config/colors';
import { Routes } from '../../config/routes';
import { WalletContext } from '../../config/walletContext';
import { theme } from '../../providers/AppThemeProvider';
import {
  formatStringifyNumberToDot,
  isCoinBalanceZero,
} from '../../utils/helpers';
import { IBalanceCardProps } from '../interfaces/BalanceCardProps';
import { CoinIcon } from '../shared/CoinIcon';
import { ContentSection } from '../shared/ContentSection';
import { DataField } from '../shared/DataField';
import { CoinIcons } from '../shared/icons/CoinIcons';
import { PixelButton } from '../shared/PixelButton';
import { SmallRoboto } from '../shared/styles/TypographyRoboto';
import { TopSection } from '../shared/TopSection';
import { LargeBoldTypography } from '../shared/Typography';

export const BalanceCard: React.FC<IBalanceCardProps> = (props) => {
  const isWalletConnected = useContext(WalletContext).isWalletConnected;
  const history = useHistory();
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <TopSection color={`${theme.palette.other.Turquoise}`}>
        <Box display="flex" alignItems="center">
          <CoinIcon src={CoinIcons[props.token.symbol]} />
          <LargeBoldTypography secondaryFont>
            {props.token.symbol}
          </LargeBoldTypography>
        </Box>
        <Box display="flex" alignItems="center">
          {!smallScreen && isCoinBalanceZero(props.balance) && (
            <WarningText>
              You’re out of credit! Insert USDC to continue.
            </WarningText>
          )}
          {/* {!smallScreen &&
            isWalletConnected &&
            isCoinBalanceZero(props.balance) && (
              <PixelButton
                size="small"
                color="secondary"
                onClick={() => {
                  props.onButtonPress && props.onButtonPress(props.token);
                }}
                style={{ width: 120 }}
              >
                Deposit
              </PixelButton>
            )} */}
          {/* {smallScreen && isWalletConnected && (
            <PixelButton
              size="small"
              color="secondary"
              fullWidth
              onClick={() => {
                props.onButtonPress && props.onButtonPress(props.token);
              }}
              disabled={isCoinBalanceZero(props.balance)}
            >
              Deposit
            </PixelButton>
          )} */}
        </Box>
        {!smallScreen && !isWalletConnected && (
          <Box display="flex" alignItems="center">
            <PixelButton
              size="small"
              color="secondary"
              onClick={() => history.push(Routes.ConnectWallet)}
              style={{ marginLeft: '18px' }}
            >
              Connect wallet
            </PixelButton>
          </Box>
        )}
      </TopSection>
      {!isWalletConnected || isCoinBalanceZero(props.balance) ? null : (
        <ContentSection>
          <DepositInfo>
            <DataField
              name="Available"
              value={formatStringifyNumberToDot(props.balance)}
              unit={props.token.symbol}
            />
          </DepositInfo>
        </ContentSection>
      )}
      {smallScreen && isCoinBalanceZero(props.balance) && (
        <ContentSection>
          <DepositInfo>
            <WarningText>
              You’re out of credit! Insert USDC to continue.
            </WarningText>
          </DepositInfo>
        </ContentSection>
      )}
    </Box>
  );
};

export const WarningText = styled(SmallRoboto)(({ theme }) => ({
  fontStyle: 'normal',
  textAlign: 'center',
  color: COLORS.LightGreen,

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    fontSize: '10px',
    lineHeight: '15px',
    textAlign: 'center',
  },
}));

const DepositInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',

  [theme.breakpoints.down('xs')]: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gridGap: theme.spacing(2),
  },
}));
