import { createSlice } from '@reduxjs/toolkit';
import { BigNumber } from 'ethers';

import { TypedEvent } from '../../types/commons';

export type VestingUserType = {
  usdcYield: number;
  bumpRewards: number;
  bumpValueUsdc: number;
  totalRewardsUsdcValue: number;
  apr: number;
};

export type ClaimedEventsType = Array<
  TypedEvent<
    [BigNumber, string, BigNumber, BigNumber] & {
      index: BigNumber;
      account: string;
      amount: BigNumber;
      timestamp: BigNumber;
    }
  >
>;
export type ClaimedDataType = {
  claimedEvents: ClaimedEventsType;
  claimedV2: number;
};

export interface IinitialStateTypeVesting {
  userData?: VestingUserType | null;
}

const initialState: IinitialStateTypeVesting = {
  userData: null,
};

export const vestingSlice = createSlice({
  name: 'vesting',
  initialState,
  reducers: {
    resetVesting: () => initialState,
  },
});

export default vestingSlice.reducer;
export const { resetVesting } = vestingSlice.actions;
