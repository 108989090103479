import { styled, Box } from '@material-ui/core';
import React from 'react';
import {
  LineChart as RechartsLineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  TooltipProps,
  DotProps,
  ResponsiveContainer,
} from 'recharts';

import { COLORS } from '../../config/colors';
import {
  dayTickFormatter,
  weekTickFormatter,
  numberFormatter,
} from '../../utils/helpers';
import { H7Typography } from '../shared/Typography';

export enum LineChartTypes {
  Daily,
  Weekly,
}

interface ILineChartsProps {
  chartTitle: string;
  chartData: Array<{ [key: string]: string | number }>;
  lineDataKey: string;
  XAxisDataKey: string;
  YAxisDataKey: string;
  type?: LineChartTypes;
}

export const LineChart: React.FC<ILineChartsProps> = React.memo((props) => {
  return (
    <GraphContainer>
      <Box ml="18px">
        <H7Typography secondaryFont>{props.chartTitle}</H7Typography>
      </Box>
      <ResponsiveContainer height={204} width="99%" minWidth="0" debounce={10}>
        <RechartsLineChart
          data={props.chartData}
          margin={{ top: 24, left: 8, right: 24, bottom: 0 }}
        >
          <CartesianGrid stroke={COLORS.Green1} vertical={false} />
          <Line
            type="linear"
            dataKey={props.lineDataKey}
            stroke={COLORS.Turquoise}
            activeDot={false}
            dot={<CustomizedDot />}
          />
          <XAxis
            dataKey={props.XAxisDataKey}
            tickMargin={8}
            stroke={COLORS.ChartAxis}
            tick={{ fill: COLORS.ChartTick, fontSize: '9px' }}
            tickFormatter={
              props.type === LineChartTypes.Daily
                ? dayTickFormatter
                : props.type === LineChartTypes.Weekly
                ? weekTickFormatter
                : undefined
            }
            type={props.type ? 'number' : 'category'}
            domain={['auto', 'auto']}
            scale={props.type ? 'time' : 'auto'}
          />
          <YAxis
            width={35}
            dataKey={props.YAxisDataKey}
            axisLine={false}
            tickLine={false}
            tickCount={6}
            tickFormatter={numberFormatter}
            stroke={COLORS.ChartAxis}
            tick={{ fill: COLORS.ChartTick, fontSize: '9px' }}
            interval="preserveEnd"
          />
          <Tooltip
            offset={6}
            content={<CustomTooltip />}
            allowEscapeViewBox={{ x: false, y: false }}
          />
        </RechartsLineChart>
      </ResponsiveContainer>
    </GraphContainer>
  );
});

const CustomizedDot: React.FC<DotProps> = (props) => {
  const { cx, cy } = props;
  return (
    <svg
      x={cx! - 2}
      y={cy! - 2}
      width="4"
      height="4"
      viewBox="0 0 4 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.500061" width="4" height="4" fill="#2DFFF2" />
    </svg>
  );
};

const CustomTooltip: React.FC<TooltipProps<number, string>> = ({
  active,
  payload,
}) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          background: COLORS.Green1,
          color: COLORS.White,
        }}
      >
        <p
          style={{
            padding: '6px 10px',
            fontFamily: 'Roboto Mono',
            fontWeight: 'bold',
          }}
        >{`${
          payload[0].value
            ? numberFormatter(parseFloat(payload[0].value.toFixed(4)))
            : payload[0].value
        }`}</p>
      </div>
    );
  }

  return null;
};

const GraphContainer = styled('div')({
  background: COLORS.Green3,
  padding: '18px 8px 8px 8px',
  '& .recharts-yAxis .recharts-cartesian-axis-tick:first-child': {
    display: 'none',
  },
});
