import { styled, Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';

import { COLORS } from '../../config/colors';
import { formatStringifyNumberToDot } from '../../utils/helpers';
import { RowTitle } from '../shared/styles/DashboardStyles';
import SmallBoldTypography from '../shared/Typography';

interface EpochInfoProps {
  eligiblePool: string;
  rewardAllocation: string;
  yieldUSDC: string;
}

export const CurrentDashboardEpochInfo: React.FC<EpochInfoProps> = ({
  eligiblePool,
  rewardAllocation,
  yieldUSDC,
}: EpochInfoProps) => {
  return (
    <Container>
      <Title>Current&nbsp;Epoch</Title>
      <Box display="flex" flexDirection="column" gridGap="12px">
        <InfoRow
          value={yieldUSDC}
          title={'USDC Yield:'}
          text={'(14-day avg. APY)'}
          isAPY
        />
        <HorizontalDivider />
        <InfoRow
          value={formatStringifyNumberToDot(rewardAllocation, 0)}
          title={'Reward Allocation (BUMP):'}
        />
        <HorizontalDivider />
        <InfoRow
          value={`$${formatStringifyNumberToDot(eligiblePool, 0)}`}
          title={'Max. Eligible Pool (USDC):'}
          text={'(current)'}
        />
      </Box>
    </Container>
  );
};

const InfoRow: FC<{
  title: string;
  value: string;
  text?: string;
  isAPY?: boolean;
}> = ({ title, value, text, isAPY }) => {
  return (
    <Row>
      <RowTitle>{title}</RowTitle>
      <RowValue isAPY={isAPY}>{value}</RowValue>
      {text && <RowText>{text}</RowText>}
    </Row>
  );
};
const Row = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
  },
}));
const RowValue = styled(SmallBoldTypography)(({ isAPY }: any) => ({
  color: COLORS.Primary2,
  fontSize: isAPY ? '30px' : '22px',
  lineHeight: isAPY ? '36px' : '28px',
  fontWeight: 700,
  marginTop: '2px',
}));
const RowText = styled(SmallBoldTypography)({
  color: COLORS.LabelText,
  fontSize: '14px',
  lineHeight: '20px',
});

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minWidth: '235px',
  height: 'fit-content',
  color: COLORS.White,
  backgroundColor: COLORS.Green1,
  border: `2px solid ${COLORS.Primary2}`,
  padding: `24px`,
  boxSizing: 'border-box',

  [theme.breakpoints.down('sm')]: {
    padding: '2.5em 1.5em',
  },
}));
const Title = styled(Typography)({
  height: 'fit-content',
  fontFamily: 'Roboto Mono',
  fontStyle: 'normal',
  display: 'flex',
  justifyContent: 'center',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '24px',
  width: '100%',
  marginBottom: '24px',
});
const HorizontalDivider = styled('div')({
  height: '1px',
  width: '100%',
  backgroundColor: COLORS.BoxOutline,
});
