import {
  withStyles,
  Theme,
  Tabs as MuiTabs,
  Tab as MuiTab,
} from '@material-ui/core';
import React from 'react';

import TabIndicator from '../../assets/tab-indicator.svg';

const PixelTabs = withStyles(() => ({
  root: {
    overflow: 'visible',
    justifyContent: 'center',
  },
  fixed: {
    overflow: 'visible !important',
  },
  scroller: {
    flexGrow: 0,
  },
  indicator: {
    bottom: '-5px',
    height: '12px',
    display: 'flex',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'transparent',
    '& > img': {
      maxWidth: '16px',
      width: '100%',
    },
    left: '140px',
    width: '140px',
  },
}))((props) => (
  <MuiTabs
    {...props}
    TabIndicatorProps={{ children: <img src={TabIndicator} /> }}
  />
)) as typeof MuiTabs;

const PixelTab = withStyles((theme: Theme) => ({
  root: {
    fontFamily: theme.font.secondary,
    fontWeight: 400,
    fontSize: '16px',
    textTransform: 'inherit',
    minWidth: '140px',
  },
  selected: {
    fontWeight: 700,
  },
  textColorInherit: {
    '&.Mui-disabled': {
      opacity: 0.2,
    },
  },
}))((props) => <MuiTab {...props} disableTouchRipple />) as typeof MuiTab;

export { PixelTabs, PixelTab };
