import { Box } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { BigNumber, ethers } from 'ethers';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { BUMP } from '../../config/coins';
import { termsAndConditionsLink } from '../../config/links';
import { Routes, subRoutes } from '../../config/routes';
import { ICoin } from '../../interfaces/ICoin';
import { StakeOptionType } from '../../services/stakingService';
import { VestingService } from '../../services/vestingService';
import { fetchBumpDetails } from '../../state/actions/bumpDetailsAction';
import { getVestingClaimDetails } from '../../state/actions/merkleTreeActions';
import { useAppSelector } from '../../state/hooks';
import { formatStringifyNumberToDot } from '../../utils/helpers';
import { PixelCheckbox } from '../shared/Checkbox';
import { CoinIcons } from '../shared/icons/CoinIcons';
import { PixelButton } from '../shared/PixelButton';
import {
  CoinIconSmall,
  ConfirmationDivider,
  GreenLink,
  NumberTypography,
  SymbolTypography,
} from '../shared/styles/ConfirmationPage';
import { useStyles } from '../shared/styles/GlobalSections';
import { DarkSection, Title } from '../shared/styles/Section';
import {
  FinalText,
  MediumBoldRoboto,
  WhiteSmallRoboto,
} from '../shared/styles/TypographyRoboto';
import { MediumBoldTypography } from '../shared/Typography';

export interface ConfirmFixedSectionProps {
  token: ICoin;
  stakingType: number;
  stakeAmount: number;
  option: StakeOptionType;
  activeOptionIndex: number;
  autorenew: boolean;
  claimedAmount: number;
  walletAmount: BigNumber;
}

export const ConfirmStakeSection: React.FC = () => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const location = useLocation<ConfirmFixedSectionProps>();
  const {
    stakingType,
    stakeAmount,
    option,
    activeOptionIndex,
    claimedAmount,
    autorenew,
    walletAmount,
  } = location.state;
  const token = location.state.token ? location.state.token : BUMP;
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const vestingService = VestingService.getInstance();
  const { addToast } = useToasts();

  const vestingData = useAppSelector((state) => state.merkleTree.vesting);
  const merkleState = useAppSelector((state) => state.merkleTree.merkle);

  const handleCheckbox = () => {
    setTermsAccepted(!termsAccepted);
  };

  const onBackButtonPress = () => {
    history.push(`${Routes.ClaimStake}/${subRoutes.Stake}`, {
      ...location.state,
    });
  };
  const onConfirmStaking = async () => {
    try {
      history.replace(`${Routes.ClaimStake}/${subRoutes.Approve}`);

      const tx = await vestingService.claimToStakeWithPermit(
        vestingData,
        activeOptionIndex,
        autorenew,
        walletAmount,
        ethers.utils.parseUnits(claimedAmount?.toString() ?? '0', 18),
        ethers.utils.parseUnits(stakeAmount.toString(), 18),
      );

      history.replace(`${Routes.ClaimStake}/${subRoutes.Processing}`);
      await tx.wait();
      history.replace(`${Routes.DepositDashboard}`);

      addToast('Later Dude!', {
        appearance: 'success',
      });
      dispatch(fetchBumpDetails());
      dispatch(getVestingClaimDetails(merkleState.vesting));
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
      history.replace(`${Routes.DepositDashboard}`);
      addToast(
        'An unexpected error occurred while trying to stake BUMP. Please try again.',
        {
          appearance: 'warning',
        },
      );
    }
  };

  return (
    <Box maxWidth={{ xs: '100%', sm: '100%' }} m="auto">
      <Box display="flex" justifyContent="center" mb={5}>
        <Title>Confirmation</Title>
      </Box>
      <DarkSection
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={{ xs: 3, sm: 3, lg: 4 }}
        position="relative"
        pt={{ xs: 9, sm: 9, lg: 10 }}
      >
        <CoinIconSmall src={CoinIcons[token.symbol]} />
        <Box display="flex" alignItems="baseline" mb={1}>
          <NumberTypography variant="h1" style={{ marginRight: '8px' }}>
            +{formatStringifyNumberToDot('' + stakeAmount, 4)}
          </NumberTypography>
          <SymbolTypography variant="h4" color="textSecondary">
            STAKED {token.symbol}
          </SymbolTypography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mt={3}
          mb={3}
          style={{ width: '100%' }}
        >
          <FinalText>BUMP Claim</FinalText>
          <MediumBoldTypography secondaryFont>
            {formatStringifyNumberToDot(
              '' +
                (claimedAmount - stakeAmount > 0
                  ? claimedAmount - stakeAmount
                  : 0),
            )}
            <span className={classes.unit}> {token.symbol}</span>
          </MediumBoldTypography>
        </Box>
        <ConfirmationDivider />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mt={3}
          style={{ width: '100%' }}
        >
          <FinalText>Staking Term</FinalText>
          <MediumBoldRoboto>
            {stakingType === 1 ? 'Flexible' : `${option.periodInDays} days`}
          </MediumBoldRoboto>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mt={3}
          style={{ width: '100%' }}
        >
          <FinalText>BUMP Stake</FinalText>
          <MediumBoldTypography secondaryFont>
            {formatStringifyNumberToDot('' + stakeAmount)}
            <span className={classes.unit}> {token.symbol}</span>
          </MediumBoldTypography>
        </Box>
      </DarkSection>
      <Box mt={3} display="flex" alignItems="center">
        <Box
          mr={3}
          style={{ position: 'relative', top: '-1px', right: '0.3em' }}
        >
          <PixelCheckbox checked={termsAccepted} onChange={handleCheckbox} />
        </Box>
        <Box>
          <WhiteSmallRoboto>
            By checking this box you acknowledge and agree to our
            <GreenLink
              href={termsAndConditionsLink}
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              terms and conditions
            </GreenLink>
            .
          </WhiteSmallRoboto>
        </Box>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="minmax(150px, 33%) auto minmax(150px, 33%)"
        mt={{ xs: 3, sm: 4 }}
        pb="10vh"
      >
        <Box>
          <PixelButton fullWidth color="secondary" onClick={onBackButtonPress}>
            Back
          </PixelButton>
        </Box>
        <Box gridColumn="3">
          <PixelButton
            fullWidth
            color="primary"
            disabled={!termsAccepted}
            onClick={onConfirmStaking}
          >
            Confirm
          </PixelButton>
        </Box>
      </Box>
    </Box>
  );
};
