import { createSlice } from '@reduxjs/toolkit';

import { IEpoch, IReward } from '../../interfaces/IEpoch';
import { getEpochsSummary, getEpochsRewards } from '../actions/epochsActions';

const initialState: {
  epochsData: IEpoch[];
  rewards: IReward[];
  result: IReward;
} = {
  epochsData: [],
  rewards: [],
  result: {
    epoch: null,
    usdc_yield_per_user: 0,
    bump_rewards: 0,
    bump_value_usdc: 0,
    total_rewards_usdc_value: 0,
  },
};
export const epochsSlice = createSlice({
  name: 'epochs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEpochsSummary.fulfilled, (state, action) => {
      state.epochsData = action.payload;
    });
    builder.addCase(getEpochsRewards.fulfilled, (state, action) => {
      const { epochsRewards, result } = action.payload;
      state.rewards = epochsRewards;
      state.result = result;
    });
  },
});
export default epochsSlice.reducer;
