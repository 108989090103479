import { createAsyncThunk } from '@reduxjs/toolkit';

import { ICoinDetails, ICoin } from '../../interfaces/ICoin';
import { MakerService } from '../../services/makerService';

const makerService = MakerService.getInstance();

export const fetchCoinDetails = createAsyncThunk<ICoinDetails[], ICoin[]>(
  'getTokenDetails',
  async (coinList: ICoin[]) => {
    const rawResponse = await Promise.allSettled(
      coinList.map((coin) => {
        return makerService.getTokenDetails(coin);
      }),
    );
    const fulfilledResult = rawResponse.filter(
      (res) => res.status === 'fulfilled',
    ) as PromiseFulfilledResult<ICoinDetails>[];
    const result = fulfilledResult.map((res) => res.value);
    return result;
  },
);
