import { BigNumber } from '@ethersproject/bignumber';
import {
  Backdrop,
  Box,
  styled,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import React from 'react';

import { COLORS } from '../../config/colors';
import { IReward } from '../../interfaces/IEpoch';
import { theme } from '../../providers/AppThemeProvider';
import { ClaimedEventArgsType } from '../../services/airdropService';
import {
  formatBUMP,
  formatStringifyNumberToDot,
  formatUSDC,
  getEpoch,
  groupBy,
} from '../../utils/helpers';
import { PixelCloseIcon } from '../shared/icons/PixelCloseIcon';

interface HistoryModalProps {
  close: () => void;
  visible: boolean;
  earnings: ClaimedEventArgsType[];
  rewards: IReward[];
}

export const AirdropHistoryModal: React.FC<HistoryModalProps> = ({
  close,
  visible,
  earnings,
  rewards,
}) => {
  const claimEvents = groupBy(
    earnings.map((v) => ({
      account: v.account,
      amountBUMP: v.amountBUMP,
      amountUSDC: v.amountUSDC,
      index: v.index,
      epoch: getEpoch(parseInt(v.timestamp) * 1000).epoch,
    })),
    (v) => v.epoch.toString(),
  );

  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Backdrop open={visible} style={{ zIndex: 1000 }}>
      <Container>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={5}
        >
          <Header>History</Header>
          <Box display="flex" alignItems="center">
            {!smallScreen && <CloseTypography>Close</CloseTypography>}
            <CancelButtonSection onClick={close}>
              <PixelCloseIcon style={{ cursor: 'pointer' }} />
            </CancelButtonSection>
          </Box>
        </Box>
        <Columns
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <ColumnName>Epoch number</ColumnName>
          <ColumnName>BUMP Earnings</ColumnName>
          <ColumnName>USDC Earnings</ColumnName>
        </Columns>
        <Rows>
          {earnings.length > 0
            ? Object.keys(claimEvents)
                .map((v) =>
                  claimEvents[v]
                    .map((v) => {
                      return {
                        amountBUMP: v.amountBUMP,
                        amountUSDC: v.amountUSDC,
                        epoch: v.epoch,
                      };
                    })
                    .reduce((prev, curr) => {
                      return {
                        amountBUMP: BigNumber.from(prev.amountBUMP)
                          .add(curr.amountBUMP)
                          .toString(),
                        amountUSDC: BigNumber.from(prev.amountUSDC)
                          .add(curr.amountUSDC)
                          .toString(),
                        epoch: prev.epoch,
                      };
                    }),
                )
                .sort((a, b) => b.epoch - a.epoch)
                .map(({ amountUSDC, amountBUMP, epoch }: any) => (
                  <AmountRow key={`claimAmount-${epoch.toString()}`}>
                    <AmountTypography>
                      EPOCH #{epoch.toString().padStart(2, '0')}
                    </AmountTypography>
                    <AmountTypography>
                      {formatBUMP(amountBUMP)}
                    </AmountTypography>
                    <AmountTypography>
                      {formatUSDC(amountUSDC)}
                    </AmountTypography>
                  </AmountRow>
                ))
            : rewards.map((reward) => {
                const { bump_rewards, usdc_yield_per_user, epoch } = reward;
                return (
                  <AmountRow key={`claimAmount-${epoch}-${bump_rewards}`}>
                    <AmountTypography>EPOCH #0{epoch}</AmountTypography>
                    <AmountTypography>
                      {formatStringifyNumberToDot(bump_rewards.toString())}
                    </AmountTypography>
                    <AmountTypography>
                      {formatStringifyNumberToDot(
                        usdc_yield_per_user.toString(),
                      )}
                    </AmountTypography>
                  </AmountRow>
                );
              })}
        </Rows>
      </Container>
    </Backdrop>
  );
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Roboto Mono',
  fontWeight: 700,
  fontSize: '15px',
  color: 'white',

  [theme.breakpoints.down('sm')]: {
    fontSize: '13px',
  },
}));
const Container = styled(Box)(({ theme }) => ({
  width: '55vw',
  height: '620px',
  background: COLORS.Green1,
  border: `4px solid ${COLORS.BoxOutline}`,
  padding: '2.5rem',

  [theme.breakpoints.down('sm')]: {
    width: '65vw',
  },
  [theme.breakpoints.down('xs')]: {
    padding: '1rem',
    width: '90%',
  },
}));

const Header = styled(Typography)({
  fontFamily: 'Roboto Mono',
  fontWeight: 700,
  fontStyle: 'normal',
  fontSize: '24px',
  lineHeight: '28px',
});

const CloseTypography = styled(Typography)({
  fontFamily: 'Roboto',
  fontWeight: 400,
  fontSize: '16px',
  textAlign: 'center',
  opacity: '0.7',
  marginRight: '1.75rem',
});

const Rows = styled(Box)(() => ({
  marginTop: '1.5rem',
  overflowY: 'auto',
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',
  maxHeight: '440px',
  '&::-webkit-scrollbar': {
    width: 0,
    background: 'transparent',
  },
}));

const CancelButtonSection = styled(Box)({
  '&:hover': {
    opacity: '0.8',
  },
});

const Columns = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
}));
const ColumnName = styled(StyledTypography)(({ theme }) => ({
  color: 'rgba(255, 255, 255, 0.7)',
  width: '27.5%',
  '&:first-child': {
    width: '45%',
  },

  [theme.breakpoints.down('sm')]: {
    width: '33% !important',
  },
}));

const AmountRow = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderTop: '1px solid #5E979E',
  paddingTop: '1.5rem',
  marginBottom: '1.5rem',
});
const AmountTypography = styled(StyledTypography)(({ theme }) => ({
  width: '27.5%',
  fontSize: '15px',
  '&:first-child': {
    width: '45%',
  },

  [theme.breakpoints.down('xs')]: {
    fontSize: '13px',
  },

  [theme.breakpoints.down('sm')]: {
    width: '33% !important',
  },
}));
