import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { Routes, subRoutes } from '../../config/routes';
import { StakingService } from '../../services/stakingService';

type UseStakingEjectReturnProps = {
  eject: (stakeIndex: number) => void;
  isLoadingEject: boolean;
  canEject: (stakeIndex: number) => Promise<boolean>;
};

export function useStakingEject(): UseStakingEjectReturnProps {
  const [isLoadingEject, setIsLoadingEject] = useState(false);
  const history = useHistory();
  const stakingService = useMemo(
    () => StakingService.getInstance(),
    [StakingService],
  );
  const { addToast } = useToasts();

  const eject = useCallback(async (stakeIndex: number) => {
    try {
      setIsLoadingEject(true);
      history.replace(`${Routes.EjectStake}/${subRoutes.Approve}`);
      const tx = await stakingService.eject(stakeIndex);

      history.replace(`${Routes.EjectStake}/${subRoutes.Processing}`);
      await tx.wait();

      history.replace(`${Routes.Staking}`);

      addToast('Later Dude!', {
        appearance: 'success',
      });
      setIsLoadingEject(false);
    } catch (ex) {
      console.log(ex);
      // debugger;
      setIsLoadingEject(false);
      history.replace(Routes.Staking);
      addToast(
        'An unexpected error occurred when trying to eject. Please try again.',
        {
          appearance: 'warning',
        },
      );
    }
  }, []);

  const canEject = useCallback(async (stakeIndex: number) => {
    return stakingService.canEject(stakeIndex);
  }, []);

  return {
    canEject,
    eject,
    isLoadingEject,
  };
}
