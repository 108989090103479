import { Box, IconButton, styled } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

import branchIcon from '../../assets/branch-icon.svg';
import { Routes } from '../../config/routes';
import { ICoin } from '../../interfaces/ICoin';
import { formatStringifyNumberToDot } from '../../utils/helpers';
import { busdcToolTipText } from '../../utils/toolTipTexts';
import { CoinIcon } from '../shared/CoinIcon';
import { ContentSection } from '../shared/ContentSection';
import { DataField } from '../shared/DataField';
import { CoinIcons } from '../shared/icons/CoinIcons';
import {
  BranchIcon,
  BusdcIcon,
  CustomHelpIcon,
  WithdrawButton,
} from '../shared/styles/DashboardStyles';
import { Tooltip } from '../shared/Tooltip';
import { TopSection } from '../shared/TopSection';
import {
  LargeBoldTypography,
  MediumBoldTypography,
  SmallTypography,
} from '../shared/Typography';

export interface ILiquidityDepositCardProps {
  bumpEarned: string;
  bumpEarnedInDollars: string;
  balance: string;
  token: ICoin;
  APR: string | null;
}

export const LiquidityDepositCard: React.FC<ILiquidityDepositCardProps> = (
  props,
) => {
  const history = useHistory();
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <TopSection color="#00FFFF">
        <Box width="100%">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <CoinIcon src={CoinIcons[props.token.symbol]} />
              <LargeBoldTypography secondaryFont>
                {props.token.symbol}
              </LargeBoldTypography>
            </Box>
          </Box>
          <Box display="flex" marginTop="2px">
            <BranchIcon src={branchIcon} />
            <Box style={{ display: 'flex' }}>
              <BusdcIcon src={CoinIcons['bUSDC']} />
              <MediumBoldTypography
                secondaryFont
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                  marginLeft: '8px',
                }}
              >
                bUSDC
                <SmallTypography
                  style={{ marginLeft: '6px' }}
                  variantMapping={{ body1: 'span' }}
                >
                  {formatStringifyNumberToDot(props.balance)}
                </SmallTypography>
              </MediumBoldTypography>
              <Tooltip title={busdcToolTipText}>
                <IconButton style={{ padding: '0px', marginBottom: '2px' }}>
                  <CustomHelpIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </TopSection>
      <DepositContentSection>
        <DataField
          name="Deposited"
          value={formatStringifyNumberToDot(props.balance)}
          unit="USDC"
        />
        <WithdrawButton
          onClick={() => history.push(Routes.WithdrawDetails + '/select')}
        >
          Withdraw
        </WithdrawButton>
      </DepositContentSection>
    </Box>
  );
};

const DepositContentSection = styled(ContentSection)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.down('xs')]: {
    gap: theme.spacing(1),
  },
}));
