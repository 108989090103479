import { BigNumber, ethers, FixedNumber } from 'ethers';

import { CONTRACTS, TokenList } from '../config/contractAddresses';
import { ICoin, ICoinDetails } from '../interfaces/ICoin';
import { BUSDC__factory } from '../types';
import { BumpMarket__factory } from '../types/factories/BumpMarket__factory';
import { IERC20__factory } from '../types/factories/IERC20__factory';

export interface MetamaskProvider {
  on: (eventName: string, handler: (accounts: Array<string>) => void) => void;
  request: (argument: { method: string }) => Promise<[string] | []>;
  selectedAddress: string | null;
}
type CustomProvider =
  | ethers.providers.Web3Provider
  | ethers.providers.JsonRpcProvider
  | undefined;

export class EthersServiceProvider {
  private static instance: EthersServiceProvider;

  public provider: CustomProvider;
  public currentAccount: string;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {
    this.currentAccount = '';
  }

  /**
   * Configures and returns singleton instance of EthersServiceProvider.
   * @returns Returns EthersServiceProvider singleton instance
   */
  static getInstance(): EthersServiceProvider {
    if (!this.instance) {
      this.instance = new EthersServiceProvider();
      this.instance.getProvider();
    }
    return this.instance;
  }

  async getProvider() {
    return this.provider;
  }

  setCurrentAccount(address: string) {
    this.currentAccount = address;
  }

  /**
   * Will load and return contract instances.
   * @param abi - ABI of contract that you want to create instance of
   * @param address - Address at which that contract is deployed on chain
   * @returns Returns contract instance if successfull or Error if failed
   */
  async loadContractInstance(
    abi: ethers.ContractInterface,
    address: string,
  ): Promise<ethers.Contract> {
    if (!this.provider) {
      throw new Error('INDX: Metamask is not connected');
    }
    if (abi == undefined) {
      throw new Error('INDX: ABI is not passed as argument');
    }
    if (address == undefined) {
      throw new Error('INDX: address is not passed as argument');
    }
    const contract: ethers.Contract = new ethers.Contract(
      address,
      abi,
      this.provider.getSigner(0),
    );
    await contract.deployed();
    return contract;
  }

  /**
   * This method gives you current user account address.
   * @returns Returns user's current metamask aacount
   */
  async getUserAddress(): Promise<string> {
    return this.currentAccount;
  }

  /**
   * This method returns user's balance
   * @returns Returns user balance in  string format
   */
  async getUserBalance(): Promise<string> {
    if (!this.provider) {
      throw new Error('INDX: Metamask is not connected');
    }
    const address = await this.getUserAddress();
    const balance: ethers.BigNumber = await this.provider.getBalance(address);
    return this.convertWeiIntoEther(balance);
  }

  /**
   * Get network info you are connected too
   * @returns Returns current metamask chain information
   */
  async getCurrentNetworkInfo(): Promise<ethers.providers.Network> {
    if (!this.provider) {
      throw new Error('INDX: Metamask is not connected');
    }
    const networkInfo: ethers.providers.Network =
      await this.provider.getNetwork();
    return networkInfo;
  }

  /**
   * This method returns all the info regarding blocknumber passed as param
   * @param blockNumber - Block number in number format
   * @returns Returns block info of provided block number
   */
  async getBlockInfo(blockNumber: number): Promise<ethers.providers.Block> {
    if (!this.provider) {
      throw new Error('INDX: Metamask is not connected');
    }
    const block: ethers.providers.Block = await this.provider.getBlock(
      blockNumber,
    );
    return block;
  }

  /**
   * This method approves certain amount of erc20 tokens to an address.
   * @param amount - Amount that needs to be approved.
   * @param to - Address to which tokens need to be approved.
   * @param contractAddress - Address at which ERC20 token is deployed.
   * @returns Returns transaction object that represents the Tx we sent to ERC20 contract.
   */
  async approveTokenAmount(
    amount: string,
    to: string,
    contractAddress: string,
  ): Promise<ethers.ContractTransaction> {
    const erc20Instance = IERC20__factory.connect(
      contractAddress,
      this.provider?.getSigner(0) as ethers.providers.JsonRpcSigner,
    );
    const tx: ethers.ContractTransaction =
      await erc20Instance.functions.approve(to, amount);

    await tx.wait();
    return tx;
  }

  async approveAmount(
    from: string,
    to: string,
    contractAddress: string,
  ): Promise<BigNumber> {
    const erc20Instance = IERC20__factory.connect(
      contractAddress,
      this.provider?.getSigner(0) as ethers.providers.JsonRpcSigner,
    );
    return await erc20Instance.allowance(from, to);
  }

  /**
   * This convert Wei(As Big Number) into Ether format
   * @param value - Big number format value
   * @returns Returns string representation of ether
   */
  convertWeiIntoEther(value: ethers.BigNumberish): string {
    return ethers.utils.formatEther(value);
  }

  /**
   * Reload page when chain id is changes in metamask
   */
  handleChainIdChange(): void {
    window.location.reload();
  }

  /**
   * Reload page when accounts changes.
   * We can modify this function later depending on how we want to handle this event.
   */
  handleAccountsChange(): void {
    window.location.reload();
  }

  /**
   * Is used to convert a big number into fixed number in string format.
   * @param usdc Amount in big number that will be converted to Fixed number format in string.
   * @param decimal Places upto which you want a decimal point.
   * @returns Returns a decimal number in fixed format in form of string.
   */
  toDecimal(usdc: BigNumber, decimal: number): string {
    const formatted = ethers.utils.formatUnits(usdc, decimal);
    return FixedNumber.fromString(formatted).round(6).toString();
  }

  /**
   * It returns details regarding token passed in argument.
   * @param token Token details in ICoin format, for which you want balance , value and price
   * @returns It returns balance , price and value of this token w.r.t current user.
   */
  async fetchTokenDetails(token: ICoin): Promise<ICoinDetails> {
    const tokenContractFactory =
      token.address.toLowerCase() ===
      CONTRACTS.CONTRACT_ADDRESS.BUSDC.address.toLowerCase()
        ? BUSDC__factory
        : IERC20__factory;
    const tokenContract = tokenContractFactory.connect(
      token.address,
      this.provider as ethers.providers.JsonRpcProvider,
    );
    const bumperContract = BumpMarket__factory.connect(
      CONTRACTS.CONTRACT_ADDRESS.BumpMarket.address,
      this.provider as ethers.providers.JsonRpcProvider,
    );
    const balanceRes: ethers.BigNumber = await tokenContract.balanceOf(
      this.currentAccount,
    );
    const currentPrice: ethers.BigNumber = await bumperContract.getCurrentPrice(
      0,
    );
    return {
      ...token,
      balance: this.toDecimal(
        balanceRes,
        CONTRACTS.TOKEN_DETAILS[token.symbol as keyof TokenList].decimal,
      ),
      price: this.toDecimal(
        currentPrice,
        CONTRACTS.TOKEN_DETAILS[token.symbol as keyof TokenList].decimal + 2,
      ),
      value: this.toDecimal(
        balanceRes.mul(currentPrice),
        2 * CONTRACTS.TOKEN_DETAILS[token.symbol as keyof TokenList].decimal +
          2,
      ).toString(),
    };
  }

  async subscribeToEvent(
    eventName: string,
    contractAddress: string,
    listener: ethers.providers.Listener,
    contractABI: ethers.ContractInterface,
  ): Promise<void> {
    if (!this.provider) {
      throw new Error('INDX: Metamask is not connected');
    }
    const contract = await this.loadContractInstance(
      contractABI,
      contractAddress,
    );
    contract.on(
      { address: contractAddress, topics: [ethers.utils.id(eventName)] },
      listener,
    );
  }

  /*
  unsubcscribeEvents(): void {
    if (!this.provider) {
      throw new Error('INDX: Metamask is not connected');
    }
    // ???
    this.provider.off;
  }
  */
}
