import { Typography, TypographyProps, styled } from '@material-ui/core';
import React from 'react';

import { theme } from '../../providers/AppThemeProvider';

interface IMuiTypographyProps extends TypographyProps {
  secondaryFont?: boolean;
}

const MuiTypography = styled(
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ secondaryFont, ...other }: IMuiTypographyProps) => (
    <Typography {...other} />
  ),
)({
  fontFamily: (props: IMuiTypographyProps) =>
    props.secondaryFont ? theme.font.secondary : 'inherit',
  '&.MuiTypography-colorSecondary': {
    color: theme.palette.other.LightText,
  },
  '&.MuiTypography-colorTextSecondary': {
    color: theme.palette.other.ExtraLightText,
  },
  cursor: 'default',
});

// Since custom variants for Typography are only available in Material UI V5,
// We are creating some styled components here to be used throughout the code.
// These components are mapped to the paragraph styles provided in the
// Bumper Design System wireframes.
// NOTE: h1, h2, h3, h4, h5, and h6 are separate and have been configured in the
// theme itself.

// h7 variant is not provided by material-ui so we are creating our own.
const H7Typography = styled(MuiTypography)({
  fontSize: '11px',
  lineHeight: '14px',
  fontWeight: 700,
});

const LargeTypography = styled(MuiTypography)({
  fontSize: '18px',
  lineHeight: '24px',
});

const LargeBoldTypography = styled(MuiTypography)({
  fontSize: '20px',
  lineHeight: '26px',
  fontWeight: 700,
});

const MediumTypography = styled(MuiTypography)({
  fontSize: '16px',
  lineHeight: '24px',
});

const MediumBoldTypography = styled(MuiTypography)({
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 700,
});

const SmallTypography = styled(MuiTypography)({
  fontSize: '14px',
  lineHeight: '20px',
});

const SmallBoldTypography = styled(MuiTypography)({
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 700,
});

const ExtraSmallTypography = styled(MuiTypography)({
  fontSize: '12px',
  lineHeight: '16px',
});

const ExtraSmallBoldTypography = styled(MuiTypography)({
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: 700,
});

export {
  MuiTypography as default,
  H7Typography,
  LargeTypography,
  LargeBoldTypography,
  MediumTypography,
  MediumBoldTypography,
  SmallTypography,
  SmallBoldTypography,
  ExtraSmallTypography,
  ExtraSmallBoldTypography,
};

export type { IMuiTypographyProps };
