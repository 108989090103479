import { Box, styled } from '@material-ui/core';
import React from 'react';

import { useAppSelector } from '../../state/hooks';
import { formatStringifyNumberToDot } from '../../utils/helpers';
import { DataField } from '../shared/DataField';
import { CoinIcons } from '../shared/icons/CoinIcons';
import { Section } from '../shared/styles/Section';
import {
  ExtraSmallBoldRoboto,
  MediumBoldRoboto,
} from '../shared/styles/TypographyRoboto';

export const StakeCoinDetails: React.FC = () => {
  const { bumpBalance, currentBumpPrice, bumpBalanceInUsdc } = useAppSelector(
    (state) => state.bumpDetails,
  );

  return (
    <Section>
      <HeaderWrapper>
        <BumpUnit>
          <StyledBump
            src={CoinIcons['BUMP']}
            style={{
              border: '2px solid #325F65',
              transform: 'none',
              marginRight: '0.5em',
            }}
          />
          <StyledBoldTypography>BUMP</StyledBoldTypography>
          &nbsp;
          <StyledBoldTypography2>
            ${formatStringifyNumberToDot(currentBumpPrice)}
          </StyledBoldTypography2>
          &nbsp;
          <StyledBoldTypography>=</StyledBoldTypography>&nbsp;
          <StyledBoldTypography2>1</StyledBoldTypography2>&nbsp;
          <StyledSmallTypography style={{ marginTop: '0.5em' }}>
            Unit
          </StyledSmallTypography>
        </BumpUnit>
      </HeaderWrapper>
      <ValuesContainer
        display="grid"
        gridTemplateColumns="repeat(auto-fit, minmax(130px, 1fr))"
        gridGap="10px"
        style={{ padding: '12px', background: '#00353C', marginTop: '24px' }}
      >
        <DataField
          name={'Units'}
          value={formatStringifyNumberToDot(bumpBalance)}
        />
        <DataField
          name={'Value'}
          value={`$${formatStringifyNumberToDot(bumpBalanceInUsdc)}`}
        />
      </ValuesContainer>
    </Section>
  );
};

const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  flexFlow: 'row nowrap',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    flexFlow: 'column nowrap',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },
}));

const BumpUnit = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexFlow: 'row nowrap',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

const StyledBump = styled('img')({
  border: '2px solid #325F65',
  backgroundPosition: '50% 25%',
  transform: 'translateX(-.675rem)',
  borderRadius: '50%',
});

const StyledBoldTypography = styled(MediumBoldRoboto)({
  fontSize: '18px',
  lineHeight: '23.74px',
  display: 'inline-block',
});

const StyledBoldTypography2 = styled(MediumBoldRoboto)({
  display: 'inline-block',
});

const StyledSmallTypography = styled(ExtraSmallBoldRoboto)({
  color: 'rgba(255, 255, 255, 0.6);',
  display: 'inline-block',
});

const ValuesContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));
