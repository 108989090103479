import { useQuery } from '@apollo/client';
// import { Box } from '@material-ui/core';
import { useWeb3React } from '@web3-react/core';
import { BigNumber, ethers } from 'ethers';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Container } from '../components/common/MainContainer';
import { Wrapper } from '../components/common/Wrapper';
// import { ClaimBlock } from '../components/DepositDashboard/Vesting/ClaimBlock';
// import {
//   ContainerMainEpoch,
//   Description,
//   MainSecondContainer,
// } from '../components/shared/styles/DashboardStyles';
import { BumpCard } from '../components/Staking/BumpCard';
import { FakeStakeCard } from '../components/Staking/FakeStakeCard';
import { FixedStakeCard } from '../components/Staking/FixedStakeCard';
import { FlexibleStakeCard } from '../components/Staking/FlexibleStakeCard';
import {
  HistoryModal,
  OperationType,
} from '../components/Staking/HistoryModal';
import { StakingSection } from '../components/Staking/StakingSection';
import { useStakingEject } from '../components/Staking/useStakingEject';
import { BUMP } from '../config/coins';
import { WalletContext } from '../config/walletContext';
import { client } from '../providers/graphql';
import { GetStakingGeneralHistoryByAddress } from '../providers/graphql/__generated__/GetStakingGeneralHistoryByAddress';
import { GET_STAKING_GENERAL_HISTORY_BY_ADDRESS } from '../providers/graphql/queries';
import {
  HistoryStakeType,
  StakingService,
  StakeWithRewards,
  // secondsByDays,
} from '../services/stakingService';
import { getStakingDetails } from '../state/actions/merkleTreeActions';
import { useAppSelector } from '../state/hooks';
// import { timeInMilliseconds } from '../utils/helpers';

const parseStakingOperation = (op: string): OperationType => {
  OperationType;
  switch (op) {
    case 'Stake':
      return OperationType.Stake;
    case 'Withdraw':
      return OperationType.Unstake;
    case 'Claim':
      return OperationType.Claim;
    case 'Request Withdraw':
      return OperationType.RequestWithdraw;
    default:
      return OperationType.Unknown;
  }
};

export const Staking: React.FC = () => {
  const [isHistoryModalVisible, toggleHistoryModal] = useState<boolean>(false);
  const stakingService = StakingService.getInstance();
  const { account } = useWeb3React();
  const dispatch = useDispatch();
  const [indexesThatCanEject, setIndexesThatCanEject] = useState<boolean[]>([]);
  const { canEject } = useStakingEject();

  useEffect(() => {
    if (isHistoryModalVisible) document.body.style.overflow = 'hidden';
    if (!isHistoryModalVisible) document.body.style.overflow = 'auto';
  }, [isHistoryModalVisible]);

  const isWalletConnected = useContext(WalletContext).isWalletConnected;
  const bumpBalance = useAppSelector((state) => state.bumpDetails.bumpBalance);
  const staking = useAppSelector((state) => state.merkleTree.staking);
  const vesting = useAppSelector((state) => state.merkleTree?.vesting);
  const stakingMerkle = useAppSelector((state) => state.merkleTree.merkle);

  const isFakeStakingNeedDisplay = useMemo(() => {
    try {
      return staking
        ? BigNumber.from(ethers.utils.parseUnits(staking.amount.toString()))
            .add(
              BigNumber.from(
                Math.round(
                  staking?.amountWithoutStaking ?? ethers.constants.Zero,
                ),
              ),
            )
            .sub(vesting?.claimed ?? ethers.constants.Zero)
            .gt(0)
        : false;
    } catch (err) {
      return false;
    }
  }, [staking, vesting]);

  const [stakes, setStakes] = useState<StakeWithRewards[]>([]);
  const [stakingHistory, setStakingHistory] = useState<HistoryStakeType[]>([]);
  const [periods, setPeriods] = useState<number[]>([]);

  useEffect(() => {
    if (stakes.length) {
      const promises = stakes.map((_, index) => canEject(index));
      Promise.all(promises).then((results) => {
        setIndexesThatCanEject(results);
      });
    }
  }, [stakes]);

  const stakingQuery = useQuery<GetStakingGeneralHistoryByAddress>(
    GET_STAKING_GENERAL_HISTORY_BY_ADDRESS,
    {
      variables: { address: `${account}` },
      client: client,
      fetchPolicy: 'network-only',
      pollInterval: 5000,
    },
  );

  // const showInfoBox = useMemo(() => {
  //   if (stakes.length) {
  //     for (const stake of stakes) {
  //       const { start, withdrawWindow, autorenew, option } = stake;
  //       if (option === 0) {
  //         continue;
  //       }
  //       const interval = periods[option];
  //       const periodsCeil = Math.ceil(
  //         (Date.now() / 1000 - start) /
  //           (secondsByDays(interval) + (autorenew ? withdrawWindow : 0)),
  //       );

  //       let multiplier = 1;
  //       if (autorenew || periodsCeil > 1) {
  //         multiplier = periodsCeil;
  //       }

  //       const endDate = timeInMilliseconds(
  //         start +
  //           (autorenew && periodsCeil > 1
  //             ? withdrawWindow * (periodsCeil - 1)
  //             : 0) +
  //           multiplier * secondsByDays(interval),
  //       );
  //       if (Date.now() > endDate) {
  //         return false;
  //       }
  //     }
  //   }
  //   return true;
  // }, [stakes, periods]);

  useEffect(() => {
    if (
      !stakingQuery.loading &&
      !stakingQuery.error &&
      stakingQuery.data &&
      isWalletConnected &&
      account
    ) {
      setStakingHistory(
        stakingQuery.data.stakingGeneralHistories
          .map((v) => ({
            amount: parseFloat(
              parseFloat(
                ethers.utils.formatUnits(BigNumber.from(v.amountBUMP)),
              ).toFixed(18),
            ),
            operation: parseStakingOperation(v.operation),
            timestamp: BigNumber.from(v.timestamp).toNumber(),
          }))
          .sort((a, b) => b.timestamp - a.timestamp),
      );
    }
  }, [stakingQuery?.loading, stakingQuery?.error, stakingQuery?.data]);

  let firstLoading = true;

  useEffect(() => {
    if (isWalletConnected && account) {
      if (firstLoading) {
        firstLoading = false;
      } else {
        stakingQuery.refetch();
      }

      Promise.all([
        stakingService.getUserStakes(),
        stakingService.getPeriods(),
      ]).then(([stakesData, stakePeriods]) => {
        setStakes(stakesData);
        setPeriods(stakePeriods);
      });

      const polling = setInterval(async () => {
        const stakeData = await stakingService.getUserStakes();
        setStakes(stakeData);
        dispatch(getStakingDetails(stakingMerkle.staking));
      }, 10000);
      return () => clearInterval(polling);
    }
  }, [account, isWalletConnected, stakingMerkle.staking]);
  return (
    <>
      <HistoryModal
        close={() => toggleHistoryModal(false)}
        visible={isHistoryModalVisible}
        historyData={stakingHistory}
      />
      <Container>
        <StakingSection />
        {/* <ClaimBlock staking /> */}
        {isWalletConnected && (
          <>
            <Wrapper title="Balances">
              <BumpCard
                balance={bumpBalance}
                token={BUMP}
                openHistoryModal={() => toggleHistoryModal(true)}
              />
            </Wrapper>
            {(stakes.length > 0 || (staking && isFakeStakingNeedDisplay)) && (
              <Wrapper title="Staked">
                {isFakeStakingNeedDisplay && staking && (
                  <FakeStakeCard
                    token={BUMP}
                    amount={staking.amount}
                    rewards={staking.rewards}
                    endDate={new Date(2022, 2, 16, 12).getTime()}
                  />
                )}
                {/* {indexesThatCanEject.includes(true) && showInfoBox ? (
                  <ContainerMainEpoch>
                    <MainSecondContainer>
                      <Box textAlign="left">
                        <Description
                          style={{
                            justifyContent: 'start',
                            textAlign: 'left',
                            fontSize: 14,
                            lineHeight: '26px',
                            fontWeight: 600,
                          }}
                        >
                          Liquidity Mining Program Ejection
                        </Description>
                        <Description
                          style={{
                            fontSize: 14,
                            lineHeight: '26px',
                          }}
                        >
                          To aid the transition for existing stakers who wish to
                          participate in our Liquidity Mining program, you are
                          now temporarily able to ‘eject’ from any open staking
                          position. You will be able to claim any accrued
                          rewards and exit staking by pressing the EJECT button
                          on any locked position.
                        </Description>
                        <Description
                          style={{
                            fontSize: 14,
                            lineHeight: '26px',
                          }}
                        >
                          Please note, the eject function will be available
                          until March 15 at 12pm UTC.
                        </Description>
                      </Box>
                    </MainSecondContainer>
                  </ContainerMainEpoch>
                ) : null} */}

                {stakes.map(
                  (
                    {
                      amount,
                      autorenew,
                      requestedAt,
                      option,
                      withdrawWindow,
                      rewards,
                      start,
                      cooldownPeriod,
                      end,
                    },
                    index,
                  ) =>
                    option === 0 ? (
                      <FlexibleStakeCard
                        key={index}
                        token={BUMP}
                        amount={amount}
                        rewards={rewards}
                        unstakeRequestedAt={requestedAt}
                        endWithdrawWindow={withdrawWindow}
                        stakeIndex={index}
                        cooldownPeriod={cooldownPeriod}
                        isEjectEnabled={indexesThatCanEject[index]}
                      />
                    ) : (
                      <FixedStakeCard
                        key={index}
                        end={end}
                        token={BUMP}
                        amount={amount}
                        rewards={rewards}
                        autorenew={autorenew}
                        option={option}
                        stakeIndex={index}
                        start={start}
                        withdrawWindow={withdrawWindow}
                        interval={periods[option]}
                        isEjectEnabled={indexesThatCanEject[index]}
                      />
                    ),
                )}
              </Wrapper>
            )}
          </>
        )}
      </Container>
    </>
  );
};
