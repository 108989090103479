import { Box, styled } from '@material-ui/core';
import { HelpRounded } from '@material-ui/icons';
import React, { memo } from 'react';

import { formatStringifyNumberToDot } from '../../../utils/helpers';
import { useStyles } from '../../shared/styles/GlobalSections';
import { Tooltip } from '../../shared/Tooltip';
import { SmallTypography, MediumBoldTypography } from '../../shared/Typography';

interface VestingInfoProps {
  title: string;
  value: number;
  tooltipText: string;
  textType: 'normal' | 'green' | 'secondary';
}

export const VestingInfoBlock: React.FC<VestingInfoProps> = memo(
  ({ title, value, tooltipText, textType }) => {
    const classes = useStyles();
    const InfoBlock = styled(Box)(({ theme }) => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      [theme.breakpoints.down('xs')]: {
        alignItems: 'center',
      },
    }));
    return (
      <InfoBlock>
        <SmallTypography
          color="secondary"
          style={{
            fontFamily: 'Roboto Mono',
            fontWeight: 'bold',
            marginBottom: '6px',
          }}
        >
          {title}
          <Tooltip title={tooltipText}>
            <HelpRounded className={classes.icon} />
          </Tooltip>
        </SmallTypography>
        <AdaptiveContainer>
          <MediumBoldTypography
            secondaryFont
            className={
              textType === 'green'
                ? classes.greenText
                : textType === 'secondary'
                ? classes.secondary
                : ''
            }
          >
            {formatStringifyNumberToDot(value + '')}
            <span className={classes.unitBold}>&nbsp; &nbsp;BUMP</span>
          </MediumBoldTypography>
        </AdaptiveContainer>
      </InfoBlock>
    );
  },
  (prev, next) => {
    return prev.value === next.value;
  },
);

const AdaptiveContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    marginTop: '1em',
  },
}));
