import { createSlice } from '@reduxjs/toolkit';

const initialState: {
  uiStateManagement: { showNavToolbar: boolean; tried: boolean };
} = {
  uiStateManagement: {
    showNavToolbar: true,
    tried: false,
  },
};
export const uiStateManagementSlice = createSlice({
  name: 'uiState',
  initialState,
  reducers: {
    toggleToolbar: (state) => {
      state.uiStateManagement.showNavToolbar =
        !state.uiStateManagement.showNavToolbar;
    },
    setTried: (state, action) => {
      state.uiStateManagement.tried = action.payload;
    },
  },
});

export const { toggleToolbar, setTried } = uiStateManagementSlice.actions;
export default uiStateManagementSlice.reducer;
