import { Box, styled } from '@material-ui/core';
import React, { FC } from 'react';

import { BrianImg, CloudImg, CloudText, Dialogue } from './styles/Brian';

import BigBrianGif from '../../assets/BumpercoinBrian.gif';
import BrianGif from '../../assets/BumpercoinBrian.gif';
import Cloud from '../../assets/cloud.svg';

export const Brian: FC<{
  text: string;
  style?: any;
  classes?: any;
  alternativePos?: boolean;
  staticPos?: boolean;
  bigBrian?: boolean;
  xsMargin?: string;
}> = (props) => {
  const { style, classes, alternativePos, staticPos, bigBrian, xsMargin } =
    props;

  return (
    <Wrapper
      className={classes}
      style={style}
      alternativePos={alternativePos}
      staticPos={staticPos}
      xsMargin={xsMargin}
    >
      <div style={{ position: 'relative' }}>
        <BrianImg src={bigBrian ? BigBrianGif : BrianGif} />
        <Dialogue bigBrian={bigBrian}>
          <CloudImg src={Cloud} />
          <CloudText>{props.text}</CloudText>
        </Dialogue>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled(Box)(
  ({ theme, alternativePos, staticPos, xsMargin }: any) => ({
    position: staticPos ? 'static' : 'absolute',
    top: '0.25rem',
    right: '6rem',
    [theme.breakpoints.down('sm')]: {
      top: alternativePos ? '3rem' : '-1rem',
    },
    [theme.breakpoints.down('xs')]: {
      right: alternativePos ? '6rem' : '4rem',
      margin: xsMargin || 0,
    },
  }),
);
