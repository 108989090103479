import React from 'react';
import { Redirect } from 'react-router-dom';

import { AppRouteComponent } from './AppRouteComponent';
import { IRoute } from './interfaces';

import { Routes } from '../../config/routes';
import { AddLiquidity } from '../AddLiquidity';
import { ClaimAirdrop } from '../ClaimAirdrop';
import { ClaimDetails } from '../ClaimDetails';
import { ClaimStake } from '../ClaimStake';
import { ClaimVesting } from '../ClaimVesting';
import { ConfirmAutoRenewCooldown } from '../ConfirmAutoRenewCooldown';
import { ConfirmClaimRewardsStaking } from '../ConfirmClaimRewardsStaking';
import { ConfirmEjectStaking } from '../ConfirmEjectStake';
// import { ConfirmRestakeStaking } from '../ConfirmRestakeStaking';
import { ConfirmUnstakeStaking } from '../ConfirmUnstakeStaking';
import { ConnectWallet } from '../ConnectWallet';
import { Dashboard } from '../Dashboard';
import { Epochs } from '../Epochs';
import { GovernancePage } from '../GovernancePage';
import { ProtocolPage } from '../ProtocolPage';
import { Staking } from '../Staking';
// import { StakingDetails } from '../StakingDetails';
import { WalletNotFound } from '../WalletNotFound';
import { WithdrawDetails } from '../WithdrawDetails';

const DefaultRedirect: React.FC = () => (
  <Redirect to={Routes.DepositDashboard}></Redirect>
);

export const appRoutes: IRoute[] = [
  {
    path: Routes.ConnectWallet,
    exact: true,
    component: ConnectWallet,
  },
  {
    path: Routes.WalletNotFound,
    exact: true,
    component: WalletNotFound,
  },
  {
    path: Routes.App,
    exact: false,
    component: AppRouteComponent,
    routes: [
      {
        path: Routes.DepositDashboard,
        exact: true,
        component: Dashboard,
      },
      {
        path: Routes.Epochs,
        exact: true,
        component: Epochs,
      },
      {
        path: `${Routes.AddLiquidity}/:step`,
        component: AddLiquidity,
        exact: false,
      },
      {
        path: `${Routes.Vesting}/:step`,
        component: ClaimVesting,
        exact: false,
      },
      {
        path: Routes.Protocol,
        component: ProtocolPage,
        exact: true,
      },
      {
        path: `${Routes.WithdrawDetails}/:step`,
        component: WithdrawDetails,
        exact: false,
      },
      {
        path: Routes.Governance,
        component: GovernancePage,
        exact: false,
      },
      {
        path: Routes.Staking,
        exact: true,
        component: Staking,
      },
      // {
      //   path: Routes.StakingDetails + '/:step',
      //   exact: true,
      //   component: StakingDetails,
      // },
      {
        path: `${Routes.ClaimStake}/:step`,
        component: ClaimStake,
        exact: false,
      },
      {
        path: `${Routes.ClaimDetails}/:step`,
        component: ClaimDetails,
        exact: false,
      },
      {
        path: `${Routes.ConfirmAutoRenew}/:step`,
        component: ConfirmAutoRenewCooldown,
        exact: false,
      },
      {
        path: `${Routes.ClaimRewardsStaking}/:step`,
        component: ConfirmClaimRewardsStaking,
        exact: false,
      },
      {
        path: `${Routes.Unstake}/:step`,
        component: ConfirmUnstakeStaking,
        exact: false,
      },
      {
        path: `${Routes.EjectStake}/:step`,
        component: ConfirmEjectStaking,
        exact: false,
      },
      // {
      //   path: `${Routes.Restake}/:step`,
      //   component: ConfirmRestakeStaking,
      //   exact: false,
      // },
      {
        path: `${Routes.Airdrop}/:step`,
        component: ClaimAirdrop,
        exact: false,
      },
      {
        path: '*',
        exact: false,
        component: DefaultRedirect,
      },
    ],
  },
  {
    path: '*',
    exact: false,
    component: DefaultRedirect,
  },
];
