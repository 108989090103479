import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import './firebase';

const getEnvironment = () => {
  const hostname = window.location.hostname;
  return hostname.split('.')[0].split('-')[0];
};

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  environment: getEnvironment(),
});

/* Now we can log events like this
import { getAnalytics, logEvent } from '@firebase/analytics';
const analytics = getAnalytics();
logEvent(analytics, 'test_event');
*/

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
