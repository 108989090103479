import { styled } from '@material-ui/core';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  maxWidth: '736px',
  flexDirection: 'column',
  paddingTop: '40px',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '90%',
    paddingTop: theme.spacing(5),
  },
  margin: 'auto',
}));
const EpochsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  maxWidth: '1120px',
  flexDirection: 'column',
  padding: '71px 0',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '90%',
    paddingTop: '60px',
  },
  margin: 'auto',
}));

export { Container, EpochsContainer };
