const currentbumpPriceToolTiptext =
  'This is a current price only. The price goes to four (4) decimal places but is shown in standard currency format here.';
const busdcToolTipText =
  'The bUSDC token is representative of your USDC that is currently residing in the Bumper smart contract.';
const aprToolTipText =
  'APR figures are representations of expected annual yield. These are estimates only and not guaranteed.';
const prospectiveAprToolTipText =
  'APR figures are representations of expected annual yield. These are estimates only and not guaranteed. The advertised APR will naturally tick upwards as the time period to LP shortens. Therefore, please do not compare your actual APR to the advertised rate.';
const unlockDateToolTipText =
  'BUMP tokens are currently locked either until IDO, or as per your Protection or Deposit details.';
const totalDepositToolTipText =
  'Total Deposit includes the total amount being sent to the Bumper smart contract. This includes USDC for yield farming, and USDC for BUMP purchase in either the Private Sale or Pre-Sale.';
const farmedbumpAndBumpPurchaseToolTiptext =
  'This is a current estimate only. The final amount will be determined when the transaction is confirmed on the blockchain. Due to the Bumper Liquidity Provision Program’s structure, initial purchase values operate on a scaling basis and as a result, the final amount of $BUMP tokens received is subject to an algorithmic increase as the value locked in the protocol increases.';
const feeAndLevyToolTipText = 'For all information on Fees and Levies,';
const finalWithdrawalToolTipText =
  'Final Withdrawal Amount includes the total USDC amount being sent from the Bumper smart contract to your wallet. This includes any fee or levy applied to your account. This does not include USDC used to purchase BUMP tokens in either the Private Sale or Pre-Sale.';
const EpochToolTipText =
  'This number reflects the average yield over the past 14 days as calculated from the external liquidity pool’s API data. The exact APY from the external liquidity pool is unknown until the epoch is finished.';
export {
  currentbumpPriceToolTiptext,
  busdcToolTipText,
  aprToolTipText,
  prospectiveAprToolTipText,
  unlockDateToolTipText,
  totalDepositToolTipText,
  farmedbumpAndBumpPurchaseToolTiptext,
  finalWithdrawalToolTipText,
  feeAndLevyToolTipText,
  EpochToolTipText,
};
