import { BigNumber } from '@ethersproject/bignumber';
import { Box, IconButton, styled, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { HistoryButton } from './Buttons';

import BumpCoin from '../../../assets/bump-coin.svg';
import UsdCoin from '../../../assets/usd-coin.svg';
import { isFrontendFunctionalityDisabled } from '../../../config';
import { COLORS } from '../../../config/colors';
import { Routes, subRoutes } from '../../../config/routes';
import { IReward } from '../../../interfaces/IEpoch';
import { theme } from '../../../providers/AppThemeProvider';
import { ClaimedEventArgsType } from '../../../services/airdropService';
import { useAppSelector } from '../../../state/hooks';
import { AirdropMerkleNodeType } from '../../../state/reducers/merkleTree';
import {
  formatUSDC,
  formatBUMP,
  formatStringifyNumberToDot,
} from '../../../utils/helpers';
import { AirdropHistoryModal } from '../../ClaimAirdrop/AirdropHistoryModal';
import { ContentSection } from '../../shared/ContentSection';
import { PixelButton } from '../../shared/PixelButton';
import { CustomHelpIcon } from '../../shared/styles/GlobalSections';
import { Tooltip } from '../../shared/Tooltip';
import { TopSection } from '../../shared/TopSection';

export interface AirdropData {
  available: AirdropMerkleNodeType | null;
  claimedEvents: ClaimedEventArgsType[];
}

interface IClaimTokensProps {
  airdropData: AirdropData | null;
  epochsData: {
    rewards: IReward[];
    result: IReward;
  };
}

type ParseMerkleTreeNode = Array<{
  index: number;
  amountUSDC: BigNumber;
  amountBUMP: BigNumber;
  account: string;
  proof: string[];
}>;

export type ClaimType = 'bump' | 'usdc' | 'both';

export const ClaimTokens: React.FC<IClaimTokensProps> = ({
  airdropData,
  epochsData,
}) => {
  const history = useHistory();
  const utcTime = useAppSelector((state) => state.lockTimestamp.utcTime);

  const [isHistoryModalVisible, toggleHistoryModal] = useState<boolean>(false);
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { rewards, result } = epochsData;

  const availableClaims = (
    (airdropData?.available?.map((v) => {
      return {
        index: v.index,
        amountBUMP: BigNumber.from(v.amountBUMP),
        amountUSDC: BigNumber.from(v.amountUSDC),
        proof: v.proof,
        account: v.account,
      };
    }) as ParseMerkleTreeNode) ?? []
  )
    .filter((v) => !v.amountUSDC.isZero() || !v.amountBUMP.isZero())
    .filter(
      (v) =>
        !airdropData?.claimedEvents.find((val) =>
          BigNumber.from(val.index).eq(v.index),
        ),
    );

  const totalAvailableUSDCToClaim = availableClaims
    .map((v) => v.amountUSDC)
    .reduce((total, cur) => total.add(cur), BigNumber.from('0'));

  const totalAvailableBumpsToClaim = availableClaims
    .map((v) => v.amountBUMP)
    .reduce((total, cur) => total.add(cur), BigNumber.from('0'));

  const onClaimBumpBtnClick = () => {
    const bumpsAvailable = availableClaims.filter((v) => !v.amountBUMP.eq(0));
    onClaimBtnClick(bumpsAvailable, 'bump');
  };

  const onClaimUsdcBtnClick = () => {
    const usdcAvailable = availableClaims.filter((v) => !v.amountUSDC.eq(0));
    onClaimBtnClick(usdcAvailable, 'usdc');
  };

  const onClaimBothBtnClick = () => {
    onClaimBtnClick(availableClaims, 'both');
  };

  const onClaimBtnClick = (values: ParseMerkleTreeNode, type: ClaimType) => {
    history.push(Routes.Airdrop + '/' + subRoutes.Confirm, {
      amountBUMP:
        type !== 'usdc' ? totalAvailableBumpsToClaim : BigNumber.from('0'),
      amountUSDC:
        type !== 'bump' ? totalAvailableUSDCToClaim : BigNumber.from('0'),
      values,
    });
  };

  return (
    <Box>
      {((airdropData?.claimedEvents && airdropData.claimedEvents.length > 0) ||
        rewards.length > 0) && (
        <AirdropHistoryModal
          close={() => toggleHistoryModal(false)}
          visible={isHistoryModalVisible}
          earnings={airdropData?.claimedEvents || []}
          rewards={rewards}
        />
      )}

      <TopSection color="#00FFFF">
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <Header>
            Epoch Earnings&nbsp;
            <HistoryButtonBox>
              <HistoryButton onClick={() => toggleHistoryModal(true)}>
                History
              </HistoryButton>
            </HistoryButtonBox>
          </Header>
        </Box>
      </TopSection>
      <Content>
        <TokenSection>
          {!smallScreen && (
            <>
              <img src={BumpCoin} width="40" height="40" />
              <TokenInfo>
                BUMP:{' '}
                {availableClaims.length > 0
                  ? formatBUMP(totalAvailableBumpsToClaim)
                  : formatStringifyNumberToDot(result.bump_rewards.toString())}
              </TokenInfo>
              <PixelButton
                disabled={
                  isFrontendFunctionalityDisabled(utcTime) ||
                  totalAvailableBumpsToClaim.isZero()
                }
                size="small"
                fullWidth
                color="primary"
                style={{ color: '#ffffff', width: '92px' }}
                onClick={onClaimBumpBtnClick}
              >
                Claim
              </PixelButton>
            </>
          )}
          {smallScreen && (
            <>
              <MobileContent>
                <img src={BumpCoin} width="40" height="40" />
                <TokenInfo>
                  BUMP:{' '}
                  {availableClaims.length > 0
                    ? formatBUMP(totalAvailableBumpsToClaim)
                    : formatStringifyNumberToDot(
                        result.bump_rewards.toString(),
                      )}
                </TokenInfo>
              </MobileContent>
              <PixelButton
                size="small"
                color="primary"
                disabled={
                  isFrontendFunctionalityDisabled(utcTime) ||
                  totalAvailableBumpsToClaim.isZero()
                }
                style={{ color: '#ffffff', width: '92px', marginTop: '.75rem' }}
                onClick={onClaimBumpBtnClick}
              >
                Claim
              </PixelButton>
            </>
          )}
        </TokenSection>
        <VerticalDivider />
        <HorizontalDivider />
        <TokenSection>
          {!smallScreen && (
            <>
              <img src={UsdCoin} width="40" height="40" />
              <TokenInfo>
                USDC:{' '}
                {availableClaims.length > 0
                  ? formatUSDC(totalAvailableUSDCToClaim)
                  : formatStringifyNumberToDot(
                      result.usdc_yield_per_user.toString(),
                    )}
              </TokenInfo>
              <PixelButton
                size="small"
                color="primary"
                disabled={
                  isFrontendFunctionalityDisabled(utcTime) ||
                  totalAvailableUSDCToClaim.isZero()
                }
                style={{ color: '#ffffff', width: '92px' }}
                onClick={onClaimUsdcBtnClick}
              >
                Claim
              </PixelButton>
            </>
          )}
          {smallScreen && (
            <>
              <MobileContent>
                <img src={UsdCoin} width="40" height="40" />
                <TokenInfo>
                  USDC:{' '}
                  {availableClaims.length > 0
                    ? formatUSDC(totalAvailableUSDCToClaim)
                    : formatStringifyNumberToDot(
                        result.usdc_yield_per_user.toString(),
                      )}
                </TokenInfo>
              </MobileContent>
            </>
          )}
          {smallScreen && (
            <PixelButton
              size="small"
              color="primary"
              disabled={
                isFrontendFunctionalityDisabled(utcTime) ||
                totalAvailableUSDCToClaim.isZero()
              }
              style={{ color: '#ffffff', width: '92px', marginTop: '.75rem' }}
              onClick={onClaimUsdcBtnClick}
            >
              Claim
            </PixelButton>
          )}
        </TokenSection>
        <VerticalDivider />
        <HorizontalDivider />
        <TokenSection
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          {!smallScreen && (
            <>
              <PixelButton
                size="small"
                color="primary"
                disabled={
                  isFrontendFunctionalityDisabled(utcTime) ||
                  totalAvailableUSDCToClaim.isZero() ||
                  totalAvailableBumpsToClaim.isZero()
                }
                style={{ color: '#ffffff', width: '141px' }}
                onClick={onClaimBothBtnClick}
              >
                Claim Both
              </PixelButton>
              <Tooltip
                title={
                  'The ‘Claim Both’ function will incur two (2) network transaction fees as you are claiming two different tokens from different smart contracts.'
                }
              >
                <IconButton
                  style={{
                    padding: '0px',
                    marginLeft: '1rem',
                  }}
                >
                  <CustomHelpIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
          {smallScreen && (
            <Box display="flex" flexDirection="row" alignItems="center">
              <PixelButton
                size="small"
                color="primary"
                disabled={
                  isFrontendFunctionalityDisabled(utcTime) ||
                  totalAvailableUSDCToClaim.isZero() ||
                  totalAvailableBumpsToClaim.isZero()
                }
                style={{
                  color: '#ffffff',
                  width: '150px',
                  marginLeft: '.75rem',
                }}
                onClick={onClaimBothBtnClick}
              >
                Claim Both
              </PixelButton>
              <Tooltip
                title={
                  'The ‘Claim Both’ function will incur two (2) network transaction fees as you are claiming two different tokens from different smart contracts.'
                }
              >
                <IconButton
                  style={{
                    padding: '0px',
                    marginLeft: '0.5em',
                  }}
                >
                  <CustomHelpIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </TokenSection>
      </Content>
    </Box>
  );
};

const Header = styled(Typography)(({ theme }) => ({
  fontFamily: 'Roboto Mono',
  fontWeight: 700,
  fontSize: '1.25rem',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '16px',
    lineHeight: '28px',
  },
}));

const HistoryButtonBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    marginLeft: '1em',
  },
}));

const Content = styled(ContentSection)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginLeft: '1.4%',
  padding: '1rem 2.5rem',

  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    height: '376px',
    marginLeft: '1.25rem',
    padding: '1.75rem 2.5rem 2rem',
  },
}));

const TokenSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const TokenInfo = styled(Typography)({
  display: 'flex',
  flexDirection: 'row',
  fontFamily: 'Roboto Mono',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#fff',
  margin: '0 1rem 0 .825rem',
});

const VerticalDivider = styled('div')(({ theme }) => ({
  height: '70px',
  width: '1px',
  backgroundColor: COLORS.BoxOutline,
  marginLeft: '2.5rem',

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const HorizontalDivider = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    height: '1px',
    width: '280px',
    backgroundColor: COLORS.BoxOutline,
  },
}));

const MobileContent = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '280px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
