import { Button, styled } from '@material-ui/core';
import { useWeb3React } from '@web3-react/core';
import React from 'react';
import { useDispatch } from 'react-redux';

import { wrapVestingComponent } from './wrapVestingComponent';

import ThreeDots from '../../../assets/navigation-menu-horizontal.svg';
import RefreshIcon from '../../../assets/refresh.svg';
import { theme } from '../../../providers/AppThemeProvider';
import { getVestingClaimDetails } from '../../../state/actions/merkleTreeActions';
import { useAppSelector } from '../../../state/hooks';
import { createPixelInsetBorder } from '../../../utils/createPixelInsetBorder';
import {
  StyledButton,
  StyledHistoryButton,
} from '../../shared/styles/DashboardStyles';

export const RefreshButton = wrapVestingComponent((props) => {
  const dispatch = useDispatch();
  const { account } = useWeb3React();
  const merkleState = useAppSelector((state) => state.merkleTree.merkle);

  const { refreshClaimEvents } = props;

  const refreshVestingData = () => {
    if (account) dispatch(getVestingClaimDetails(merkleState.vesting));
    if (refreshClaimEvents) refreshClaimEvents();
  };

  return (
    <StyledButton {...props} onClick={refreshVestingData}>
      <img src={RefreshIcon} alt="Refresh vesting" />
    </StyledButton>
  );
});

export const ThreeDotsButton = (props: any) => (
  <StyledThreeDots size="small" color="secondary" {...props}>
    <img src={ThreeDots} />
  </StyledThreeDots>
);

export const HistoryButton = (props: any) => (
  <StyledHistoryButton {...props}>History</StyledHistoryButton>
);

const StyledThreeDots = styled(Button)({
  minHeight: '36px',
  maxHeight: '36px',
  minWidth: '36px',
  color: theme.palette.text.primary,
  textTransform: 'inherit',
  borderRadius: '0px',

  boxShadow: createPixelInsetBorder('#000000', '#00A9BE', '#012A2E'),
  backgroundColor: theme.palette.primary.dark,

  '&.MuiButton-containedPrimary': {
    backgroundColor: theme.palette.primary.light,
    boxShadow: createPixelInsetBorder('#000000', '#E0AD85', '#914F1B'),
    color: theme.palette.common.black,

    '&:hover': {
      background: '#BB621B',
    },
    '&:active': {
      boxShadow: createPixelInsetBorder('#000000', '#914F1B', '#E0AD85'),
    },

    '&.Mui-disabled': {
      '&> span': {
        color: '#99AEB1',
      },
      backgroundColor: '#865E31',
      boxShadow: createPixelInsetBorder('#000000', '#867D68', '#867D68'),
    },
  },
});
