import { createAsyncThunk } from '@reduxjs/toolkit';

import { ILiquidityDetails } from '../../interfaces/ILiquidityDetails';
import { MakerService } from '../../services/makerService';

const makerService = MakerService.getInstance();

export const fetchbUSDDetails = createAsyncThunk<ILiquidityDetails>(
  'fetchbUSDDetails',
  async () => {
    const res = await makerService.getUserUsdcDeposit();
    return {
      bUSD: {
        amount: res,
      },
    };
  },
);
