import { Box, styled, Typography } from '@material-ui/core';

export const CloudImg = styled('img')(({ theme }: any) => ({
  width: '9.25rem',
  position: 'absolute',
  [theme.breakpoints.down('sm')]: {
    width: '8.8rem',
  },
}));

export const CloudText = styled(Typography)(({ theme }) => ({
  fontFamily: theme.font.secondary,
  color: theme.palette.other.Green3,
  fontWeight: 700,
  fontSize: '10px',
  textAlign: 'center',
  position: 'relative',
  zIndex: 10,
  padding: '.5rem 0 .75rem .3rem',
}));

export const BrianImg = styled('img')(({ theme, bigBrian }: any) => ({
  transform: 'scaleX(-1)',
  width: bigBrian ? '4rem' : '3rem',
  [theme.breakpoints.down('sm')]: {
    width: '3rem',
  },
}));

export const Dialogue = styled(Box)(({ theme, bigBrian }: any) => ({
  position: 'absolute',
  top: '-5em',
  left: bigBrian ? '0.5rem' : '0.25rem',
  width: '8.75rem',
  [theme.breakpoints.down('sm')]: {
    left: '0.25rem',
    width: '8.75rem',
  },
}));
