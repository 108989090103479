import { Box, makeStyles, styled } from '@material-ui/core';
import { HelpRounded } from '@material-ui/icons';

import { SmallTypography } from '../Typography';

export const useStyles = makeStyles((theme) => ({
  yieldValue: {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 'bold',
  },
  greenText: {
    color: theme.palette.other.BrightGreen,
  },
  icon: {
    fontSize: '0.75rem',
    marginLeft: '0.4rem',
    width: '1rem',
    height: '1rem',
    position: 'relative',
    top: '0.1rem',
  },
  unit: {
    fontSize: '14px',
    color: theme.palette.other.LabelText,
    fontWeight: 700,
    fontFamily: 'Roboto',
  },
  justifyRight: {
    textAlign: 'right',
  },
  opacity: {
    opacity: 0.8,
    textAlign: 'right',
  },
  spacing: {
    width: '54%',
  },
  firstIcon: {
    left: '8.5px',
  },
  secondIcon: {
    left: '-8.5px',
  },
  container: {
    padding: '9px 12px',
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    width: '24px',
    height: '24px',
    marginRight: theme.spacing(1),
    marginBottom: '-5px',
    borderRadius: '50%',
    position: 'relative',
    top: '-2px',
  },
  info: {
    fontSize: '14px',
    lineHeight: '24px',
    fontFamily: theme.font.secondary,
    fontWeight: 'bold',
    color: theme.palette.other.ExtraLightText,
    cursor: 'default',
  },
  helpIcon: {
    fontSize: '1rem',
    marginLeft: '0.5em',
    color: theme.palette.other.ExtraLightText,
  },
  label: {
    fontFamily: theme.font.secondary,
    fontWeight: 700,
    fontSize: '10px',
    marginBottom: '4px',
    cursor: 'default',
  },
  lineThrough: {
    textDecoration: 'line-through',
  },
  inline: {
    display: 'inline',
  },
  input: {
    '& .MuiOutlinedInput-root.MuiOutlinedInput-adornedStart': {
      paddingLeft: 12,
    },
    '& .MuiInputBase-root': {
      height: 44,
      width: 228,
    },
  },
  unitBold: {
    fontSize: '12px',
    fontWeight: 700,
    fontFamily: 'Roboto',
  },
  secondary: {
    color: theme.palette.other.LabelText,
  },
}));

//---------------------------------------------------------------

export const CustomHelpIcon = styled(HelpRounded)({
  width: '14px',
  height: '14px',
  color: '#ffffff',
});

export const ContainerDetails = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '3fr 4fr',
  gridGap: '16px',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'none',
    justifyContent: 'center',
  },
}));

export const RemainingAmount = styled(SmallTypography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: '1em',
    marginBottom: '-0.5em',
    textAlign: 'start',
  },
}));
