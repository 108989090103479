import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Header } from '../components/common/Header';
import { TransactionProcessingSection } from '../components/common/TransactionProcessingSection';
import { WalletApproveSection } from '../components/common/WalletApproveSection';
import { ConfirmAutoRenew } from '../components/ConfirmStaking/ConfirmAutoRenew';
import { ConfirmUnstake } from '../components/ConfirmStaking/ConfirmUnstake';
import { CoinIcons } from '../components/shared/icons/CoinIcons';
import { FlowContainer } from '../components/shared/styles/FlowPages';
import { Routes, subRoutes } from '../config/routes';
import { useAppDispatch } from '../state/hooks';
import { toggleToolbar } from '../state/reducers/uiStateManagementReducer';

export const ConfirmUnstakeStaking: React.FC = () => {
  const history = useHistory();
  const { step } = useParams<{ step?: string }>();
  const coinIcon = CoinIcons['BUMP'];
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(toggleToolbar());
    return () => {
      dispatch(toggleToolbar());
    };
  }, []);

  const onCancel = () => {
    history.replace(Routes.Staking);
  };

  const showSection = () => {
    switch (step) {
      case subRoutes.Confirm: {
        return <ConfirmUnstake coinIcon={coinIcon} />;
      }
      case subRoutes.Approve: {
        return <WalletApproveSection />;
      }
      case subRoutes.Processing: {
        return <TransactionProcessingSection />;
      }
      default: {
        return <ConfirmAutoRenew coinIcon={coinIcon} />;
      }
    }
  };

  return (
    <>
      <Header onCancel={onCancel} showStepper={false} activeStep={0} />
      <FlowContainer>{showSection()}</FlowContainer>
    </>
  );
};
