import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import timemachine from 'timemachine';

import { IRenderRoutesProps } from './interfaces';
import { RenderRoutes } from './RenderRoutes';

import { LoadingScreen } from '../../components/common/LoadingScreen';
import { MainAppBar } from '../../components/shared/AppBar';
import { bUSDCCoin, USDCoin } from '../../config/coins';
import { Routes } from '../../config/routes';
import { useWeb3Wallet } from '../../hooks/useWallet';
import { EthersServiceProvider } from '../../services/ethersServiceProvider';
import { fetchBumpDetails } from '../../state/actions/bumpDetailsAction';
import { fetchCoinDetails } from '../../state/actions/coinActions';
import { fetchbUSDDetails } from '../../state/actions/liquidityActions';
import { fetchProspectiveApr } from '../../state/actions/prospectiveAprAction';
import { useAppSelector } from '../../state/hooks';
import { incrementUtcTime } from '../../state/reducers/lockTimestampReducer';
import { timeInMilliseconds } from '../../utils/helpers';

timemachine.reset();

export const AppRouteComponent: React.FC<IRenderRoutesProps> = React.memo(
  (props: IRenderRoutesProps) => {
    const dispatch = useDispatch();
    const coin = useAppSelector((state) => state.coin);
    const bUSD = useAppSelector(
      (state) => state.liquidityDetails.liquidityDetails.bUSD.amount,
    );
    const uiStateMagmt = useAppSelector(
      (state) => state.uiStateMgmt.uiStateManagement,
    );
    const prospectiveApr = useAppSelector(
      (state) => state.prospectiveApr.prospectiveAprDetails.prospectiveApr,
    );
    const { active: isConnected, tried } = useWeb3Wallet();
    const {
      replace,
      location: { pathname },
    } = useHistory();
    useEffect(() => {
      if (
        !isConnected &&
        pathname !== '/app/epochs' &&
        pathname !== '/app/staking'
      ) {
        replace(Routes.DepositDashboard);
      }
    }, [isConnected]);
    useEffect(() => {
      if (prospectiveApr === '') {
        dispatch(fetchProspectiveApr({ amount: 10000, token: USDCoin }));
      }

      const interval = setInterval(() => {
        dispatch(incrementUtcTime());
      }, 1000);

      return () => clearInterval(interval);
    }, []);

    useEffect(() => {
      const delayBalancePolling = 7000;
      const delayBlockTimeStampPolling = 2000;

      const isTimeMachineEnabled =
        (process.env.REACT_APP_TIME_MACHINE as string) === 'true';

      const poolingBlockTimeStamp = isTimeMachineEnabled
        ? setInterval(() => {
            (async () => {
              const provider = EthersServiceProvider.getInstance().provider;
              if (provider) {
                const lastBlockTimeStamp = timeInMilliseconds(
                  (await provider.getBlock('latest')).timestamp,
                );
                if (lastBlockTimeStamp > Date.now()) {
                  timemachine.config({
                    timestamp: lastBlockTimeStamp,
                    tick: true,
                  });
                }
              }
            })();
          }, delayBlockTimeStampPolling)
        : null;

      const pollingBalanceInterval = setInterval(() => {
        dispatch(fetchbUSDDetails());
        dispatch(fetchBumpDetails());
        dispatch(fetchCoinDetails([USDCoin, bUSDCCoin]));
      }, delayBalancePolling);
      return () => {
        clearInterval(pollingBalanceInterval);
        if (poolingBlockTimeStamp) {
          timemachine.reset();
          clearInterval(poolingBlockTimeStamp);
        }
      };
    }, []);

    const renderRoutes = () => {
      return (
        <>
          <MainAppBar showNavToolbar={uiStateMagmt.showNavToolbar}></MainAppBar>
          <RenderRoutes {...props} />
        </>
      );
    };

    if (isConnected && tried) {
      if (bUSD === '' && coin.coinDetails[USDCoin.symbol].balance === '') {
        return <LoadingScreen />;
      } else {
        return renderRoutes();
      }
    } else if (!isConnected && tried) {
      return renderRoutes();
    } else {
      return <LoadingScreen />;
    }
  },
);
