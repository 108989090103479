import { createAsyncThunk } from '@reduxjs/toolkit';

import { IEpoch, IReward } from '../../interfaces/IEpoch';
import {
  getEpochData,
  getEpochResultsData,
  getEpochSummaryData,
  EpochUserData,
} from '../../services/firebaseStorageService';

export const getEpochsSummary = createAsyncThunk(
  'getEpochsSummary',
  async (): Promise<IEpoch[]> => {
    try {
      const data = await getEpochSummaryData();
      return data;
    } catch (err) {
      console.error(err);
      return [];
    }
  },
);

export const getEpochsRewards = createAsyncThunk<
  { epochsRewards: IReward[]; result: IReward },
  { address: string | null | undefined; epochsCount: number }
>('getEpochsRewards', async ({ address, epochsCount }) => {
  try {
    const epochsRewards = [];

    for (let i = 1; i <= epochsCount; i++) {
      let epochRewards: EpochUserData[];

      try {
        epochRewards = await getEpochData(i);
      } catch (_) {
        continue;
      }

      const entry = epochRewards.find(
        (entry) => entry.wallet_address === address,
      );

      if (entry) epochsRewards.push({ epoch: i, ...entry });
    }

    const result = await getEpochResultsData();

    const entry: any =
      result && result.find((entry) => entry.wallet_address === address);

    return {
      epochsRewards,
      result: entry
        ? {
            epoch: null,
            usdc_yield_per_user:
              typeof entry.usdc_yield_per_user === 'number'
                ? entry.usdc_yield_per_user
                : entry.usdc_yield_per_user.replace(/,/g, ''),
            bump_rewards:
              typeof entry.bump_rewards === 'number'
                ? entry.bump_rewards
                : entry.bump_rewards.replace(/,/g, ''),
            bump_value_usdc:
              typeof entry.bump_value_usdc === 'number'
                ? entry.bump_value_usdc
                : entry.bump_value_usdc.replace(/,/g, ''),
            total_rewards_usdc_value:
              typeof entry.total_rewards_usdc_value === 'number'
                ? entry.total_rewards_usdc_value
                : entry.total_rewards_usdc_value.replace(/,/g, ''),
          }
        : {
            epoch: null,
            usdc_yield_per_user: 0,
            bump_rewards: 0,
            bump_value_usdc: 0,
            total_rewards_usdc_value: 0,
          },
    };
  } catch (err) {
    console.error(err);
    return {
      epochsRewards: [],
      result: {
        epoch: null,
        usdc_yield_per_user: 0,
        bump_rewards: 0,
        bump_value_usdc: 0,
        total_rewards_usdc_value: 0,
      },
    };
  }
});
