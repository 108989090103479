import { Box } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Header } from '../components/common/Header';
import { Stepper } from '../components/common/Stepper';
import { TransactionProcessingSection } from '../components/common/TransactionProcessingSection';
import { WalletApproveSection } from '../components/common/WalletApproveSection';
import { ConfirmAutoRenew } from '../components/ConfirmStaking/ConfirmAutoRenew';
import { CoinIcons } from '../components/shared/icons/CoinIcons';
import { FlowContainer } from '../components/shared/styles/FlowPages';
import { Routes, subRoutes } from '../config/routes';
import { theme } from '../providers/AppThemeProvider';
import { useAppDispatch } from '../state/hooks';
import { toggleToolbar } from '../state/reducers/uiStateManagementReducer';

export const ConfirmAutoRenewCooldown: React.FC = () => {
  const history = useHistory();
  const { step } = useParams<{ step?: string }>();
  const coinIcon = CoinIcons['BUMP'];
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(toggleToolbar());
    return () => {
      dispatch(toggleToolbar());
    };
  }, []);

  const onCancel = () => {
    history.replace(Routes.Staking);
  };

  const showSection = () => {
    switch (step) {
      case subRoutes.Confirm: {
        return <ConfirmAutoRenew coinIcon={coinIcon} />;
      }
      case subRoutes.Approve: {
        return <WalletApproveSection />;
      }
      case subRoutes.Processing: {
        return <TransactionProcessingSection />;
      }
      default: {
        return <ConfirmAutoRenew coinIcon={coinIcon} />;
      }
    }
  };
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Header
        onCancel={onCancel}
        showStepper={
          !isSmall && (step == subRoutes.Select || step == subRoutes.Confirm)
        }
        activeStep={step == subRoutes.Confirm ? 1 : 0}
      />
      {isSmall && (step == subRoutes.Select || step == subRoutes.Confirm) && (
        <Box mt="30px" mb="16px" display="flex" justifyContent="center">
          <Stepper activeStep={step == subRoutes.Confirm ? 1 : 0} />
        </Box>
      )}
      <FlowContainer>{showSection()}</FlowContainer>
    </>
  );
};
