import { createAsyncThunk } from '@reduxjs/toolkit';
import { ethers } from 'ethers';

import { CONTRACTS } from '../../config/contractAddresses';
import { IBumpDetails } from '../../interfaces/IBumpDetails';
import { MakerService } from '../../services/makerService';
import { convertBumpToDollars } from '../../utils/helpers';

const makerService = MakerService.getInstance();

export const fetchBumpDetails = createAsyncThunk<
  Pick<IBumpDetails, 'bumpBalance' | 'currentBumpPrice' | 'bumpBalanceInUsdc'>
>('fetchBumpDetails', async () => {
  const bumpBalance = await makerService.getBumpBalanceBigNumber();
  const bumpBalanceParsed = ethers.utils.formatUnits(
    bumpBalance,
    CONTRACTS.TOKEN_DETAILS.BUMP.decimal,
  );

  const singleBumpUsdcPrice = await convertBumpToDollars('1');
  const buildPrice = ethers.utils.parseUnits(
    singleBumpUsdcPrice,
    CONTRACTS.TOKEN_DETAILS.USDC.decimal,
  );
  const bumpBalanceInUsdc = bumpBalance.mul(buildPrice).div(10 ** 6);
  const bumpBalanceInUsdcStr = ethers.utils.formatEther(bumpBalanceInUsdc);
  const slicedBalance = bumpBalanceParsed.slice(
    0,
    bumpBalanceParsed.indexOf('.') !== -1
      ? bumpBalanceParsed.indexOf('.') + 5
      : undefined,
  );

  return {
    bumpBalance: slicedBalance,
    bumpBalanceInUsdc: bumpBalanceInUsdcStr,
    currentBumpPrice: singleBumpUsdcPrice,
  };
});

export const fetchBumpPurchaseAllocation = createAsyncThunk(
  'fetchBumpPurchaseAllocation',
  async () => {
    const bumpPurAlloc = await makerService.getBumpPurchaseAllocation();

    return bumpPurAlloc.toString();
  },
);

export const fetchBumpRewardAllocation = createAsyncThunk(
  'fetchBumpRewardAllocation',
  async () => {
    const bumpRewAlloc = await makerService.getBumpRewardAllocation();

    return bumpRewAlloc.toString();
  },
);

export const fetchCurrentTVL = createAsyncThunk('fetchCurrentTVL', async () => {
  const currTVL = await makerService.getCurrentTVL();

  return currTVL.toString();
});

export const fetchMaxPercent = createAsyncThunk('fetchMaxPercent', async () => {
  const maxP = await makerService.getMaxBumpPercent();

  return maxP.toUnsafeFloat();
});
