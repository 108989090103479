import { Box, IconButton, styled } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import { useHistory } from 'react-router-dom';

import { CountDownStaking } from './FixedStakeCard';

import { isFrontendFunctionalityDisabled } from '../../config';
import { Routes, subRoutes } from '../../config/routes';
import { ICoin } from '../../interfaces/ICoin';
import { theme } from '../../providers/AppThemeProvider';
import { RewardType } from '../../services/stakingService';
import { useAppSelector } from '../../state/hooks';
import {
  formatStringifyNumberToDot,
  timeInMilliseconds,
} from '../../utils/helpers';
import { ConfirmAutoRenewState } from '../ConfirmStaking/ConfirmAutoRenew';
import { ConfirmClaimRewardsState } from '../ConfirmStaking/ConfirmClaimRewards';
import { ConfirmEjectStakeState } from '../ConfirmStaking/ConfirmEject';
import { ConfirmUnstakeState } from '../ConfirmStaking/ConfirmUnstake';
import { CoinIcon } from '../shared/CoinIcon';
import { ContentSection } from '../shared/ContentSection';
import { CoinIcons } from '../shared/icons/CoinIcons';
import { PixelButton } from '../shared/PixelButton';
import { CustomHelpIcon } from '../shared/styles/GlobalSections';
import {
  SectionContent,
  SectionFixed,
  SectionInfo,
  SectionName,
  StakedAmount,
  UnstakeButton,
} from '../shared/styles/StakeStyles';
import { Tooltip } from '../shared/Tooltip';
import { TopSection } from '../shared/TopSection';
import { LargeBoldTypography } from '../shared/Typography';

const CoolDownButton = React.memo<CountdownRenderProps>((props) => {
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <>
      {!props.completed && (
        <PixelButton
          size="small"
          color="secondary"
          style={{
            pointerEvents: 'none',
            width: smallScreen ? '120px' : 'unset',
          }}
          disabled
        >
          <Timer>
            {props.formatted.days}
            <span>:</span>
            {props.formatted.hours}
            <span>:</span>
            {props.formatted.minutes}
            <span>:</span>
            {props.formatted.seconds}
          </Timer>
        </PixelButton>
      )}
    </>
  );
});

type FlexibleStakeCardPropsType = {
  token: ICoin;
  amount: number;
  rewards: RewardType;
  unstakeRequestedAt: number;
  endWithdrawWindow: number;
  stakeIndex: number;
  cooldownPeriod: number;
  isEjectEnabled: boolean;
};

export const FlexibleStakeCard: React.FC<FlexibleStakeCardPropsType> = ({
  token,
  amount,
  rewards,
  unstakeRequestedAt,
  endWithdrawWindow,
  cooldownPeriod,
  stakeIndex,
  isEjectEnabled,
}) => {
  const history = useHistory();

  const utcTime = useAppSelector((state) => state.lockTimestamp.utcTime);

  const endRequestTimestamp = timeInMilliseconds(
    unstakeRequestedAt + cooldownPeriod,
  );
  const { rewards: stakingRewards } = rewards;

  const startUnstakeFlow = () => {
    const props: ConfirmUnstakeState = {
      amount,
      rewards: stakingRewards,
      stakeIndex,
    };
    history.push({
      pathname: `${Routes.Unstake}/${subRoutes.Confirm}`,
      state: props,
    });
  };

  const startEjectFlow = () => {
    const props: ConfirmEjectStakeState = {
      stakeIndex,
      amount,
      rewards: stakingRewards,
    };
    history.push({
      pathname: `${Routes.EjectStake}/${subRoutes.Confirm}`,
      state: props,
    });
  };

  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
      <TopSection color={`${theme.palette.other.Turquoise}`}>
        <Box display="flex" alignItems="center">
          <CoinIcon src={CoinIcons[token.symbol]} />
          {!smallScreen && (
            <LargeBoldTypography secondaryFont>
              {token.symbol}
            </LargeBoldTypography>
          )}
        </Box>
        <Box display="flex" alignItems="center" mt={1}>
          {!smallScreen &&
            Date.now() > endRequestTimestamp &&
            Date.now() < endRequestTimestamp + endWithdrawWindow * 1000 && (
              <Box mr={3} display="flex" alignItems="center">
                <Tooltip title={'Time in which you can unstake your BUMP'}>
                  <IconButton
                    style={{
                      padding: '0px',
                      marginRight: '0.375rem',
                    }}
                  >
                    <CustomHelpIcon />
                  </IconButton>
                </Tooltip>
                <Countdown
                  date={endRequestTimestamp + endWithdrawWindow * 1000}
                  renderer={(properties) => (
                    <CountDownStaking {...properties} />
                  )}
                />
              </Box>
            )}
          <PixelButton
            size="small"
            color="secondary"
            style={{
              marginRight: smallScreen ? '10px' : '18px',
              width: smallScreen ? '120px' : 'unset',
            }}
            disabled={
              Date.now() < endRequestTimestamp + endWithdrawWindow * 1000
            }
            onClick={() => {
              const props: ConfirmAutoRenewState = {
                autorenew: false,
                stakeIndex,
              };
              history.push({
                pathname: `${Routes.ConfirmAutoRenew}/${subRoutes.Confirm}`,
                state: props,
              });
            }}
          >
            Cooldown
          </PixelButton>
          <Countdown
            date={endRequestTimestamp}
            renderer={(properties) => <CoolDownButton {...properties} />}
          />
          {(Date.now() > endRequestTimestamp + endWithdrawWindow * 1000 ||
            (Date.now() > endRequestTimestamp &&
              Date.now() < endRequestTimestamp + endWithdrawWindow * 1000)) && (
            <UnstakeButton
              disabled={
                Date.now() > endRequestTimestamp + endWithdrawWindow * 1000
              }
              onClick={startUnstakeFlow}
            >
              Unstake
            </UnstakeButton>
          )}
          <PixelButton
            onClick={startEjectFlow}
            disabled={!isEjectEnabled}
            size="small"
            color="secondary"
            style={{
              marginLeft: smallScreen ? 0 : '20px',
              marginTop: smallScreen ? '0.5em' : 0,
            }}
          >
            Eject
          </PixelButton>
        </Box>
      </TopSection>
      <ContentSection>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <SectionFixed>
            {smallScreen && Date.now() < endRequestTimestamp && (
              <SectionInfo sx={{ paddingTop: '5px' }}>
                <Box display="flex" alignItems="center">
                  <Tooltip
                    title={
                      '‘Earned’ shows your total BUMP staking rewards for your current term.'
                    }
                  >
                    <IconButton
                      style={{
                        padding: '0px',
                        marginRight: '0.2em',
                        marginTop: '-2px',
                      }}
                    >
                      <CustomHelpIcon />
                    </IconButton>
                  </Tooltip>
                  <Countdown
                    date={endRequestTimestamp}
                    renderer={(properties) => (
                      <CountDownStaking {...properties} />
                    )}
                  />
                </Box>
              </SectionInfo>
            )}
            <SectionInfo
              sx={{
                paddingTop: smallScreen ? '24px' : '5px',
              }}
            >
              <Box display="flex" flexDirection="row">
                <SectionName>Staked:</SectionName>
              </Box>
              <SectionContent>
                <StakedAmount>{`${formatStringifyNumberToDot(
                  amount + '',
                )} `}</StakedAmount>
                <StakedAmount
                  style={{
                    lineHeight: '16px',
                    fontSize: '12px',
                    padding: '0.4em 0 0 0.4em',
                  }}
                >
                  BUMP
                </StakedAmount>
              </SectionContent>
            </SectionInfo>
            <SectionInfo>
              <Box
                display="flex"
                flexDirection="column"
                sx={{
                  alignItems: smallScreen ? 'center' : 'start',
                }}
              >
                <SectionName>Period:</SectionName>
                <Box
                  display="flex"
                  flexDirection="row"
                  sx={{ paddingTop: '10px' }}
                >
                  <SectionName
                    style={{
                      color: '#8CA6AE',
                    }}
                  >
                    Flexible
                  </SectionName>
                </Box>
              </Box>
            </SectionInfo>
            <SectionInfo
              style={{
                flexDirection: 'row',
                flexWrap: smallScreen ? 'wrap' : 'nowrap',
                alignItems: 'center',
                justifyContent: smallScreen ? 'center' : 'unset',
                paddingRight: 0,
                borderRight: 0,
                width: 275,
              }}
            >
              <Box
                sx={{
                  minWidth: 178,
                  width: smallScreen ? '100%' : 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: smallScreen ? 'center' : 'unset',
                }}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  sx={{
                    maxWidth: '220px',
                  }}
                >
                  <SectionName>Earned</SectionName>
                  <Tooltip
                    title={
                      '‘Earned’ shows your total BUMP staking rewards for your current term.'
                    }
                  >
                    <IconButton
                      style={{
                        padding: '0px',
                        marginLeft: '0.375rem',
                      }}
                    >
                      <CustomHelpIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box display="flex" sx={{ paddingTop: '.5em' }}>
                  <StakedAmount
                    style={{
                      fontStyle: 'bold',
                      color: '#ffffff',
                    }}
                  >
                    {`${formatStringifyNumberToDot(stakingRewards + '')} `}
                  </StakedAmount>
                  <StakedAmount
                    style={{
                      lineHeight: '16px',
                      fontSize: '12px',
                      color: '#ffffff',
                      padding: '0.4em 0 0 0.4em',
                    }}
                  >
                    BUMP
                  </StakedAmount>
                </Box>
              </Box>
              <PixelButton
                size="small"
                color="primary"
                fullWidth
                style={{
                  color: '#ffffff',
                  opacity: rewards.rewards === 0 ? 0.6 : 1,
                  minWidth: 90,
                  marginTop: smallScreen ? '1rem' : '0',
                  maxWidth: smallScreen ? '101px' : 'unset',
                }}
                disabled={
                  isFrontendFunctionalityDisabled(utcTime) ||
                  stakingRewards < 0.1
                }
                onClick={() => {
                  const props: ConfirmClaimRewardsState = {
                    stakeIndex,
                    rewardsAmount: stakingRewards,
                  };
                  history.push({
                    pathname: `${Routes.ClaimRewardsStaking}/${subRoutes.Confirm}`,
                    state: props,
                  });
                }}
              >
                Claim
              </PixelButton>
            </SectionInfo>
          </SectionFixed>
        </Box>
      </ContentSection>
    </Box>
  );
};

const Timer = styled('span')({
  fontFamily: 'Roboto Mono',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
});
