import {
  Box,
  makeStyles,
  styled,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import React from 'react';

import { COLORS } from '../../config/colors';
import { theme } from '../../providers/AppThemeProvider';
import { StakeOptionType } from '../../services/stakingService';
import { createPixelInsetBorder } from '../../utils/createPixelInsetBorder';
import { BrianBottomPos } from '../shared/BrianBottomPos';
import { PixelButton } from '../shared/PixelButton';
import { ContainerStake } from '../shared/styles/StakeStyles';
import {
  ExtraSmallBoldRoboto,
  LargeBoldRoboto,
  SmallRoboto,
} from '../shared/styles/TypographyRoboto';
import { SwitchComponent } from '../Staking/SwitchComponent';

interface IStakePeriodProps {
  title: string;
  text: string;
  stakeOptions: StakeOptionType[];
  activeOptionIndex: number;
  setActiveOptionIndex: React.Dispatch<React.SetStateAction<number>>;
  autoRenew: boolean;
  setAutoRenew: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
}

export const FixedStakePeriod: React.FC<IStakePeriodProps> = ({
  title,
  text,
  stakeOptions,
  activeOptionIndex,
  setActiveOptionIndex,
  autoRenew,
  setAutoRenew,
  disabled,
}) => {
  const classes = styles();
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Box>
      <ContainerStake>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems={'center'}
          sx={{ marginRight: '-0.1em' }}
        >
          <StyledTitle>{title}</StyledTitle>
          <Box>
            <SwitchComponent
              value={autoRenew}
              setValue={setAutoRenew}
              tooltip="Select ‘Auto-renew’ to continue your staking beyond your current fixed term. If selected, at the end of your current fixed term, you will enter another term for the same fixed period as before. This option will incur a network transaction fee."
              text="Auto-renew"
              disabled={disabled}
            />
          </Box>
        </Box>
        <StyledText>{text}</StyledText>
        <StakingButtons>
          {stakeOptions.map(({ periodInDays, multiplier }, index) => {
            const activeStyle = {
              backgroundColor: theme.palette.other.BrightGreen,
              boxShadow: createPixelInsetBorder('black', '#9CE1A9', '#20772E'),
              color: 'black',
              '&:hover': {
                background: theme.palette.other.BrightGreen,
              },
            };
            const currentStyle = {
              padding: '12px 6px',
              ...(activeOptionIndex === index + 1 ? activeStyle : {}),
            };

            return (
              <StakingButton
                display="flex"
                flexDirection="column"
                mt={3.5}
                key={periodInDays}
              >
                <PixelButton
                  style={currentStyle}
                  color="secondary"
                  fullWidth
                  onClick={() => setActiveOptionIndex(index + 1)}
                  disabled={disabled}
                >
                  <ExtraSmallBoldRoboto>
                    {periodInDays} days
                  </ExtraSmallBoldRoboto>
                </PixelButton>
                <StyledStakeRate>{multiplier / 100}x</StyledStakeRate>
              </StakingButton>
            );
          })}
          {!smallScreen && !disabled && (
            <BrianContainer>
              <BrianBottomPos
                classes={classes.brian}
                text="Pssst! Got some quality BUMP tokens. You interested?"
              />
            </BrianContainer>
          )}
        </StakingButtons>
      </ContainerStake>
    </Box>
  );
};
const BrianContainer = styled(Box)(({ theme }) => ({
  marginTop: '3em',
  marginLeft: '1.5rem',
  marginBottom: '-1em',

  [theme.breakpoints.down('xs')]: {
    marginLeft: '10em',
    marginTop: '-2em',
    marginBottom: '-4em',
  },
}));

const StyledTitle = styled(LargeBoldRoboto)(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    fontSize: '16px',
    fontWeight: 900,
  },
}));

const StyledText = styled(SmallRoboto)({
  marginTop: '0.5em',
});

const StyledStakeRate = styled(Typography)({
  fontFamily: 'Roboto',
  width: '72px',
  fontWeight: 400,
  fontSize: '10px',
  lineHeight: '16px',
  color: COLORS.Green5,
  marginTop: '1em',
  textAlign: 'center',
});

const StakingButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    justifyContent: 'space-between',
  },
}));
const StakingButton = styled(Box)(() => ({
  '&:not(:last-child)': {
    marginRight: '1.5rem',
  },
}));

const styles = makeStyles((theme) => ({
  brian: {
    right: '5rem',
    [theme.breakpoints.down('sm')]: {
      right: '3rem !important',
      top: '-1em',
    },
  },
}));
