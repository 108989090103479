// Returns a string that should be set as the value for box-shadow property in your CSS.
export const createPixelInsetBorder = (
  borderColor: string,
  insetTLColor: string,
  insetBRColor: string,
  borderWidth?: number,
): string => {
  const width = borderWidth ? borderWidth : 2;

  return `${width}px 0px 0px 0px ${insetBRColor}, 
      0px ${width}px 0px 0px ${insetBRColor}, 
      -${width}px 0px 0px 0px ${insetTLColor}, 
      0px -${width}px 0px 0px ${insetTLColor}, 
      -${2 * width}px 0px 0px 0px ${borderColor}, 
      ${2 * width}px 0px 0px 0px ${borderColor}, 
      0px ${2 * width}px 0px 0px ${borderColor}, 
      0px -${2 * width}px 0px 0px ${borderColor}, 
      0px 0px 0px ${width}px ${borderColor}`;
};

export const createPixelInsetBorder2 = (
  borderColor: string,
  insetTLColor: string,
  insetBRColor: string,
  borderWidth?: number,
): string => {
  const width = borderWidth ? borderWidth : 2;

  return `${width}px 0px 0px 0px ${insetBRColor}, 
      0px ${width}px 0px 0px ${insetBRColor}, 
      -${width}px 0px 0px 0px ${insetTLColor}, 
      0px -${width}px 0px 0px ${insetTLColor}, 
      -${2 * width}px 0px 0px 0px ${borderColor}, 
      ${2 * width}px 0px 0px 0px ${borderColor}, 
      0px ${2 * width}px 0px 0px ${borderColor}, 
      0px -${2 * width}px 0px 0px ${borderColor}, 
      -${3 * width}px 0px 0px 0px ${borderColor}, 
      ${3 * width}px 0px 0px 0px ${borderColor}, 
      0px ${3 * width}px 0px 0px ${borderColor}, 
      0px -${3 * width}px 0px 0px ${borderColor},
      0px 0px 0px ${width}px ${borderColor}`;
};
