import React from 'react';

import { WalletApproveScreen } from '../common/WalletApproveScreen';
import {
  ApproveContainer,
  ApproveTextContainer,
} from '../shared/styles/ApproveSection';
import { SmallTypography } from '../shared/Typography';

export const WalletApproveSection: React.FC = () => {
  return (
    <>
      <WalletApproveScreen
        headingFirstText="Confirm transactions in your wallet"
        subtitleSecondText={
          <React.Fragment>
            <ApproveContainer>
              <ApproveTextContainer>
                <SmallTypography style={{ fontWeight: 700 }}>
                  *** IMPORTANT ***
                </SmallTypography>
                To complete your deposit, you need to do two (2) steps to
                confirm your transaction.
                <br />
                <br />
                <SmallTypography>
                  <strong>Step 1</strong>: Signature Request - no gas fee
                  required
                </SmallTypography>
                <SmallTypography>
                  <strong>Step 2</strong>: Transfer Funds - gas fee required
                </SmallTypography>
                <br />
                <SmallTypography style={{ fontWeight: 700 }}>
                  GAS FEES
                </SmallTypography>
                Setting a higher or {"'Fast'"} gas fee will complete your
                transaction quickly.
              </ApproveTextContainer>
            </ApproveContainer>
          </React.Fragment>
        }
      />
    </>
  );
};
