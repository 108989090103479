import { Box, styled } from '@material-ui/core';
import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';

import BumperErrorLogo from '../../../assets/bumperErrorLogo.svg';
import { Routes } from '../../../config/routes';
import { LargeBoldTypography, MediumTypography } from '../../shared/Typography';

interface IGeneralErrorScreenlocation {
  title: string;
  description: string;
}
export const GeneralErrorScreen: React.FC = () => {
  const location = useLocation<IGeneralErrorScreenlocation>();
  if (!location.state) {
    return <Redirect to={Routes.DepositDashboard} />;
  }
  const { title, description } = location.state;

  return (
    <Container display="flex" flexDirection="column" alignItems="center">
      <img src={BumperErrorLogo} />
      <ErrorTitle>Title-{title}</ErrorTitle>
      <ErrorDescription>{description}</ErrorDescription>
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  maxWidth: '554px',
  height: '194px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: `-${theme.spacing(9)}px`,
  paddingTop: '25vh',
}));

const ErrorTitle = styled(LargeBoldTypography)(() => ({
  marginTop: '40px',
  textAlign: 'center',
}));

const ErrorDescription = styled(MediumTypography)(() => ({
  padding: '1px 5px',
  textAlign: 'center',
  marginTop: '24px',
}));
