import * as Sentry from '@sentry/react';
import { signERC2612Permit } from 'eth-permit';
import { RSV } from 'eth-permit/dist/rpc';
import { BigNumber, BigNumberish, ContractTransaction, ethers } from 'ethers';

import { EthersServiceProvider } from './ethersServiceProvider';

import { chainID, CONTRACTS, TokenDetails } from '../config/contractAddresses';
import { VestingMerkleType } from '../state/reducers/merkleTree';
import { ClaimedEventsType } from '../state/reducers/vestingReducer';
import { Vesting, Vesting__factory } from '../types';

export type VestingInfo = {
  start: number;
  end: number;
  cliff: number;
  vestingPerSec: BigNumberish;
  totalAmount: BigNumberish;
  onStartAmount: BigNumberish;
  previousAmount: BigNumberish;
  claimedV1: BigNumberish;
};
export class VestingService {
  private static instance: VestingService;
  private ethersServiceProvider: EthersServiceProvider;
  private constructor() {
    this.ethersServiceProvider = EthersServiceProvider.getInstance();
  }
  public static getInstance(): VestingService {
    if (!VestingService.instance) {
      VestingService.instance = new VestingService();
    }
    return VestingService.instance;
  }
  private getVesting(): Vesting {
    return Vesting__factory.connect(
      CONTRACTS.CONTRACT_ADDRESS.Vesting.address,
      this.ethersServiceProvider.provider?.getSigner(
        0,
      ) as ethers.providers.JsonRpcSigner,
    );
  }
  private async tryPermit(
    tokenDetails: TokenDetails,
    userAddress: string,
    transferAmount: BigNumber,
  ): Promise<({ deadline: number | string } & RSV) | undefined> {
    // @todo: Need to update Bump permit arguments
    const bumpDomain2 = {
      name: 'BUMP',
      version: '1',
      chainId: chainID,
      verifyingContract: tokenDetails.address,
    };
    const deadline = Math.floor(new Date().getTime() / 1000) + 1000000;
    let permit;
    try {
      permit = await signERC2612Permit(
        this.ethersServiceProvider.provider,
        bumpDomain2,
        userAddress,
        CONTRACTS.CONTRACT_ADDRESS.Vesting.address,
        transferAmount.toString(),
        deadline,
      );
    } catch (err) {
      Sentry.captureException(err);
      return undefined;
    }
    if (permit.v !== 27 && permit.v !== 28) {
      return undefined;
    }
    return permit;
  }
  private async getAccount(address?: string): Promise<string> {
    return address ?? (await this.ethersServiceProvider.getUserAddress());
  }
  public async claimedEvents(): Promise<ClaimedEventsType> {
    return await this.getVesting().queryFilter(
      this.getVesting().filters.Claimed(
        null,
        await this.getAccount(),
        null,
        null,
      ),
    );
  }
  public async claim(
    vestingData: VestingMerkleType,
  ): Promise<ContractTransaction> {
    if (!vestingData) throw new Error('Vesting data is null');
    return await this.getVesting().claim(
      vestingData.account,
      vestingData.info,
      vestingData.proof,
    );
  }

  public async claimToStakeWithPermit(
    vestingData: VestingMerkleType | null,
    stakingOption: number,
    autorenew: boolean,
    walletAmount: BigNumber,
    claimAmount: BigNumber,
    stakingAmount: BigNumber,
  ): Promise<ContractTransaction> {
    if (!vestingData) throw new Error('Vesting data is null');

    let permit: ({ deadline: string | number } & RSV) | undefined = undefined;

    if (!walletAmount.isZero()) {
      permit = await this.tryPermit(
        CONTRACTS.TOKEN_DETAILS.BUMP,
        await this.getAccount(),
        walletAmount,
      );
    }

    if (permit) {
      return await this.getVesting().stakeOwnAndVestedTokensWithPermit(
        vestingData.account,
        vestingData.info,
        stakingOption,
        autorenew,
        walletAmount,
        vestingData.proof,
        permit.deadline,
        permit.v,
        permit.r,
        permit.s,
      );
    } else if (!walletAmount.isZero()) {
      if (
        (
          await this.ethersServiceProvider.approveAmount(
            vestingData.account,
            this.getVesting().address,
            CONTRACTS.TOKEN_DETAILS.BUMP.address,
          )
        ).lt(walletAmount)
      ) {
        const approveTx = await this.ethersServiceProvider.approveTokenAmount(
          walletAmount.toString(),
          this.getVesting().address,
          CONTRACTS.TOKEN_DETAILS.BUMP.address,
        );
        await approveTx.wait();
      }

      return await this.getVesting().stakeOwnAndVestedTokensWithApprove(
        vestingData.account,
        vestingData.info,
        stakingOption,
        autorenew,
        walletAmount,
        vestingData.proof,
      );
    } else {
      return await this.getVesting().stakeVestedTokens(
        vestingData.account,
        vestingData.info,
        stakingOption,
        autorenew,
        claimAmount,
        stakingAmount,
        vestingData.proof,
      );
    }
  }

  public async getClaimableAmount(
    vestingData: VestingMerkleType,
  ): Promise<BigNumber> {
    if (!vestingData) throw new Error('Vesting data is null');
    return await this.getVesting().getClaimableAmountFor(
      vestingData.account,
      vestingData.info,
    );
  }
  public async totalLockedOf(
    vestingData: VestingMerkleType,
  ): Promise<BigNumber> {
    if (!vestingData) throw new Error('Vesting data is null');
    return await this.getVesting().totalLockedOf(vestingData.info);
  }

  public async totalClaimedOf(address: string): Promise<BigNumber> {
    return await this.getVesting().recipientClaimed(address);
  }

  public async claimedFromNewVersionContract(
    address?: string,
  ): Promise<BigNumber> {
    return await this.getVesting().recipientClaimed(
      await this.getAccount(address),
    );
  }
}
