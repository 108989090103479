import { Box, IconButton, styled, Toolbar } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { MobileNavDialog } from './MobileNavDialog';

import pixelBumperIcon from '../../../assets/bumper-pixel-icon.svg';
import { Routes } from '../../../config/routes';
import { useAppSelector } from '../../../state/hooks';
import { timeInMilliseconds } from '../../../utils/helpers';
import Typography from '../Typography';

const pathNames = {
  [Routes.DepositDashboard]: 'Dashboard',
  [Routes.Staking]: 'Staking',
  // [Routes.Protocol]: 'Protocol',
  [Routes.Governance]: 'Governance',
  [Routes.AddLiquidity]: 'Deposit',
  [Routes.SwapPage]: 'Buy',
  [Routes.WithdrawLiquidity]: 'Withdraw',
};

export const MobileAppBar: React.FC = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const location = useLocation();
  const unlockTime = useAppSelector(
    (state) => state.lockTimestamp.lockTimestampDetails.SwapUnlockTime,
  );
  return (
    <>
      <MobileMainToolbar>
        <Box
          width="100%"
          display="grid"
          alignItems="center"
          gridTemplateColumns="min-content 1fr 15%"
          gridGap="25px"
        >
          <img
            style={{ width: '22px', height: '22px' }}
            src={pixelBumperIcon}
          />
          <Box display="grid" alignItems="center">
            <Typography variant="h4" secondaryFont>
              {pathNames[location.pathname] ? pathNames[location.pathname] : ''}
            </Typography>
          </Box>
          <Box justifySelf="end">
            <IconButton
              style={{ padding: '0px' }}
              aria-label="Menu"
              onClick={() => setDialogOpen(true)}
              disabled={timeInMilliseconds(unlockTime) > Date.now()}
            >
              <Menu style={{ color: 'white' }} />
            </IconButton>
          </Box>
        </Box>
      </MobileMainToolbar>
      <MobileNavDialog
        isDialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
      />
    </>
  );
};

const MobileMainToolbar = styled(Toolbar)(({ theme }) => ({
  background: theme.palette.other.Green3,
  minHeight: '72px',
  paddingLeft: '24px',
  paddingRight: '24px',
}));
