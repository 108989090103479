import { styled } from '@material-ui/core';

import jupiterBg from '../../assets/jupiterBg.png';

export const JupiterBackground = styled('div')(() => ({
  backgroundImage: `url(${jupiterBg})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  backgroundAttachment: 'fixed',
  backgroundRepeat: 'no-repeat',
  minHeight: '100vh',
  maxWidth: '100vw',
}));
