import { Box, styled } from '@material-ui/core';
import React from 'react';

import { PixelButton } from './PixelButton';
import { LargeBoldTypography, MediumTypography } from './Typography';

import BumperLogoVerticalSvg from '../../assets/bumper-logo-vertical.svg';

export interface IErrorStateProps {
  errorMessage: string;
  errorDescription: string;
  buttonText: string;
  hideButton: boolean;
  buttonAction: () => void;
}

export const ErrorState: React.FC<IErrorStateProps> = (props) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100vw"
      height="100vh"
      margin="auto"
    >
      <BasicContainer>
        <BumperLogoVertical src={BumperLogoVerticalSvg} alt="Bumper logo" />
        <Box mb={3}>
          <LargeBoldTypography>{props.errorMessage}</LargeBoldTypography>
        </Box>
        <Box textAlign="center" mb={3}>
          <MediumTypography color="secondary">
            {props.errorDescription}
          </MediumTypography>
        </Box>
        {!props.hideButton ? (
          <Box
            width={{ xs: '100%', md: '50%' }}
            display="flex"
            justifyContent="center"
          >
            <PixelButton color="primary" onClick={props.buttonAction}>
              {props.buttonText}
            </PixelButton>
          </Box>
        ) : (
          <></>
        )}
      </BasicContainer>
    </Box>
  );
};

const BumperLogoVertical = styled('img')(({ theme }) => ({
  marginBottom: '40px',
  width: '217px',
  height: '82px',
  [theme.breakpoints.down('sm')]: {
    width: '160px',
    height: '61px',
  },
}));

const BasicContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '544px',
  height: '50vh',
  margin: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '90vw',
  },
}));
