import { styled, Typography } from '@material-ui/core';
import React from 'react';

// TODO: Replace # with actual links
export const Meta: React.FC = () => {
  return (
    <Wrapper>
      <Block>
        <p style={{ fontFamily: 'Roboto Mono' }}>
          By unlocking your wallet you agree to our{' '}
          <Link
            href="https://www.bumper.fi/terms-and-conditions"
            target="_blank"
          >
            Terms of Service
          </Link>
          ,{' '}
          <Link href="https://www.bumper.fi/privacy-policy" target="_blank">
            Privacy
          </Link>{' '}
          and{' '}
          <Link href="https://www.bumper.fi/privacy-policy" target="_blank">
            Cookie Policy
          </Link>
          .
        </p>
      </Block>
      <Block>
        <p style={{ fontFamily: 'Roboto Mono' }}>
          Need help connecting a wallet? Check out our{' '}
          <Link
            href="https://www.bumper.fi/learn/connecting-your-wallet"
            target="_blank"
          >
            guide
          </Link>
          .{' '}
        </p>
      </Block>
      <Disclaimer>
        <strong>Disclaimer:</strong> Wallets are provided by External Providers
        and by selecting you agree to the Terms of those Providers. Your access
        to the wallet might be reliant on the External Provider being
        operational. Bumper accepts no responsibility for their services.
      </Disclaimer>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  maxWidth: '85%',
  marginTop: '2.5rem',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    paddingLeft: '2em',
  },
}));
const Block = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h6.fontSize,
  fontWeight: theme.typography.h4.fontWeight,
  borderBottom: '1px solid #c4c4c4',
  paddingBottom: '2rem',
  marginBottom: '2rem',
}));
const Disclaimer = styled(Block)(({ theme }) => ({
  borderBottom: 'none',
  padding: 0,
  margin: 0,
  opacity: 0.8,
  fontWeight: theme.typography.body1.fontWeight,
  textAlign: 'justify',
}));
const Link = styled('a')(({ theme }) => ({
  color: theme.palette.other.BrightGreen,
  textDecoration: 'none',
}));
