import { Switch, styled, Box, Typography, IconButton } from '@material-ui/core';
import React from 'react';

import { COLORS } from '../../config/colors';
import { CustomHelpIcon } from '../shared/styles/GlobalSections';
import { Tooltip } from '../shared/Tooltip';

type AutoRenewPropsType = {
  value: boolean;
  setValue?: React.Dispatch<React.SetStateAction<boolean>>;
  confirmAutoRenew?: () => void;
  text: string;
  tooltip: NonNullable<React.ReactNode>;
  disabled?: boolean;
};

export const SwitchComponent: React.FC<AutoRenewPropsType> = ({
  value,
  setValue,
  confirmAutoRenew,
  text,
  tooltip,
  disabled,
}) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <AutoRenew>{text}</AutoRenew>
      <Tooltip title={tooltip}>
        <IconButton
          style={{
            padding: '0px',
            marginLeft: '0.2em',
            marginRight: '0.5em',
          }}
        >
          <CustomHelpIcon />
        </IconButton>
      </Tooltip>
      <SwitchBackground
        checked={value}
        onChange={() => {
          if (!disabled) {
            if (setValue) setValue(!value);
            if (confirmAutoRenew) confirmAutoRenew();
          }
        }}
        disabled={disabled}
      />
    </Box>
  );
};

const AutoRenew = styled(Typography)({
  fontFamily: 'Roboto',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  color: 'rgba(255, 255, 255, 0.8)',
});

const SwitchBackground = styled(Switch)(({ disabled }: any) => ({
  width: '43px',
  height: '32px',
  padding: 7,
  transform: 'scale(1.3)',
  opacity: disabled ? 0.5 : 1,
  cursor: disabled ? 'not-allowed' : 'pointer',
  '& .MuiSwitch-switchBase': {
    marginTop: 11,
    padding: 0,
    transform: 'translateX(12px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: COLORS.Primary2,
        border: '2px solid #012A2E',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    background: '#FAFAFA;',
    border: '2px solid #285D64',
    width: '10px',
    height: '10px',
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#00353C',
    border: '2px solid #061C1F',
    borderRadius: 20 / 2,
  },
}));
