import { Box, styled } from '@material-ui/core';

import { COLORS } from '../../../config/colors';

export const ApproveContainer = styled(Box)(({ theme }) => ({
  backgroundColor: COLORS.Green3,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  border: '2px solid #4A7D83',
  width: '27vw',
  padding: '1rem 0',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const ApproveTextContainer = styled(Box)({
  backgroundColor: COLORS.Green3,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '80%',
  opacity: 0.8,
});
