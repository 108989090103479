import { useQuery } from '@apollo/client';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { useWeb3React } from '@web3-react/core';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';

import OrangeArrowLeft from '../assets/orange-arrow-left.svg';
import { EpochsContainer } from '../components/common/MainContainer';
import { EpochInfo } from '../components/DepositDashboard/EpochInfo';
import { EpochSection } from '../components/DepositDashboard/PreSaleSection';
import {
  AirdropData,
  ClaimTokens,
} from '../components/DepositDashboard/Vesting/ClaimTokens';
import { IS_AIRDROP_ENABLED } from '../config/airdrop';
import { COLORS } from '../config/colors';
import { WalletContext } from '../config/walletContext';
import { IEpoch } from '../interfaces/IEpoch';
import { theme } from '../providers/AppThemeProvider';
import { client } from '../providers/graphql';
import { GetAirdropClaimsByAddress } from '../providers/graphql/__generated__/GetAirdropClaimsByAddress';
import { GET_AIRDROP_CLAIMS_BY_ADDRESS } from '../providers/graphql/queries';
import { ClaimedEventArgsType } from '../services/airdropService';
import { useAppSelector } from '../state/hooks';

interface PrevEpochsMobileProps {
  current: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  epochs: IEpoch[];
}

const PrevEpochsMobile = (props: PrevEpochsMobileProps) => {
  const { current, setCurrent, epochs } = props;

  const {
    apr,
    eligible_pool,
    bump_reward_allocation,
    epoch,
    total_usdc_reward_value,
  } = epochs[current - 1];
  return (
    <Box width="100%">
      <EpochInfo
        type="previous"
        epochNumber={epoch}
        eligiblePool={eligible_pool}
        rewardAllocation={bump_reward_allocation}
        yieldUSDC={'TBC'}
        totalUSDCRewardValue={total_usdc_reward_value}
        apr={apr}
      />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={2}
      >
        <SliderButton
          onClick={() =>
            setCurrent(current - 1 === 0 ? epochs.length : current - 1)
          }
        >
          <img
            src={OrangeArrowLeft}
            alt="Go left"
            style={{ position: 'relative', left: -1 }}
          />
        </SliderButton>
        <SliderInfo>
          <strong>{current}</strong> from <span>{epochs.length}</span>
        </SliderInfo>
        <SliderButton
          style={{ transform: 'rotate(180deg)' }}
          onClick={() =>
            setCurrent(current + 1 > epochs.length ? 1 : current + 1)
          }
        >
          <img
            src={OrangeArrowLeft}
            alt="Go right"
            style={{ position: 'relative', left: -1 }}
          />
        </SliderButton>
      </Box>
    </Box>
  );
};

export const Epochs: React.FC = () => {
  const epochs = useAppSelector((state) => state.epochs.epochsData);

  // Epochs slider
  const [current, setCurrent] = useState(1);
  const [airdropHistoryState, setAirdropHistoryState] =
    useState<AirdropData | null>(null);
  const [airdropCondition, setAirdropCondition] = useState<boolean>(false);

  const { account } = useWeb3React();
  const airdropState = useAppSelector((v) => v.merkleTree.airdrop);

  const isWalletConnected = useContext(WalletContext).isWalletConnected;

  const airdropQuery = useQuery<GetAirdropClaimsByAddress>(
    GET_AIRDROP_CLAIMS_BY_ADDRESS,
    {
      variables: { address: `${account}` },
      client: client,
      fetchPolicy: 'network-only',
      pollInterval: 5000,
    },
  );

  useEffect(() => {
    let claimedEvents = Array<ClaimedEventArgsType>();

    if (!airdropQuery.loading && !airdropQuery.error && airdropQuery.data) {
      claimedEvents = airdropQuery.data.airdropClaimHistories.map((v) => ({
        account: v.account,
        amountBUMP: v.amountBUMP.toString(),
        amountUSDC: v.amountUSDC.toString(),
        index: v.index.toString(),
        timestamp: v.timestamp.toString(),
      }));
    }

    const state = {
      claimedEvents: claimedEvents,
      available: airdropState?.available ?? [],
    };

    setAirdropHistoryState(state);
  }, [airdropQuery?.loading, airdropQuery?.error, airdropState]);

  let firstLoading = true;

  useEffect(() => {
    if (firstLoading) {
      firstLoading = false;
    } else {
      airdropQuery.refetch();
    }
  }, [account, isWalletConnected]);

  useEffect(() => {
    const condition =
      IS_AIRDROP_ENABLED &&
      airdropHistoryState &&
      ((airdropHistoryState.claimedEvents &&
        airdropHistoryState.claimedEvents.length > 0) ||
        (airdropHistoryState.available &&
          airdropHistoryState.available.length > 0));

    setAirdropCondition(condition ?? false);
  }, [airdropHistoryState]);

  const epochData = useAppSelector((state) => state.epochs);

  const tabletScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <EpochsContainer
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CurrentEpochsInfoContainer style={{ maxWidth: '730px' }}>
          <EpochSection />
          {/* <ClaimBlock style={{ marginTop: '1rem', marginBottom: 0 }} /> */}
        </CurrentEpochsInfoContainer>
        {(airdropCondition || epochData.rewards.length > 0) && (
          <Box style={{ marginTop: '3rem', width: '100%' }}>
            <ClaimTokens
              airdropData={airdropHistoryState}
              epochsData={{
                rewards: epochData.rewards,
                result: epochData.result,
              }}
            />
          </Box>
        )}

        <Title isPrev>Previous Epochs</Title>
        {epochs.length > 0 && tabletScreen ? (
          <PrevEpochsMobile
            current={current}
            setCurrent={setCurrent}
            epochs={epochs}
          />
        ) : (
          <PrevEpochsInfoContainer>
            {epochs.map(
              ({
                epoch,
                eligible_pool,
                bump_reward_allocation,
                apr,
                total_usdc_yield,
                total_usdc_reward_value,
              }) => (
                <EpochInfo
                  key={epoch}
                  type="previous"
                  epochNumber={epoch}
                  eligiblePool={eligible_pool}
                  rewardAllocation={bump_reward_allocation}
                  yieldUSDC={total_usdc_yield}
                  totalUSDCRewardValue={total_usdc_reward_value}
                  apr={apr}
                />
              ),
            )}
          </PrevEpochsInfoContainer>
        )}
      </EpochsContainer>
    </>
  );
};

const PrevEpochsInfoContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 302px)',
  gap: '20px',
  gridAutoFlow: 'revert',
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    gap: '24px',
  },
}));
const CurrentEpochsInfoContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    gap: '24px',
  },
  [theme.breakpoints.down('xs')]: {
    width: '100%',
  },
}));
const Title = styled(Typography)(({ theme, isPrev }: any) => ({
  height: 'fit-content',
  fontFamily: 'Roboto Mono',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '24px',
  width: '100%',
  marginBottom: '2rem',
  marginRight: '8em',
  marginTop: isPrev ? '75px' : 0,
  marginLeft: isPrev ? '1rem' : 0,
  color: COLORS.White,
  [theme.breakpoints.down('sm')]: {
    marginBottom: isPrev ? '24px' : 0,
    marginTop: isPrev ? '24px' : 0,
    marginLeft: '0',
    marginRight: '0',
  },
}));
const SliderButton = styled('div')({
  width: '2rem',
  height: '2rem',
  borderRadius: '50%',
  border: '1px solid #4A7D83',
  backgroundColor: '#145962',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
const SliderInfo = styled(Typography)({
  fontFamily: 'Roboto Mono',
  fontStyle: 'normal',
  fontSize: 20,
});
