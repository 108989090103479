export const Routes = {
  ConnectWallet: '/',
  App: '/app',
  DepositDashboard: '/app/deposit',
  Epochs: '/app/epochs',
  AddLiquidity: '/app/addliquidity',
  WalletNotFound: '/walletNotFound',
  Protocol: '/app/protocol',
  Governance: '/app/governance',
  SwapPage: '/app/swap',
  WithdrawLiquidity: '/app/withdrawliquidity',
  WithdrawDetails: '/app/withdrawdetails',
  Vesting: '/app/vesting',
  Staking: '/app/staking',
  StakingDetails: '/app/stakingdetails',
  ClaimDetails: '/app/claimdetails',
  ConfirmAutoRenew: '/app/autorenew',
  Restake: '/app/restake',
  ClaimRewardsStaking: '/app/claimrewards',
  Unstake: '/app/unstake',
  Airdrop: '/app/airdrop',
  ClaimStake: '/app/claim',
  EjectStake: '/app/ejectstake',
};

export const subRoutes = {
  Claim: 'claim',
  Select: 'select',
  Confirm: 'confirm',
  Presale: 'presale',
  Processing: 'processing',
  Approve: 'approve',
  Error: 'error',
  Stake: 'stake',
};
