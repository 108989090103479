import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { GeneralErrorScreen } from '../components/common/GeneralErrorScreen';
import { Header } from '../components/common/Header';
import { Stepper } from '../components/common/Stepper';
import { TransactionProcessingSection } from '../components/common/TransactionProcessingSection';
import { WalletApproveSection } from '../components/common/WalletApproveSection';
import { FlowContainer } from '../components/shared/styles/FlowPages';
import { ClaimLiquidity } from '../components/Staking/ClaimLiquidity';
import { ConfirmClaimSection } from '../components/Staking/ConfirmClaimSection';
import { BUMP } from '../config/coins';
import { Routes, subRoutes } from '../config/routes';
import { ICoin } from '../interfaces/ICoin';
import { useAppDispatch } from '../state/hooks';
import { toggleToolbar } from '../state/reducers/uiStateManagementReducer';

interface Claim {
  token: ICoin;
}

export const ClaimDetails: React.FC<Claim> = () => {
  const history = useHistory();
  const theme = useTheme();
  const location = useLocation<Claim>();
  const { step } = useParams<{ step?: string }>();

  const token = location.state ? location.state.token : BUMP;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();

  const onCancel = () => {
    history.replace(Routes.DepositDashboard);
  };
  useEffect(() => {
    dispatch(toggleToolbar());
    return () => {
      dispatch(toggleToolbar());
    };
  }, []);

  const showSection = () => {
    switch (step) {
      case subRoutes.Select: {
        return <ClaimLiquidity />;
      }
      case subRoutes.Confirm: {
        return <ConfirmClaimSection token={token} />;
      }
      case subRoutes.Processing: {
        return <TransactionProcessingSection />;
      }
      case subRoutes.Approve: {
        return <WalletApproveSection />;
      }
      case subRoutes.Error: {
        return <GeneralErrorScreen />;
      }
      default: {
        return <ClaimLiquidity />;
      }
    }
  };

  return (
    <>
      <Header
        onCancel={onCancel}
        showStepper={
          !isSmallScreen &&
          (step == subRoutes.Select || step == subRoutes.Confirm)
        }
        activeStep={step == subRoutes.Confirm ? 1 : 0}
      />
      {isSmallScreen &&
        (step == subRoutes.Select || step == subRoutes.Confirm) && (
          <Box mt="30px" mb="16px" display="flex" justifyContent="center">
            <Stepper activeStep={step == subRoutes.Confirm ? 1 : 0} />
          </Box>
        )}
      <FlowContainer>{showSection()}</FlowContainer>
    </>
  );
};
