import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { COLORS } from '../../config/colors';
import { termsAndConditionsLink } from '../../config/links';
import { Routes, subRoutes } from '../../config/routes';
import { StakingService } from '../../services/stakingService';
import { PixelCheckbox } from '../shared/Checkbox';
import { PixelButton } from '../shared/PixelButton';
import {
  ClaimVestingSummary,
  CoinIconSmall,
  GreenLink,
} from '../shared/styles/ConfirmationPage';
import { DarkSection, Title } from '../shared/styles/Section';
import { WhiteSmallRoboto } from '../shared/styles/TypographyRoboto';

interface IConfirmClaimVestingProps {
  coinIcon: string;
}
export interface ConfirmAutoRenewState {
  stakeIndex?: number;
  autorenew: boolean;
  renewValue?: boolean;
}

export const ConfirmAutoRenew: React.FC<IConfirmClaimVestingProps> = ({
  coinIcon,
}) => {
  const history = useHistory();
  const location = useLocation<ConfirmAutoRenewState>();
  const { stakeIndex, autorenew, renewValue } = location.state;
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { addToast } = useToasts();
  const stakingService = StakingService.getInstance();

  const onBackButtonPress = () => {
    history.replace(Routes.Staking);
  };

  const onConfirm = async () => {
    try {
      history.replace(`${Routes.ConfirmAutoRenew}/${subRoutes.Approve}`);
      const tx = autorenew
        ? await stakingService.switchAutorenew(stakeIndex || 0)
        : await stakingService.requestUnstake(stakeIndex || 0);
      history.replace(`${Routes.ConfirmAutoRenew}/${subRoutes.Processing}`);
      await tx.wait();
      history.replace(`${Routes.Staking}`);
      addToast('Later Dude!', {
        appearance: 'success',
      });
    } catch (err) {
      console.error(err);
      history.replace(Routes.Staking);
      addToast('An unexpected error occurred. Please try again.', {
        appearance: 'warning',
      });
    }
  };

  const handleCheckbox = () => {
    setTermsAccepted(!termsAccepted);
  };

  const autorenewText = renewValue
    ? `By turning off auto-renew, you will no longer able to continue staking at the end of this term for the same period as your current term.`
    : `By turning on auto-renew, you will be able to continue staking at the end of this term for the same period as your current term.`;
  return (
    <Box maxWidth={{ xs: '100%', sm: '100%' }} m="auto">
      <Box display="flex" justifyContent="center" mb={5}>
        <Title>Confirmation</Title>
      </Box>
      <DarkSection
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={{ xs: 3, sm: 3, lg: 4 }}
        position="relative"
        pt={{ xs: 9, sm: 9, lg: 10 }}
      >
        <CoinIconSmall src={coinIcon} />
        <ClaimVestingSummary width="100%" style={{ fontWeight: 700 }}>
          {autorenew ? (
            <>
              Auto-renew toggle{' '}
              {renewValue ? (
                <span style={{ color: COLORS.Red }}>OFF</span>
              ) : (
                <span style={{ color: COLORS.Secondary2 }}>ON</span>
              )}
            </>
          ) : (
            'Activate cooldown'
          )}
        </ClaimVestingSummary>
        <ClaimVestingSummary width="100%">
          {autorenew
            ? autorenewText
            : `By activating the cooldown, you will have to wait for 10 days\nbefore unstaking. At that point you will have a further two (2)\ndays to execute the unstake before your BUMP tokens are returned\nto the staking pool.`}
        </ClaimVestingSummary>
      </DarkSection>
      <Box mt={3} display="flex" alignItems="center">
        <Box
          mr={3}
          style={{ position: 'relative', top: '-1px', right: '-3px' }}
        >
          <PixelCheckbox
            checked={termsAccepted}
            onChange={handleCheckbox}
            style={{ padding: 0 }}
          />
        </Box>
        <Box>
          <WhiteSmallRoboto color="secondary">
            By checking this box you acknowledge and agree to our{' '}
            <GreenLink
              href={termsAndConditionsLink}
              target="_blank"
              rel="noreferrer"
            >
              terms and conditions
            </GreenLink>
            .
          </WhiteSmallRoboto>
        </Box>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="minmax(150px, 33%) auto minmax(150px, 33%)"
        mt={{ xs: 3, sm: 4 }}
        pb="10vh"
      >
        <Box>
          <PixelButton fullWidth color="secondary" onClick={onBackButtonPress}>
            Back
          </PixelButton>
        </Box>
        <Box gridColumn="3">
          <PixelButton
            fullWidth
            color="primary"
            disabled={!termsAccepted}
            onClick={onConfirm}
          >
            Confirm
          </PixelButton>
        </Box>
      </Box>
    </Box>
  );
};
