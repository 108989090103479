import { Box, Backdrop, useMediaQuery } from '@material-ui/core';
import { format } from 'date-fns';
import { BigNumber, ethers } from 'ethers';
import React from 'react';

import { theme } from '../../providers/AppThemeProvider';
import { formatStringifyNumberToDot } from '../../utils/helpers';
import { PixelCloseIcon } from '../shared/icons/PixelCloseIcon';
import {
  AmountRow,
  AmountTypography,
  CancelButtonSection,
  CloseTypography,
  ColumnName,
  ContainerHistory,
  HistoryHeader,
  ScrollingList,
} from '../shared/styles/History';

export interface VestingClaimEvent {
  // txHash: string;
  claimedAmount: BigNumber;
  stakedAmount: BigNumber;
  timestamp: BigNumber;
}

interface HistoryModalProps {
  close: () => void;
  visible: boolean;
  historicalData?: VestingClaimEvent[];
}

const formatClaimAmounts = (data: VestingClaimEvent[]) => {
  const updatedTimestamp = +(process.env
    .REACT_APP_VESTING_UPDATED_TIMESTAMP as string);
  return data
    .map((v) => v.timestamp.toString())
    .filter((timestamp, i, self) => self.indexOf(timestamp) === i)
    .map((v) => BigNumber.from(v))
    .reduce<VestingClaimEvent[]>((acc, timestamp): VestingClaimEvent[] => {
      const equalTimestamp = data.filter((event) =>
        event.timestamp.eq(timestamp),
      );
      return [
        ...acc,
        {
          timestamp,
          claimedAmount: equalTimestamp
            .map((v) => v.claimedAmount)
            .reduce((acc, n) => acc.add(n), BigNumber.from(0)),
          stakedAmount: equalTimestamp
            .map((v) => v.stakedAmount)
            .reduce((acc, n) => acc.add(n), BigNumber.from(0)),
        } as VestingClaimEvent,
      ];
    }, [])
    .sort((a, b) => b.timestamp.sub(a.timestamp).toNumber())
    .map(({ timestamp, claimedAmount, stakedAmount }) => ({
      timestamp: `${format(
        parseInt(timestamp.toString(), 10) * 1000,
        'MMM-d-yyyy hh:mm:ss bb',
      )} +UTC`,
      claimedAmount: formatStringifyNumberToDot(
        `${ethers.utils.formatUnits(claimedAmount)}`,
      ),
      stakedAmount:
        timestamp.toNumber() > updatedTimestamp && !stakedAmount.isZero()
          ? formatStringifyNumberToDot(
              `${ethers.utils.formatUnits(stakedAmount)}`,
            )
          : '-',
    }));
};

export const HistoryModal: React.FC<HistoryModalProps> = (
  props: HistoryModalProps,
) => {
  const { close, visible, historicalData } = props;

  if (!historicalData) return <></>;

  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const formattedData = formatClaimAmounts(historicalData);

  return (
    <Backdrop open={visible} style={{ zIndex: 1000 }}>
      <ContainerHistory>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={5}
        >
          <HistoryHeader>History of Claims</HistoryHeader>
          <Box display="flex" alignItems="center">
            {!smallScreen && <CloseTypography>Close</CloseTypography>}
            <CancelButtonSection onClick={close}>
              <PixelCloseIcon style={{ cursor: 'pointer' }} />
            </CancelButtonSection>
          </Box>
        </Box>
        <ScrollingList>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <ColumnName>Timestamp</ColumnName>
            <ColumnName>Claimed BUMP</ColumnName>
            <ColumnName>Staked BUMP</ColumnName>
          </Box>
          {formattedData && (
            <Box style={{ marginTop: '1.5rem' }}>
              {formattedData.map(
                ({ timestamp, claimedAmount, stakedAmount }) => (
                  <AmountRow key={`claimTime-${timestamp}`}>
                    <AmountTypography>{timestamp}</AmountTypography>
                    <AmountTypography>{claimedAmount}</AmountTypography>
                    <AmountTypography>{stakedAmount}</AmountTypography>
                  </AmountRow>
                ),
              )}
            </Box>
          )}
        </ScrollingList>
      </ContainerHistory>
    </Backdrop>
  );
};
