import { styled, Typography } from '@material-ui/core';

import LargeBoldTypography, { ExtraSmallBoldTypography } from '../Typography';
import MediumBoldTypography from '../Typography';
import MediumTypography from '../Typography';
import ExtraSmallTypography from '../Typography';

export const ExtraSmallBoldRoboto = styled(ExtraSmallBoldTypography)({
  fontFamily: 'Roboto Mono',
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: 700,
});

export const SmallRoboto = styled(Typography)({
  fontFamily: 'Roboto',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 400,
});

export const LargeBoldRoboto = styled(LargeBoldTypography)({
  fontFamily: 'Roboto Mono',
  fontSize: '20px',
  lineHeight: '26px',
  fontWeight: 700,
});

export const MediumBoldRoboto = styled(MediumBoldTypography)({
  fontFamily: 'Roboto Mono',
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 700,
});

export const MediumRoboto = styled(MediumTypography)({
  fontFamily: 'Roboto Mono',
  fontSize: '16px',
  lineHeight: '24px',
});

export const ExtraSmallRoboto = styled(ExtraSmallTypography)({
  fontFamily: 'Roboto Mono',
  fontSize: '12px',
  lineHeight: '16px',
});

export const WhiteSmallRoboto = styled(SmallRoboto)({
  color: 'rgba(255, 255, 255, 0.8)',
});

export const FinalText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Roboto',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: 'rgba(255, 255, 255, 0.8)',

  [theme.breakpoints.down('xs')]: {
    fontSize: '12px',
    lineHeight: '18px',
    marginTop: '0.2em',
  },
}));
