interface ContractDetail {
  address: string;
}

export interface TokenDetails {
  address: string;
  decimal: number;
  enumId: number;
}

export interface TokenList {
  USDC: TokenDetails;
  bUSDC: TokenDetails;
  BUMP: TokenDetails;
}

interface ContractList {
  CONTRACT_ADDRESS: {
    Airdrop: ContractDetail;
    BumpMarket: ContractDetail;
    BUSDC: ContractDetail;
    BumpToken: ContractDetail;
    Vesting: ContractDetail;
    Staking: ContractDetail;
  };
  TOKEN_DETAILS: TokenList;
}

export const CONTRACTS: ContractList = {
  CONTRACT_ADDRESS: {
    Airdrop: {
      address: process.env.REACT_APP_AIRDROP as string,
    },
    BumpMarket: {
      address: process.env.REACT_APP_BUMP_MARKET_ADDR as string,
    },
    BUSDC: {
      address: process.env.REACT_APP_BUSDC_ADDRESS as string,
    },
    BumpToken: {
      address: process.env.REACT_APP_BUMP_TOKEN_ADDR as string,
    },
    Vesting: {
      address: process.env.REACT_APP_VESTING_ADDR as string,
    },
    Staking: {
      address: process.env.REACT_APP_STAKING_ADDR as string,
    },
  },
  TOKEN_DETAILS: {
    USDC: {
      address: process.env.REACT_APP_USDC_ADDRESS as string,
      decimal: parseInt(process.env.REACT_APP_USDC_DECIMAL as string),
      enumId: 0,
    },
    bUSDC: {
      address: process.env.REACT_APP_BUSDC_ADDRESS as string,
      decimal: parseInt(process.env.REACT_APP_USDC_DECIMAL as string),
      enumId: 0,
    },
    BUMP: {
      address: process.env.REACT_APP_BUMP_TOKEN_ADDR as string,
      decimal: 18,
      enumId: 0,
    },
  },
};

export const chainID: number = +(process.env.REACT_APP_CHAIN_IDs as string);
export const chainName: string = process.env.REACT_APP_CHAIN_NAME as string;
