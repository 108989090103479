import { useWeb3React } from '@web3-react/core';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ErrorState } from '../components/shared/ErrorState';
import { Routes } from '../config/routes';
import { useAppDispatch, useAppSelector } from '../state/hooks';
import { removeError } from '../state/reducers/errorReducer';

export const ErrorHandler: React.FC = (props) => {
  const history = useHistory();
  const { chainId } = useWeb3React();
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.error);

  useEffect(() => {
    return history.listen(() => {
      if (state.error) {
        dispatch(removeError());
      }
    });
  }, [history, state]);

  useEffect(() => {
    if (chainId) {
      dispatch(removeError());
    }
  }, [chainId]);

  const goToEarnPage = () => {
    history.push(Routes.DepositDashboard);
  };

  if (state.error) {
    return (
      <ErrorState
        errorMessage={state.errorMessage}
        errorDescription={state.errorDescription}
        buttonText="Back to Deposit Page"
        buttonAction={goToEarnPage}
        hideButton={state.hideBtn}
      />
    );
  }

  return <>{props.children}</>;
};
