import { Box } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useContext } from 'react';
// import { useHistory } from 'react-router';

import { TopSection } from './../shared/TopSection';

// import { isFrontendFunctionalityDisabled } from '../../config';
// import { Routes } from '../../config/routes';
import { WalletContext } from '../../config/walletContext';
import { theme } from '../../providers/AppThemeProvider';
import { useAppSelector } from '../../state/hooks';
import {
  formatStringifyNumberToDot,
  isCoinBalanceZero,
} from '../../utils/helpers';
import { HistoryButton } from '../DepositDashboard/Vesting/Buttons';
import { IBalanceCardProps } from '../interfaces/BalanceCardProps';
import { CoinIcon } from '../shared/CoinIcon';
import { ContentSection } from '../shared/ContentSection';
import { SecondDataField } from '../shared/DataField';
import { CoinIcons } from '../shared/icons/CoinIcons';
// import { PixelButton } from '../shared/PixelButton';
import { LargeBoldTypography } from '../shared/Typography';

export const BumpCard: React.FC<IBalanceCardProps> = (props) => {
  // const history = useHistory();

  const { token, openHistoryModal } = props;

  const { bumpBalance, bumpBalanceInUsdc } = useAppSelector(
    (v) => v.bumpDetails,
  );

  const isWalletConnected = useContext(WalletContext).isWalletConnected;

  // const utcTime = useAppSelector((state) => state.lockTimestamp.utcTime);

  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <TopSection color={`${theme.palette.other.Turquoise}`}>
        <Box display="flex" alignItems="center">
          <CoinIcon src={CoinIcons[token.symbol]} />
          <LargeBoldTypography secondaryFont>
            {token.symbol}
          </LargeBoldTypography>
        </Box>
        {/* <Box display="flex" alignItems="center">
          {!smallScreen && (
            <PixelButton
              size="small"
              style={{ marginLeft: '18px' }}
              color="secondary"
              onClick={() => history.push(Routes.StakingDetails + '/select')}
              disabled={isFrontendFunctionalityDisabled(utcTime)}
            >
              Stake
            </PixelButton>
          )}
          {smallScreen && (
            <PixelButton
              size="small"
              color="secondary"
              fullWidth
              onClick={() => history.push(Routes.StakingDetails + '/select')}
              disabled={isFrontendFunctionalityDisabled(utcTime)}
            >
              Stake
            </PixelButton>
          )}
        </Box> */}
      </TopSection>
      {(!smallScreen && isCoinBalanceZero(bumpBalance)) ||
      !isWalletConnected ? null : (
        <ContentSection>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <SecondDataField
              name="Balance"
              value={formatStringifyNumberToDot(bumpBalance)}
              currentBumpPriceInDollars={formatStringifyNumberToDot(
                bumpBalanceInUsdc,
              )}
              unit={token.symbol}
            />
            <HistoryButton onClick={openHistoryModal} />
          </Box>
        </ContentSection>
      )}
    </Box>
  );
};
