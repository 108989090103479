import { Box, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles<Theme, ITopSectionProps>((theme) => ({
  root: {
    background: theme.palette.other.Green3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '2px solid black',
    boxShadow: (props) => `inset 0px 2px 0px 0px ${props.color}`,
    width: '100%',
    padding: '.75rem 2rem',
    [theme.breakpoints.down('sm')]: {
      padding: '14px 26px',
    },
  },
}));

interface ITopSectionProps {
  color: string;
}

export const TopSection: React.FC<ITopSectionProps> = (props) => {
  const classes = useStyles({ color: props.color });
  return <Box className={classes.root}>{props.children}</Box>;
};
