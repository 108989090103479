import { InputAdornment, Box, styled } from '@material-ui/core';
import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

import { ICoin } from '../../interfaces/ICoin';
import { CoinIcons } from '../shared/icons/CoinIcons';
import { ExtraSmallBoldRoboto } from '../shared/styles/TypographyRoboto';
import { PixelTextDepositField } from '../shared/TextField';

export interface IDecimalNumberFieldProps {
  token: ICoin;
}

export const DepositNumberField: React.FC<
  NumberFormatProps & IDecimalNumberFieldProps
> = (props) => {
  return (
    <Box style={{ position: 'relative' }}>
      <NumberFormat
        customInput={PixelTextDepositField}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" style={{ borderRadius: 0 }}>
              <img
                style={{
                  width: '1.5rem',
                  height: '1.5rem',
                  maxWidth: '1.5rem',
                  maxHeight: '1.5rem',
                  position: 'relative',
                  top: 1,
                }}
                src={CoinIcons[props.token.symbol]}
              />
            </InputAdornment>
          ),
          placeholder: '0.0000',
        }}
        decimalScale={4}
        fixedDecimalScale={true}
        allowNegative={false}
        isNumericString={true}
        {...props}
      />
      <Text>BUMP</Text>
    </Box>
  );
};

export const Text = styled(ExtraSmallBoldRoboto)(({ theme }) => ({
  position: 'absolute',
  top: '55%',
  transform: 'translateY(-50%)',
  right: '17.5%',

  [theme.breakpoints.down('sm')]: {
    top: '52.5%',
  },
}));
