import { BytesLike } from '@ethersproject/bytes';
import { BigNumber, ContractTransaction, ethers } from 'ethers';

import { EthersServiceProvider } from './ethersServiceProvider';

import { CONTRACTS } from '../config/contractAddresses';
import { AirdropMerkleNodeType } from '../state/reducers/merkleTree';
import { Airdrop, Airdrop__factory } from '../types';
import { TypedEvent } from '../types/commons';

export type ClaimedEventType = TypedEvent<
  [BigNumber, string, BigNumber, BigNumber, BigNumber] & {
    index: BigNumber;
    account: string;
    amountUSDC: BigNumber;
    amountBUMP: BigNumber;
    timestamp: BigNumber;
  }
>;

export type ClaimedEventArgsType = {
  index: string;
  account: string;
  amountUSDC: string;
  amountBUMP: string;
  timestamp: string;
};

export class AirdropService {
  private static instance: AirdropService;
  private ethersServiceProvider: EthersServiceProvider;

  private constructor() {
    this.ethersServiceProvider = EthersServiceProvider.getInstance();
  }

  public static getInstance(): AirdropService {
    if (!AirdropService.instance) {
      AirdropService.instance = new AirdropService();
    }
    return AirdropService.instance;
  }

  private getAirdrop(): Airdrop {
    return Airdrop__factory.connect(
      CONTRACTS.CONTRACT_ADDRESS.Airdrop.address,
      this.ethersServiceProvider.provider?.getSigner(
        0,
      ) as ethers.providers.JsonRpcSigner,
    );
  }

  private async getAccount(address?: string): Promise<string> {
    return address ?? (await this.ethersServiceProvider.getUserAddress());
  }

  public encodeClaimBulkArgs(args: AirdropMerkleNodeType): Array<BytesLike> {
    if (!args) throw new Error('Args is null');
    const coder = new ethers.utils.AbiCoder();
    return args.map((v) =>
      coder.encode(
        ['uint256', 'address', 'uint256', 'uint256', 'bytes32[]'],
        [v.index, v.account, v.amountUSDC, v.amountBUMP, v.proof],
      ),
    );
  }

  public async claimBulk(args: BytesLike[]): Promise<ContractTransaction> {
    return await this.getAirdrop().claimBulk(args);
  }

  public async claimBulkDecodedArgs(
    args: AirdropMerkleNodeType,
  ): Promise<ContractTransaction> {
    return await this.claimBulk(this.encodeClaimBulkArgs(args));
  }
}
