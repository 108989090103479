import { createSlice } from '@reduxjs/toolkit';

import { IProspectiveApr } from '../../interfaces/IProspectiveApr';
import { fetchProspectiveApr } from '../actions/prospectiveAprAction';
const initialState: { prospectiveAprDetails: IProspectiveApr } = {
  prospectiveAprDetails: {
    prospectiveApr: '',
  },
};
export const prospectiveAprSlice = createSlice({
  name: 'fetchProspectiveApr',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProspectiveApr.fulfilled, (state, action) => {
      state.prospectiveAprDetails = { ...action.payload };
    });
  },
});
export default prospectiveAprSlice.reducer;
