import { useQuery } from '@apollo/client';
import { useWeb3React } from '@web3-react/core';
import { BigNumber, ethers, FixedNumber } from 'ethers';
import React, { FC, ReactNode, useContext, useEffect, useState } from 'react';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import { useHistory } from 'react-router-dom';

import PixelLockIcon from '../assets/pixel-lock-icon.svg';
import { Container } from '../components/common/MainContainer';
import { Wrapper } from '../components/common/Wrapper';
import { BalanceCard } from '../components/DepositDashboard/BalanceCard';
import { ConnectBlock } from '../components/DepositDashboard/ConnectBlock';
import {
  VestingClaimEvent,
  HistoryModal,
} from '../components/DepositDashboard/HistoryModal';
import { LiquidityDepositCard } from '../components/DepositDashboard/LiquidityDepositCard';
import {
  MainEpochSection,
  EpochTimer,
} from '../components/DepositDashboard/PreSaleSection';
import { SecondBalanceCard } from '../components/DepositDashboard/SecondBalanceCard';
import { DashboardVesting } from '../components/DepositDashboard/Vesting/DashboardVesting';
import { PixelButton } from '../components/shared/PixelButton';
import { TimeUnits } from '../components/shared/styles/DashboardStyles';
import { USDCoin, BUMP } from '../config/coins';
import { Routes } from '../config/routes';
import { IS_VESTING_ENABLED } from '../config/vesting';
import { WalletContext } from '../config/walletContext';
import { ICoin } from '../interfaces/ICoin';
import { clientLpp, client } from '../providers/graphql';
import { GetAllVestingClaimsByAddress } from '../providers/graphql/__generated__/GetAllVestingClaimsByAddress';
import { GetMakerData } from '../providers/graphql/lpp/__generated__/GetMakerData';
import { MAKER_DATA } from '../providers/graphql/lpp/queries';
import { GET_VESTING_CLAIMS_BY_ADDRESS } from '../providers/graphql/queries';
import { useAppSelector } from '../state/hooks';
import {
  convertBumpToDollars,
  isCoinBalanceZero,
  getEpoch,
  getEndEpochDate,
} from '../utils/helpers';

export const CountDownButtonBuySwap: FC<
  CountdownRenderProps & { childrenNode: ReactNode }
> = (props) => {
  return (
    <>
      {props.completed ? (
        props.childrenNode
      ) : (
        <PixelButton
          size="small"
          color="secondary"
          fullWidth
          disabled
          style={{ padding: '18px 10px' }}
        >
          <img
            src={PixelLockIcon}
            style={{
              marginRight: '8px',
              display: 'block',
            }}
            alt=""
          />
          <span>
            {props.formatted.days}
            <TimeUnits>d</TimeUnits>
            {props.formatted.hours}
            <TimeUnits>h</TimeUnits>
            {props.formatted.minutes}
            <TimeUnits>m</TimeUnits>
            {props.formatted.seconds}
            <TimeUnits>s</TimeUnits>
          </span>
        </PixelButton>
      )}
    </>
  );
};

const EARN_PERIOD_START_DATE = process.env.REACT_APP_EARN_PERIOD_START_DATE;

export const Dashboard: React.FC = () => {
  const history = useHistory();
  const busdDeposit = useAppSelector(
    (state) => state.coin.coinDetails['bUSDC'],
  );

  const [epochData, setEpochData] = useState(getEpoch());
  const { epoch, newEpochEndDate } = epochData;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setEpochData(getEpoch());
    }, 60000);
    return () => clearInterval(intervalId);
  }, []);

  const { account } = useWeb3React();

  const usdcDetails = useAppSelector((state) => state.coin.coinDetails['USDC']);
  const bumpBalance = useAppSelector((state) => state.bumpDetails.bumpBalance);
  const vestingMerkleState = useAppSelector(
    (state) => state.merkleTree.vesting,
  );

  const isWalletConnected = useContext(WalletContext).isWalletConnected;
  const [bumpRewards, setBumpRewards] = useState('');

  const [isHistoryModalVisible, toggleHistoryModal] = useState<boolean>(false);

  const { data, loading, error, previousData, startPolling, stopPolling } =
    useQuery<GetMakerData>(MAKER_DATA, {
      variables: { makerAddress: `${account?.toLowerCase()}` },
      client: clientLpp,
      fetchPolicy: 'network-only',
    });

  const startAddLiquidityFlow = (token: ICoin) => {
    history.push(Routes.AddLiquidity + '/select', {
      token,
    });
  };

  const [bumpRewardsInUsdc, setBumpRewardsInUsdc] = useState<string>('');

  useEffect(() => {
    if (!loading && !error && isWalletConnected) {
      const totalBumpReward = data?.maker?.totalBumpRewarded
        ? FixedNumber.from(
            ethers.utils.formatUnits(
              BigNumber.from(data?.maker?.totalBumpRewarded),
              18,
            ),
          )
            .round(4)
            .toString()
        : '0';

      setBumpRewards(totalBumpReward);
    }
  }, [data?._meta?.block.number]);

  useEffect(() => {
    startPolling(5000);
    if (
      previousData !== undefined &&
      data?._meta?.block.number !== previousData?._meta?.block.number
    ) {
      stopPolling();
    }
    return () => {
      stopPolling();
    };
  }, [data?._meta?.block.number]);

  useEffect(() => {
    convertBumpToDollars(bumpRewards).then(setBumpRewardsInUsdc);
  }, [bumpRewards]);

  const [vestingHistoryData, setVestingHistoryData] = useState<
    VestingClaimEvent[]
  >([]);

  const vestingQuery = useQuery<GetAllVestingClaimsByAddress>(
    GET_VESTING_CLAIMS_BY_ADDRESS,
    {
      variables: { address: `${account}` },
      client: client,
      fetchPolicy: 'network-only',
      pollInterval: 10000,
    },
  );

  useEffect(() => {
    if (!vestingQuery.loading && !vestingQuery.error && vestingQuery.data)
      setVestingHistoryData(
        vestingQuery.data.vestingClaimHistories.map((v) => ({
          claimedAmount: BigNumber.from(v.amount),
          stakedAmount: BigNumber.from(v.index),
          timestamp: BigNumber.from(v.timestamp),
        })),
      );
  }, [vestingQuery?.loading, vestingQuery?.error, vestingQuery?.data]);

  useEffect(() => {
    if (isHistoryModalVisible) {
      document.body.style.overflow = 'hidden';
    }
    if (!isHistoryModalVisible) document.body.style.overflow = 'auto';
  }, [isHistoryModalVisible]);

  let firstLoading = true;

  useEffect(() => {
    if (firstLoading) {
      firstLoading = false;
    } else {
      vestingQuery.refetch();
    }
  }, [account, isWalletConnected]);

  return (
    <>
      <HistoryModal
        close={() => toggleHistoryModal(false)}
        visible={isHistoryModalVisible}
        historicalData={vestingHistoryData}
      />
      <Container>
        <DashboardVesting />
        {IS_VESTING_ENABLED !== 'true' &&
          Date.now() >= new Date(EARN_PERIOD_START_DATE as string).getTime() &&
          !isCoinBalanceZero(bumpBalance) && (
            <>
              <Countdown
                date={newEpochEndDate}
                renderer={(properties) => <MainEpochSection {...properties} />}
              />
              {/* TODO : Remove the below section  */}
              {/* <EpochsInfoContainer>
                <EpochInfo
                  type="previous"
                  epochNumber={epoch - 1}
                  eligiblePool={'10400000'}
                  rewardAllocation={'137000'}
                  yieldUSDC={'TBC'}
                  apr={'8.97%'}
                />
                <EpochInfo
                  type="actual"
                  end={newEpochEndDate}
                  epochNumber={epoch}
                  eligiblePool={'10400000'}
                  rewardAllocation={'137000'}
                  yieldUSDC={'TBC'}
                  apr={'10.3%'}
                />
              </EpochsInfoContainer> */}
              <Countdown
                date={getEndEpochDate(epoch + 1).epochStartDate}
                renderer={(properties) => (
                  <EpochTimer {...properties} epoch={epoch + 1} />
                )}
              />
            </>
          )}

        <Wrapper title="Balances">
          {!isWalletConnected ? (
            <ConnectBlock />
          ) : (
            <BalanceCard
              balance={usdcDetails.balance}
              onButtonPress={startAddLiquidityFlow}
              token={USDCoin}
            />
          )}
          {!isWalletConnected || !vestingMerkleState ? null : (
            <SecondBalanceCard
              balance={bumpBalance}
              onButtonPress={startAddLiquidityFlow}
              token={BUMP}
              openHistoryModal={() => toggleHistoryModal(true)}
              vestingClaimEvents={vestingHistoryData}
              refreshClaimEvents={vestingQuery.refetch}
            />
          )}
        </Wrapper>
        {!isWalletConnected || isCoinBalanceZero(busdDeposit.balance) ? null : (
          <Wrapper title="Deposits">
            <LiquidityDepositCard
              token={USDCoin}
              balance={busdDeposit.balance}
              bumpEarned={bumpRewards}
              bumpEarnedInDollars={bumpRewardsInUsdc}
              APR={
                data?.maker?.currentAPR
                  ? parseFloat(data.maker.currentAPR).toFixed(2)
                  : null
              }
            />
          </Wrapper>
        )}
      </Container>
    </>
  );
};

// const EpochsInfoContainer = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'row',
//   alignItems: 'center',
//   justifyContent: 'center',
//   width: '100%',
//   gap: '44px',
//   [theme.breakpoints.down('sm')]: {
//     flexDirection: 'column-reverse',
//     gap: '24px',
//   },
// }));
