import { signERC2612Permit } from 'eth-permit';
import { RSV } from 'eth-permit/dist/rpc';
import { BigNumber, ethers, FixedNumber } from 'ethers';

import { EthersServiceProvider } from './ethersServiceProvider';

import { chainID, CONTRACTS, TokenDetails } from '../config/contractAddresses';
import { ICoin, ICoinDetails } from '../interfaces/ICoin';
import {
  BumpMarket__factory,
  BumpMarketV2__factory,
  BumpToken__factory,
  BUSDC__factory,
  IERC20__factory,
} from '../types';

export class MakerService {
  private static instance: MakerService;
  private etherServiceProvider: EthersServiceProvider;
  private infuraServiceProvider = new ethers.providers.JsonRpcProvider(
    process.env.REACT_APP_PROVIDER_URL as string,
  );
  private constructor() {
    this.etherServiceProvider = EthersServiceProvider.getInstance();
  }

  static getInstance(): MakerService {
    if (!MakerService.instance) {
      MakerService.instance = new MakerService();
    }
    return MakerService.instance;
  }

  /**
   * This function will get details like balance , price of coin that is passed as an argument.
   * @param coin Coin of whom details you want to recieve.
   * @returns It returns details of coin in ICoinDetails format.
   */
  async getTokenDetails(coin: ICoin): Promise<ICoinDetails> {
    return await this.etherServiceProvider.fetchTokenDetails(coin);
  }

  /**
   * Approves certain number of ERC20 tokens to an given address
   * @param amount - Amount of ERC20  tokens to approve.
   * @param to - Address to which tokens need to be approved.
   * @param tokenDetails - Details of type of ERC20 token need to be approved.
   * @returns - Returns transaction object after transaction is confirmed.
   */
  async approveMakerToken(
    amount: string,
    to: string,
    tokenDetails: TokenDetails,
  ): Promise<ethers.ContractTransaction> {
    const bigIntAmount = ethers.utils.parseUnits(amount, tokenDetails.decimal);
    return await this.etherServiceProvider.approveTokenAmount(
      bigIntAmount.toString(),
      to,
      tokenDetails.address,
    );
  }

  /**
   * Will call reserve depositAmount method to transfer tokens from user wallet to reserve contract.
   * @param amount - Amount that need to be transfered yo Reserve contract.
   * @param amountForBumpPurchase
   * @param tokenDetails - Type of token that user want to transfer.
   * @return Returns contract transaction
   */
  async transferMakerTokensToReserve(
    amount: string,
    amountForBumpPurchase: string,
    tokenDetails: TokenDetails,
  ): Promise<ethers.ContractTransaction> {
    const transferAmount: ethers.BigNumber = ethers.utils.parseUnits(
      amount,
      tokenDetails.decimal,
    );

    const bumpPurchaseAmount: ethers.BigNumber = ethers.utils.parseUnits(
      amountForBumpPurchase,
      tokenDetails.decimal,
    );

    const userAddress: string =
      await this.etherServiceProvider.getUserAddress();
    const stablecoinReserve = BumpMarketV2__factory.connect(
      CONTRACTS.CONTRACT_ADDRESS.BumpMarket.address,
      this.etherServiceProvider.provider?.getSigner(
        0,
      ) as ethers.providers.JsonRpcSigner,
    );

    const permit = await this.tryPermit(
      tokenDetails,
      userAddress,
      transferAmount,
    );

    if (permit) {
      return await stablecoinReserve.functions.depositAmountWithPermit(
        transferAmount.toString(),
        bumpPurchaseAmount.toString(),
        tokenDetails.enumId,
        0,
        permit.deadline,
        permit.v,
        permit.r,
        permit.s,
      );
    }

    const tx = await this.approveMakerToken(
      transferAmount.toString(),
      stablecoinReserve.address,
      tokenDetails,
    );
    await tx.wait();

    return await stablecoinReserve.functions.depositAmount(
      transferAmount.toString(),
      bumpPurchaseAmount.toString(),
      tokenDetails.enumId,
      0,
    );
  }

  private async tryPermit(
    tokenDetails: TokenDetails,
    userAddress: string,
    transferAmount: BigNumber,
  ): Promise<({ deadline: number | string } & RSV) | undefined> {
    const usdcDomain2 = {
      name: 'USD Coin',
      version: '2',
      chainId: chainID,
      verifyingContract: tokenDetails.address,
    };
    const deadline = Math.floor(new Date().getTime() / 1000) + 1000000;
    let permit;
    try {
      permit = await signERC2612Permit(
        this.etherServiceProvider.provider,
        usdcDomain2,
        userAddress,
        CONTRACTS.CONTRACT_ADDRESS.BumpMarket.address,
        transferAmount.toString(),
        deadline,
      );
    } catch (err) {
      return undefined;
    }

    if (permit.v !== 27 && permit.v !== 28) {
      return undefined;
    }

    return permit;
  }

  /**
   * It will be used to get current user deposit USDC balance on Reserve of Bumper
   * @returns Returns deposit USDC balance of current user in Reserve of Bumper
   */
  async getUserUsdcDeposit(): Promise<string> {
    const stablecoinReserve = BumpMarket__factory.connect(
      CONTRACTS.CONTRACT_ADDRESS.BumpMarket.address,
      this.etherServiceProvider.provider as ethers.providers.JsonRpcProvider,
    );
    const userAddress = this.etherServiceProvider.currentAccount;
    const userDepositDetails = await stablecoinReserve.depositDetails(
      userAddress,
    );
    return this.etherServiceProvider.toDecimal(
      BigNumber.from(userDepositDetails[1].toString()),
      CONTRACTS.TOKEN_DETAILS.USDC.decimal,
    );
  }

  /**
   * It will be used to get current BUMP balance of a user
   * @returns Returns the BUMP balance of the current account
   */
  async getBumpBalance(): Promise<string> {
    const userBumpBalance = await this.getBumpBalanceBigNumber();
    return (parseInt(userBumpBalance.toString()) / 10 ** 18).toString();
  }

  public async getBumpBalanceBigNumber(): Promise<BigNumber> {
    const bumpToken = BumpToken__factory.connect(
      CONTRACTS.CONTRACT_ADDRESS.BumpToken.address,
      this.etherServiceProvider.provider as ethers.providers.JsonRpcProvider,
    );
    const userAddress = this.etherServiceProvider.currentAccount;
    return await bumpToken.balanceOf(userAddress);
  }

  async getBUSDCUnlockTimestamp(): Promise<number> {
    const BUSDC = BUSDC__factory.connect(
      CONTRACTS.CONTRACT_ADDRESS.BUSDC.address,
      this.infuraServiceProvider as ethers.providers.InfuraProvider,
    );
    const lockTimeStamp = await BUSDC.unlockTimestamp();
    return parseInt(lockTimeStamp.toString());
  }
  async getSwapUnlockTimestamp(): Promise<number> {
    const BumpMarketV2 = BumpMarketV2__factory.connect(
      CONTRACTS.CONTRACT_ADDRESS.BumpMarket.address,
      this.infuraServiceProvider as ethers.providers.InfuraProvider,
    );
    const lockTimeStamp = await BumpMarketV2.swapAllowedPeriod();
    return parseInt(lockTimeStamp.toString());
  }

  async getCurrentBumpPrice(): Promise<FixedNumber> {
    const reserve = BumpMarket__factory.connect(
      CONTRACTS.CONTRACT_ADDRESS.BumpMarket.address,
      this.etherServiceProvider.provider as ethers.providers.JsonRpcProvider,
    );

    const bumpPrice = await reserve.getSwapRateBumpUsdc();

    // Since bumpPrice returned is in cents with two decimal places,
    // To show the value in dollars we add a decimal point 4 places from the right.
    const formattedBumpPrice = await this.etherServiceProvider.toDecimal(
      bumpPrice,
      4,
    );

    return FixedNumber.fromString(formattedBumpPrice);
  }

  async getCurrentTVL(): Promise<FixedNumber> {
    const reserve = BumpMarket__factory.connect(
      CONTRACTS.CONTRACT_ADDRESS.BumpMarket.address,
      this.etherServiceProvider.provider as ethers.providers.JsonRpcProvider,
    );

    const currentTVL = await reserve.currentTVL();

    const formattedCurrentTVL: string =
      await this.etherServiceProvider.toDecimal(
        currentTVL,
        CONTRACTS.TOKEN_DETAILS.USDC.decimal,
      );

    return FixedNumber.fromString(formattedCurrentTVL);
  }

  async getMaxBumpPercent(): Promise<FixedNumber> {
    const reserve = BumpMarket__factory.connect(
      CONTRACTS.CONTRACT_ADDRESS.BumpMarket.address,
      this.infuraServiceProvider as ethers.providers.InfuraProvider,
    );

    const maxPercentage = await reserve.maxBumpPercent();

    const formattedMaxPercentage = this.etherServiceProvider.toDecimal(
      maxPercentage,
      2,
    );

    return FixedNumber.fromString(formattedMaxPercentage);
  }

  async getBumpPurchaseAllocation(): Promise<FixedNumber> {
    const reserve = BumpMarket__factory.connect(
      CONTRACTS.CONTRACT_ADDRESS.BumpMarket.address,
      this.etherServiceProvider.provider as ethers.providers.JsonRpcProvider,
    );

    const bumpPurchaseAllocation = await reserve.bumpPurchaseAllocation();

    const formattedAllocation = ethers.utils.formatUnits(
      bumpPurchaseAllocation,
      18,
    );

    return FixedNumber.fromString(formattedAllocation).round(10);
  }

  async getBumpRewardAllocation(): Promise<FixedNumber> {
    const reserve = BumpMarket__factory.connect(
      CONTRACTS.CONTRACT_ADDRESS.BumpMarket.address,
      this.etherServiceProvider.provider as ethers.providers.JsonRpcProvider,
    );

    const bumpRewardAllocation = await reserve.bumpRewardAllocation();

    const formattedAllocation = ethers.utils.formatUnits(
      bumpRewardAllocation,
      18,
    );

    return FixedNumber.fromString(formattedAllocation).round(10);
  }

  /**
   * This function is meant to replicate the behavior of estimateSwapRateBumpUSDC() function on BumpMarket.sol
   * We add it here in order to avoid repeated calls to the contract to compute this value
   */
  async getEstimatedBumpPrice(deposit: string): Promise<FixedNumber> {
    const depositAmount = ethers.utils.parseUnits(deposit, 6);

    const stablecoinReserve = BumpMarketV2__factory.connect(
      CONTRACTS.CONTRACT_ADDRESS.BumpMarket.address,
      this.infuraServiceProvider as ethers.providers.InfuraProvider,
    );

    const totalDeposits = await stablecoinReserve.functions.totalDeposits();
    const swapRateParam1 = 0;
    const swapRateParam2 = 10723;

    const price = totalDeposits[0]
      .add(depositAmount)
      .mul(swapRateParam1)
      .div(BigNumber.from('1000000000000000000').mul(100))
      .add(swapRateParam2);

    return FixedNumber.fromValue(price);
  }

  // Get estimated amount of BUMP user would receive for a certain bumpPurchaseAmount
  async getEstimatedBumpForPurchase(
    currentTVL: string,
    deposit: string,
    bumpPurchaseAmount: string,
  ): Promise<string> {
    const estimatedBumpPrice = await this.getEstimatedBumpPrice(deposit);

    const bumpPurchaseAmountNum = FixedNumber.fromString(bumpPurchaseAmount);
    return bumpPurchaseAmountNum
      .divUnsafe(estimatedBumpPrice)
      .mulUnsafe(FixedNumber.fromString('10000'))
      .round(4)
      .toString();
  }

  //Get estimated USDC a user would have to provide to purchase a certain bumpAmount
  async getEstimatedUSDCForBUMP(
    currentTVL: string,
    deposit: string,
    bumpAmount: string,
  ): Promise<string> {
    const estimatedBumpPrice = await this.getEstimatedBumpPrice(deposit);

    const estimatedUSDCValue =
      FixedNumber.from(bumpAmount).mulUnsafe(estimatedBumpPrice);

    return estimatedUSDCValue.round(6).toString();
  }

  getTokenValueInDollars(
    tokenAmount: string,
    currentTokenPrice: string,
  ): string {
    return FixedNumber.fromString(tokenAmount)
      .mulUnsafe(FixedNumber.from(currentTokenPrice))
      .round(2)
      .toString();
  }

  /**
   * It will calculate what amount of USDC will used for BUMP purchase from the total amount.
   * @param percentage What percentage of total amount to be used for BUMP purchase.
   * @param usdcAmount Total amount of USDC that will be sent by user to protocol.
   * @returns Amount of USDC that will be used for BUMP purchase out of total amount.
   */
  calcPercentageOfUSDC(percentage: number, usdcAmount: string): string {
    const amountForBumpPurchase = FixedNumber.fromString(usdcAmount)
      .mulUnsafe(FixedNumber.fromString(percentage.toString()))
      .divUnsafe(FixedNumber.from(100));

    // We have to make sure the result is truncated to six decimal places,
    // NOT rounded up, otherwise amount sent to contract will be incorrect
    const newAmount = amountForBumpPurchase
      .mulUnsafe(FixedNumber.from(1000000))
      .floor()
      .divUnsafe(FixedNumber.from(1000000));

    return newAmount.toString();
  }
  /**
   * It will be used to get estimated BUMP rewards (including dollar value of those rewards)
   * for a particular deposit amount
   * @param deposit
   * @param bumpPurchase
   * @param tokenDetails - The token being used
   * @returns Returns the estimated reward value in both dollars and BUMP.
   */
  async getEstimatedRewards(
    deposit: string,
    bumpPurchase: string,
    tokenDetails: TokenDetails,
  ): Promise<string> {
    const bumpPurchaseAmount: ethers.BigNumber = ethers.utils.parseUnits(
      bumpPurchase,
      tokenDetails.decimal,
    );

    const price = await this.getEstimatedBumpPrice(deposit);

    const amount = bumpPurchaseAmount
      .mul(BigNumber.from('10000000000000000'))
      .div(price.floor().toUnsafeFloat().toString());

    const stablecoinReserve = BumpMarketV2__factory.connect(
      CONTRACTS.CONTRACT_ADDRESS.BumpMarket.address,
      this.infuraServiceProvider as ethers.providers.InfuraProvider,
    );
    // это можно оптимизовать
    const depositDetails = await stablecoinReserve.depositDetails(
      this.etherServiceProvider.currentAccount,
    );
    const swapAllowedPeriod = await stablecoinReserve.swapAllowedPeriod();

    const timestamp = BigNumber.from(Date.now()).div(1000);
    if (
      timestamp > swapAllowedPeriod.start &&
      timestamp < swapAllowedPeriod.end &&
      !depositDetails.timestamp.isZero() &&
      depositDetails.timestamp < swapAllowedPeriod.start
    ) {
      // можно оптимизировать, нужно получать с чейна
      const estimatedRewards = amount.div(10);

      const estimatedBumpRewards: string = this.etherServiceProvider.toDecimal(
        BigNumber.from(estimatedRewards.toString()),
        18,
      );

      return FixedNumber.from(estimatedBumpRewards).round(4).toString();
    }

    return '0';
  }

  /**
   * It will be used to get prospective APR for a particular deposit amount
   * @param amount - The liquidity amount for which we want to calculate rewards
   * @param tokenDetails - The token being used
   * @returns Returns the prospective APR after calculated
   */
  async getProspectiveAPR(
    amount: number,
    tokenDetails: TokenDetails,
  ): Promise<string> {
    const res = await Promise.all([
      this.getMaxBumpPercent(),
      this.getBUSDCUnlockTimestamp(),
      this.getCurrentBumpPrice(),
    ]);
    const maxBumpPercent: number = parseFloat(res[0].toString()) / 100;
    const bumpPurchase = maxBumpPercent * amount;
    const bumpRewards = await this.getEstimatedRewards(
      amount.toString(),
      bumpPurchase.toString(),
      tokenDetails,
    );
    const lockTimeStamp = res[1];
    const unlockDate = new Date(lockTimeStamp * 1000);
    const currentDate = new Date();
    const noOfDaysLeft =
      (unlockDate.getTime() - currentDate.getTime()) / 1000 / 86400;
    const bumpPrice = res[2];
    const purchasedBump =
      (amount * maxBumpPercent) / parseFloat(bumpPrice.toString());
    const APR =
      (((purchasedBump + parseInt(bumpRewards)) * 2.4 -
        amount * maxBumpPercent) *
        365 *
        100) /
      (amount * noOfDaysLeft);
    return APR.toFixed(2);
  }
  /**
   * It will be used to get the amount of token that contract is allowed to spend
   * @param tokenDetails - The token being used
   * @param contractAddress -Contract address for which allowance is being checked
   * @returns Returns the allowance amount after calculation
   */
  async checkAllowance(
    tokenDetails: TokenDetails,
    contractAddress: string,
  ): Promise<string> {
    const userAddress: string =
      await this.etherServiceProvider.getUserAddress();
    const tokenInstance = IERC20__factory.connect(
      tokenDetails.address,
      this.etherServiceProvider.provider?.getSigner(
        0,
      ) as ethers.providers.JsonRpcSigner,
    );
    const amountInBigNumber = await tokenInstance.allowance(
      userAddress,
      contractAddress,
    );
    return await this.etherServiceProvider.toDecimal(
      BigNumber.from(amountInBigNumber.toString()),
      tokenDetails.decimal,
    );
  }

  /**
   * This function is used to burn bUSDC and withdraw USDC from Bumper market.
   * @param amountToBurn Amount of liquidity tokens user wants to burn.
   * @return Returns transaction sent to bump market to withdraw.
   */
  async withdrawFromBumpMarket(
    amountToBurn: string,
  ): Promise<ethers.ContractTransaction> {
    const tokensToBurn: ethers.BigNumber = ethers.utils.parseUnits(
      amountToBurn,
      6,
    );
    const stablecoinReserve = BumpMarketV2__factory.connect(
      CONTRACTS.CONTRACT_ADDRESS.BumpMarket.address,
      this.etherServiceProvider.provider?.getSigner(
        0,
      ) as ethers.providers.JsonRpcSigner,
    );
    return await stablecoinReserve.functions['withdrawLiquidity(uint256)'](
      tokensToBurn.toString(),
    );
  }

  async swapFromBumpMarket(
    amountToSwap: string,
  ): Promise<ethers.ContractTransaction> {
    const tokensToBurn: ethers.BigNumber = ethers.utils.parseUnits(
      amountToSwap,
      6,
    );
    const stablecoinReserve = BumpMarketV2__factory.connect(
      CONTRACTS.CONTRACT_ADDRESS.BumpMarket.address,
      this.etherServiceProvider.provider?.getSigner(
        0,
      ) as ethers.providers.JsonRpcSigner,
    );
    return await stablecoinReserve.swap(tokensToBurn.toString());
  }

  /**
   * This function is used to fetch Levy and Fee percentages
   * @return Returns Fee and levy percentage
   */
  async feeAndLevy(): Promise<{ fee: string; levy: string } | undefined> {
    if (!this.etherServiceProvider.provider) return;
    const bumpMarketContract = BumpMarketV2__factory.connect(
      CONTRACTS.CONTRACT_ADDRESS.BumpMarket.address,
      this.etherServiceProvider.provider?.getSigner(
        0,
      ) as ethers.providers.JsonRpcSigner,
    );
    const feeInBigNumber = await bumpMarketContract.functions['fee()']();
    const fee = await this.etherServiceProvider.toDecimal(
      BigNumber.from(feeInBigNumber.toString()),
      2,
    );
    const levyInBigNumber = await bumpMarketContract.functions['levy()']();
    const levy = await this.etherServiceProvider.toDecimal(
      BigNumber.from(levyInBigNumber.toString()),
      2,
    );
    return { fee, levy };
  }
  async isUserExistingLP(): Promise<boolean | undefined> {
    if (
      !this.infuraServiceProvider ||
      !this.etherServiceProvider.currentAccount
    )
      return;
    const stablecoinReserve = BumpMarketV2__factory.connect(
      CONTRACTS.CONTRACT_ADDRESS.BumpMarket.address,
      this.infuraServiceProvider as ethers.providers.InfuraProvider,
    );

    const depositDetails = await stablecoinReserve.depositDetails(
      this.etherServiceProvider.currentAccount,
    );
    const swapAllowedPeriod = await stablecoinReserve.swapAllowedPeriod();

    return (
      +depositDetails.timestamp !== 0 &&
      depositDetails.timestamp <= swapAllowedPeriod.start
    );
  }
  async startEndPeriod(): Promise<{
    text: 'Ends' | 'Starts';
    time: number;
    start: number;
    end: number;
  }> {
    const stablecoinReserve = BumpMarketV2__factory.connect(
      CONTRACTS.CONTRACT_ADDRESS.BumpMarket.address,
      this.infuraServiceProvider as ethers.providers.InfuraProvider,
    );
    const swapAllowedPeriod = await stablecoinReserve.swapAllowedPeriod();

    if (new Date().getTime() < +swapAllowedPeriod.start * 1000) {
      return {
        text: 'Starts',
        time: +swapAllowedPeriod.start,
        start: +swapAllowedPeriod.start,
        end: +swapAllowedPeriod.end,
      };
    } else {
      return {
        text: 'Ends',
        time: +swapAllowedPeriod.end,
        start: +swapAllowedPeriod.start,
        end: +swapAllowedPeriod.end,
      };
    }
  }
}
