import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const PixelCloseIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 36 36" fontSize="large">
      <rect x="2" y="2" width="2" height="2" fill="black" />
      <rect x="4" width="28" height="2" fill="black" />
      <rect x="4" y="2" width="28" height="2" fill="#00A9BE" />
      <rect x="32" y="2" width="2" height="2" fill="black" />
      <rect y="4" width="2" height="28" fill="black" />
      <rect x="2" y="4" width="2" height="28" fill="#00A9BE" />
      <rect width="28" height="28" transform="translate(4 4)" fill="#004851" />
      <path
        d="M19.1527 17.7944C19.1226 17.7644 19.1057 17.7236 19.1057 17.6811C19.1057 17.6385 19.1226 17.5978 19.1527 17.5677L25.0847 11.6363C25.4598 11.2607 25.4594 10.6522 25.0838 10.2771C24.7082 9.90201 24.0996 9.90244 23.7245 10.2781L17.7931 16.2069C17.7631 16.237 17.7223 16.2539 17.6798 16.2539C17.6373 16.2539 17.5965 16.237 17.5664 16.2069L11.6351 10.2781C11.26 9.9028 10.6517 9.90265 10.2765 10.2777C9.9012 10.6528 9.90105 11.2611 10.2761 11.6363L16.2069 17.5677C16.237 17.5978 16.2539 17.6385 16.2539 17.6811C16.2539 17.7236 16.237 17.7644 16.2069 17.7944L10.2761 23.7265C10.0335 23.9692 9.9388 24.323 10.0277 24.6545C10.1166 24.986 10.3756 25.2448 10.7072 25.3336C11.0387 25.4224 11.3924 25.3275 11.6351 25.0847L17.5664 19.1527C17.5965 19.1226 17.6373 19.1057 17.6798 19.1057C17.7223 19.1057 17.7631 19.1226 17.7931 19.1527L23.7245 25.0847C24.0996 25.46 24.7079 25.4601 25.0831 25.0851C25.4584 24.71 25.4585 24.1017 25.0835 23.7265L19.1527 17.7944Z"
        fill="white"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="9"
        y="9"
        width="17"
        height="17"
      >
        <path
          d="M19.1527 17.7944C19.1226 17.7644 19.1057 17.7236 19.1057 17.6811C19.1057 17.6385 19.1226 17.5978 19.1527 17.5677L25.0847 11.6363C25.4598 11.2607 25.4594 10.6522 25.0838 10.2771C24.7082 9.90201 24.0996 9.90244 23.7245 10.2781L17.7931 16.2069C17.7631 16.237 17.7223 16.2539 17.6798 16.2539C17.6373 16.2539 17.5965 16.237 17.5664 16.2069L11.6351 10.2781C11.26 9.9028 10.6517 9.90265 10.2765 10.2777C9.9012 10.6528 9.90105 11.2611 10.2761 11.6363L16.2069 17.5677C16.237 17.5978 16.2539 17.6385 16.2539 17.6811C16.2539 17.7236 16.237 17.7644 16.2069 17.7944L10.2761 23.7265C10.0335 23.9692 9.9388 24.323 10.0277 24.6545C10.1166 24.986 10.3756 25.2448 10.7072 25.3336C11.0387 25.4224 11.3924 25.3275 11.6351 25.0847L17.5664 19.1527C17.5965 19.1226 17.6373 19.1057 17.6798 19.1057C17.7223 19.1057 17.7631 19.1226 17.7931 19.1527L23.7245 25.0847C24.0996 25.46 24.7079 25.4601 25.0831 25.0851C25.4584 24.71 25.4585 24.1017 25.0835 23.7265L19.1527 17.7944Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)"></g>
      <rect x="32" y="4" width="2" height="28" fill="#012A2E" />
      <rect x="34" y="4" width="2" height="28" fill="black" />
      <rect x="2" y="32" width="2" height="2" fill="black" />
      <rect x="4" y="32" width="28" height="2" fill="#012A2E" />
      <rect x="4" y="34" width="28" height="2" fill="black" />
      <rect x="32" y="32" width="2" height="2" fill="black" />
    </SvgIcon>
  );
};
