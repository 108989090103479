import { makeStyles, Box, styled } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import React from 'react';

import { ExtraSmallBoldTypography, MediumBoldTypography } from './Typography';

import { theme } from '../../providers/AppThemeProvider';
import { useAppSelector } from '../../state/hooks';
import { isCoinBalanceZero } from '../../utils/helpers';
import { WarningText } from '../DepositDashboard/BalanceCard';

interface IDataFieldProps {
  name: string;
  value: string;
  unit?: string;
  currentBumpPriceInDollars?: number | string;
}

const useStyles = makeStyles((theme) => ({
  label: {
    fontFamily: theme.font.secondary,
    fontWeight: 700,
    fontSize: '13px',
    marginBottom: '4px',
    cursor: 'default',
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
  },
  light: {
    color: theme.palette.other.LabelText,
  },
  inline: {
    display: 'inline',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
}));

export const DataField: React.FC<IDataFieldProps> = (props) => {
  const classes = useStyles();

  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div>
      <div className={clsx(classes.label, classes.light)}>{props.name}</div>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        flexWrap="wrap"
      >
        <MediumBoldTypography
          secondaryFont
          className={classes.inline}
          style={{ fontSize: smallScreen && true ? '14px' : '16px' }}
        >
          {props.value}
        </MediumBoldTypography>
        {props.unit && (
          <ExtraSmallBoldTypography
            className={clsx(classes.light, classes.inline)}
          >
            <TokenBalance>{' ' + props.unit}</TokenBalance>
          </ExtraSmallBoldTypography>
        )}
      </Box>
    </div>
  );
};

export const SecondDataField: React.FC<IDataFieldProps> = (props) => {
  const classes = useStyles();
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const bumpBalance = useAppSelector((state) => state.bumpDetails.bumpBalance);
  return (
    <div>
      {smallScreen && isCoinBalanceZero(bumpBalance) ? (
        <WarningText>
          Want some rad tokens? Press button to continue...
        </WarningText>
      ) : (
        <>
          <Box
            style={{ margin: '0.4em' }}
            className={clsx(classes.label, classes.light)}
          >
            {props.name}
          </Box>
          <div>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              flexWrap="wrap"
              style={{
                margin: '0.3em',
                fontSize: smallScreen && true ? '14px' : '16px',
              }}
            >
              <MediumBoldTypography secondaryFont className={classes.inline}>
                {props.value}
              </MediumBoldTypography>

              {props.unit && (
                <ExtraSmallBoldTypography
                  className={clsx(classes.light, classes.inline)}
                >
                  <TokenBalance>{' ' + props.unit}</TokenBalance>
                </ExtraSmallBoldTypography>
              )}
              {!smallScreen && (
                <MediumBoldTypography>
                  &nbsp;($
                  {props.currentBumpPriceInDollars})
                </MediumBoldTypography>
              )}
              {smallScreen && (
                <MediumBoldTypography className={classes.inline}>
                  ($
                  {props.currentBumpPriceInDollars})
                </MediumBoldTypography>
              )}
            </Box>
          </div>
        </>
      )}
    </div>
  );
};

const TokenBalance = styled(Box)(({ theme }) => ({
  paddingTop: '4px',
  marginLeft: '.25rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
    marginRight: '.5rem',
  },
}));
