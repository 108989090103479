import { ethers } from 'ethers';
import React from 'react';

import { useAppSelector } from '../../../state/hooks';
import { VestingClaimEvent } from '../HistoryModal';

// HOC for vesting components
export const wrapVestingComponent =
  (Component: React.FC<any>): React.FC<any> =>
  (props: { vestingClaimEvents?: VestingClaimEvent[] }) => {
    const { vestingClaimEvents } = props;

    const vestingData = useAppSelector((state) => state.merkleTree.vesting);
    const stakingData = useAppSelector((state) => state.merkleTree.staking);

    if (!vestingData) return null;

    const claimed =
      vestingClaimEvents?.reduce(
        (sum, curr) => sum.add(curr.claimedAmount),
        ethers.constants.Zero,
      ) ?? vestingData.claimed;

    const { availableToClaim, unvested } = vestingData;

    const vested = availableToClaim;
    const isDisplayVestingAndStaking = stakingData
      ? ethers.utils
          .parseUnits(stakingData.amountWithoutStaking.toString())
          .sub(claimed)
          .gt(0)
      : true;

    const renderCondition =
      (!unvested.isZero() || !vested.isZero()) && isDisplayVestingAndStaking;

    const unvestedDisplay = stakingData
      ? unvested
          .sub(ethers.utils.parseUnits(stakingData.amount + '', 18))
          .gt(ethers.constants.Zero)
        ? unvested.sub(ethers.utils.parseUnits(stakingData.amount + '', 18))
        : ethers.constants.Zero
      : unvested;

    const vestedDisplay = !stakingData
      ? vested
      : unvestedDisplay.isZero()
      ? ethers.utils.parseUnits(stakingData.amountWithoutStaking.toString())
      : vested;

    if (renderCondition) {
      return (
        <Component
          {...props}
          unvested={parseFloat(ethers.utils.formatUnits(unvestedDisplay))}
          vested={parseFloat(ethers.utils.formatUnits(vestedDisplay))}
          claimed={parseFloat(ethers.utils.formatUnits(claimed))}
        />
      );
    } else {
      return null;
    }
  };
