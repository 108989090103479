import { Box, styled } from '@material-ui/core';
import React from 'react';
import Lottie from 'react-lottie';

import loadingAnimation from '../../assets/loadingAnimation.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const LoadingScreen: React.FC = () => {
  return (
    <ContainerBox>
      <Box margin="auto">
        <Lottie options={defaultOptions} height={144} width={144} />
      </Box>
    </ContainerBox>
  );
};

const ContainerBox = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  minWidth: '320px',
  transform: 'translate(-50%, -50%)',
});
