import React from 'react';
import { Redirect } from 'react-router-dom';

import { LoginSection } from '../components/ConnectWallet/LoginSection';
import { Routes } from '../config/routes';
import {
  removeWeb3WalletType,
  setWeb3WalletType,
  Web3WalletType,
} from '../config/wallets';
import {
  useBsc,
  useInjected,
  useFrame,
  useLattice,
  useLedger,
  usePortis,
  useTrezor,
  useWalletConnect,
  useWalletLink,
} from '../hooks/useWallet';

export const ConnectWallet: React.FC = () => {
  const {
    active: isConnectedInjected,
    tried: triedInjected,
    connectWallet: connectInjected,
  } = useInjected();
  const {
    active: isConnectedWalletConnect,
    tried: triedWalletConnect,
    connectWallet: connectWalletConnect,
  } = useWalletConnect();
  const {
    active: isConnectedBsc,
    tried: triedBsc,
    connectWallet: connectBsc,
  } = useBsc();
  const {
    active: isConnectedFrame,
    tried: triedFrame,
    connectWallet: connectFrame,
  } = useFrame();
  const {
    active: isConnectedLedger,
    tried: triedLedger,
    connectWallet: connectLedger,
  } = useLedger();
  const {
    active: isConnectedTrezor,
    tried: triedTrezor,
    connectWallet: connectTrezor,
  } = useTrezor();
  const {
    active: isConnectedLattice,
    tried: triedLattice,
    connectWallet: connectLattice,
  } = useLattice();
  const {
    active: isConnectedWalletLink,
    tried: triedWalletLink,
    connectWallet: connectWalletLink,
  } = useWalletLink();
  const {
    active: isConnectedPortis,
    tried: triedPortis,
    connectWallet: connectPortis,
  } = usePortis();

  const onConnectInjected = async () => {
    await connectInjected()
      .then(() => setWeb3WalletType(Web3WalletType.INJECTED))
      .catch(() => removeWeb3WalletType());
  };
  const onConnectWalletConnect = async () => {
    await connectWalletConnect()
      .then(() => setWeb3WalletType(Web3WalletType.WALLET_CONNECT))
      .catch(() => removeWeb3WalletType());
  };
  const onConnectBsc = async () => {
    await connectBsc()
      .then(() => setWeb3WalletType(Web3WalletType.BSC))
      .catch(() => removeWeb3WalletType());
  };
  const onConnectFrame = async () => {
    await connectFrame()
      .then(() => setWeb3WalletType(Web3WalletType.FRAME))
      .catch(() => removeWeb3WalletType());
  };
  const onConnectLedger = async () => {
    await connectLedger()
      .then(() => setWeb3WalletType(Web3WalletType.LEDGER))
      .catch(() => removeWeb3WalletType());
  };
  const onConnectTrezor = async () => {
    await connectTrezor()
      .then(() => setWeb3WalletType(Web3WalletType.TREZOR))
      .catch(() => removeWeb3WalletType());
  };
  const onConnectLattice = async () => {
    await connectLattice()
      .then(() => setWeb3WalletType(Web3WalletType.LATTICE))
      .catch(() => removeWeb3WalletType());
  };
  const onConnectWalletLink = async () => {
    await connectWalletLink()
      .then(() => setWeb3WalletType(Web3WalletType.WALLET_LINK))
      .catch(() => removeWeb3WalletType());
  };
  const onConnectPortis = async () => {
    await connectPortis()
      .then(() => setWeb3WalletType(Web3WalletType.PORTIS))
      .catch(() => removeWeb3WalletType());
  };

  const isConnected =
    isConnectedInjected ||
    isConnectedWalletConnect ||
    isConnectedBsc ||
    isConnectedFrame ||
    isConnectedLedger ||
    isConnectedTrezor ||
    isConnectedLattice ||
    isConnectedWalletLink ||
    isConnectedPortis;
  const tried =
    triedInjected ||
    triedWalletConnect ||
    triedBsc ||
    triedFrame ||
    triedLedger ||
    triedTrezor ||
    triedLattice ||
    triedWalletLink ||
    triedPortis;

  const renderPage = () => {
    if (isConnected && tried) {
      return <Redirect to={Routes.DepositDashboard}></Redirect>;
    } else {
      if (!isConnected && tried) {
        return (
          <LoginSection
            onConnectInjected={onConnectInjected}
            onConnectWalletConnect={onConnectWalletConnect}
            onConnectBsc={onConnectBsc}
            onConnectLedger={onConnectLedger}
            onConnectTrezor={onConnectTrezor}
            onConnectLattice={onConnectLattice}
            onConnectWalletLink={onConnectWalletLink}
            onConnectPortis={onConnectPortis}
            onConnectFrame={onConnectFrame}
          />
        );
      }
    }
    return <></>;
  };

  return renderPage();
};
