import { InputAdornment } from '@material-ui/core';
import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

import { CoinIcons } from './icons/CoinIcons';
import { PixelTextField } from './TextField';
import { SmallBoldTypography } from './Typography';

import { ICoin } from '../../interfaces/ICoin';
export interface IDecimalNumberFieldProps {
  token: ICoin;
}

export const DecimalNumberField: React.FC<
  NumberFormatProps & IDecimalNumberFieldProps
> = (props) => {
  return (
    <NumberFormat
      customInput={PixelTextField}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" style={{ borderRadius: 0 }}>
            <SmallBoldTypography
              style={{ lineHeight: 'inherit' }}
              color="textSecondary"
            >
              {props.token.symbol}
            </SmallBoldTypography>
          </InputAdornment>
        ),
        startAdornment: (
          <InputAdornment position="start" style={{ borderRadius: 0 }}>
            <img
              style={{
                width: '1.5rem',
                height: '1.5rem',
                maxWidth: '1.5rem',
                maxHeight: '1.5rem',
              }}
              src={CoinIcons[props.token.symbol]}
            />
          </InputAdornment>
        ),
        placeholder: '0.0000',
      }}
      decimalScale={4}
      fixedDecimalScale={true}
      allowNegative={false}
      isNumericString={true}
      {...props}
    />
  );
};
