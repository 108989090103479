import { Box } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useState, useEffect } from 'react';
import { NumberFormatValues } from 'react-number-format';

import { ICoin } from '../../interfaces/ICoin';
import { theme } from '../../providers/AppThemeProvider';
import { useAppSelector } from '../../state/hooks';
import {
  floorTokenValue,
  formatStringifyNumberToDot,
} from '../../utils/helpers';
import { DecimalNumberField } from '../shared/DecimalNumberField';
import {
  ContainerDetails,
  RemainingAmount,
} from '../shared/styles/GlobalSections';
import { Section } from '../shared/styles/Section';
import { WhiteSmallRoboto } from '../shared/styles/TypographyRoboto';
import { ToggleButton, ToggleButtonGroup } from '../shared/ToggleButton';
import { LargeBoldTypography } from '../shared/Typography';

enum InputTypes {
  Slider,
  Input,
}

interface ILiquidityDepositDetailsProps {
  liquidityValue: string;
  setLiquidityValue: React.Dispatch<React.SetStateAction<string>>;
  token: ICoin;
  text: string;
  title: string;
  isWithdraw?: boolean;
}

const PERCENT_OPTIONS = [10, 25, 50, 75, 90, 100];
export const DepositDetails: React.FC<ILiquidityDepositDetailsProps> = ({
  liquidityValue,
  setLiquidityValue,
  token,
  text,
  title,
  isWithdraw,
}) => {
  // FIXME: Bad code
  const [selectedPercent, setSelectedPercent] = useState<number>(0);

  const coin = useAppSelector((state) => state.coin.coinDetails[token.symbol]);
  const onLiquidityChange = (inputType: InputTypes, value: string) => {
    switch (inputType) {
      case InputTypes.Input: {
        const liqValue = value ? parseFloat(value) : 0;
        if (value === '') {
          setLiquidityValue('0');
        } else {
          setLiquidityValue(floorTokenValue(liqValue));
        }
        break;
      }
    }
  };
  const handleToggleButtonChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: number | null,
  ) => {
    if (newValue !== null) {
      setSelectedPercent(newValue);
      setLiquidityValue(
        ((parseFloat(coin.balance) * newValue) / 100).toString(),
      );
    }
  };

  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    const percent = isWithdraw ? 10 : 100;
    setSelectedPercent(percent);
    setLiquidityValue(((parseFloat(coin.balance) * percent) / 100).toString());
  }, []);

  return (
    <Section>
      <Box>
        <LargeBoldTypography>{title}</LargeBoldTypography>
        <Box mt={1}>
          <WhiteSmallRoboto color="secondary">{text}</WhiteSmallRoboto>
        </Box>
        <Box mt={4}>
          <ToggleButtonGroup
            exclusive
            value={selectedPercent}
            onChange={handleToggleButtonChange}
          >
            {PERCENT_OPTIONS.map((pOpt, i) => (
              <ToggleButton key={i} value={pOpt}>
                {pOpt}%
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
        {!smallScreen && (
          <ContainerDetails mt={3}>
            <DecimalNumberField
              value={formatStringifyNumberToDot(liquidityValue)}
              onChange={(event) => {
                onLiquidityChange(InputTypes.Input, event.target.value);
              }}
              isAllowed={(val: NumberFormatValues) => {
                const { floatValue } = val;
                return floatValue
                  ? floatValue <= parseFloat(coin.balance)
                  : true;
              }}
              token={token}
            />
            <RemainingAmount color="textSecondary">
              Remaining Balance:{' '}
              {liquidityValue
                ? formatStringifyNumberToDot(
                    '' +
                      (parseFloat(coin.balance) - parseFloat(liquidityValue)),
                  )
                : formatStringifyNumberToDot(coin.balance)}{' '}
              <span style={{ fontWeight: 700 }}>{token.symbol}</span>
            </RemainingAmount>
          </ContainerDetails>
        )}
        {smallScreen && (
          <ContainerDetails>
            <RemainingAmount color="textSecondary">
              Remaining Balance:{' '}
              {liquidityValue
                ? formatStringifyNumberToDot(
                    '' +
                      (parseFloat(coin.balance) - parseFloat(liquidityValue)),
                  )
                : formatStringifyNumberToDot(coin.balance)}{' '}
              <span style={{ fontWeight: 700 }}>{token.symbol}</span>
            </RemainingAmount>
            <DecimalNumberField
              value={formatStringifyNumberToDot(liquidityValue)}
              onChange={(event) => {
                onLiquidityChange(InputTypes.Input, event.target.value);
              }}
              isAllowed={(val: NumberFormatValues) => {
                const { floatValue } = val;
                return floatValue
                  ? floatValue <= parseFloat(coin.balance)
                  : true;
              }}
              token={token}
            />
          </ContainerDetails>
        )}
      </Box>
    </Section>
  );
};
