import { gql as gqlLpp } from '@apollo/client';

const BUMPER_STATUS_DATA = gqlLpp`
  query GetBumperStatusData {
    dailyReserveDatas(orderBy: id, orderDirection: desc, first: 5) {
      id
      reserveBalance
    }
  }
`;

const MAKER_DATA = gqlLpp`
  query GetMakerData($makerAddress: String) {
    maker(id: $makerAddress) {
      id
      totalBumpRewarded
      currentAPR
    }
    _meta {
      block {
        number
      }
    }
  }
`;

const GET_CLAIM = gqlLpp`
  query getClaim($recipient: String) {
    claimAmounts(recipient: $recipient) {
      timestamp
      amount
      totalClaimed
    }
  }
`;

export { BUMPER_STATUS_DATA, MAKER_DATA, GET_CLAIM };
