import { Box, styled } from '@material-ui/core';
import React, { FC } from 'react';

import { BrianImg, CloudImg, CloudText, Dialogue } from './styles/Brian';

import BigBrianGif from '../../assets/BumpercoinBrian.gif';
import BrianGif from '../../assets/BumpercoinBrian.gif';
import Cloud from '../../assets/cloud.svg';

export const BrianBottomPos: FC<{
  text: string;
  style?: any;
  classes?: any;
  alternativePos?: boolean;
  staticPos?: boolean;
  bigBrian?: boolean;
  xsMargin?: string;
}> = (props) => {
  const { style, classes, bigBrian } = props;

  return (
    <Box className={classes} style={style}>
      <div style={{ position: 'relative' }}>
        <BrianImg src={bigBrian ? BigBrianGif : BrianGif} />
        <StyledDialogue bigBrian={bigBrian}>
          <CloudImg src={Cloud} />
          <CloudText>{props.text}</CloudText>
        </StyledDialogue>
      </div>
    </Box>
  );
};

const StyledDialogue = styled(Dialogue)({
  top: '-4em',
});
