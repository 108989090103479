import { createAsyncThunk } from '@reduxjs/toolkit';

import { CONTRACTS, TokenList } from '../../config/contractAddresses';
import {
  IProspectiveApr,
  IProspectiveAprProps,
} from '../../interfaces/IProspectiveApr';
import { MakerService } from '../../services/makerService';

export const fetchProspectiveApr = createAsyncThunk<
  IProspectiveApr,
  IProspectiveAprProps
>('fetchProspectiveApr', async (props: IProspectiveAprProps) => {
  const makerService = MakerService.getInstance();

  const res = await makerService.getProspectiveAPR(
    props.amount,
    CONTRACTS.TOKEN_DETAILS[props.token.symbol as keyof TokenList],
  );
  return {
    prospectiveApr: res || '',
  };
});
