import { Box, Link, LinkProps, styled } from '@material-ui/core';
import React from 'react';

import Typography from '../Typography';

export const CoinIcon = styled('img')(() => ({
  width: 80,
  height: 80,
  position: 'absolute',
  top: '-35px',
}));

export const CoinIconSmall = styled('img')(({ theme }) => ({
  width: 58,
  height: 58,
  position: 'absolute',
  top: '-35px',

  [theme.breakpoints.down('xs')]: {
    width: 48,
    height: 48,
  },
}));

export const SymbolTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    fontSize: '1.25rem',
  },
}));

export const NumberTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    fontSize: '1.5rem',
  },
}));

export const DepositValue = styled(NumberTypography)(({ theme }) => ({
  fontFamily: theme.font.secondary,
  marginRight: '.5rem',
}));

export const GreenLink = styled((props: LinkProps) => {
  return <Link underline="none" {...props} />;
})(({ theme }) => ({
  color: theme.palette.other.BrightGreen,
}));

export const ClaimVestingValue = styled(Typography)(({ theme }) => ({
  fontFamily: theme.font.secondary,
  marginRight: '.5rem',
  [theme.breakpoints.down('xs')]: {
    fontSize: '1.5rem',
  },
}));

export const ClaimVestingSummary = styled(Box)(() => ({
  paddingTop: '24px',
  borderTop: '1px solid rgba(255, 255, 255, 0.1)',
  color: '#8CA6AE',
  fontFamily: 'Roboto Mono',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 400,
}));

export const ConfirmationDivider = styled('div')(() => ({
  width: '100%',
  height: '1px',
  background: 'rgba(255, 255, 255, 0.1)',
  zIndex: 5,
}));
