import React from 'react';
import { useLocation } from 'react-router-dom';

import { JupiterBackground } from './JupiterBackground';
import { MoonBackground } from './MoonBackground';

import { Routes } from '../../config/routes';

export const BackgroundImage: React.FC = (props) => {
  return <BackgroundSelector>{props.children}</BackgroundSelector>;
};

const BackgroundSelector: React.FC = (props) => {
  const location = useLocation();

  const changeBackground = () => {
    if (
      location.pathname.includes(Routes.AddLiquidity) ||
      location.pathname.includes(Routes.Vesting)
    ) {
      return <MoonBackground {...props}></MoonBackground>;
    } else {
      return <JupiterBackground {...props}></JupiterBackground>;
    }
  };

  return changeBackground();
};
